import { 
    mdiFerry,
    mdiFormatQuoteOpen,
    mdiCardAccountPhoneOutline,
    mdiStoreOutline,
    mdiCubeOutline,
    mdiTruckCargoContainer, 
    mdiClipboardTextClockOutline
} from "@mdi/js";

export const dashboardIcons = {
    "OPEN_ORDERS":mdiClipboardTextClockOutline,
    "BOAT_ORDERS":mdiFerry,
    "QUOTES":mdiFormatQuoteOpen,
    "LEADS":mdiCardAccountPhoneOutline,
    "DEALERS":mdiStoreOutline,
    "DEALER_INVENTORY":mdiCubeOutline,
    "RETAIL_SOLD":mdiTruckCargoContainer
}

