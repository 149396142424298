import React, { useEffect } from "react";
import { mdiMenu } from "@mdi/js";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { useAppDispatch } from "../../../store/hooks/hook";
import { NavMode } from "../../../helpers/enums/Layout";
import { changeNavType } from "../../../store/slices/Layout/LayoutSlice";
import UserHeader from "./UserHeader";
import HumburgerIcon from "../../../_assets/images/sidebar-icons/hamburger-icon.svg";
import CloseIcon from "../../../_assets/images/sidebar-icons/close-icon.svg";

interface AppHeaderProps {
  navMode: string;
}

const AppHeader = (props: AppHeaderProps) => {
  const dispatch = useAppDispatch();

  const navIconToggle = props.navMode === NavMode.DEFAULT ? mdiClose : mdiMenu;

  function toggleNavMode() {
    switch (props.navMode) {
      case NavMode.COLLAPSED:
        dispatch(changeNavType(NavMode.COLLAPSED_OPEN));
        break;
      case NavMode.COLLAPSED_OPEN:
        dispatch(changeNavType(NavMode.COLLAPSED));
        break;
      case NavMode.DEFAULT:
        dispatch(changeNavType(NavMode.COMPACT));
        break;
      case NavMode.COMPACT:
        dispatch(changeNavType(NavMode.DEFAULT));
        break;
      //default - do nothing.
    }
  }

  return (
    <div className="header-topbar">
      <div className="header-body">
        <div className="d-flex">
          {props.navMode !== NavMode.LEADS && (
          <div className="d-inline-block header-nav-toggle">
            <button
              className="btn header-item"
              onClick={() => {
                toggleNavMode();
              }}
            >
              <img
                src={
                  props.navMode === NavMode.DEFAULT ? CloseIcon : HumburgerIcon
                }
              />
            </button>
          </div>
          )}
          
          <span>
            <img className="logo-image" alt=""></img>
          </span>
        </div>
        {props.navMode !== NavMode.LEADS && (
        <div className="d-flex">
          <UserHeader />
        </div>
        )}
        
      </div>
    </div>
  );
};

export default AppHeader;
