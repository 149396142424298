import { Button, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import SubmitButtonGroup from "../../../../components/button/SubmitButtonGroup";
import { MainCard } from "../../../../components/surface/MainCard";
import { SectionBox } from "../../../../components/surface/SectionBox";
import { LayeredModel } from "../../../../models/Configuration";
import defaultImg from "../../../../_assets/images/placeholder-transparent.png";
import uploadFile from '../../utils/UploadFile';
import uploadPerspectiveImage from "../../utils/uploadPerspectiveImage";

type IProps = {
    title?: string;
    perspective: LayeredModel;
    closeModal:(perspective: LayeredModel) => any;
}

const UpdatePerspective = (props: IProps) =>
{

    const [file, setFile] = useState<File>();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            record: { ...props.perspective, layerOrder: props.perspective.layerOrder ?? 1 },
            file: file,
            submit: null
        },
        onSubmit: (values, {setSubmitting, setErrors}) => {
            
            if(values.record !== null && values.record !== undefined) {
                setSubmitting(true);

                const result = uploadPerspectiveImage(values.record, values.file);
                result.then((response: LayeredModel) => {
                    props.closeModal(response);
                })
                .catch((error) => {
                    if (error.response?.data?.length > 0) {
                        setErrors({ submit: error.response?.data[0].errorMessage ?? "Unexpected Error" })
                    }
                    else {
                        setErrors({ submit: "Unexpected Error" });
                    }
                })
                .finally(() => {
                    setSubmitting(false);
                })
            }
            
        }
    })

    const handleBrowseImage = async(event: React.ChangeEvent<HTMLInputElement>) => {
        const result:any = await uploadFile(event);
        formik.setFieldValue("record.mediaUrl", result?.imageSrc)
        formik.setFieldValue("file",result?.file);
    }

    return (
        <>


            <form onSubmit={formik.handleSubmit}>

                <MainCard>
                    <SectionBox>
                        <Typography variant="h6" >{props.perspective?.boatOptionChoiceId ? "Choice " : "Model " + " - " + props.perspective?.perspectiveName + " View"}</Typography>

                        <Grid container spacing={2}>
                    
                            <Grid item xs={12}>
                                <img style={{ maxWidth: "400px" }} src={formik.values.record?.mediaUrl || defaultImg} alt="slide-img" />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="text" component="label" >
                                    Browse Image
                                    <input hidden accept="image/*" type="file" onChange={(event) => handleBrowseImage(event)} />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>

                                <InputLabel htmlFor="order">
                                    Order *
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="order"
                                    {...formik.getFieldProps("record.layerOrder")}
                                />

                            </Grid>
                            {formik.errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error id="submit-helper">{formik.errors.submit}</FormHelperText>
                                </Grid>
                            )}
                        </Grid>
                        <Stack justifyContent="flex-end" mt={3} direction="row" spacing={2} >
                            <Button
                                variant="outlined"
                                onClick={() => props.closeModal(formik.values.record)}
                                disabled = {formik.isSubmitting}
                                color="secondary"
                            >
                                {" Close "}
                            </Button>
                            <Button variant="contained" type="submit" disabled={formik.isSubmitting}>Save Changes</Button>
                        </Stack>

                    </SectionBox>

                </MainCard>

            </form>

        </>
    )
}

export default UpdatePerspective;