import { createSlice } from "@reduxjs/toolkit";
import {
  seriesListState,
  modelListState,
  lookupsState,
  ruleListState,
  orderDetailsState,
  IRule,
  IRuleAction,
  ISpecialCaseSelectOption,
} from "../../../models/BoatOrdering";
import { AppThunk } from "../../store";
import {
  getRuleList,
  getSeriesList,
  getModelList,
  getOrderDetails,
  getLookupsDetails,
  //getDealerDetails,
} from "./BoatOrderingSelector";
import { UiWidget } from "../../../helpers/enums/UiWidget";

interface boatState {
  seriesList: any;
  modelList: any;
  ruleList: ruleListState | null;
  appLoadComplete: boolean;
  lookupsData: any;
  selectedModal: any;
  selectedSeriesMsrp: number;
  currentStep: number;
  breadCrumbTitle?: string;
  panelImage?: any;
  accentImage?: any;
  completedSteps: {
    [k: number]: boolean;
  };
  boatForm: {
    dealer: string | number;
    location: string | number;
    orderType: string | number;
    delivery: string | number;
    term: string | number;
    freight: string | number;
    customerPO: string;
    customerNotes: string;
    showDate: any;
    retailCustomer: string;
    trailer: string;
    trailerType: string;
  };
  leadsForm: {
    lastName: string;
    firstName: string;
    email: string;
    phone: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    comment: string;
  };
  motor: {
    name: string;
    price: number;
    partNumber: string;
  };
  orderDetails?: any;
}

const initialState: boatState = {
  seriesList: [],
  modelList: [],
  ruleList: null,
  lookupsData: null,
  appLoadComplete: false,
  selectedModal: "",
  selectedSeriesMsrp: 0,
  currentStep: 0,
  breadCrumbTitle: "",
  panelImage: null,
  accentImage: null,
  completedSteps: {},
  boatForm: {
    dealer: "",
    location: "",
    orderType: "",
    delivery: "",
    term: "",
    freight: "",
    customerPO: "",
    customerNotes: "",
    showDate: null,
    retailCustomer: "",
    trailer: "No",
    trailerType: ""
  },
  leadsForm: {
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    zipcode: "",
    comment: "",
  },
  motor: {
    name: "",
    price: 0,
    partNumber: "",
  },
  orderDetails: null,
};

const slice = createSlice({
  name: "boat ordering",
  initialState,
  reducers: {
    getSeriesAction: (state, action) => {
      state.seriesList = action.payload;
      state.appLoadComplete = true;
      state.selectedModal = false;
      state.completedSteps = {};
      state.panelImage = null;
      state.accentImage = null;
      state.boatForm = initialState.boatForm;
      state.orderDetails = initialState.orderDetails;
    },

    getModelsAction: (state, action) => {
      state.modelList = action.payload;
      state.appLoadComplete = true;
      state.selectedModal = false;
      state.panelImage = initialState.panelImage;
      state.accentImage = initialState.accentImage;
      state.motor = initialState.motor;
      const title = action.payload[0]?.series;
      
      if(state.modelList && state.modelList.length > 0) {
        //msrp is derived from series but set on the returned model list. This is because the state.serieslist does not hold its state
        //if there is a refresh or if the user navigates directly to the model page (example: trying to navigate directly to /boat/model/3).
        state.selectedSeriesMsrp = state.modelList[0].msrp;
      }

      if (window?.location?.pathname?.includes("boat"))
        if (title?.length > 0) state.breadCrumbTitle = title;
    },

    getRuleAction: (state, action) => {
      state.ruleList = action.payload;
    },

    getLookupsAction: (state, action) => {
      state.lookupsData = action.payload;
      state.appLoadComplete = true;
    },

    appLoadCompleteAction: (state, action) => {
      state.appLoadComplete = action.payload;
    },

    getOrderDetailsAction: (state, action) => {
      state.orderDetails = action.payload;
    },

    setSelectedModal: (state, action) => {
      state.completedSteps = initialState.completedSteps;
      state.panelImage = initialState.panelImage;
      state.accentImage = initialState.accentImage;
      state.boatForm = initialState.boatForm;
      state.leadsForm = initialState.leadsForm;
      state.motor = initialState.motor;
      let modal = JSON.parse(JSON.stringify(state.modelList[action.payload.id]));
      
      const orderDetails = JSON.parse(JSON.stringify(state.orderDetails));

      var rulefilter: IRule[] | null = JSON.parse(JSON.stringify(state.ruleList));
      const rules = rulefilter != null ? rulefilter.filter(x => x.revisionId == modal.revisionId) : null;
      
      let specialruleOption: any;

      modal?.sections?.forEach((section: any) => {
        section?.options?.forEach((option: any) => {
          if (orderDetails) {

            option?.choices?.forEach((choice: any) => {

              choice.preSelected = false;
              if(typeof orderDetails?.selectedOptions[choice.importKey] === "string") {

                const selectedChoice: string = orderDetails?.selectedOptions[choice.importKey]
                const compareChoice: string = choice.value;

                if(selectedChoice.toLowerCase() === compareChoice.toLowerCase()) {
                  choice.preSelected = true;

                  if(specialruleOption === undefined && choice.importKey === "rdoHullSide") {
                    specialruleOption = option;
                  }

                }
              }
            });
          }
          const hasPreselected: any = option?.choices?.filter(
            (choice: any) => choice.preSelected || choice.selected
          );

          option.choices?.forEach((choice: any) => {
            choice["isDisabled"] = false;
            choice["isVisible"] = true;
          });

          if (!orderDetails && hasPreselected?.length === 0) {

          } else {
            const panel = hasPreselected?.find(
              (el: any) => el.importKey === "rdoPanel"
            );
            state.panelImage = panel?.layeredMedia[0];
            const accent = hasPreselected?.find(
              (el: any) => el.importKey === "rdoAccent"
            );

            state.accentImage = accent?.layeredMedia[0];
          }
        });
      });

      if(specialruleOption !== undefined) {
        applySpecialCases(modal, specialruleOption);
      }

      // This method for rules
      modal = applyRules(modal, rules, null);

      // apply validation for radio buttons
      modal = applyValidations(modal);

      // Calculate total price
      const totalPrice = calculateTotalPrice(modal);

      modal.totalPrice = totalPrice + modal.price;
      state.selectedModal = modal;
      state.currentStep = 0;
      state.completedSteps = {};
    },

    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },

    setCompletedSteps: (state, action) => {
      state.completedSteps = action.payload;
    },

    setBoatBreadCrumbTitle: (state, action) => {
      state.breadCrumbTitle = action.payload;
    },

    setBoatForm: (state, action) => {
      state.boatForm = action.payload;
    },

    setLeadsForm: (state, action) => {
      state.leadsForm = action.payload;
    },

    resetBoatStates: (state, action) => {
      state = initialState;
    },

    setMotor: (state, action) => {
      const lookupsData = JSON.parse(JSON.stringify(state.lookupsData));
      const motor = lookupsData?.motorList?.find(
        (el: any) => el.partNumber === action.payload
      );
      state.motor = motor?.partNumber ? motor : initialState.motor;
    },

    updateSelectedModal: (state, action) => {
      const { val, optionIdx } = action.payload;
      let modal = JSON.parse(JSON.stringify(state.selectedModal));
      //const rules = JSON.parse(JSON.stringify(state.ruleList));

      var rulefilter: IRule[] | null = JSON.parse(JSON.stringify(state.ruleList));
      const rules = rulefilter != null ? rulefilter.filter(x => x.revisionId == modal.revisionId) : null;

      const option: any = modal?.sections[state.currentStep]?.options[optionIdx];

      option?.choices?.forEach((c: any) => {

        switch (option.uiWidget) {
          case UiWidget.DropdownList:
            c.preSelected = false;
            if (c.id === val) {
              c.preSelected = !c.preSelected;
            }
            break;
          case UiWidget.ColorSwatch:
            c.preSelected = false;
            if (c.id === val) {
              c.preSelected = !c.preSelected;
            }
            break;
          case UiWidget.ChoiceList:
            c.preSelected = false;
            val?.forEach((id: number) => {
              if (id === c.id) {
                c.preSelected = true;
              }
            });
            break;
          case UiWidget.CheckBox:
            if (c.id === val) {
              c.preSelected = !c.preSelected;
            }
            break;
          case UiWidget.RadioButton:
            c.preSelected = false;
            if (c.id === val) {
              c.preSelected = !c.preSelected;
            }
            break;
          case UiWidget.RadioCheckCombo:
            if (c.importKey.includes("chk") || c.importKey.includes("cmb")) {
              if (c.id === val) {
                c.preSelected = !c.preSelected;
              }
            } else if (c.importKey.includes("rdo")) {
              /*
                First find the radio button from the array and match with selected valaue
                If the selected value matches with rdoList 
                Deselect the all radio buttons and made selection selected radio button
              */
              const rdoList = option?.choices?.filter((choice: any) =>
                choice.importKey.includes("rdo")
              );

              const hasRdo = rdoList?.find((rdo: any) => rdo.id === val);
              if (hasRdo?.id) {
                rdoList?.forEach((el: any) => {
                  el.preSelected = false;
                  if (el.id === val) {
                    el.preSelected = true;
                  }
                });
              }
            }
            break;
        }
      });

      applySpecialCases(modal, option);

      // Reset the isVisible and isDisabled for rules
      modal.sections?.forEach((section: any) => {
        section?.options?.forEach((option: any) => {
          option?.choices?.forEach((choice: any) => {
            choice.isVisible = true;
            choice.isDisabled = false;
          });
        });
      });

      const obj = modal.sections[state.currentStep].options[optionIdx].choices?.find((el: any) => el.id === val);

      // This method for rules
      modal = applyRules(modal, rules, obj);

      // apply validation for radio buttons
      modal = applyValidations(modal);

      // Calculate total price
      const totalPrice = calculateTotalPrice(modal);

      modal.totalPrice = totalPrice + modal.price;

      state.selectedModal = modal;
    },
  },
});

const applySpecialCases = (model: any, option: any) => {

  let selected = option?.choices?.find((el: any) => el.preSelected === true);
  if(selected?.importKey === "rdoHullSide" || selected?.importKey === "rdoHullBottom") {

    const otherik = selected.importKey == "rdoHullSide" ? "rdoHullBottom" : "rdoHullSide";
    let compareOption: any;
    let compareSelected: any;

    model.sections?.forEach((section: any) => {
      section?.options?.forEach((opt: any) => {
        
        var choice = opt?.choices?.find((el: any) => el.importKey === otherik);

        if(!!choice) {
          compareOption = opt;
        }

      });
    });
    
    compareSelected = compareOption?.choices?.find((el: any) => el.preSelected === true);

    compareOption?.choices.forEach((choice: any) => {
      choice.adjustedPrice = null;
    });

    option?.choices.forEach((choice: any) => {
      choice.adjustedPrice = null;
    });

    if(!!compareSelected) {

      if(selected.price > compareSelected.price) {

        compareOption?.choices.forEach((choice: any) => {
          if(selected.price >= choice.price) {
            choice.adjustedPrice = 0;
          }
        })

      }
      else if (selected.price < compareSelected.price) {

        option?.choices.forEach((choice: any) => {
          if(compareSelected.price >= choice.price) {
            choice.adjustedPrice = 0;
          }
        })
      }
      else {

        if(selected.importKey === "rdoHullSide") {

          compareOption?.choices.forEach((choice: any) => {
            if(selected.price >= choice.price) {
              choice.adjustedPrice = 0;
            }
          })

        }
        else {

          option?.choices.forEach((choice: any) => {
            if(compareSelected.price >= choice.price) {
              choice.adjustedPrice = 0;
            }
          })

        }

      }
    }
    else {
      
      compareOption?.choices.forEach((choice: any) => {
        if(selected.price >= choice.price) {
          choice.adjustedPrice = 0;
        }
      })

    }
  }

}

const calculateTotalPrice = (modal: any) => {
  let totalPrice = 0;
  modal?.sections?.forEach((section: any) => {
    section?.options?.forEach((option: any) => {
      option?.choices?.forEach((choice: any) => {
        if (choice?.preSelected && choice?.isVisible) {
          totalPrice = totalPrice +  ( choice.adjustedPrice ?? choice.price );
        }
      });
    });
  });
  return totalPrice;
};

const applyValidations = (model: any) => {
  var modal = model;
  model.sections?.forEach((section: any) => {
    let validationErrors: any[] = [];
    section?.options?.forEach((option: any) => {

      switch(option.uiWidget) {
        case UiWidget.DropdownList:
        case UiWidget.ColorSwatch:
        case UiWidget.RadioButton:

          const hasChoice: any =  option?.choices?.filter((choice: any) => {
            return ( choice.isVisible && !choice.isDisabled );
          });

          if(hasChoice?.length > 0 && !hasChoice?.filter((choice: any) => choice.preSelected).length) {
            validationErrors.push(`Please select a ${option.name}`);
          };

          break;
        case UiWidget.RadioCheckCombo:

          const hasRDO: any = option?.choices?.filter((choice: any) => {
            return ( choice.importKey.includes("rdo") && choice.isVisible && !choice.isDisabled );
          });
          
          if ( hasRDO?.length > 0 && !hasRDO?.filter((rdo: any) => rdo.preSelected).length) {
            validationErrors.push(`Please select a ${option.name}`);
          }
          break;
      }

    });
    section.validationErrors = [...validationErrors];
  });
  return modal;
};

const applyRules = (modal: any, rules: IRule[] | null, obj: any) => {
  var model = modal;
  if (rules !== null && rules.length > 0) {
    var evaluateRulesAgain = false;
    for (let rule of rules) {
      if (rule.conditions.length > 0 && rule.actions.length > 0) {
        //Apply Actions only if all conditions are met for this rule.
        var applyActions = validateRuleConditions(model, rule, obj);

        if (applyActions) {
          /*
            First Validate Actions to see if there are changes that need to be applied. If there are then apply them and after all the 
            rules have been evaluated then evaluate rules again.
          
            We do this because Actions potentially change the selected choices. If that happens that could cause conditions on other rules
            to be met, thus applying action on those rules. 

            Note: This could potentially lead to an infinite loop due to poorly designed rules that would result in a recursive operation. 
            In the future we may want to consider handling this situation by limiting the recursive operations.
          */
          if (validateRuleActions(model, rule, obj)) {
            model = applyRuleActions(model, rule, obj);
            evaluateRulesAgain = true;
          }
        }
      }
    }
    if (evaluateRulesAgain) {
      applyRules(model, rules, obj);
    }
  }

  return model;
};

const validateRuleConditions = (model: any, rule: IRule, obj: any): boolean => {
  var conditionCount = rule.conditions.length;
  var conditionsPassed = 0;

  for (let condition of rule.conditions) {
    if (conditionCount === conditionsPassed) {
      break;
    }

    if (condition.importKey === "rdoSeriesModel") {
      if (
        condition.isSelected ===
        (condition.revisionChoiceId === model?.revisionChoiceId)
      ) {
        conditionsPassed++;
      } else {
        //If the condition is conerning a Model and it doesn't pass exit out of the loop. No need to continue.
        break;
      }
    } else {
      for (let section of model.sections) {
        var skiprest = false;

        for (let option of section?.options) {
          const filterChoices = option?.choices?.filter((c: any) => {
            return (
              c?.revisionChoiceId === condition?.revisionChoiceId &&
              c?.preSelected === condition?.isSelected
            );
          });

          if (filterChoices !== null && filterChoices.length > 0) {
            conditionsPassed++;
            skiprest = true;
            break;
          }
        }

        if (skiprest) {
          break;
        }
      }
    }
  }

  //return true/false, indicating that all conditions have been met for this rule and actions can be applied.
  return conditionCount === conditionsPassed;
};

const validateRuleActions = (model: any, rule: IRule, obj: any): boolean => {
  let actiontriggered = false;

  for (let action of rule.actions) {
    for (let section of model.sections) {
      for (let option of section?.options) {
        for (let choice of option?.choices) {
          if (choice?.revisionChoiceId === action?.revisionChoiceId) {
            if (
              choice.isVisible !== action.isVisible ||
              choice.isDisabled !== action.isDisabled ||
              choice.preSelected !== action.isSelected
            ) {
              actiontriggered = true;
              break;
            }
          }
        }
        if (actiontriggered) {
          break;
        }
      }
      if (actiontriggered) {
        break;
      }
    }
    if (actiontriggered) {
      break;
    }
  }

  return actiontriggered;
};

const applyRuleActions = (model: any, rule: IRule, obj: any) => {
  rule.actions?.forEach((a: IRuleAction) => {
    model.sections?.forEach((section: any) => {
      section?.options?.forEach((option: any) => {
        option?.choices?.forEach((choice: any) => {
          if (choice?.revisionChoiceId === a?.revisionChoiceId) {
            if (
              choice.isVisible !== a.isVisible ||
              choice.isDisabled !== a.isDisabled ||
              choice.preSelected !== a.isSelected
            ) {
              choice.isVisible = a.isVisible;
              choice.isDisabled = a.isDisabled;
              choice.preSelected = a.isSelected;
            }
          }
          //The else will apply to choices that share the same import key.
          else if (choice?.importKey === a?.importKey) {
            //This choice will be unselected if the rule action is meant to select a different choice in the same grouping (as defined by importkey)
            if (choice?.preSelected && a?.isSelected) {
              choice.preSelected = false;
            }
          }
        });
      });
    });
  });

  return model;
};

export const {
  setMotor,
  setBoatForm,
  setLeadsForm,
  getRuleAction,
  resetBoatStates,
  getSeriesAction,
  getModelsAction,
  appLoadCompleteAction,
  getLookupsAction,
  setSelectedModal,
  setCurrentStep,
  updateSelectedModal,
  setCompletedSteps,
  getOrderDetailsAction,
  setBoatBreadCrumbTitle,
} = slice.actions;

//Thunks
export const getBoatSeries =
  (value: seriesListState | null): AppThunk =>
  (dispatch, getState) => {
    const currentvalue = getSeriesList(getState());
    if (currentvalue !== value) {
      dispatch(getSeriesAction(value));
    }
  };

export const getBoatModels =
  (value: modelListState | null): AppThunk =>
  (dispatch, getState) => {
    const currentvalue = getModelList(getState());
    if (currentvalue !== value) {
      dispatch(getModelsAction(value));
    }
  };

export const getRules =
  (value: ruleListState | null): AppThunk =>
  (dispatch, getState) => {
    const currentvalue = getRuleList(getState());
    if (currentvalue !== value) {
      dispatch(getRuleAction(value));
    }
  };

export const getLookups =
  (value: lookupsState | null): AppThunk =>
  (dispatch, getState) => {
    const currentvalue = getLookupsDetails(getState());
    if (currentvalue !== value) {
      dispatch(getLookupsAction(value));
    }
  };

export const getOrder =
  (value: orderDetailsState | null): AppThunk =>
  (dispatch, getState) => {
    const currentvalue = getOrderDetails(getState());

    if (currentvalue !== value) {
      dispatch(getOrderDetailsAction(value));
    }
  };

export default slice.reducer;
