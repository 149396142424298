import {
  Autocomplete,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Column, TableInstance } from "react-table";
import { apiStore } from "../../api/ApiFactory";
import { SelectColumnFilter } from "../../components/table/filters/SelectColumnFilter";
import { ReactTable } from "../../components/table/ReactTable";
import { LookupExtended, NameValueEntity } from "../../models/Common";
import { IDealer, IDealerLocation } from "../../models/Dealer";
import { LeadItem } from "../../models/LeadManagement";
import { useNavigate } from "react-router-dom";
import MainModal from "../../components/modal/MainModal";
import PrintModal from "../orders/PrintModal";
import { useSelector } from "react-redux";
import { getAllDealers } from "../../store/slices/Dealer/DealerSelector";
import { getBoatSeriesList, getYearList } from "../../store/slices/Lookup/LookupSelector";

const LeadsManagement = () => {
  const [dealerOptions, setDealerOptions] = useState<Array<IDealer>>();
  const [series, setSeries] = useState<Array<LookupExtended>>();
  const [modelYears, setModelYears] = useState<Array<NameValueEntity>>();
  const [tableVisible, setTableVisible] = useState<boolean>(false);
  const [location, setLocation] = useState<Array<IDealerLocation>>();
  const [tableData, setTableData] = useState<Array<LeadItem>>([]);

  const [open, setOpenModal] = useState(false);
  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);
  const [leadNumber, setLeadNumber] = useState<number>(-1);

  const navigate = useNavigate();
  
  const state_dealerList = useSelector(getAllDealers);
  const state_yearList = useSelector(getYearList);
  const state_seriesList = useSelector(getBoatSeriesList);

  useEffect(() => {

    const obj: IDealer = {id:-1, name: "All"};
    const state = [obj, ...state_dealerList];
    setDealerOptions(state);

  }, [state_dealerList])

  useEffect(() => {

    const obj: LookupExtended = {id: -1, name: "All", key: "All"};
    const state = [obj, ...state_seriesList];
    setSeries(state);

  }, [state_seriesList])

  useEffect(() => {

    const obj: NameValueEntity = {name: "All", value: "All"};
    const state = [obj, ...state_yearList];
    setModelYears(state);

  }, [state_yearList])

  //Function to create a location record list with one "All" option.
  const createLocationAllOption = (data: IDealerLocation[]) => {
    var locations = data;

    locations.unshift({
      customerID: "",
      name: "All",
    } as IDealerLocation);

    return locations;
  };

  //Function to create Location List
  const createLocationList = (data: IDealerLocation[] | undefined) => {
    var locations: Array<IDealerLocation> = [];
    if (!!data) {
      data.map((item) => {
        locations.push(item);
      });
    }
    if (data && data.length > 0) {
      if (data.length > 1) {
        createLocationAllOption(locations);
        formik.setFieldValue("locations", {
          id: "",
          customerID: "",
          name: "All",
        });
      } 
      else {
        formik.setFieldValue("locations", { ...locations[0] });
      }
      var year = modelYears && modelYears.length > 0 ? modelYears[modelYears.length! - 1] : "All";

      formik.setFieldValue("series", { id: null, name: "All" });
      formik.setFieldValue("year", year);

    } 
    else {
      formik.setFieldValue("locations", { id: "", customerID: "", name: "" });
      formik.setFieldValue("series", { id: "", name: "" });
      formik.setFieldValue("year", "");
    }
    setLocation(locations);
  };

  //Function to reset form values
  const handleReset = () => {
    setTableVisible(false);
    formik.resetForm();
  };

  const onPrint = useCallback(
    (instance: TableInstance<LeadItem>) => () => {
      const order = instance.selectedFlatRows[0].original;
      setLeadNumber(order.orderNumber);
      openModal();
    },
    []
  );

  const onEdit = useCallback(
    (instance: TableInstance<LeadItem>) => () => {
      const order = instance.selectedFlatRows[0].original;
      navigate(`/boat/order/view/${order.orderNumber}`);
    },
    []
  );

  const columns: Column<LeadItem>[] = useMemo(
    () => [
      {
        Header: "Model Year",
        accessor: "modelYearDisplayLabel",
        width: 100,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Dealer Name",
        accessor: "dealerName",
        width: 200,
      },
      {
        Header: "Lead Number",
        accessor: "orderNumber",
        width: 120,
      },
      {
        Header: "Series",
        accessor: "seriesName",
        width: 100,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Model",
        accessor: "modelName",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phone",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );

  //Formik initial values
  const initialValues = {
    dealerOptions: { name: "" } as IDealer,
    locations: { name: "", customerID: "" } as IDealerLocation,
    series: { id: "", key: "", name: "" } as LookupExtended,
    year: {name: "", value: ""} as NameValueEntity,
    showformfilters: true,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: any, { setSubmitting }: any) => {
      getLeadHistory(values);
    },
  });

  const getLeadHistory = (values: any) => {

    let { customerID, name } = values.locations;
    const revisionYear: string | undefined = modelYears?.find(x => x.name === values.year.name)?.value;
    let series: any = values.series.key;
    let params = new URLSearchParams();

    if (customerID.length <= 0 && location?.length! > 1) {
      location?.forEach((item) => {
        if (item.customerID.length > 0) {
          params.append("Customers", item.customerID);
        }
      });
    } else {
      if (customerID === "" && name === "All") {
        params.append("Customers", "All");
      } else {
        params.append("Customers", customerID);
      }
    }

    if (series) {
      params.append("series", series);
    }

    if (revisionYear) {
      params.append("year", revisionYear);
    }

    const result = apiStore.leadsApi.getList(params);

    result
      .then((response) => {
        console.log(response);
        setTableData(response);
        setTableVisible(true);
      })
      .catch((error) => {
        console.log(error);
        setTableVisible(false);
      });
  };

  return (
    <>
      <MainModal
        open={open}
        handleClose={closeModal}
        customStyle={{ maxWidth: "600px" }}
      >
        <PrintModal
          orderNum={leadNumber}
          handleClose={closeModal}
          label="Leads"
          isLead={true}
        />
      </MainModal>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={4}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel>Dealers *</InputLabel>
            <Autocomplete
              disabled={tableVisible}
              value={formik.values.dealerOptions}
              onChange={(event, value: any) => {
                formik.setFieldValue("dealerOptions", value);
                if (dealerOptions && value) {

                  if (value.name === "All") {
                    createLocationList(createLocationAllOption([]));
                  } 
                  else {
                    const locations = dealerOptions.find((item: IDealer) => item?.id === value?.id)?.locations;
                    createLocationList(locations);
                  }
                } 
                else if (!value) {
                  if (value === "All") {
                    createLocationList(createLocationAllOption([]));
                  } 
                  else {
                    createLocationList([]);
                  }
                }
              }}
              size="small"
              fullWidth
              disablePortal
              id="dealerOptions"
              getOptionLabel={(option: IDealer) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id || value.name === "" || value.name === "All"
              }
              options={dealerOptions ? dealerOptions : []}
              renderInput={(params) => (
                <TextField {...params} name="dealerOptions" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel>Locations *</InputLabel>
            <Autocomplete
              disabled={tableVisible}
              size="small"
              fullWidth
              disablePortal
              value={formik.values.locations}
              onChange={(event, value) => {
                formik.setFieldValue("locations", value);
              }}
              id="locations"
              getOptionLabel={(option: IDealerLocation) =>
                option.customerID !== ""
                  ? option.customerID + " - " + option.name
                  : option.name
              }
              isOptionEqualToValue={(option, value) =>
                option.customerID === value.customerID || value.customerID === "" || value.name === "All"
              }
              options={location ? location : []}
              renderInput={(params) => (
                <TextField {...params} name="values.locations" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel>Series *</InputLabel>
            <Autocomplete
              size="small"
              disabled={tableVisible}
              fullWidth
              disablePortal
              value={formik.values.series}
              id="series"
              onChange={(event, value) => {
                formik.setFieldValue("series", value);
              }}
              getOptionLabel={(option: LookupExtended) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id || value.id === "" || value.name === "All"
              }
              options={series ? series : []}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel>Year *</InputLabel>

            <Autocomplete
              size="small"
              fullWidth
              disabled={tableVisible}
              disablePortal
              id="Year"
              value={formik.values.year}
              onChange={(event, value) => {
                formik.setFieldValue("year", value);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.value == value.value || value.value === "" || value.value === "All"
              }
              options={modelYears ? modelYears : []}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12}>
            {!tableVisible && (
              <Button type="submit" variant="contained">
                Get Leads
              </Button>
            )}
            {tableVisible && (
              <Button type="submit" variant="contained" onClick={handleReset}>
                Reset
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      {tableVisible && (
        <Grid mt={4}>
          <ReactTable<LeadItem>
            name="lead-table"
            columns={columns}
            serverOnChange={false}
            data={tableData ? tableData : []}
            isLoading={false}
            onEdit={onEdit}
            onPrint={onPrint}
          />
        </Grid>
      )}
    </>
  );
};

export default LeadsManagement;
