import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { ReportsData } from "../../../../src/models/ReportsData";
import { formatDate } from "../../../helpers/utils/formatDate";


interface PrintReportTableProps {
    reportData: ReportsData[];
}

const PrintReportTable: React.FC<PrintReportTableProps> = ({ reportData }) => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}, &.${tableCellClasses.body}`]: {
            paddingLeft: '2px !important',
            border: '1px solid black',
        },
        [`&.${tableCellClasses.head}`]: {
            fontWeight: 700,
            backgroundColor: theme.palette.grey[200],           
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:last-child td, &:last-child th": {
            border: '1px solid black',
        },
    }));

    return (
        <TableContainer className="price-table">
            <Table sx={{ minWidth: 700, tableLayout: 'fixed' }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{ width: '12%' }}>Sales Rep</StyledTableCell>
                        <StyledTableCell sx={{ width: '12%' }}>Customer Name</StyledTableCell>
                        <StyledTableCell sx={{ width: '8%' }}>Order Number</StyledTableCell>
                        <StyledTableCell sx={{ width: '10%' }}>Part Number</StyledTableCell>
                        <StyledTableCell sx={{ width: '12%' }}>Hull Number</StyledTableCell>
                        <StyledTableCell sx={{ width: '9%' }}>Order Date</StyledTableCell>
                        <StyledTableCell sx={{ width: '9%' }}>Calculated Gel Date</StyledTableCell>
                        <StyledTableCell sx={{ width: '9%' }}>Calculated ECD</StyledTableCell>
                        <StyledTableCell sx={{ width: '10%' }}>Order Amount</StyledTableCell>
                        <StyledTableCell sx={{ width: '8%' }}>Approval Number</StyledTableCell>
                        <StyledTableCell sx={{ width: '10%' }}>PO Number</StyledTableCell>
                        <StyledTableCell sx={{ width: '8%' }}>Calculated Invoice Num</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reportData && reportData.map((row, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell>{row.salesRep_Name}</StyledTableCell>
                            <StyledTableCell align="left">{row.customer_Name}</StyledTableCell>
                            <StyledTableCell align="left">{row.orderHed_OrderNum}</StyledTableCell>
                            <StyledTableCell align="left">{row.orderDtl_PartNum}</StyledTableCell>
                            <StyledTableCell align="left">{row.orderRel_HullNum_c}</StyledTableCell>
                            <StyledTableCell align="left">{row.orderHed_OrderDate ? formatDate(new Date(row.orderHed_OrderDate)) : ""}</StyledTableCell>
                            <StyledTableCell align="left">{row.calculated_GelDate ? formatDate(new Date(row.calculated_GelDate)) : ""}</StyledTableCell>
                            <StyledTableCell align="left">{row.calculated_ECD ? formatDate(new Date(row.calculated_ECD)) : ""}</StyledTableCell>
                            <StyledTableCell align="left">{row.orderHed_DocOrderAmt}</StyledTableCell>
                            <StyledTableCell align="left">{row.orderHed_ApprovalNum_c}</StyledTableCell>
                            <StyledTableCell align="left">{row.orderHed_PONum}</StyledTableCell>
                            <StyledTableCell align="left">{row.calculated_MaxInvcNum}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PrintReportTable;
