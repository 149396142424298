import AuthApi from "./AuthApi";
import { AppStore } from "../store/store";
import TableApi from "./TableApi";
import UserApi from "./UserApi";
import BoatConfigureApi from "./BoatConfigurationApi";
import BoatOrderingApi from "./BoatOrderingApi";
import WarrantyApi from "./WarrantyApi";
import DealerApi from "./DealerApi";
import LeadsApi from "./LeadsApi";
import LookupApi from "./LookupApi";
import MarketingFormApi from "./MarketingFormApi";
import ReportsApi from "./ReportsApi";
import DashboardApi from "./DashboardApi";
import OrderManagementApi from "./OrderManagementApi";
import DealerManagementApi from "./DealerManagementApi";
export let apiStore: ApiFactory;
export const setupApiFactory = (_store: AppStore) => {
  apiStore = new ApiFactory(_store);
};

//Should contain declaration for all api classes.
export class ApiFactory {
  authApi: AuthApi;
  tableApi: TableApi;
  userApi: UserApi;
  boatOrderingApi: BoatOrderingApi;
  boatConfigApi: BoatConfigureApi;
  warrantyApi: WarrantyApi;
  store: AppStore;
  dealerApi: DealerApi;
  leadsApi: LeadsApi;
  lookupApi: LookupApi;
  marketingFormApi: MarketingFormApi;
  reportsApi: ReportsApi;
  dashboardApi: DashboardApi;
  orderManagementApi: OrderManagementApi;
  dealerManagementApi: DealerManagementApi;

  constructor(_store: AppStore) {
    this.store = _store;
    this.authApi = new AuthApi(this);
    this.tableApi = new TableApi(this);
    this.userApi = new UserApi(this);
    this.boatConfigApi = new BoatConfigureApi(this);
    this.boatOrderingApi = new BoatOrderingApi(this);
    this.warrantyApi = new WarrantyApi(this);
    this.dealerApi = new DealerApi(this);
    this.leadsApi = new LeadsApi(this);
    this.lookupApi = new LookupApi(this);
    this.marketingFormApi = new MarketingFormApi(this);
    this.reportsApi = new ReportsApi(this);
    this.dashboardApi = new DashboardApi(this);
    this.orderManagementApi = new OrderManagementApi(this);
    this.dealerManagementApi = new DealerManagementApi(this)
  }
}
