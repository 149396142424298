import { Typography, TypographyTypeMap } from "@mui/material"

type ExtensionProps = {
    title?: string;
    subtitle?: string;
}

export const HeaderLabel: React.FC<Partial<TypographyTypeMap> & ExtensionProps> = (
    {
        title,
        subtitle,
        ...rest
    }

) =>
{
    return (
        <>
            {!!title && (
                <Typography className="header-label">
                    {title}
                </Typography>
            )}

            {!!subtitle && (
                <Typography className="sub-header-label-alt">
                    {subtitle}
                </Typography>
            )}
            
        </>

        
    )
}