import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grid,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { apiStore } from "../../api/ApiFactory";
import { useSelector } from "react-redux";
import { getSeriesList } from "../../store/slices/BoatOrdering/BoatOrderingSelector";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import SmartText from "../../components/smartText/SmartText";

const BoatOrderingSeries = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const seriesList = useSelector(getSeriesList);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    apiStore.boatOrderingApi
      .getSeriesList()
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Boat series", error);
      });
  }, []);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#193a4a",
      fontSize: 14,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
      borderRadius: 0,
    },
  }));

  const goToModel = (seriesId: any) => {
    if (location.pathname === "/order") {
      navigate(`/order/model/${seriesId}`);
    } else {
      navigate(`/boat/model/${seriesId}`);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {seriesList?.map((series: any, index: number) => {
        if (!series?.modelCount) {
          return <></>;
        }

        return (
          <Grid
            item
            xl={3}
            lg={4}
            md={4}
            sm={12}
            //xs={12}
            key={index}
            className="container-grid"
          >
            <Card className="configureCard">
              <CardMedia
                component="img"
                height="250"
                image={series?.mediaUrl}
                alt={series.name}
              />
              <CardContent className="configureCardContent">
                <Typography
                  gutterBottom={false}
                  variant="h5"
                  component="div"
                  align="left"
                  className="configureCardHeading"
                  mt={2}
                >
                  {`${series.name}`}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="left"
                  gutterBottom
                  className="configureCardModel"
                >
                  {`${series?.modelCount ? series?.modelCount : 0}+ Model(s)`}
                </Typography>
                <Box className="configureCardDescription">
                  <SmartText text={series?.description} char={150} />
                </Box>
                <CardActions className="justify-center">
                  <Button
                    size="small"
                    variant="contained"
                    disabled={!series?.modelCount}
                    className="text-capitalize configureCardButton boat-ordering"
                    onClick={() => goToModel(series.id)}
                  >
                    View All Models
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BoatOrderingSeries;
