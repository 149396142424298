import React, { useState } from 'react'
import { Grid, Typography, Select, MenuItem, InputLabel, TextField, Button, Stack, FormControl, FormHelperText, FormControlLabel, Checkbox, Switch } from '@mui/material'
import { UiWidget } from '../../../helpers/enums/UiWidget'
import { Formik } from 'formik'
import { apiStore } from '../../../api/ApiFactory'
import { IOption } from '../../../models/Configuration'
import { useNotification } from '../../../store/context/NotificationContext'
import * as Yup from "yup";



type optionProps = {
  options: IOption[],
  selectedModelList: []
}


const Options = ({ options, selectedModelList }: optionProps) => {

  const { Toast } = useNotification();

  const initialValues = {
    optionId: "",
    name: "",
    description: "",
    sortOrder: "",
    uiWidget: "",
    models: selectedModelList,
    nameChk: false,
    descriptionChk: false,
    sortOrderChk: false,
    uiWidgetChk: false
  }


  const validationSchema = Yup.object({
    optionId: Yup.string()
      .required("Please select an option"),
  })

  const handleFormSubmit = async (values: any) => {
    const { optionId, name, description, sortOrder, uiWidget, models } = values;
    const formData = {
      optionId,
      name: name.length > 0 ? name : null,
      description: description.length > 0 ? description : null,
      sortOrder: sortOrder.length > 0 ? sortOrder : null,
      uiWidget: uiWidget.length > 0 ? uiWidget : null,
      models
    }
    console.log(formData);
    try {
      const response = await apiStore.boatConfigApi.saveMasterConfigOptions(formData);
      Toast.success({ title: "Saved Successfully" })
    }
    catch (error) {
      Toast.error({ title: "Error Occured" });
    }
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any, { resetForm }) => {
          handleFormSubmit(values);
        }}
      >
        {
          (
            {
              touched,
              errors,
              resetForm,
              setFieldValue,
              handleChange,
              handleSubmit,
              values
            }
          ) => (
            <form onSubmit={handleSubmit}>
              <Grid container >
                <Grid item md={6} xs={12}>
                  <Typography>Select Option</Typography>
                  <Select
                    fullWidth
                    size="small"
                    id="optionId"
                    name="optionId"
                    value={values?.optionId}
                    onChange={(event) => {
                      resetForm();
                      handleChange(event);
                    }}
                    error={Boolean(
                      touched?.optionId && errors?.optionId
                    )}
                  >
                    <MenuItem disabled><em>Select Option</em></MenuItem>
                    {
                      options?.length > 0 && options.map((option: IOption) => <MenuItem key={option?.id} value={option?.id}>{option?.displayName}</MenuItem>)
                    }
                  </Select>
                  {touched?.optionId && errors?.optionId && (
                    <FormHelperText error id="option-helper">
                      {errors?.optionId}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid item md={6}>
                  <Typography>Select Fields To Update</Typography>
                </Grid>
              </Grid>
              <Grid container mt={2} spacing={2}>
                <Grid item md={6} xs={12} >
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("name", "")
                    }}
                    name="nameChk"
                    control={<Switch checked={values?.nameChk ? true : false} />}
                    label={`Name`} />
                 {
                  values?.nameChk && (
                    <TextField
                    disabled={!values.nameChk}
                    name="name"
                    fullWidth
                    size="small"
                    id="name"
                    placeholder="Name"
                    onChange={handleChange}
                    value={values?.name}
                  />
                  )
                 }
                </Grid>
                <Grid item md={6} xs={12} >
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("description", "")
                    }}
                    control={<Switch />}
                    label={`Description`}
                    name="descriptionChk"
                  />
                 {
                  values?.descriptionChk && (
                    <TextField
                    disabled={!values.descriptionChk}
                    name="description"
                    fullWidth
                    size="small"
                    id="description"
                    placeholder="Description"
                    onChange={handleChange}
                    value={values.description}
                  />
                  )
                 }
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("sortOrder", "")
                    }}
                    control={<Switch />}
                    label={`Sort Order`}
                    name="sortOrderChk"
                  />
                  {
                    values?.sortOrderChk && (
                      <TextField
                    disabled={!values?.sortOrderChk}
                    name="sortOrder"
                    fullWidth
                    size="small"
                    id="sortOrder"
                    placeholder="Sort Order"
                    onChange={handleChange}
                    value={values?.sortOrder}
                  />
                    )
                  }
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("uiWidget", "")
                    }}
                    control={<Switch checked={values?.uiWidgetChk ? true : false} />}
                    label={`Ui Widget`}
                    name="uiWidgetChk"
                  />
                  {
                    values?.uiWidgetChk && (
                      <Select
                    disabled={!values?.uiWidgetChk}
                    size="small"
                    fullWidth
                    displayEmpty
                    id="uiWidget"
                    name="uiWidget"
                    value={values.uiWidget}
                    onChange={handleChange}
                  >
                    <MenuItem disabled value="">
                      <em>Ui Widget *</em>
                    </MenuItem>
                    {
                      Object.values(UiWidget).map((item, index) => {
                        return (<MenuItem value={item} key={index}>
                          <em>{item}</em>
                        </MenuItem>)
                      })
                    }
                  </Select>
                    )
                  }
                </Grid>
              </Grid>
              <Stack alignItems="end" mt={2}>
                <Button type="submit" variant="contained">Save Changes</Button>
              </Stack>
            </form>
          )
        }
      </Formik>
    </>
  )
}

export default Options