import { createSlice } from "@reduxjs/toolkit";
import { seriesListState } from "../../../models/BoatOrdering";
import { AppThunk } from "../../store";

interface warrantyState {
  seriesList: any;
}

const initialState: warrantyState = {
  seriesList: [],
};

const slice = createSlice({
  name: "boat ordering",
  initialState,
  reducers: {
    getSeriesAction: (state, action) => {
      state.seriesList = action.payload;
    },
  },
});

export const { getSeriesAction } = slice.actions;

//Thunks

export default slice.reducer;
