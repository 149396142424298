import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Data, EnhancedTableProps, HeadCell, } from "../utils/types/TableType";
import { visuallyHidden } from '@mui/utils';


export function LeadsTableHead(props: EnhancedTableProps) {
    const {  order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };     
        const headCells: readonly HeadCell[] = [
        {
          id: 'orderNumber',
          numeric: false,
          disablePadding: false,
          alignLeft:true,
          label: 'Lead no',
        },
        {
          id: 'dealerName',
          numeric: false,
          disablePadding: false,
          alignLeft: false,
          label: "Dealer"
        },
        {
          id: 'model',
          numeric: true,
          disablePadding: false,
          alignLeft:false,
          label: 'Model Name',
        },
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          alignLeft:false,
          label: 'Name',
        },
        {
          id: 'leadDate',
          numeric: true,
          disablePadding: false,
          alignLeft:false,
          label: 'Last Date',
        },
        {
          id: 'status',
          numeric: false,
          disablePadding: false,
          alignLeft:false,
          label: 'Status',
        },
      ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell?.alignLeft ? "left" :"right"}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }