import { IAuthUser, IUserFormValues } from "../models/Auth";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";
import {
  setMotor,
  setBoatForm,
  getBoatSeries,
  getBoatModels,
  getRules,
  getLookups,
  resetBoatStates,
  setSelectedModal,
  setCurrentStep,
  updateSelectedModal,
  setCompletedSteps,
  setBoatBreadCrumbTitle,
  setLeadsForm,
} from "../store/slices/BoatOrdering/BoatOrderingSlice";

export default class BoatConfigureApi {
  private apiFactory: ApiFactory;
  constructor(apiFactory: ApiFactory) {
    this.apiFactory = apiFactory;
  }
  getSeriesList = async () => {
    try {
      const res = await agent.api.get("/ordering/serieslist", null);
      this.apiFactory.store.dispatch(getBoatSeries(res));
    } catch (error) {
      throw error;
    }
  };

    getModelList = async (params: any) => {
        try {
            const res = await agent.api.get("/ordering", params);

            this.apiFactory.store.dispatch(getBoatModels(res.boatModelOrderList));
            this.apiFactory.store.dispatch(getRules(res.ruleList));

        } catch (error) {
            throw error;
        }
    };

  getLookups = async (params: any) => {
    try {
      const res = await agent.api.get("/ordering/lookuplist", params);
      this.apiFactory.store.dispatch(getLookups(res));
    } catch (error) {
      throw error;
    }
  };

  createOrder = async (body: any) => {
    try {
      await agent.api.post("/ordering", body);
    } catch (error) {
      throw error;
    }
  };


  getStandardFeatures = async (boatModelId: number) => {
    try {
      const res = await agent.api.get(`/ordering/GetStandardFeatures?boatModelId=${boatModelId}`, null);
      return res;
    } catch (error) {
      throw error;
    }
  };

  setMotor = (data: any) => {
    this.apiFactory.store.dispatch(setMotor(data));
  };

  resetBoatStates = (data?: any) => {
    this.apiFactory.store.dispatch(resetBoatStates(data));
  };

  setBoatForm = (data: any) => {
    this.apiFactory.store.dispatch(setBoatForm(data));
  };

  setLeadsForm = (data: any) => {
    this.apiFactory.store.dispatch(setLeadsForm(data));
  };

  setSelectedModal = (data: any) => {
    this.apiFactory.store.dispatch(setSelectedModal(data));
  };

  setCurrentStep = (id: number) => {
    this.apiFactory.store.dispatch(setCurrentStep(id));
  };

  updateSelectedModal = (data: any) => {
    this.apiFactory.store.dispatch(updateSelectedModal(data));
  };

  setCompletedSteps = (completedSteps: { [k: number]: boolean }) => {
    this.apiFactory.store.dispatch(setCompletedSteps(completedSteps));
  };

  setBoatBreadCrumbTitle = (data: string) => {
    this.apiFactory.store.dispatch(setBoatBreadCrumbTitle(data));
  };
}
