import { Grid, Stack, Typography, Button } from '@mui/material'
import React, { useState } from 'react'
import { apiStore } from '../../../api/ApiFactory'


type PropType = {
    closeModel: () => void,
    deleteRuleId: [],
    updateComponent: () => void
}


const DeleteRuleModel = ({ closeModel, deleteRuleId, updateComponent }: PropType) => {

    const [btnVisibility , setBtnVisibility ] = useState<boolean>(true);


    const deleteRule = async (data: []) => {
       
        setBtnVisibility(false);
        const response = await Promise.allSettled(
            data.map((id: number) => {
                const result = apiStore.boatConfigApi.deleteRule(id);
                console.log("inside", result);
            }
            )
        )
        if (response.length > 0) {
            updateComponent();
            closeModel();
        }

    }



    return (
        <>
            <Grid container direction="column" spacing={5} sx={{ height: "100%" }}>
                <Grid item>
                    <Typography variant="h5" >Are you sure you want to delete the rule?</Typography>
                    <Typography color="#616161" >Following rules will be deleted -</Typography>
                    <ul>
                        {
                          deleteRuleId &&  deleteRuleId.map((rule:any, index:number)=><li key={index} style={{color:"#616161"}}><Typography color="#616161">Rule  {rule}</Typography></li>)
                    }
                    </ul>
                </Grid>
                <Grid item >
                    <Stack direction="row" spacing={4} justifyContent="flex-end">
                        <Button variant="contained" disabled={!btnVisibility} onClick={() => deleteRule(deleteRuleId)}>Delete</Button>
                        <Button variant="contained" onClick={closeModel}>Cancel</Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default DeleteRuleModel