import { Box, Checkbox } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Column, TableInstance } from "react-table";
import { apiStore } from "../../../../api/ApiFactory";
import { Table } from "../../../../components/table/Table";
import { convertValue } from "../../../../helpers/utils/valueConverter";
import { IChoice } from "../../../../models/Configuration";

const ChoiceTableComponent = ({ data }: any) => {

    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let optionId = searchParams.get('id');

    const [isLocked, setIsLocked] = useState<boolean>(true);

    //Table columns
    const columns: Column<IChoice>[] = useMemo(() => [
        {
            Header: "Import Key / Key2",
            accessor: "importKey"
        },
        {
            Header: "Name",
            accessor: "displayName",

        },
        {
            Header: "Import Value / Key3",
            accessor: "importValue"
        },
        {
            Header: "Media",
            accessor: "hexcode",
            Cell: (cell: any) => <Box sx={{ backgroundColor: `${cell.value}`, width: "40px", height: "40px", borderRadius: "50%" }}></Box>
        },
        {
            Header: "Sort Order",
            accessor: "order",
            InLineEdit: true,
            inputType: 'text',
        },
        {
            Header: "Pre Selected",
            accessor: "preSelected",
            Cell: ({ cell }: any) => <span>{`${cell.value}`}</span>
        },
        {
            Header: "Is Active",
            accessor: "isActive",
            Cell: ({ cell }: any) => <Checkbox className={isLocked ? "row-checkbox" : "row-checkbox-disabled"} disabled checked={cell.value} />
        },
        {
            Header: "Price",
            accessor: "price",
        },
        {
            Header: "Adj. Price",
            accessor: "adjustedPrice"
        }
    ], [isLocked])

    const handleToggle = useCallback(
        (instance: TableInstance<IChoice>) => () => {
            setIsLocked(prevState => !prevState);
        },
        []
    );

    const updateChoice = async (values: IChoice): Promise<boolean> => {
        try {
            await apiStore.boatConfigApi.updateChoice(values);
            console.log('Update successful');
            return true;
        } catch (error) {
            console.error('Update failed:', error);
            return false;
        }
    };

    const onInlineEdit = useCallback(
        async (rowId: string | number, columnId: keyof IChoice, newValue: any, row: IChoice) => {
            if (row) {
                const oldValue = row[columnId];
                const newValueConverted = convertValue(oldValue, newValue);

                // Only update if the new value is different from the old value
                if (oldValue !== newValueConverted) {
                    const updatedValues: IChoice = {
                        ...row,
                        [columnId]: newValueConverted,
                    };

                    const result = await updateChoice(updatedValues);
                    if (result) {
                        return true;
                    }
                } else {
                    console.log('No change detected. Update skipped.');
                }
            } else {
                console.error('Row not found:', rowId);
            }
            return false;
        },
        []
    );

    //Function to edit row
    const onEdit = useCallback((instance: TableInstance<IChoice>) => () => {

            const choice = instance.selectedFlatRows[0].original as IChoice;
            const { id } = choice
            navigate(`/config/modellist/manageOption/manageChoice?id=${id}`)
        },
        []
    );

    return (
        <>
            {optionId && (
                <Table<IChoice>
                name="choice-table"
                serverOnChange={false}
                columns={columns}
                apiGet={`/configuration/choicelist?boatOptionId=${optionId}`}
                onEdit={onEdit}
                inlineLock={handleToggle}
                inlineLockState={isLocked}
                onInlineEdit={onInlineEdit}
                />
            )}
        </>
        
       
    )

}


export default ChoiceTableComponent;