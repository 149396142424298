import React, { ReactElement } from "react";
import {
  OutlinedInput,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import Select from "@mui/material/Select";
import { SelectInterface, ISelectOption } from "../../models/BoatOrdering";
import "./Style.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface selectProps {
  disabled?: boolean;
  selectData: SelectInterface;
  options?: ISelectOption[];
  handleChange?: (arg: any) => void;
  handleBlur?: (arg: any) => void;
  value?: any;
  error?: string;
}

const SelectDropdown = React.forwardRef(
  (
    {
      selectData,
      options,
      handleChange,
      handleBlur,
      value,
      error,
      disabled,
    }: selectProps,
    ref
  ) => {
    const { name, label, classes } = selectData;
    return (
      <>
        <InputLabel>{label}</InputLabel>
        <FormControl
          sx={{ width: "100%" }}
          error={error ? true : false}
        >
          
          <Select
            className={classes}
            size="small"
            ref={ref}
            name={name}
            id={name}
            multiple={false}
            displayEmpty
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {options !== undefined && options?.length > 1 && (
              <MenuItem disabled value="">
                <em>Select</em>
              </MenuItem>
            )}

            {options?.map((option) => (
              <MenuItem
                key={option?.key}
                value={option?.key}
                disabled={option?.disabled}
              >
                {option?.value}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error={true}>{error}</FormHelperText>}
        </FormControl>
      </>
      
    );
  }
);
export default SelectDropdown;
