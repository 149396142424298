import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { GetAuthStatus } from "../services/AuthService";
import { Permissions } from "../helpers/enums/AuthConstants";
import { useAppSelector } from "../store/hooks/hook";
import { useAuth } from "../store/hooks/useAuth";

interface AuthRequiredProps {
    children: JSX.Element,
    permissions?: Array<Permissions>
}

const AuthRequired = ({ 
    children, 
    permissions 
}: AuthRequiredProps) => {

    let authStatus = GetAuthStatus(permissions);
    let location = useLocation();

    const { subdomain } = useAppSelector((state) => ({
      subdomain: state.reducer.Layout.subdomain
    }));

    if(subdomain === "build") {
      return <Navigate replace to="/order" />
    }

    if (!authStatus.isAuthenticated) {
        return <Navigate replace to="/auth/login" />;
    }
    else if(!authStatus.isAuthorized)
    {
      if(authStatus.isLegacy) {
        return <Navigate replace to="/unauthorized" />;
      }
      else if(location.pathname !== '/dashboard')
      {
        return <Navigate replace to="/dashboard" />;
      }
      else {
        return <Navigate replace to="/unauthorized" />;
      }
    }
    else if(authStatus.isLegacy) {
      if(location.pathname === '/dashboard' || location.pathname === '/marketing') {
        return <Navigate replace to="/orders" />;
      }
    }
    
    return children;
  }

  export default AuthRequired;