import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAppLoadComplete, selectAuthUser } from '../slices/Auth/AuthSelector'

/*
  This hook serves two purposes
  1. Any change to these values which is managed by the redux store will re-render the component
     tree that uses it.

  2. Pull back auth user information.
*/

export const useAuth = () => {
  const authuser = useSelector(selectAuthUser);
  const apploaded = useSelector(selectAppLoadComplete);

  return useMemo(() => 
  ({ authuser, apploaded }), [authuser, apploaded])
}
