import React, { useEffect, useState } from "react";
import { useCallback, useMemo } from "react";
import { Column, TableInstance } from "react-table";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IDealer } from "../../models/Dealer";
import { ReactTable } from "../../components/table/ReactTable";
import { apiStore } from "../../api/ApiFactory";
import { formatDate } from "../../helpers/utils/formatDate";
import { useAuth } from "../../store/hooks/useAuth";
import { Roles } from "../../helpers/enums/AuthConstants";
import { getValueByKey } from "../../helpers/utils/getEnumValueKey";
import { DefaultCostType } from "../../helpers/enums/Common";
import { SelectColumnFilter } from "../../components/table/filters/SelectColumnFilter";
import { Checkbox, Chip } from "@mui/material";
import { NameValueEntity } from "../../models/Common";

const DealerTable = () => {
  let navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  let authuser = useAuth().authuser;


  const columns: Column<IDealer>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Location Tags",
        accessor: "locationTags",
        Cell: (cell: any) => {
          return (
            <>
              {cell.value &&
                cell.value.map((item: string, index: number) => {
                  return (
                    <span key={index}>
                      <Chip
                        size="small"
                        className="cell-chip"
                        label={item}
                        sx={{ marginLeft: index !== 0 ? "5px" : "0px" }}
                      />
                    </span>
                  );
                })}
            </>
          );
        },
      },
      {
        Header: "Default Cost Type",
        accessor: "defaultCostType",
        Cell: (cell: any) =>
          cell.value !== "" ? getValueByKey(cell.value, DefaultCostType) : "",
        width: 70,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Sale Price Mark Up Model",
        accessor: "salePriceMarkupModel",
        Cell: (cell: any) =>
          cell?.value ? Math.trunc(cell?.value * 100) + "%" : "0%",
        width: 70,
      },
      {
        Header: "Sale Price Mark Up Option",
        accessor: "salePriceMarkupOption",
        Cell: (cell: any) =>
          cell?.value ? Math.trunc(cell?.value * 100) + "%" : "0%",
        width: 70,
      },
      {
        Header: "Is Legacy",
        accessor: "isLegacy",
        Cell: (cell: any) => {
          return (
            <Checkbox
              className="row-checkbox"
              disabled
              checked={cell.value}
              value={cell.value}
            />
          );
        },
        width: 50
      },
      {
        Header: "Available for Leads",
        accessor: "isAvailableForLeads",
        Cell: (cell: any) => {
          return (
            <Checkbox
              className="row-checkbox"
              disabled
              checked={cell.value}
              value={cell.value}
            />
          );
        },
        width: 50
      },
      {
        Header: "Designation",
        accessor: (row) => {
          return row.designationTypeName
        },
        Filter: SelectColumnFilter
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
        width: 70,
        Cell: (cell: any) => formatDate(cell.value),
      },
    ],
    []
  );
  const AddRow = useCallback(
    (instance: TableInstance<IDealer>) => () => {
      navigate("/dealer/add");
    },
    []
  );

  const ViewRow = useCallback(
    (instance: TableInstance<IDealer>) => () => {
      const dealer = instance.selectedFlatRows[0].original;
      navigate(`/dealer/view/${dealer.id}`);
    },
    []
  );

  useEffect(() => {
    const result = apiStore.dealerApi.getDealerList({includeInactive: true});
    result.then((response) => {
      if (authuser?.user.role == Roles.DEALER) {
        const dealer = response[0];

        if (dealer.id === authuser.user.dealerId) {
          navigate(`/dealer/view/${dealer.id}`);
        }
      }
      setTableData(response);
    });
  }, []);

  return (
    <>
      <ReactTable
        name="order-table"
        columns={columns}
        serverOnChange={false}
        data={tableData ? tableData : []}
        isLoading={false}
        onAdd={AddRow}
        onEdit={ViewRow}
      />
    </>
  );
};

export default DealerTable;
