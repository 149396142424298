import { IDealer, IDealerLocation } from "../models/Dealer";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class DealerManagementApi {
    private apiFactory: ApiFactory;

    constructor(apiFactory: ApiFactory) {
        this.apiFactory = apiFactory;
    }

    getSalesRepCode =async () => {
        try {
            const response = await agent.api.get(`/dealerManagement/getsalesrepcodes`, null);
            return response;
        } 
        catch (error) {
            throw error;
        }
    }
     
    saveDealer = async (data: IDealer) => {
        try {
            const response: IDealer = await agent.api.post(`/dealerManagement/savedealer`, data);
            return response;
        } 
        catch (error) {
            throw error;
        }
    } 

    saveDealerLocation = async (values:IDealerLocation)=> {

        try {
            const response = await agent.api.post(`/dealerManagement/savelocation`,values);
            return response;
        } 
        catch (error) {
            throw error;
        }

    }

}