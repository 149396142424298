import { fetchDashboardData } from "../store/slices/Dashboard/DashboardSlice";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class DashboardApi{
    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
      this.apiFactory = apiFactory;
    }


    getDashboardData = async (year:number)=>{
        try {
            const data= await agent.api.get(`/Reports/GetDashboardReport?Year=${year}`, null);
            this.apiFactory.store.dispatch(fetchDashboardData(data));
            return data;
          } 
          catch (error) {
            throw error;
          }
    }
}