
import { Divider, Stack, Typography, TypographyTypeMap } from "@mui/material"
import CurrencyFormat from "../currencyFormat/CurrencyFormat";

type ExtensionProps = {
    title: string;
    label: string | undefined;
    currency?: boolean;
    className?: string;
    noBoldTitle?: boolean;
    variant?: "subtitle2" | "h6" | "inherit" | "print"
}

export const StackLabel: React.FC<Partial<TypographyTypeMap> & ExtensionProps> = (
    {
        title,
        label,
        currency,
        className,
        variant,
        noBoldTitle,
        ...rest
    }

) =>
{

    let variantType = variant === "print" ? undefined : (variant ?? "subtitle2");
    let printClass = variant === 'print' ? 'line-item' : '';

    return (
        <>
            <Stack 
                direction="row" 
                spacing={2}
                className={className ?? ""}
                justifyContent="space-between" 
                alignItems="center"
                divider={<Divider variant="middle" component="div" className='text-divider' />}
            >
                <Typography variant={variantType} className={(noBoldTitle ? '' : 'bold-label') + printClass}>{title}</Typography>

                {!currency && (
                    <Typography variant={variantType} className={printClass} >{label}</Typography>
                )}   

                {currency && (
                    <Typography variant={variantType} className={printClass}><CurrencyFormat value={label ?? "0"} /></Typography>
                )} 
            </Stack>
        </>
    )
}