import { Typography, TypographyProps } from "@mui/material"
import { IAddress } from "../../models/Common";

type IProps = TypographyProps & {
    address: IAddress;
    className?: string;
    title?: string;
}

const formatPartialAddress = (useComma: boolean, val1?: string, val2?: string) => {

    let value1 = val1 ?? "";
    let value2 = val2 ?? "";

    let result: string = value1;
    let seperator: string = useComma ? ", " : " ";

    if(value1.length == 0 || value2.length == 0) {
      seperator = "";
    }

    result += (seperator + value2);

    return result;

}

const getPartialAddress = (address: IAddress) => {

    let result: string = "";

    result = formatPartialAddress(true, address.city, address.state);
    result = formatPartialAddress(true, result, address.country);
    result = formatPartialAddress(false, result, address.zip);

    return result;
}


export const AddressLabel = ({...rest} : IProps
) => 
{
    return (
        <>
            { rest.title && ( 
                <Typography variant={rest?.variant} className="bold-label" > {rest.title} </Typography>
            )}
            {rest.address.address1 && (
                <Typography variant={rest?.variant} className={rest.className} >
                {rest.address.address1}
                </Typography>
                )}
            {rest.address.address2 && (
                <Typography variant={rest?.variant} className={rest.className} >
                {rest.address.address2}
                </Typography>
            )}
            <Typography variant={rest?.variant} className={rest.className} >
                {getPartialAddress(rest.address)}
            </Typography>
        
        </>
    )
}