import { Table } from "../../../components/table/Table";
import { Column, TableInstance } from "react-table";
import { useNavigate } from "react-router-dom";
import { ISeries } from "../../../models/Configuration";
import { ChangeEvent, useCallback, useMemo } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from 'react';
import { apiStore } from "../../../api/ApiFactory";
import { async } from "rxjs";
import { convertValue } from "../../../helpers/utils/valueConverter";
const Series = () => {
  const navigate = useNavigate();

  //Table Columns
  const columns: Column<ISeries>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        InLineEdit : false,
      },
      {
        Header: "Sort Order",
        accessor: "order",
        InLineEdit: true,
        inputType : 'text',
      }
    ],
    []
    );

    const [isLocked, setIsLocked] = useState<boolean>(true);  

  //Function to edit row
  const onEdit = useCallback(
    (instance: TableInstance<ISeries>) => () => {
      const record = instance.selectedFlatRows[0].original;
      const { id } = record;
      navigate(`series?seriesId=${id}`, {state: { record }});
    },
    []
    );

    const handleToggle = useCallback(
        (instance: TableInstance<ISeries>) => () => {
          setIsLocked(prevState => !prevState);
        },
        [isLocked]
    );

    const updateSeries = async (values: ISeries): Promise<boolean> => {
        try {
            await apiStore.boatConfigApi.updateSeries(values);
            console.log('Update successful');
            return true;
        } catch (error) {
            console.error('Update failed:', error);
            return false;
        }
    }; 
    
    const onInlineEdit = useCallback(
        async (rowId: string | number, columnId: keyof ISeries, newValue: any, row: ISeries) => {
            
            if (row) {
                const oldValue = row[columnId];
                const newValueConverted = convertValue(oldValue, newValue);

                // Only update if the new value is different from the old value
                if (oldValue !== newValueConverted) {
                    const updatedValues: ISeries = {
                        ...row,
                        [columnId]: newValueConverted,
                    };
                    
                    const result = await updateSeries(updatedValues);
                    if (result) {
                        return true;
                    }
                } else {
                    console.log('No change detected. Update skipped.');
                }
            } else {
                console.error('Row not found:', rowId);
            }
            return false;
        },
        []
    );

  return (
    <>
          <Table<ISeries>
              name="series-table"
              serverOnChange={false}
              columns={columns}
              apiGet="/configuration"
              onEdit={onEdit}
              inlineLock={handleToggle}
              inlineLockState={isLocked}
              onInlineEdit={onInlineEdit}
      />
    </>
  );
};

export default Series;
