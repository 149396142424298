import { Button, Card, CardActions, CardMedia, CardMediaTypeMap, Grid, InputLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import defaultImg from "../../_assets/images/placeholder-transparent.png";

type ExtProps = {
    onDelete?: (value: string) => any;
    onUpdate?: (value: string) => any;
    onUpload?: (event: React.ChangeEvent<HTMLInputElement>) => any;
    imageUrl?: string | null
    value?: string
    useBoxShadow?: boolean
    className?: string
    infoTag?: string
    maxSize?: number
}

export const MainMediaCard: React.FC<Partial<CardMediaTypeMap<{}, 'div'>> & ExtProps> = (
    {
        onDelete,
        onUpdate,
        onUpload,
        imageUrl,
        value,
        useBoxShadow,
        className,
        infoTag,
        maxSize,
        ...rest
    }
) =>
{

    const boxshadow = useBoxShadow === undefined ? "none" : "auto";
    const componentvalue = value ? value : "";

    const [image, setImage] = useState(defaultImg);
    const [maxWidth, setMaxWidth] = useState(250);

    useEffect(() => {
        if(imageUrl) {
            setImage(imageUrl);
            setMaxWidth(maxSize ?? 600);
        }
        else {
            setImage(defaultImg);
            setMaxWidth(250);
        }

    }, [imageUrl])

    return (
        <>
            <Grid container spacing={2}>
                { infoTag && (
                <Grid item xs={12}>
                    <Typography variant="caption" sx={{fontWeight:600}}>{infoTag}</Typography>
                </Grid>
                )}
                <Grid item xs={12} sx={{justifyContent: maxWidth == 250 ? "inherit" : "space-around", display:"flex"}} >
                    <Card sx={{boxShadow: boxshadow, maxWidth: maxWidth, alignItems:"center" }} className={className} >
                        <CardMedia
                            component="img"
                            image={image}
                        /> 
                        {(onDelete || onUpdate || onUpload) && (

                            <CardActions sx={{justifyContent: "space-between"}}>
                                
                                {onDelete && imageUrl && (
                                    <Button size="small" onClick={() => onDelete(componentvalue)}>Delete</Button>
                                )}

                                {onUpdate && (
                                    <Button size="small" onClick={() => onUpdate(componentvalue)}>Update</Button>
                                )}

                                {onUpload && (
                                    <Button variant="text" size="small" component="label" sx={{textTransform:"none"}} >
                                        Upload
                                    <input hidden accept="image/*" type="file" onChange={(event) => onUpload(event)} />
                                    </Button>
                                )}

                            </CardActions>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
