import React from 'react';
import { calculatePriceByType } from "../../../helpers/utils/utils"
import { format } from 'date-fns';
import { CSVExportModelProps } from "../../../models/BoatOrdering";

const convertDataToCSV = (data: any, type: string | null, msrp: number, dealer: any) => {
    const rows = [];
    const headers = ["Model", "ModelPrice", "Group Name", "Option Name", "Option Price"];
    rows.push(headers.join(","));

    //function to handle " & , in values
    const escapeCsvValue = (value: string) => {
        if (value.includes('"') || value.includes(',')) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    };


    const getPercentMarkup = (section: string) => {
        if (type === "salesPrice" && dealer != null) {
            if (section.toUpperCase() === "MODEL") {
                return dealer.salePriceMarkupModel;
            }
            return dealer.salePriceMarkupOption;
        }
        if (type === "msrp") {
            return msrp;
        }
        return 0;
    }; 

    data.sections?.forEach((section: any) => {
        let sectionOptions = section?.options?.filter((o: any) => o.name !== "Rigging / Prop");

        sectionOptions?.forEach((option: any) => {         

            option.choices?.forEach((choice: any) => {
                rows.push([
                    escapeCsvValue(data.name),
                    calculatePriceByType(data.price, type, getPercentMarkup("MODEL")),
                    escapeCsvValue(`${option.name} (${section.name})`),
                    escapeCsvValue(choice.name),
                    calculatePriceByType(choice.price, type, getPercentMarkup(section))
                ].join(","));
            });            
        });
    });

    return rows.join("\n");
};

// function to trigger CSV download
const exportToCSV = (filename: string, data: string) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

const CSVExportModel: React.FC<CSVExportModelProps> = ({ data, type, series, dealer, onExportComplete }) => {
    const exportData = React.useCallback(() => {
        try {
        const csvData = convertDataToCSV(data, type, series.msrp, dealer);
        const dateTimeStr = getFormattedDateTime();
        const fileName = `${data.name}_${dateTimeStr}.csv`;
            exportToCSV(fileName, csvData);
        } catch (error) {
            console.error("Error exporting data to CSV:", error);
        }
    }, [data, type, series.msrp, dealer]);

    const getFormattedDateTime = () => {
        const now = new Date();
        return format(now, 'MMddyyyyHHmmss');
    };    

    React.useEffect(() => {
        exportData();
        onExportComplete();
    }, [exportData, onExportComplete]);

    return null;
};

export default CSVExportModel;
