import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
interface IConfirmationProps {
  open: any;
  title: string;
  description: string;
  handleClickYes: () => void;
  handleClickCancel: () => void;
}
const ConfirmationModal = ({
  open,
  title,
  description,
  handleClickYes,
  handleClickCancel,
}: IConfirmationProps) => {
  return (
    <Dialog
      open={Object.keys(open)?.length ? true : false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel}>No</Button>
        <Button onClick={handleClickYes} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmationModal;
