import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import appReducer from "./AppReducer";

export const store = configureStore({
  reducer: { reducer: appReducer }
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
