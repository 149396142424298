import { IDealer, IDealerLocation } from "../models/Dealer";
import { setDealer, setDealerList } from "../store/slices/Dealer/DealerSlice";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class DealerApi {
    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
      this.apiFactory = apiFactory;
    }

    getDealerList = async (params: any) => {
        try {
          const response = await agent.api.get(`/dealer/getdealerlist`, params);
          this.apiFactory.store.dispatch(setDealerList(response));
          return response;
        } 
        catch (error) {
          throw error;
        }
    };
     
    getDealer = async (params: any) => {
      try {
        const response = await agent.api.get(`/dealer/getdealer`, params);
        this.apiFactory.store.dispatch(setDealer(response));

      }
      catch(error) {
        throw error;
      }
    }


}