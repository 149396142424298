import { Grid, Typography, Select, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import { IChoice, IOption } from '../../../models/Configuration';
import LayeredMedia from './LayeredMedia';

type ChoiceLayerdMediaProps = {
  options: IOption[],
  selectedModelList: []
}
const ChoiceLayerdMedia = ({ options, selectedModelList }: ChoiceLayerdMediaProps) => {
  const [choiceList, setChoiceList] = useState<IChoice[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption>({} as IOption);
  const [selectedChoiceId, setSelectedChoiceId] = useState<any>(-1);
  const handleOptionChange = (event: any) => {
    const { choices } = event.target.value;
    setSelectedOption(event.target.value);
    setChoiceList(choices);
  }
  const handleChoiceChange = (event: any) => {
    setSelectedChoiceId(event.target.value);
  }
  return (
    <>
      <Grid container spacing={4} mb={4}>
        <Grid item md={6} xs={12}>
          <Typography>Select Option</Typography>
          <Select
            fullWidth
            size="small"
            id="series"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <MenuItem disabled><em>Select Option</em></MenuItem>
            {
              options?.length > 0 && options.map((option: any) => <MenuItem key={option.id} value={option}>{option?.displayName}</MenuItem>)
            }
          </Select>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Select Choice</Typography>
          <Select
            fullWidth
            size="small"
            id="series"
            value={selectedChoiceId}
            onChange={handleChoiceChange}
            disabled={!selectedOption?.id}
          >
            <MenuItem disabled><em>Select Choice</em></MenuItem>
            {
              choiceList?.length > 0 && choiceList.map((choice: IChoice) => <MenuItem key={choice?.id} value={choice?.id}>{choice?.description} - ({choice?.importKey})</MenuItem>)
            }
          </Select>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <LayeredMedia choiceId={selectedChoiceId} selectedModelList={selectedModelList} />
        </Grid>
      </Grid>
    </>
  )
}

export default ChoiceLayerdMedia