import { debounce } from "lodash";
import { format as dateFormat, parseISO } from "date-fns";
import { InputLabel, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as DatePickerInput } from "@mui/x-date-pickers/DatePicker";
interface IDatePickerProps {
  id: any;
  value: any;
  label: string;
  error?: any;
  format?: string;
  onChange: any;
  handleBlur?: any;
  disabled?: boolean;
  disablePast?: boolean;
  small?: boolean;
}

const inputFormat = "yyyy-MM-dd"; //MM-dd-yyyy

export default function DatePicker({
  id,
  value,
  label,
  error,
  format,
  disabled,
  onChange,
  handleBlur,
  disablePast,
  small
}: IDatePickerProps) {
  const handleChange = debounce((date: any) => {
    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date)) {
        onChange("invalid");
        // write here code for error
      } else {
        const formatedDate = dateFormat(date, format || inputFormat);
        onChange(formatedDate);
      }
    } else {
      onChange(null);
    }
  }, 700);

  let date = value;
  if (value?.includes(":")) {
    date = dateFormat(new Date(value), format || inputFormat);
  }
  return (
    <div className="form-group">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <InputLabel>{label}</InputLabel>
        <DatePickerInput
          value={value ? parseISO(date) : null}
          disabled={disabled}
          disablePast={disablePast}
          onChange={(e: any) => handleChange(e)}
          inputFormat={format || inputFormat}
          renderInput={(params) => (
            <TextField
              {...params}
              id={id}
              fullWidth
              size={small ? "small" : undefined}
              helperText={error}
              onBlur={handleBlur}
              error={error ? true : false}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
