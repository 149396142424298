import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useNotification } from "../../store/context/NotificationContext";
import { ISelectOption, SelectInterface } from "../../models/BoatOrdering";
import { useEffect, useState } from "react";
import { enumWarrantyType } from "../../helpers/utils/utils";
import { apiStore } from "../../api/ApiFactory";
import { defaultIWarrantyHistory, IWarrantyHistory } from "../../models/WarrantyManagement";
import { useFormik, validateYupSchema } from "formik";
import PageLoader from "../../components/loader/PageLoader";
import { FormHelperText, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { MainCard } from "../../components/surface/MainCard";
import { SectionBox } from "../../components/surface/SectionBox";
import { StackLabel } from "../../components/label/StackLabel";
import DatePicker from "../../components/datePicker/DatePicker";
import { addYears, format, parseISO } from "date-fns";
import SelectDropdown from "../../components/select/SelectDropdown";
import NumericFormat from "react-number-format";
import SubmitButtonGroup from "../../components/button/SubmitButtonGroup";
import { isValidDate } from "../../helpers/utils/isValidDate";

type IPageDef = {
    isTransfer: boolean; //if this is true then warrantyId needs to be set. If false, orderNum needs to be set.
    id: string;
}

const IWarrantySelect: SelectInterface = {
    label: "Warranty Type*",
    size: "small",
    name: "data.warrantyType",
};

const FormValidationSchema = Yup.object().shape({
    data: Yup.object({ 
        dealerLocation: Yup.string(),
        purchaseDate: Yup.string().required("Purchase date is required.").nullable()
            .test('purchaseDate', "Invalid date.", (value) => {
                return validateDate(value);
        }),
        deliveryDate: Yup.string().required("Delivery date is required.").nullable()
            .test('deliveryDate', "Invalid date.", (value) => {
                return validateDate(value);
        }),
        firstName: Yup.string().required("First name is required."),
        lastName: Yup.string().required("Last name is required."),
        phone: Yup.string().required("Phone is required.").test('phone', "Invalid phone.", (value) => {
            return validatePhone(value);
        }),
        streetAddress1: Yup.string().required("Address1 is required."),
        streetAddress2: Yup.string(),
        city: Yup.string().required("City is required."),
        state: Yup.string().required("State is required."),
        postalCode: Yup.string().required("Zip code is required."),
        country: Yup.string().required("Country is required."),
        engineMake: Yup.string().required("Engine model is required."),
        warrantyType: Yup.string().required("Warranty type is required."),
        boatModel: Yup.string(),
        serialNumber: Yup.string().required("Serial number is required."),
        hin: Yup.string(),
        modelYear: Yup.string(),
        expirationDate: Yup.string().nullable(),
        effectiveDate: Yup.string().nullable(),
        email: Yup.string().email("Invalid email address").required("Email is required")
    })
});

const validatePhone = (phone: any) => {
    
    if (phone) {
        phone = phone.replace(/[{()}]/g, "");
        phone = phone.replaceAll("-", "");
        phone = phone.replaceAll(" ", "");
    }

    if (phone?.length !== 10) {
        return false;
    }

    return true;
}

const validateDate = (value: any) => {

    if(value === "invalid") {
        return false;
    }

    return true;

}


const EditWarranty = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const { Toast } = useNotification();
    const [warrantyTypeOptions] = useState<ISelectOption[]>(enumWarrantyType);
    const [pageDef, setPageDef] = useState<IPageDef>();
    const [InitialFormData, setInitialFormData] = useState<IWarrantyHistory>(defaultIWarrantyHistory);

    useEffect(() => {
        
        var isTransfer = location.pathname.includes("transfer")

        let pagedef: IPageDef = {
            isTransfer: isTransfer,
            id: params.id! //id is either orderId if coming from Order Mgmt or HIN if being done as a transfer.
        }

        apiStore.warrantyApi.getFormData(pagedef)
            .then((response) => {
                setInitialFormData({ ...response });
                setPageDef(pagedef);
            })
            .catch((error) => {
              console.log("warranty form error", error);
            })
        
    }, [])

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: FormValidationSchema,
        initialValues: {
            data: InitialFormData,
            submit: null
        },
        onSubmit: (values, {setSubmitting, setErrors}) => {

            console.log(values);
            let phone: string = values.data.phone;

            if (phone) {
                phone = phone.replace(/[{()}]/g, "");
                phone = phone.replaceAll("-", "");
            }

            const formData: IWarrantyHistory = {
                ...values.data,
                phone: phone?.toString(),
            };

            apiStore.warrantyApi
                .createWarranty(formData)
                .then((response) => {

                    let v0 = values.data.isTransferRequest ? "transferred" : "created";
                    let title = `Warranty ${v0} successfully.`;
                    Toast.success({ title });

                    //navigate(`/warranty/view/${formData.hin}`);

                    navigate(-1);
                })
                .catch((error) => {
                    console.error(error);
        
                    if(values.data.isTransferRequest) {
                      setErrors({submit: "Unable to make transfer request. Make sure delivery date is not the same as previous warranty owners. "});
                    }
                    else {
                      setErrors({submit: "Unexpected Error."})
                    }
        
                    Toast.error({title: "Unable to save."});
                });
            
            setSubmitting(false);
        },
    });

    useEffect(() => {

        if(!formik.values.data.isTransferRequest) {

            let year = formik.values.data.deliveryDate;

            if(year) {

                let expirationDate: Date | null = null;
                
                switch(formik?.values.data.warrantyType) {
                  case "consumer":
                    expirationDate = addYears(new Date(year), 5);
                    break;
                  case "rental":
                    expirationDate = addYears(new Date(year), 0);
                    break;
                  case "club":
                    expirationDate = addYears(new Date(year), 1);
                    break;
                }

                if(expirationDate !== null) {
                  const formatedDate = format(expirationDate, "yyyy-MM-dd");
                  formik.setFieldValue("data.expirationDate", parseISO(formatedDate));
                }
                else {
                  formik.setFieldValue("data.expirationDate", null)
                }
            }
        }

    }, [formik?.values?.data?.deliveryDate, formik?.values?.data?.warrantyType]);



    if(pageDef === undefined) {
        return <PageLoader loading={true} />
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className="page-header-label">
                            {formik.values.data.isTransferRequest ? "Warranty Transfer" : "Warranty Registration"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing = {2}>
                    <Grid item md={12} lg={6}>
                        <Grid container spacing ={2}>
                            <Grid item xs={12}>
                                <MainCard title="DEALER INFORMATION" divider={true}>
                                    <SectionBox>
                                        <StackLabel title="Dealer Ship Location" label={formik.values.data?.dealerName + " (" + formik.values.data?.customerId + ")"}></StackLabel>
                                    </SectionBox>
                                </MainCard>
                            </Grid>

                            <Grid item xs= {12}>
                                <MainCard title="BOAT INFORMATION" divider={true}>
                                    <SectionBox>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <StackLabel title="Series" label={formik.values.data?.boatSeries}></StackLabel>
                                                <StackLabel title="Model" label={formik.values.data?.boatModel}></StackLabel>
                                                <StackLabel title="Model Year" label={formik.values.data?.modelYear}></StackLabel>
                                                <StackLabel title="HIN" label={formik.values.data?.hin}></StackLabel>
                                            </Grid>
                                            <Grid item xs={12} xl={6}>
                                                <InputLabel>Engine Model</InputLabel>
                                                <TextField 
                                                    fullWidth
                                                    size="small"
                                                    id="engineMake"
                                                    placeholder="Engine Model"
                                                    {...formik.getFieldProps("data.engineMake")}
                                                    error={Boolean(formik.touched.data?.engineMake && formik.errors.data?.engineMake)}
                                                />
                                                {formik.touched.data?.engineMake && formik.errors.data?.engineMake && (
                                                    <FormHelperText error>
                                                        {formik.errors.data?.engineMake}
                                                    </FormHelperText>
                                                )}

                                            </Grid>
                                            <Grid item xs={12} xl={6}>
                                                <InputLabel>Serial Number</InputLabel>
                                                <TextField 
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Serial Number"
                                                    {...formik.getFieldProps("data.serialNumber")}
                                                    error={Boolean(formik.touched.data?.serialNumber && formik.errors.data?.serialNumber)}
                                                />
                                                {formik.touched.data?.serialNumber && formik.errors.data?.serialNumber && (
                                                    <FormHelperText error>
                                                        {formik.errors.data?.serialNumber}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </SectionBox>
                                </MainCard>
                            </Grid>
                            <Grid item xs= {12}>
                                <MainCard title="PURCHASE INFORMATION">
                                    <SectionBox>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} lg={6}>
                                                <DatePicker
                                                    label="Date Purchased *"
                                                    id="data.purchaseDate"
                                                    small={true}
                                                    value={formik.values.data.purchaseDate}
                                                    onChange={(date: any) =>
                                                    formik.setFieldValue("data.purchaseDate", date)
                                                    }
                                                    handleBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.data?.purchaseDate && formik.errors.data?.purchaseDate)}
                                                />
                                                {formik.touched.data?.purchaseDate && formik.errors.data?.purchaseDate && (
                                                    <FormHelperText error>
                                                        {formik.errors.data?.purchaseDate}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <DatePicker
                                                    label="Delivery Date *"
                                                    id="data.deliveryDate"
                                                    small={true}
                                                    value={formik.values.data.deliveryDate}
                                                    onChange={(date: any) =>
                                                    {
                                                        formik.setFieldValue("data.deliveryDate", date);
                                                        
                                                        if(isValidDate(date)) {
                                                            formik.setFieldValue("data.effectiveDate",  parseISO(date));
                                                        }
                                                        else {
                                                            formik.setFieldValue("data.effectiveDate", null);
                                                        }
                                                    }}
                                                    handleBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.data?.deliveryDate && formik.errors.data?.deliveryDate)}
                                                />
                                                {formik.touched.data?.deliveryDate && formik.errors.data?.deliveryDate && (
                                                    <FormHelperText error>
                                                        {formik.errors.data?.deliveryDate}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </SectionBox>
                                </MainCard>
                            </Grid>
                            <Grid item xs={12}>
                                <MainCard title="WARRANTY INFORMATION">
                                    <SectionBox>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                {warrantyTypeOptions && (
                                                    <SelectDropdown
                                                        disabled={
                                                            InitialFormData.warrantyType !== "" && pageDef.isTransfer ? true :
                                                            (formik?.values?.data.deliveryDate ? false : true)
                                                        }
                                                        selectData={IWarrantySelect}
                                                        options={warrantyTypeOptions}
                                                        handleChange={formik.handleChange}
                                                        value={formik.values.data.warrantyType}
                                                        handleBlur={formik.handleBlur}
                                                        error={ formik.errors?.data?.warrantyType &&  formik?.touched?.data?.warrantyType 
                                                                ? formik.errors?.data?.warrantyType
                                                                : ""
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StackLabel title="Effective Date" 
                                                    label={formik.values.data.effectiveDate && format(new Date(formik.values.data.effectiveDate), "yyyy-MM-dd")}>
                                                </StackLabel>
                                                <StackLabel title="Expiration Date" 
                                                    label={formik.values.data.expirationDate && format(new Date(formik.values.data.expirationDate), "yyyy-MM-dd")}>
                                                </StackLabel>
                                                
                                            </Grid>
                                        </Grid>
                                    </SectionBox>
                                </MainCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <MainCard title="CUSTOMER INFORMATION" divider={true}>
                            <SectionBox>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} xl={6}>
                                        <InputLabel>First Name *</InputLabel>
                                        <TextField 
                                        fullWidth 
                                        size="small" 
                                        placeholder="First Name" 
                                        {...formik.getFieldProps("data.firstName")}
                                        error={Boolean(formik.touched.data?.firstName && formik.errors.data?.firstName)}
                                        />
                                        {formik.touched.data?.firstName && formik.errors.data?.firstName && (
                                        <FormHelperText error>
                                            {formik.errors.data?.firstName}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <InputLabel>Last Name *</InputLabel>
                                        <TextField 
                                        fullWidth 
                                        size="small" 
                                        placeholder="Last Name" 
                                        {...formik.getFieldProps("data.lastName")}
                                        error={Boolean(formik.touched.data?.lastName && formik.errors.data?.lastName)}
                                        />
                                        {formik.touched.data?.lastName && formik.errors.data?.lastName && (
                                        <FormHelperText error>
                                            {formik.errors.data?.lastName}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <InputLabel>Phone *</InputLabel>
                                        <NumericFormat
                                            id="phone"
                                            fullWidth
                                            size="small"
                                            format="(###)-###-####"
                                            customInput={TextField}
                                            placeholder="(###)-###-####"
                                            {...formik.getFieldProps("data.phone")}
                                            error={Boolean(formik.touched.data?.phone && formik.errors.data?.phone)}
                                        />
                                        {formik.touched.data?.phone && formik.errors.data?.phone && (
                                        <FormHelperText error>
                                            {formik.errors.data?.phone}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <InputLabel>Email *</InputLabel>
                                        <TextField 
                                            fullWidth 
                                            size="small" 
                                            placeholder="Email" 
                                            {...formik.getFieldProps("data.email")}
                                            error={Boolean(formik.touched.data?.email && formik.errors.data?.email)}
                                        />
                                        {formik.touched.data?.email && formik.errors.data?.email && (
                                        <FormHelperText error>
                                            {formik.errors.data?.email}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </SectionBox>
                            <SectionBox title="Address" divider={true}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <InputLabel>Address line 1 *</InputLabel>
                                        <TextField 
                                            fullWidth 
                                            size="small" 
                                            placeholder="Address line 1" 
                                            {...formik.getFieldProps("data.streetAddress1")}
                                            error={Boolean(formik.touched.data?.streetAddress1 && formik.errors.data?.streetAddress1)}
                                        />
                                        {formik.touched.data?.streetAddress1 && formik.errors.data?.streetAddress1 && (
                                        <FormHelperText error>
                                            {formik.errors.data?.streetAddress1}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel>Address line 2</InputLabel>
                                        <TextField 
                                            fullWidth 
                                            size="small" 
                                            placeholder="Address line 2" 
                                            {...formik.getFieldProps("data.streetAddress2")}
                                            error={Boolean(formik.touched.data?.streetAddress2 && formik.errors.data?.streetAddress2)}
                                        />
                                        {formik.touched.data?.streetAddress2 && formik.errors.data?.streetAddress2 && (
                                        <FormHelperText error>
                                            {formik.errors.data?.streetAddress2}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <InputLabel>City *</InputLabel>
                                        <TextField 
                                            fullWidth 
                                            size="small" 
                                            placeholder="City" 
                                            {...formik.getFieldProps("data.city")}
                                            error={Boolean(formik.touched.data?.city && formik.errors.data?.city)}
                                        />
                                        {formik.touched.data?.city && formik.errors.data?.city && (
                                        <FormHelperText error>
                                            {formik.errors.data?.city}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <InputLabel>State *</InputLabel>
                                        <TextField 
                                            fullWidth 
                                            size="small" 
                                            placeholder="State" 
                                            {...formik.getFieldProps("data.state")}
                                            error={Boolean(formik.touched.data?.state && formik.errors.data?.state)}
                                        />
                                        {formik.touched.data?.state && formik.errors.data?.state && (
                                        <FormHelperText error>
                                            {formik.errors.data?.state}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <InputLabel>Postal / Zip Code *</InputLabel>
                                        <TextField 
                                            fullWidth 
                                            size="small" 
                                            placeholder="Postal / Zip Code" 
                                            {...formik.getFieldProps("data.postalCode")}
                                            error={Boolean(formik.touched.data?.postalCode && formik.errors.data?.postalCode)}
                                        />
                                        {formik.touched.data?.postalCode && formik.errors.data?.postalCode && (
                                        <FormHelperText error>
                                            {formik.errors.data?.postalCode}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <InputLabel>Country *</InputLabel>
                                        <TextField 
                                            fullWidth 
                                            size="small" 
                                            placeholder="Country" 
                                            {...formik.getFieldProps("data.country")}
                                            error={Boolean(formik.touched.data?.country && formik.errors.data?.country)}
                                        />
                                        {formik.touched.data?.country && formik.errors.data?.country && (
                                        <FormHelperText error>
                                            {formik.errors.data?.country}
                                        </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </SectionBox>
                        </MainCard>
                        {formik.errors.submit && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <br/>
                                <FormHelperText error id="submit-helper">
                                    {formik.errors.submit}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        )}
                    </Grid>
                </Grid>
                <SubmitButtonGroup disabled={formik.isSubmitting} />
            </form>
        </>
    )
};

export default EditWarranty;