import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Typography } from './typography';

const ThemeCustomization: React.FC<{}> = (
    {
        children
    }
) =>
{
    const themeOptions = {
        typography: Typography,
        zIndex: {
            modal: 5000
        }
    };

    const themes = createTheme(themeOptions);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                    {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default ThemeCustomization;