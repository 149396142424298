import React, { useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import ChartWrapper from "../ChartWrapper";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

type barChartPropType = {
  data: any;
  height?: string;
  hasScroll?: boolean;
};

const BarChart = ({ data, height, hasScroll }: barChartPropType) => {
  useEffect(() => {}, []);

  const getColor = (bar: any) => {
    if (bar.data?.key % 2 == 0) {
      return "#FF8F6B";
    } else {
      return "#5B93FF";
    }
  };

  return (
    <ChartWrapper
      title="Total Leads per Month"
      height={height}
      hasScroll={hasScroll}
    >
      <ResponsiveBar
        data={data.filter((item: { value: number }) => item.value != 0)}
        keys={["value"]}
        borderRadius={5}
        enableGridY={false}
        //enableLabel={false}
        indexBy="name"
        margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
        padding={0.3}
        minValue={0}
        layout="horizontal"
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={getColor}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          format: function (value) {
            return value;
          },
          legendPosition: "middle",
          legendOffset: -40,
        }}
        
        //labelSkipWidth={12}
        //labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        tooltip={({ data, color }: any) => {
          const { name, value } = data;
          return <CustomTooltip label={name} value={value} color={color} />;
        }}
        role="application"
        ariaLabel="Total Leads per Month"
        
      />
    </ChartWrapper>
  );
};

export default BarChart;
