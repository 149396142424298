import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiStore } from "../../../api/ApiFactory";


export const fetchModelDetails = createAsyncThunk('modelDetails', () => {
    return apiStore.boatConfigApi.getModelDetails("1");
})


type initialStateType = {
    data: Array<any>,
    status: string
}
const initialState: initialStateType = {
    data: [],
    status: ""
}

const modelDetailSlice = createSlice({
    name: "modelDetails",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchModelDetails.fulfilled, (state, { payload }) => {
            state.data.push(payload.data);
            state.status = "success"
        })
        builder.addCase(fetchModelDetails.rejected, (state, { payload }) => {
            state.status = 'failed'
        })
        builder.addCase(fetchModelDetails.pending, (state, { payload }) => {
            state.status = 'loading'
        })
    },
})

export default modelDetailSlice.reducer;

