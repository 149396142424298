import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../../store';

const initialState = {
    data: []
}

const ruleSlice = createSlice({
    name: "series",
    initialState,
    reducers: {
        fetchRules: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { fetchRules } = ruleSlice.actions;

export const fetchRulesData = (value: any):
    AppThunk => (dispatch, getState) => {
        dispatch(fetchRules(value));

    };

export default ruleSlice.reducer;