import { combineReducers } from "redux";

//Reducers
import layoutReducer from "./slices/Layout/LayoutSlice";
import authReducer from "./slices/Auth/AuthSlice";
import SeriesReducer from "./slices/BoatConfiguration/SeriesSlice";
import ModelsReducer from "./slices/BoatConfiguration/ModelSlice";
import ModelDetailReducer from "./slices/BoatConfiguration/ModelDetailSlice";
import SnackbarReducer from "./slices/Snackbar/Snackbar";
import boatReducer from "./slices/BoatOrdering/BoatOrderingSlice";
import RuleReducer from "./slices/BoatConfiguration/BoatRules/RuleSlice";
import WarrantyReducer from "./slices/Warranty/WarrantySlice";
import DealerReducer from "./slices/Dealer/DealerSlice";
import TabReducer from "./slices/Tabs/TabSlice";
import LookupReducer from "./slices/Lookup/LookupSlice";
import SystemReducer from "./slices/System/SystemSlice";
import DashboardReducer from "./slices/Dashboard/DashboardSlice";

const appReducer = combineReducers({
  Layout: layoutReducer,
  Auth: authReducer,
  Boat: boatReducer,
  Series: SeriesReducer,
  Models: ModelsReducer,
  ModelDetails: ModelDetailReducer,
  Snackbar: SnackbarReducer,
  Rules: RuleReducer,
  Warranty: WarrantyReducer,
  Dealers: DealerReducer,
  Tabs:TabReducer,
  Lookup: LookupReducer,
  System: SystemReducer,
  Dashboard: DashboardReducer,
});

export default appReducer;
