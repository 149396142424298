import React, { useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import StepComponent from "../stepWizard/stepComponent";
import { IOptions } from "../../models/BoatOrdering";
import { apiStore } from "../../api/ApiFactory";
import CircleIcon from "@mui/icons-material/Circle";
import "./Style.scss";
import {
  getCurrentStep,
  getCompletedSteps,
} from "../../store/slices/BoatOrdering/BoatOrderingSelector";
import { useSelector } from "react-redux";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

type stepProps = {
  priceType: string;
  isView: boolean;
  stepHeading: String[];
  currentOptions?: IOptions[];
  salePriceMarkupOption?: string | null | number;
  salePriceMarkupMotor?: string | null | number;
  selectedModal: any;
  msrp: number;
};

export default function StepWizard({
  priceType,
  stepHeading,
  currentOptions,
  isView,
  salePriceMarkupOption,
  salePriceMarkupMotor,
  selectedModal,
  msrp,
}: stepProps) {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [open, setOpen] = React.useState<boolean>(false);
  const currentStep = useSelector(getCurrentStep);
  const completedSteps = useSelector(getCompletedSteps);

  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  const setCurrentStep = (id: number) => {
    apiStore.boatOrderingApi.setCurrentStep(id);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleComplete = () => {
    const { validationErrors }: any = selectedModal.sections[currentStep];
    if (validationErrors?.length) {
      setOpen(true);
      return null;
    }
    const newCompleted = JSON.parse(JSON.stringify(completedSteps));
    newCompleted[activeStep] = true;
    apiStore.boatOrderingApi.setCompletedSteps(newCompleted);
    setCurrentStep(currentStep + 1);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const _renderValidation = () => {
    const { validationErrors }: any = selectedModal.sections[currentStep];
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Validation Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List dense={true}>
              {validationErrors?.map((error: string, index: number) => {
                return (
                  <ListItem key={index}>
                    <ListItemIcon sx={{ minWidth: 15 }}>
                      <CircleIcon sx={{ fontSize: 6 }} />
                    </ListItemIcon>
                    <ListItemText primary={error} />
                  </ListItem>
                );
              })}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ width: "100%" }} className="step-wrapper">
      {_renderValidation()}
      <Stepper
        className="stepper-sm"
        nonLinear
        activeStep={activeStep}
        alternativeLabel
      >
        {stepHeading?.map((label: any, index) => {
          const { validationErrors }: any = selectedModal.sections[index];
          return (
            <Step
              className="steps-sm"
              key={label}
              completed={completedSteps[index]}
            >
              <StepButton
                color="inherit"
                className="step-btn"
                disabled={validationErrors?.length > 0 || index > currentStep}
                onClick={() => {
                  setCurrentStep(index);
                }}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <StepComponent
          priceType={priceType}
          isView={isView}
          stepHeading={stepHeading[currentStep]}
          currentOptions={currentOptions}
          salePriceMarkupOption={salePriceMarkupOption}
          salePriceMarkupMotor={salePriceMarkupMotor}
          msrp={msrp}
        />
        <Box
          className="step-actions"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            pt: 2,
          }}
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            variant="contained"
          >
            Back
          </Button>
          <Button
            onClick={handleComplete}
            variant="contained"
            endIcon={<ArrowCircleRightOutlinedIcon />}
          >
            Continue
          </Button>
        </Box>
      </div>
    </Box>
  );
}
