import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { GetAuthStatus } from "../../services/AuthService";

const AuthLayout = () => {

    const authStatus = GetAuthStatus();

    //in case a user navigates to an auth endpoint like login/register but they are already authenticated.
    if(authStatus.isAuthenticated)
    {
        return <Navigate replace to="/" />;
    }
    else
    {  
        return (
            <div className="auth-layout-wrapper"> 
                <Outlet />
            </div>
        )
    }
}

export default AuthLayout;