
import agent from "./agent";
import { ApiFactory, apiStore } from "./ApiFactory";
import { IUserExtended, IUserFormFields, UserProfile } from "../models/User";
import { Result } from "../models/Common";
import { IAuthUser } from "../models/Auth";
import { setAuthState } from "../store/slices/Auth/AuthSlice";

export default class UserApi {

    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
        this.apiFactory = apiFactory;
    }

    getFormData = async () => {
        try {
            const user: IUserFormFields = await agent.api.get('/usermanagement/getFormData', null);
            return user;
        }
        catch (error) {
            throw error;
        }
    }

    saveFormData = async (user: IUserExtended) => {
        try {

            user.roles = [user.role];

            const result: Result<IUserExtended> = await agent.api.post('/usermanagement', user);
            return result;
        }
        catch (error) {
            throw error;
        }
    }

    updateName = async (user: IUserExtended) => {
        try {
            const result: IUserExtended = await agent.api.post("/user/updatename", user);
            var token = this.apiFactory.store.getState().reducer.Auth.authuser?.token;

            var authUser: IAuthUser = {
                user: result,
                token: token!
            }

            this.apiFactory.store.dispatch(setAuthState(authUser));
        }
        catch (error) {
            throw error;
        }
    }

    updateContact = async (user: IUserExtended) => {
        try {
            const result: IUserExtended = await agent.api.post("/user/updatecontact", user);
            var token = this.apiFactory.store.getState().reducer.Auth.authuser?.token;

            var authUser: IAuthUser = {
                user: result,
                token: token!
            }

            this.apiFactory.store.dispatch(setAuthState(authUser));
        }
        catch (error) {
            throw error;
        }
    }
}
