export const DefaultCostType = [
  { value: "Dealer Cost", key: "dealerCost" },
  { value: "Sales Price", key: "salesPrice" },
  { value: "MSRP", key: "msrp" },
  { value: "MAP", key: "map" },
];

export const PrintFormatType = [
    { value: "Print", key: "print" },
    { value: "Export", key: "export"},
]

export enum Roles {
  salesRep = "Sales Rep",
  dealer = "Dealer",
  admin = "Admin",
}
