import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { apiStore } from '../../api/ApiFactory';
import { useAppSelector } from '../../store/hooks/hook';
const DealerDetailIndex = () => {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const { id } = useParams();
    
    const {
        dealers
    } = useAppSelector((state) => ({
        dealers: state.reducer.Dealers.dealerList
    }));

    //The intent of this extra code is to retrieve dealers in the case the user navigates to this url directly or if the user does a hard refresh.
    useEffect(() => {

        setIsLoading(true);

        apiStore.lookupApi.getDesignationType();

        apiStore.lookupApi.getCountryList()
        .then((result) => {
            if(id != undefined && dealers.length <= 0) {
                let result = apiStore.dealerApi.getDealerList({includeInactive: true});
                result.then((response) => {
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate('/dealer');
                })
            }
            else {
                setIsLoading(false);
            }
        })

    }, [id])

    if(isLoading) {
        return (
            <>
                {"Loading...."}
            </>
        )
    }

    return (
        <>
            <Outlet />
        </>
    )
}

export default DealerDetailIndex