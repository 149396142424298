import { Button, Stack, Typography, Grid, FormGroup, Checkbox, FormControlLabel } from '@mui/material'
import React,{useEffect} from 'react'


type displayModelListProps = {
  modelList : [] , 
  selectedModelList:[],
  setSelectedModelList:(param:[])=>void
}


const DisplayModelList = ({modelList , selectedModelList , setSelectedModelList}:displayModelListProps) => {
  
  const handleChange = (event:any)=>{
    const tempState:any = [...selectedModelList];
    const selectedModelId = event.target.value;
    const isChecked = event.target.checked;
    console.log(isChecked);
    if(isChecked && !tempState.includes(selectedModelId))
    {
      tempState.push(selectedModelId);
    }else if(!isChecked && tempState.includes(selectedModelId))
    {
      const indexOfElementToDelete = tempState.indexOf(selectedModelId);
      tempState.splice(indexOfElementToDelete, 1);
    }
   setSelectedModelList(tempState);  
  }


  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography>Configure For</Typography>
        <Button>(Select All)</Button>
      </Stack>
      <Grid container >
        <Grid item>
          <FormGroup>
           {
            modelList?.length > 0  && modelList.map((model:any,index:number)=> <FormControlLabel 
            onChange={handleChange}
            key={index}
            control={<Checkbox value={model?.id}     />} 
            label={`${model?.displayName}`} />)
           }
          </FormGroup>
        </Grid>
      </Grid>
    </>
  )
}

export default DisplayModelList