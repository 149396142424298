import { mdiDeleteOutline, mdiEye, mdiPencilOutline, mdiPrinter } from "@mdi/js";
import { TableCell } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";
import { Row, TableInstance } from "react-table";
import { TableMouseEventHandler } from "../../types/react-table-config";
import { RowActionButton } from "../button/SmallIconActionButton";
import { Command } from "./ICommand";

type IProps<T extends Record<string, unknown>> = {
    instance: TableInstance<T>;
    onDelete?: TableMouseEventHandler<T>;
    onEdit?: TableMouseEventHandler<T>;
    onView?: TableMouseEventHandler<T>;
    onPrint?: TableMouseEventHandler<T>;
    //extraCommands allows us to add any generic command we want.
    extraCommands?: Command<T>[];
    row: Row<T>
  };

export function SmallScreenRowAction<T extends Record<string, unknown>>({
    instance,
    onDelete,
    onEdit,
    onView,
    onPrint,
    extraCommands = [],
    row
  }: PropsWithChildren<IProps<T>>): ReactElement | null {
    const { columns, selectedFlatRows } = instance;

    const showActions =
    !!onEdit ||
    !!onDelete ||
    !!onPrint ||
    !!extraCommands ||
    !!onView;

    if (showActions) {

        return (

            <TableCell scope={"Actions"} >
                <span>
                    {onEdit && (
                        <RowActionButton<T>
                            instance={instance}
                            icon={mdiPencilOutline}
                            onClick={ onEdit }
                            label="Edit"
                            variant="left"
                            row={row}
                        />
                    )}
                    {onDelete && (
                        <RowActionButton<T>
                            instance={instance}
                            icon={mdiDeleteOutline}
                            onClick={onDelete}
                            label="Delete"
                            row={row}
                            variant="left"
                        />
                    )}

                    {onPrint && (
                        <RowActionButton<T>
                            instance={instance}
                            icon={mdiPrinter}
                            onClick={onPrint}
                            label="Print"
                            row={row}
                            variant="left"
                        />
                    )}
                    {onView && (
                        <RowActionButton<T>
                            instance={instance}
                            icon={mdiEye}
                            onClick={onView}
                            label="View"
                            row={row}
                            variant="left"
                        />
                    )}
                    {extraCommands.map((c) => {
                        return <RowActionButton<T>
                            instance={instance}
                            icon={c.icon}
                            onClick={c.onClick}
                            label={c.label}
                            row={row}
                            variant="left"
                        />
                    })}
                </span>
            </TableCell>
        )
    }
    
    return (
        <></>
    )
  }