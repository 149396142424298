import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from "@mui/lab";
import { IWarrantyHistory } from "../../models/WarrantyManagement"
import { Grid, Typography } from "@mui/material";
import { getAddressObject } from "../../helpers/utils/getAddressObject";
import { AddressLabel } from "../../components/label/AddressLabel";
import { format } from "date-fns";


type IProps = {
    list: Array<IWarrantyHistory>;
}

const getAddress = (record: IWarrantyHistory) => {

    return getAddressObject(
      record?.streetAddress1,
      record?.streetAddress2,
      record?.city,
      record?.state,
      record?.country,
      record?.postalCode
    )
  }

const WarrantyTimeline = (props: IProps) => {

    return (
        <Timeline
            sx={{[`& .${timelineItemClasses.root}:before`]: {flex: 0, padding: 0, }}}
        >

        {props.list.map((record, index) => (
            <TimelineItem key={index}>
                <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>

                            <Typography variant="subtitle2" className="bold-label">
                                {`${record?.firstName} ${record?.lastName}`}
                            </Typography>
                            <Typography variant="subtitle2">
                                {record?.email} 
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AddressLabel address={getAddress(record)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" className="warrantyDate">
                                Start Date
                            </Typography>
                            <Typography variant="subtitle2" className="warrantyDate">
                                {record?.effectiveDate &&
                                    format(new Date(record?.effectiveDate),"yyyy-MM-dd")
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" className="warrantyDate">
                                Expiration Date
                            </Typography>
                            <Typography variant="subtitle2" className="warrantyDate">
                                {record?.expirationDate &&
                                    format(new Date(record?.expirationDate),"yyyy-MM-dd")
                                }
                            </Typography>
                        </Grid>
                    </Grid>          
                    
                </TimelineContent>
            </TimelineItem>
        ))}


        </Timeline>        

    );


}

export default WarrantyTimeline;