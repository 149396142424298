import { Grid, Typography } from "@mui/material";
import { IOrderPrint, ItemsEntity } from "../../../models/Print";
import { StackLabel } from "../../../components/label/StackLabel";
import { calculatePriceByType } from "../../../helpers/utils/utils";
import { useEffect, useState } from "react";

const WindowStickerPricing = ({ data }: { data: IOrderPrint }) => {
  const [priceData, setPriceData] = useState<ItemsEntity[]>();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<{
    [key: string]: boolean;
  }>({});

  const [hasAdditionalPricing, setHasAdditionalPricing] = useState<boolean>(false);

  useEffect(() => {
    const storedCheckboxes = sessionStorage.getItem("selectedCheckboxes");
    if (storedCheckboxes) {
      setSelectedCheckboxes(JSON.parse(storedCheckboxes));
    }
    updateData(data);

  }, []);

  useEffect(() => {
    let exists = data.additionalItems.filter((item) => selectedCheckboxes.hasOwnProperty(item.name)).length > 0
    setHasAdditionalPricing(exists);
  }, [selectedCheckboxes]);


  const updateData = (data: IOrderPrint) => {
    let options = [...data.items];
    options = options.filter((o) => o.price !== 0);
    options = options.map((item) => {
      return { ...item, price: calculatePriceByType(item.price, "msrp", data.msrp) };
    });

    setPriceData(options);
  };

  return (
    <>
      {priceData && (
        <Grid justifyContent="space-between">
          <Typography className="window-sticker-header">Options</Typography>
          {priceData.map((item, index) => {
            let title = item.optionName + ":" + item.name;

            return (
              <StackLabel
                key={index}
                title={title}
                label={item.price.toString()}
                className="pricing"
                variant="print"
                noBoldTitle
                currency
              />
            );
          })}
          {data.additionalItems && data.additionalItems.length > 0 && hasAdditionalPricing && (
            <Grid sx={{ paddingTop: "8px" }}>
              <Typography className="window-sticker-header">
                Additional Options & Considerations
              </Typography>
              {data.additionalItems
                .filter((item) => selectedCheckboxes.hasOwnProperty(item.name))
                .map((item, index) => {
                  return (
                    <StackLabel
                      key={index}
                      title={item.name}
                      label={item.price.toString()}
                      className="pricing"
                      variant="print"
                      noBoldTitle
                      currency
                    />
                  );
                })}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default WindowStickerPricing;
