import {
    Grid,
    Typography,
    Button,
    Stack,
    IconButton
  } from "@mui/material";
  import { apiStore } from "../../api/ApiFactory";
  import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
  import { format } from "date-fns";
  import { enumWarrantyType } from "../../helpers/utils/utils";
  import React, { useState, useEffect } from "react";
  import { MainCard } from "../../components/surface/MainCard";
  import { SectionBox } from "../../components/surface/SectionBox";
  import { StackLabel } from "../../components/label/StackLabel";
  import { IWarrantyHistory } from "../../models/WarrantyManagement";
  import { AddressLabel } from "../../components/label/AddressLabel";
  import { getAddressObject } from "../../helpers/utils/getAddressObject";
  import WarrantyTimeline from "./WarrantyTimeline";
  import PrintIcon from "@mui/icons-material/Print";
  
  const WarrantyDetails = () => {
  
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
  
    const [details, setDetails] = useState<IWarrantyHistory>();
    const [history, setHistory] = useState<Array<IWarrantyHistory>>();
  
    useEffect(() => {
  
      if(params?.id !== undefined) {
        const id: any = params?.id;

        apiStore.warrantyApi
          .getWarrantyDetails({hin: id})
          .then((res: any) => {
            setDetails(res[0]);
            setHistory(res);
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
      
    }, [params?.id]);
  
    const _renderWarrantyType = () => {
      let type: any = enumWarrantyType?.find(
        (item: any) => item.key === details?.warrantyType
      );
      return type?.value ?? "N/A";
    };
  
    const handlePrint = () => {
      window.print();
    }
  
    const getAddress = () => {
  
      return getAddressObject(
        details?.streetAddress1,
        details?.streetAddress2,
        details?.city,
        details?.state,
        details?.country,
        details?.postalCode
      )
    }
  
    const transferWarranty = () => {
      navigate(`/warranty/details/${details?.hin}/transfer`);
    }
  
    return (
      <>
  
      {details && (
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            
            <Stack 
              direction={{xs: 'column', sm: 'row'}}
              justifyContent={{xs:'space-between'}}
              className="page-header-stack"
              alignItems={{xs: 'start', sm: 'end'}}
              spacing={2}
            >
              <Typography component="span" className="page-header-label nbm">
                  {"Warranty Detail"}
              </Typography>
              <Stack
                direction="row"
                width={{xs: '100%', sm: 'inherit'}}
                justifyContent={{xs: 'space-between', sm: 'inherit'}}
                spacing={2}
              >
                  <Button color="success" variant="contained" endIcon={<PrintIcon/>} onClick={handlePrint}>Print</Button>
                  <Button variant="contained" onClick={transferWarranty}  >Transfer Warranty</Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} id="print">
              <Grid item md={12} lg={7} className="print-page">
                <MainCard title="GENERAL INFORMATION" divider={true}>
                  <SectionBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <MainCard subcard title="Consumer Details" divider={true}>
                          <SectionBox>
                            <StackLabel title="Name" label={`${details?.firstName} ${details?.lastName}`}></StackLabel>
                            <StackLabel title="Email" label={details?.email}></StackLabel>
                            <StackLabel title="Phone" label={details?.phone}></StackLabel>
                          </SectionBox>
                          <SectionBox>
                            <AddressLabel address={getAddress()} title="Address" />
                          </SectionBox>
                        </MainCard>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MainCard subcard title="Boat Details" divider={true}>
                          <SectionBox>
                            <StackLabel title="Series" label={details?.boatSeries}></StackLabel>
                            <StackLabel title="Model" label={details?.boatModel}></StackLabel>
                            <StackLabel title="Year" label={details?.modelYear}></StackLabel>
                            <StackLabel title="HIN" label={details?.hin}></StackLabel>
                            <StackLabel title="Engine Model" label={details?.engineMake}></StackLabel>
                            <StackLabel title="Serial Number" label={details?.serialNumber}></StackLabel>
                          </SectionBox>
                        </MainCard>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MainCard subcard title="Purchase Details" divider={true}>
                          <SectionBox>
                            <StackLabel title="Purchase Date" label={details?.purchaseDate && format(new Date(details?.purchaseDate), "yyyy-MM-dd")}></StackLabel>
                            <StackLabel title="Date Delivered to Consumer" label={details?.deliveryDate && format(new Date(details?.deliveryDate), "yyyy-MM-dd")}></StackLabel>
                          </SectionBox>
                        </MainCard>
                      </Grid>
                    </Grid>
  
                  </SectionBox>
                </MainCard>
              </Grid>
              <Grid item md={12} lg={5} className="print-page">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
  
                    <MainCard title="WARRANTY INFORMATION" divider={true}>
                      <SectionBox>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <StackLabel title="Warranty Type" label={_renderWarrantyType()}></StackLabel>
                          </Grid>
                          <Grid item xs={12}>
                            {history && (
                              <WarrantyTimeline list={history} />
                            )}
                          </Grid>
                        </Grid>
                      </SectionBox>
                    </MainCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      </>
    );
  };
  export default WarrantyDetails;
  