//icons
import {
} from "@mdi/js";
import { useActiveRoutePaths } from "../../../services/RouteService";
import DashboardIcon from "../../../_assets/images/sidebar-icons/dashboard-icon.svg";
import DashboardActiveIcon from "../../../_assets/images/sidebar-icons/dashboard-active-icon.svg";
import OrderIcon from "../../../_assets/images/sidebar-icons/order-icon.svg";
import OrderActiveIcon from "../../../_assets/images/sidebar-icons/order-active-icon.svg";
import WarrantyHistoryIcon from "../../../_assets/images/sidebar-icons/warranty-history-icon.svg";
import WarrantyHistoryActiveIcon from "../../../_assets/images/sidebar-icons/warranty-history-active-icon.svg";
import UserHistoryIcon from "../../../_assets/images/sidebar-icons/user-history-icon.svg";
import UserHistoryActiveIcon from "../../../_assets/images/sidebar-icons/user-history-active-icon.svg";
import SettingIcon from "../../../_assets/images/sidebar-icons/setting-icon.svg";
import SettingActiveIcon from "../../../_assets/images/sidebar-icons/setting-active-icon.svg";
import OrderHistoryIcon from "../../../_assets/images/sidebar-icons/order-history-icon.svg";
import OrderHistoryActiveIcon from "../../../_assets/images/sidebar-icons/order-history-active-icon.svg";
import ProfileIcon from "../../../_assets/images/sidebar-icons/profile-icon.svg";
import ProfileActiveIcon from "../../../_assets/images/sidebar-icons/profile-active-icon.svg";
import PersonalCardIcon from "../../../_assets/images/sidebar-icons/personal-card-icon.svg";
import PersonalCardActiveIcon from "../../../_assets/images/sidebar-icons/personal-card-active-icon.svg";
import MarketingIcon from "../../../_assets/images/sidebar-icons/note-text-icon.svg";
import MarketingActiveIcon from "../../../_assets/images/sidebar-icons/note-text-active-icon.svg";
import AssessmentIcon from "../../../_assets/images/sidebar-icons/assessment.svg";
import AssessmentActiveIcon from "../../../_assets/images/sidebar-icons/assessment-active-icon.svg";
import { OpacityRounded } from "@mui/icons-material";

export interface MenuItemProps {
  activeIcon?: string;
  label?: string;
  icon?: string;
  link?: string;
  children?: Array<MenuItemProps>;
  isHeader?: boolean;
}

const _menuItems: Array<MenuItemProps> = [
  {
    icon: DashboardIcon,
    activeIcon: DashboardActiveIcon,
    link: "/dashboard",
  },
  {
    icon: OrderIcon,
    activeIcon: OrderActiveIcon,
    link: "/boat",
  },
  {
    icon: OrderHistoryIcon,
    activeIcon: OrderHistoryActiveIcon,
    link: "/orders",
  },
  {
    icon: PersonalCardIcon,
    activeIcon: PersonalCardActiveIcon,
    link: "/leads",
  },
  {
    icon: WarrantyHistoryIcon,
    activeIcon: WarrantyHistoryActiveIcon,
    link: "/warranty"
  },
  
  /*
  {
    icon: WarrantyHistoryIcon,
    activeIcon: WarrantyHistoryActiveIcon,
    isHeader: true,
    label: "Warranty",
    children: [
      {
        link: "/warranty",
        label: "Registrations",
      },
      {
        link: "/warranty",
        label: "Pre-Authorizations",
      },
      {
        link: "/warranty",
        label: "Claims",
      },
    ]
  },
  */
  {
    icon: MarketingIcon,
    activeIcon: MarketingActiveIcon,
    link: "/documents",
  },
  {
    icon: AssessmentIcon,
    activeIcon: AssessmentActiveIcon,
    link: "/report",
  },
  {
    icon: ProfileIcon,
    activeIcon: ProfileActiveIcon,
    link: "/user",
  },
  {
    icon: UserHistoryIcon,
    activeIcon: UserHistoryActiveIcon,
    link: "/dealer",
  },
  {
    icon: SettingIcon,
    activeIcon: SettingActiveIcon,
    link: "/config",
  },
];

const useMenuItems = () => {
  const authMenuItems: MenuItemProps[] = [];

  const activeRoutePaths = useActiveRoutePaths();

  _menuItems.forEach((item) => {
    let menuItem: MenuItemProps = { ...item };

    const route = activeRoutePaths.find((x) => 
      x.path === item.link 
      || 
      (item.isHeader !== undefined && item?.isHeader === true && item.children?.some(c => x.path === c.link) === true)
    );

    if (route) {
      menuItem.label = route.title;
      authMenuItems.push(menuItem);
    }
  });

  return authMenuItems;
};

export { useMenuItems };
