import {
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { PropsWithChildren, ReactElement, useCallback } from "react";
import { RowsPerPageOptions } from "../../helpers/constants/TableConfig";
import useEffectSkipInitialRender from "../../helpers/utils/useEffect";
import { TablePaginationProps } from "./TableProps";

export function TablePagination<T extends Record<string, unknown>>({
  instance,
}: PropsWithChildren<TablePaginationProps<T>>): ReactElement | null {
  const {
    gotoPage,
    setPageSize,
    nextPage,
    previousPage,
    pageCount,
    pageChangeHandler,
    state: { pageSize, pageIndex },
  } = instance;

  const updatePage = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      var baseline = pageIndex + 1;

      if (page === baseline + 1) {
        nextPage();
      } else if (page === baseline - 1) {
        previousPage();
      } else {
        gotoPage(page - 1);
      }
      managePageChange(pageSize, page - 1);
    },
    [gotoPage, nextPage, pageIndex, previousPage]
  );

  const updatePageSize = useCallback(
    (pageSize: number) => {
      if (pageIndex !== 0) {
        gotoPage(0);
      }
      setPageSize(pageSize);
      managePageChange(pageSize, pageIndex);
    },
    [pageSize, setPageSize]
  );

  useEffectSkipInitialRender(() => {
    //console.log("Page Size Changed");
  }, [pageSize]);

  function managePageChange(pageSize: number, pageIndex: number) {
    //console.log("managePageChange", pageSize, pageIndex);
    if (!!pageChangeHandler) {
      pageChangeHandler({ currentPage: pageIndex, pageSize: pageSize });
    }
  }

  return pageCount > 1 ? (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className="tablePagination"
      sx={{ width: "auto", py: 2 }}
    >
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" sx={{ color: "#8c8c8c" }}>
              Row per page
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <Select
                dir="rtl"
                id="page"
                value={pageSize}
                onChange={(e) => {
                  updatePageSize(Number(e.target.value));
                }}
                size="small"
                label=""
                displayEmpty
                sx={{ "& .MuiSelect-select": { py: 0.75, px: 1.25 } }}
              >
                {RowsPerPageOptions.map((val, idx) => (
                  <MenuItem key={idx} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Typography variant="caption" sx={{ color: "#8c8c8c" }}>
            Go to
          </Typography>
          <TextField
            size="small"
            type="number"
            InputLabelProps={{ shrink: false }}
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 0;
              updatePage(e, page);
              //gotoPage(page - 1);
            }}
            sx={{
              "& .MuiOutlinedInput-input": { py: 0.75, px: 1.25, width: 36 },
            }}
          />
        </Stack>
      </Grid>
      <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
        <Pagination
          size="small"
          siblingCount={0}
          count={pageCount}
          page={pageIndex + 1}
          onChange={updatePage}
          color="primary"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  ) : null;
}
