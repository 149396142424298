import { useMediaQuery } from '@mui/material';
import { ResponsiveLine } from '@nivo/line'
import ChartWrapper from '../ChartWrapper';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import { IsMobileView } from '../utils/isMobileView';


type lineChartProps = {
  data: any,
  height?: string,
  hasScroll?: boolean
}


const LineChart = ({ data, height, hasScroll }: lineChartProps) => {
  
  let formatAxisData = (month: any) => {
    if (IsMobileView()) {
      return `${month?.slice(0, 1)}`
    }
    else {
      return `${month?.slice(0)}`
    }
  }

  const orderData = data.map((item: any) => { return { "key": `${item?.key}`, "x": `${item?.name}`, "y": item?.value } });

  let graphData = [{
    "id": "lineChart",
    "color": "hsl(145, 70%, 50%)",
    "data": orderData.length > 0 ? orderData : [{key: "empty", x: "No Boat Orders", y: 0}]
  }];

  return (
    <ChartWrapper title="Boat Orders" hasScroll={hasScroll}>
      <ResponsiveLine
        data={graphData}
        margin={{ top: 20, right: 40, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: "auto",
          stacked: true,
          reverse: false
        }}
        colors={["#5BC4FF"]}
        enableArea={true}
        areaOpacity={0.1}
        enablePoints={false}
        useMesh={true}
        curve="cardinal"
        axisBottom={{
          format: (month: any) => formatAxisData(month),
          tickSize: 0,
          tickPadding: 12
        }}
        defs={[
          {
            id: 'gradientC',
            type: 'linearGradient',
            colors: [
              { color: '#FF8F6B' },
              { color: '#5B93FF' },
            ],
          }
        ]
        }
        tooltip={({ point }: any) => {
          const { x, y } = point?.data;
          const { color } = point;
          return (
            <>
              <CustomTooltip label={x} value={y} color={color} />
            </>
          )
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 12,
        }}
      />
    </ChartWrapper>
  )
}

export default LineChart;