import { Permissions } from "../helpers/enums/AuthConstants";
import { useAuth }  from "../store/hooks/useAuth";
import { IUser, IUserExtended } from "../models/User";
import { Roles } from "../helpers/enums/Common";

interface AuthStatus {
    isAuthenticated: boolean;
    isAuthorized: boolean;
    isLegacy: boolean;
}

const GetAuthStatus = (permissions?: Array<Permissions>) => {

    let authstatus: AuthStatus = {
        isAuthenticated: true,
        isAuthorized: true,
        isLegacy: false
    } 

    let user = useAuth().authuser?.user;

    authstatus.isAuthenticated = !!user;
    authstatus.isAuthorized = IsAuthorized(user, permissions);
    authstatus.isLegacy = user?.isLegacyDealer ?? false;
  
    return authstatus;
}

const IsAuthorized = (user: IUser | undefined, permissions?: Array<Permissions>) => {

    if(!!permissions)
    {
        let validPermissions = permissions?.filter( PP => user?.permissions?.find(UP => UP === PP));
        return validPermissions.length > 0;
    }

    return true;
}

//If Role is specified then users assigned to that role must have that permission, else permission not required.
const HasPermission = (user: IUserExtended | undefined, permission: Permissions, requiredForRole?: Roles) => {

    if(user === undefined) {
        return false; //User is not authenticated.
    }

    if(requiredForRole !== undefined) {

        if(requiredForRole !== user.role) {
            return true;
        }
    }
        
    let deriviedPermission = !!user?.permissions?.find(p => p === permission);

    return !!deriviedPermission;
}

const GetUserPermissions = () => {
    return useAuth().authuser?.user?.permissions;
}

const IsLegacyDealerUser = () => {
    return useAuth().authuser?.user?.isLegacyDealer ?? false;
}

export { GetAuthStatus, GetUserPermissions, HasPermission, IsLegacyDealerUser }