import { createSlice } from '@reduxjs/toolkit';
import { ISeries } from '../../../models/Configuration';
import { AppThunk } from '../../store';

const initialState = {
    data: []
}

const seriesSlice = createSlice({
    name: "series",
    initialState,
    reducers: {
        fetchSeries: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { fetchSeries } = seriesSlice.actions;

export const fetchSeriesData = (value: ISeries):
    AppThunk => (dispatch, getState) => {
        dispatch(fetchSeries(value));

    };

export default seriesSlice.reducer;