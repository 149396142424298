import React, { useState, useEffect, useContext } from "react";
import { WithAxios } from "../../api/agent";
import { Alert, Snackbar } from "@mui/material";
const dismissTime = 2000;
const NotificationContext = React.createContext<any | undefined>(undefined);
const NotificationProvider = (props: any) => {
  const [list, setList] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const id = Math.floor(Math.random() * 101 + 1);
  let properties: any = null;
  const Toast = {
    success: (data: any) => {
      properties = {
        id,
        type: "success",
        title: data?.title ?? "Success",
        description: data?.description,
      };
      setList(properties);
      setOpen(true);
    },
    error: (data: any) => {
      properties = {
        id,
        type: "error",
        title: data?.title ?? "Error",
        description: data?.description,
      };
      setList(properties);
      setOpen(true);
    },
  };

  useEffect(() => {
    if (list?.title) {
      setOpen(true);
    }
  }, [list]);

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
      setList(null);
    }, dismissTime);
  };

  return (
    <NotificationContext.Provider value={{ Toast }}>
      {open && (
        <Snackbar
          open={open}
          className="top-90"
          onClose={handleClose}
          autoHideDuration={dismissTime}
          resumeHideDuration={dismissTime}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={list?.type} sx={{ width: "100%" }} variant="filled">
            {list?.title ? list.title : ""}
          </Alert>
        </Snackbar>
      )}
      <WithAxios>{props.children}</WithAxios>
    </NotificationContext.Provider>
  );
};

const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

export { NotificationProvider, useNotification };
