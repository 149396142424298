import Icon from "@mdi/react"
import { IconButton, Tooltip } from "@mui/material"
import classnames from "classnames"
import { MouseEventHandler, ReactElement, useCallback } from "react"
import { Row, TableInstance } from "react-table"
import { TableMouseEventHandler } from "../../types/react-table-config"

type ActionButton = {
    icon: string
    onClick: MouseEventHandler
    enabled?: boolean
    label: string
    variant?: 'right' | 'left'
  }

export const IconActionButton = ({
    icon,
    onClick,
    label,
    enabled = true,
    variant,
  }: ActionButton): ReactElement => {

    return (
      <Tooltip title={label} aria-label={label}>
        <span>
          <IconButton
            className={classnames({
              "icon-right": variant === 'right',
              "icon-left": variant === 'left',
            })}
            onClick={onClick}
            disabled={!enabled}
          >
            <Icon path={icon} size="24px" />
          </IconButton>
          
        </span>
      </Tooltip>
    )
  }


//Instanced Variant of SmallIconActionButton (used for Tables)
  type TableActionButton<T extends Record<string, unknown>> = {
    instance: TableInstance<T>
    icon: any
    onClick: TableMouseEventHandler<T>
    enabled?: (instance: TableInstance<T>) => boolean
    label: string
    variant?: 'right' | 'left'
  }

  export const TableIconActionButton = <T extends Record<string, unknown>>({
    instance,
    icon,
    onClick,
    label,
    enabled = () => true,
    variant,
  }: TableActionButton<T>): ReactElement => {

    return (
      <Tooltip title={label} aria-label={label}>
        <span>
          <IconButton
            className={classnames({
                "icon-right": variant === 'right',
                "icon-left": variant === 'left',
            })}
            onClick={onClick(instance)}
            disabled={!enabled(instance)}
          >
            <Icon path={icon} size="24px" />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

   //Instanced Variant of SmallIconActionButton (used for Tables)
   type RowActionButton<T extends Record<string, unknown>> = {
    instance: TableInstance<T>
    icon: any
    onClick: TableMouseEventHandler<T>
    enabled?: (instance: TableInstance<T>) => boolean
    label: string
    variant?: 'right' | 'left'
    row: Row<T>
  }

  export const RowActionButton = <T extends Record<string, unknown>>({
    instance,
    icon,
    onClick,
    label,
    enabled = () => true,
    variant,
    row
  }: RowActionButton<T>): ReactElement => {

    const ClickEvent = useCallback(
      (instance: TableInstance<T>) => {

        instance.toggleAllRowsSelected(false);

        console.log(row);

        //instance.toggleRowSelected(row.id, true);
        row.toggleRowSelected(true);

        setTimeout(() => {
          row.toggleRowSelected(true);
        }, 50); 
      },
      []
    );

    return (
      <Tooltip title={label} aria-label={label}>
        <span>
          <IconButton
            className={classnames({
                "icon-right": variant === 'right',
                "icon-left": variant === 'left',
            })}
            onClick={ 
              e => {
                ClickEvent(instance);

                setTimeout(() => {
                  var test = onClick(instance);
                  test(e);
                }, 50); 
        

              }
            }

            disabled={!enabled(instance)}
          >
            <Icon path={icon} size="24px" />

          </IconButton>
        </span>
      </Tooltip>
    )
  }