import React from "react";
import { ResponsivePie } from "@nivo/pie";
import ChartWrapper from "../ChartWrapper";
import { IsMobileView } from "../utils/isMobileView";
import { schemePastel1 } from "d3-scale-chromatic";

type pieChartType = {
  data: any;
  height?: string;
  hasScroll?: boolean;
};

const PieChart = ({ data, hasScroll }: pieChartType) => {
  let legendWidth = 70;
  let legendTranslateToX = 0;
  let formatPieChartData = () => {
    if (IsMobileView()) {
      legendTranslateToX = 15;
      legendWidth = 70;
      return data.map((item: any) => {
        return {
          id: item?.key,
          label: item?.key.replace("Series", ""),
          value: item?.value,
        };
      });
    } else {
      legendTranslateToX = 180;
      legendWidth = 95;
      return data.map((item: any) => {
        return {
          id: item?.key,
          label: item?.key.replace("Series", ""),
          value: item?.value,
        };
      });
    }
  };
  let pieChartData = formatPieChartData();

  const generateColors = (dataLength: number) => {
    const colorScale = schemePastel1;
    return Array.from(
      { length: dataLength },
      (_, index) => colorScale[index % colorScale.length]
    );
  };

  return (
    <ChartWrapper title="Order by Boat Series" hasScroll={hasScroll}>
      <ResponsivePie
        colors={generateColors(pieChartData.length)}
        data={pieChartData.filter((item: { value: number }) => item.value != 0)}
        margin={{ top: 10, right: 25, bottom: 60, left: 25 }}
        innerRadius={0.6}
        padAngle={0}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={0}
        arcLabelsSkipAngle={10}
        enableArcLinkLabels={false}
        legends={[
          {
            anchor: "bottom",
            direction: "column",
            justify: false,
            translateX: legendTranslateToX,
            translateY: 40,
            itemsSpacing: 1,
            itemWidth: legendWidth,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </ChartWrapper>
  );
};

export default PieChart;
