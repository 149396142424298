import { apiStore } from "../../../api/ApiFactory";
import { IChoiceExtended } from "../../../models/Configuration";


const uploadChoiceImage = (file: File, data: IChoiceExtended) => {
    if (!data) return;
    const { id } = data;
    const form = new FormData();
    if (file) {
        form.append('file', file);
    }
    if (id) {
        form.append('boatOptionChoiceId', id.toString());
    }

    apiStore.boatConfigApi.uploadChoiceImage(form);

}

export default uploadChoiceImage