import { IAddress } from "../../models/Common"

export const getAddressObject = (add1?: string, add2?: string, city?: string, state?: string, country?: string, zip?: string) => {

    var result: IAddress = {
        address1: add1,
        address2: add2,
        city: city,
        state: state,
        country: country,
        zip: zip
    }

    return result;
}