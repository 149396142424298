import { Button, FormHelperText, Grid, IconButton, InputLabel, Stack, TextField, Typography } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Field, FieldArray, Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { apiStore } from "../../../api/ApiFactory";
import { StackLabel } from "../../../components/label/StackLabel";
import { MainCard } from "../../../components/surface/MainCard";
import { SectionBox } from "../../../components/surface/SectionBox";
import { UserDefinedFieldType } from "../../../helpers/enums/Types";
import { IModelExtended } from "../../../models/Configuration";
import SubmitButtonGroup from "../../../components/button/SubmitButtonGroup";
import * as Yup from "yup";
import { IUserDefinedField } from "../../../models/Common";

interface IProps {
    data?: IModelExtended
}

const ManagePrintDetails = () => {

    // Getting id from url
    const [searchParams] = useSearchParams();
    const modelId = searchParams.get('modelId');

    const location = useLocation();
    let navigate = useNavigate();
    const state: any = location.state;
    let { data }: IProps = state ?? undefined;

    const [model, setModelDetails] = useState<IModelExtended | undefined>(data);

    const getEmptySpec = () => {
        const item: IUserDefinedField = {
            name: "",
            value: "",
            type: UserDefinedFieldType.MODELSPECIFICATION
        }

        return item;
    }

    //Calling Model Details Api
    useEffect(() => {
       if(modelId != null && model == undefined)
       {
            const response:any = apiStore.boatConfigApi.getModelDetails(modelId);
            response.then((res:any) => {
                    setModelDetails(res);
            });
       }
    }, [modelId]);

    const ValidationSchema = Yup.object().shape({
        record: Yup.object({
            specifications: Yup.array()
            .of(
                Yup.object().shape(
                    {
                        name: Yup.string().required("Name is required"),
                        value: Yup.string().required("Value is required")
                    }
                )
            )
        })
    });

    if(model === undefined) {
        return <>{"Loading..."}</>
    }

    return (
        <>
             <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className="page-header-label">
                        {"Print Details"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                    <MainCard title="GENERAL INFORMATION" divider={true}>
                        <SectionBox>
                            <Grid container spacing={2}>
                                <Grid item xs ={12}>
                                    {model && (
                                    <MainCard subcard title="Details" divider={true}>
                                        <SectionBox>
                                            <StackLabel title='Series' label={model.seriesName} />
                                            <StackLabel title='Model' label={model.displayName} />
                                            <StackLabel title='Model Year' label={model.year} />
                                            <StackLabel title='Price' label={model.price} currency/>
                                        </SectionBox>
                                    </MainCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} sx={{display:"flex", justifyContent:"center", mt:3}}>
                                    <Typography className='info-emphasis-lg' component="span" sx={{display:"flex"}}>
                                        {"Fields configured here will be used on the Window Sticker."}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SectionBox>
                    </MainCard>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <MainCard title="PRINT CONFIGURATION" divider={true}>
                        <SectionBox>
                        <Formik
                            initialValues={{
                                record: model.printInfo!,
                                submit: null
                            }}
                            validationSchema={ValidationSchema}
                            onSubmit={(values, {setErrors, setSubmitting}) => {

                                apiStore.boatConfigApi.updatePrint(values.record)
                                    .then((response) => {
                                        navigate(-1);
                                    })
                                    .catch((error) => {

                                        if(error.response?.data?.length > 0) {
                                            setErrors({ submit: error.response?.data[0].errorMessage ?? "Unexpected Error" });
                                        }
                                        else {
                                            setErrors({ submit: "Unexpected Error" });
                                        }
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    })
                            }}
                        >
                            {({
                                errors,
                                handleChange,
                                handleSubmit,
                                getFieldProps,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                setFieldTouched,
                                values,
                                status,
                                }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            {errors.submit && (
                                            <>
                                                <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <FormHelperText error id="submit-helper">
                                                    {errors.submit}
                                                    </FormHelperText>
                                                </Grid>
                                                </Grid>
                                            </>
                                            )}
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="record.standardEquipment">Standard Equipment</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        minRows={4}
                                                        size="small"
                                                        id="username"
                                                        {...getFieldProps("record.standardEquipment")}
                                                        placeholder="Standard Equipment"
                                                        error={Boolean(
                                                            touched.record?.standardEquipment && errors.record?.standardEquipment
                                                        )}
                                                    />
                                                    {touched.record?.standardEquipment && errors.record?.standardEquipment && (
                                                        <FormHelperText error id="standardEquipment-helper">
                                                            {errors.record.standardEquipment}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>

                                                    <FieldArray name="record.specifications">
                                                        {({insert, remove, push}) => (
                                                            <>
                                                                {values.record.specifications.length > 0 && (
                                                                    <Grid container spacing={1}>                
                                                                        <Grid item xs={12}> 
                                                                            <InputLabel>Specifications</InputLabel>
                                                                        </Grid>
                                                                        {values.record.specifications.map((item, index) => 
                                                                            <Grid item xs={12} key={index}>
                                                                                <MainCard>
                                                                                    <SectionBox onDelete={() =>remove(index) } title={`Specification # ${index+1}`} divider={true} className="header-panel-color">
                                                                                        <Grid container spacing={2}>
                                                                                            <Grid item xs={12} sm={4}>
                                                                                                <InputLabel>Name *</InputLabel>
                                                                                                <TextField
                                                                                                    value={item.name}
                                                                                                    name={`record.specifications.${index}.name`}
                                                                                                    fullWidth
                                                                                                    onBlur={() => setFieldTouched(`record.specifications.${index}.name`, true)}
                                                                                                    onChange={handleChange} 
                                                                                                    size="small"
                                                                                                    id={`record.specifications.${index}.name`}
                                                                                                    placeholder="Name *"
                                                                                                    error={Boolean(
                                                                                                        touched.record?.specifications?.at(index)?.name &&
                                                                                                        (errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>)?.name
                                                                                                    )}
                                                                                                />
                                                                                                {touched.record?.specifications?.at(index)?.name && 
                                                                                                    (errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>)?.name && (
                                                                                                    <FormHelperText error id={`record.specifications.${index}.name-error`}>
                                                                                                        {(errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>).name}
                                                                                                    </FormHelperText>
                                                                                                )}
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={4}>
                                                                                                <InputLabel>Value *</InputLabel>
                                                                                                <TextField
                                                                                                    value={item.value}
                                                                                                    name={`record.specifications.${index}.value`}
                                                                                                    fullWidth
                                                                                                    onBlur={() => setFieldTouched(`record.specifications.${index}.value`, true)}
                                                                                                    onChange={handleChange}
                                                                                                    size="small"
                                                                                                    id={`record.specifications.${index}.value`}
                                                                                                    placeholder="Value *"
                                                                                                    error={Boolean(
                                                                                                        touched.record?.specifications?.at(index)?.value &&
                                                                                                        (errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>)?.value
                                                                                                    )}
                                                                                                />
                                                                                                {touched.record?.specifications?.at(index)?.value && 
                                                                                                    (errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>)?.value && (
                                                                                                    <FormHelperText error id={`record.specifications.${index}.value-error`}>
                                                                                                        {(errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>).value}
                                                                                                    </FormHelperText>
                                                                                                )}
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={4} >
                                                                                                <InputLabel>Order</InputLabel>
                                                                                                <TextField
                                                                                                    value={item.order}
                                                                                                    name={`record.specifications.${index}.order`}
                                                                                                    fullWidth
                                                                                                    onBlur={() => setFieldTouched(`record.specifications.${index}.order`, true)}
                                                                                                    onChange={handleChange}
                                                                                                    size="small"
                                                                                                    id={`record.specifications.${index}.order`}
                                                                                                    placeholder="Order"
                                                                                                    error={Boolean(
                                                                                                        touched.record?.specifications?.at(index)?.order &&
                                                                                                        (errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>)?.order
                                                                                                    )}
                                                                                                />
                                                                                                {touched.record?.specifications?.at(index)?.order && 
                                                                                                    (errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>)?.order && (
                                                                                                    <FormHelperText error id={`record.specifications.${index}.order-error`}>
                                                                                                        {(errors.record?.specifications?.at(index) as FormikErrors<IUserDefinedField>).order}
                                                                                                    </FormHelperText>
                                                                                                )}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </SectionBox>
                                                                                </MainCard>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                )}
                                                            <Stack direction="row" mt={1}>
                                                                <Button onClick={() => push(getEmptySpec())}>Add Specification</Button>
                                                            </Stack>
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Grid>
                                            <SectionBox>
                                                <SubmitButtonGroup disabled={isSubmitting} />
                                            </SectionBox>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                        </SectionBox>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    )
}

export default ManagePrintDetails;