import { Formik } from "formik";
import React, { useEffect } from "react";
import { HeaderLabel } from "../../components/label/HeaderLabel";
import { MainCard } from "../../components/surface/MainCard";
import * as Yup from "yup";
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { IUserFormValues, IUserPasswordReset } from "../../models/Auth";
import { apiStore } from "../../api/ApiFactory";
import { Link, useLocation, useNavigate } from "react-router-dom";

const FormValidationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required."),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const ResetPassword = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const search = location.search;

  const paramToken = new URLSearchParams(search).get("token");
  const paramEmail = new URLSearchParams(search).get("email");

  const state: any = location.state;

  return (
    <MainCard className="auth-card" elevation={4}>
      <Stack
        className="auth-container center-on-xs mb-3"
        justifyContent="center"
        alignItems="flex-start"
        onClick={() => navigate("/auth/login")}
      >
        <img className="auth-logo" alt=""></img>
      </Stack>
      <HeaderLabel
        title="Reset Password"
        subtitle="Create a password with a unique mix of letters, numbers, and symbols."
      />

      <Formik
        initialValues={{
          token: paramToken,
          email: paramEmail,
          password: "",
          passwordConfirmation: "",
          submit: null,
        }}
        validationSchema={FormValidationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          let data: IUserPasswordReset = {
            token: values.token ?? "",
            email: values.email ?? "",
            password: values.password,
          };

          apiStore.authApi
            .resetpassword(data)
            .catch((error) => {
              if(error.length > 0) {
                  setErrors({ submit: error[0].errorMessage})
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ errors, handleSubmit, getFieldProps, isSubmitting, touched }) => (
          <form noValidate onSubmit={handleSubmit} className="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor="password">
                  Create a new password
                </InputLabel>
                <TextField
                  fullWidth
                  id="password"
                  placeholder="Enter new password"
                  type="password"
                  {...getFieldProps("password")}
                  size="small"
                  error={Boolean(touched.password && errors.password)}
                />

                {touched.password && errors.password && (
                  <FormHelperText error id="password-helper">
                    {errors.password}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="confirmPassword">
                  Re-enter new password
                </InputLabel>
                <TextField
                  fullWidth
                  id="passwordConfirmation"
                  placeholder="Re-enter new password"
                  type="password"
                  {...getFieldProps("passwordConfirmation")}
                  size="small"
                  error={Boolean(
                    touched.passwordConfirmation && errors.passwordConfirmation
                  )}
                />

                {touched.passwordConfirmation && errors.passwordConfirmation && (
                  <FormHelperText error id="passwordConfirmation-helper">
                    {errors.passwordConfirmation}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                {errors.submit && (
                  <Grid container className="error-spacer">
                    <Grid item xs={12}>
                      <FormHelperText error id="submit-helper">
                        {errors.submit}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                )}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default ResetPassword;
