import { setBoatSeriesList, setCountryList, setDesignationTypes, setYearList } from "../store/slices/Lookup/LookupSlice";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class LookupApi {

    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
      this.apiFactory = apiFactory;
    }

    //Lookups that don't change that often can and should be stored in the store and can be accessed without making subsequent calls to APIs to retrieve
    //the information.

    //For lookups anything that can be cached for later use should via the store.
    getCountryList = async () => {
        try {

            var value = this.apiFactory.store.getState().reducer.Lookup.countryList;

            if(value.length <= 0) {
                const response = await agent.api.get(`/lookup/getcountries`, null);
                this.apiFactory.store.dispatch(setCountryList(response));

                value = this.apiFactory.store.getState().reducer.Lookup.countryList;
            }

            return value;
        }
        catch (error) {
            throw error;
        }
    }

    //Called when the app first loads for an authenticated user. Stores information in state that can be used throughout the app.
    getBoatSeriesList = async () => {
        const response = await agent.api.get(`/lookup/getboatserieslist`, null);
        this.apiFactory.store.dispatch(setBoatSeriesList(response));
    }

    getYearList = async () => {
        const response = await agent.api.get(`/lookup/getyearlist`, null);
        this.apiFactory.store.dispatch(setYearList(response));
    }

    getDesignationType = async () => {

        var value = this.apiFactory.store.getState().reducer.Lookup.designationTypes;

        if(value.length <= 0) {
            const response = await agent.api.get(`/lookup/getdesignationtypes`, null);
            this.apiFactory.store.dispatch(setDesignationTypes(response));

            value = this.apiFactory.store.getState().reducer.Lookup.designationTypes;
        }

        return value;
    }
}