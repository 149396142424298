import React, { ReactNode, forwardRef } from "react";
import { Button as FancyButton, CircularProgress } from "@mui/material";
interface Props {
  children?: ReactNode;
  type?: "submit" | "button";
  variant?: "text" | "outlined" | "contained" | undefined;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  size?: "small" | "medium" | "large" | undefined;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  sx?: any;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}
export type Ref = HTMLButtonElement;
const Button = forwardRef<Ref, Props>(
  (
    {
      children,
      variant,
      onClick,
      loading,
      type,
      disabled,
      size,
      startIcon,
      endIcon,
      color,
      sx,
      ...props
    },
    ref
  ) => {
    return (
      <FancyButton
        sx={sx}
        ref={ref}
        variant={variant}
        onClick={onClick}
        type={type}
        disabled={disabled}
        size={size}
        startIcon={startIcon}
        endIcon={endIcon}
        color={color}
      >
        {loading ? <CircularProgress color="inherit" size={23} /> : children}
      </FancyButton>
    );
  }
);

export default Button;
