import { Box, Button, Grid, Typography, styled } from "@mui/material";
import PrintModelTable from "./PrintModelTable";
import { useEffect } from "react";

type IProps = {
    model: any; //This should be changed to an interface type.
    type: string;
    series: any;
    dealer: any;
}

function setToLandscape() {
    const style = document.createElement('style');
    style.innerHTML = `@page {size: landscape}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

const PrintModel = ({model, type, series, dealer}: IProps) => {

    useEffect(() => {
        setToLandscape();
        return () => {
            const child = document.getElementById('page-orientation');
            child?.parentNode?.removeChild(child);
        };
    }, []);

    return (
        <>
            <Box id="print" className='hide-display price-sheet-container'>
                {
                model && (
                    <>
                        <Grid className="condensed-print-body">
                            <PrintModelTable data={model} type={type} msrp={series.msrp} dealer={dealer}/>
                        </Grid>
                    </>
                )}
            </Box>
        </>
    )
}

export default PrintModel;