import { createSlice } from '@reduxjs/toolkit';
import { changeBodyAttribute } from '../../../helpers/utils/styles';

import { InitialConfig } from '../../../helpers/constants/AppConfig';
import { NavMode } from '../../../helpers/enums/Layout';
import { AppThunk } from '../../store';
import { selectNavMode } from './LayoutSelector';

const getsubdomain = () => {

    const host = window.location.host;
    const arr = host.split(".").slice(0, -2);

    return arr.length > 0 ? arr[0] : "";
}

const initialState = {
    ...InitialConfig,
    subdomain: getsubdomain()
}

const slice = createSlice({
    name: 'layout',
    initialState, 
    reducers: {
        changeNavTypeAction: (state, action) => {
            state.navMode = action.payload;
        }
    }
});

export const { changeNavTypeAction } = slice.actions;

//Thunks
export const changeNavType = (value: NavMode): AppThunk => (
    dispatch,
    getState
) => {
    const currentValue = (selectNavMode(getState()));
    if(currentValue !== value)
    {
        changeBodyAttribute("nav-mode", value);
        dispatch(changeNavTypeAction(value))
    }
};


export default slice.reducer;