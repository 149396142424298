import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import ModalComponent from "../../../components/modal/ModalComponent";
import CreateRuleModel from "./EditRuleModel";
import { apiStore } from "../../../api/ApiFactory";
import { ReactTable } from "../../../components/table/ReactTable";
import { Column, TableInstance } from "react-table";
import { useFormik } from "formik";
import { IRule } from "../../../models/Configuration";
import DeleteRuleModel from "./DeleteRuleModel";

const ModelRule = () => {

  const [editModel, setOpenEditMode] = useState(false);
  const openEditModel = () => setOpenEditMode(true);
  const closeEditModel = () => setOpenEditMode(false);

  const [deleteModel, setOpenDeleteModel] = useState(false);
  const openDeleteModel = () => setOpenDeleteModel(true);
  const closeDeleteModel = () => setOpenDeleteModel(false);

  const [editModelData, setEditModelData] = useState(null);

  const [data, setData] = useState<Array<any>>();
  const [revisionOptions, setRevisionOptions] = useState([]);
  const [revisionId, setRevision] = useState(0);

  const [ruleData, setRuleData] = useState<IRule>();
  const [reactTableData, setReactTableData] = useState<any>([]);
  const [deleteRuleId, setDeleteRuleId] = useState<[]>([]);
  const [update, setUpdate] = useReducer((x) => x + 1, 0);
  const [visibility, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    const result = apiStore.boatConfigApi.getRuleRevisionOptions();
    result.then((response) => {
      setData(response);
      createList(response);
    });
    return () => {
      mounted = false;
    };
  }, []);

  const createList = (data: any) => {
    const list = data.map((item: any) => {
      return { label: item.seriesName };
    });
    
  };

  const createRevisionList = (seriesName: string) => {
    const list = data?.filter((item) => item?.seriesName === seriesName);
    if (list) {
      setRevisionOptions(list[0]?.revisions);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    formik.setFieldValue("series", event.target.value);
    createRevisionList(event.target.value);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      series: "",
      revision: "",
    },
    onSubmit: (values) => {
      setVisibility(true);
      getRuleDetails(values);
    },
  });
  const getRuleDetails = (values: any) => {
    const { revisionId } = values.revision;
    setRevision(revisionId);
    const result = apiStore.boatConfigApi.getRules(revisionId);
    result.then((response) => {
      setRuleData(response);
      const { rules } = response;
      setReactTableData(rules);
    });
  };

  const createConditional = (row: any) => {
    const { conditions } = row;
    let result = "";
    let isSelected = "";
    if (ruleData?.availableOptions) {
      conditions.map((condition: any) => {
        let str = "";
        isSelected = condition.isSelected ? `<span class="table-text-blue">is selected</span>` : `<span class="table-text-red">is not selected</span>`;
        ruleData?.availableOptions.find((option: any) => {
          if (condition.revisionChoiceId === option.revisionChoiceId) {
            str = `<span class="table-txt-str">${option.choice}</span>`;
          }
        });
        if (result.length > 0) {
          result += `<p class="table-txt"> AND </p>  ${str} ${isSelected} `;
        } else {
          result += ` ${str} ${isSelected}`;
        }
      });
    }
    result = `When ${result}.`;
    return result;
  };

  const createActions = (row: any) => {
    const { actions } = row;
    let result = "";
    let isSelected = "";
    let isDisabled = "";
    let isVisible = "";
    if (ruleData?.availableOptions) {
      actions.map((action: any) => {
        let str = "";

        isSelected = action.isSelected ? `<span class="table-text-blue">select</span>` : `<span class="table-text-red">unselect</span>`;
        isDisabled = action.isDisabled ? `<span class="table-text-red">disable</span>` : `<span class="table-text-blue">enable</span>`;
        isVisible = action.isVisible ? `<span class="table-text-blue">show</span>` : `<span class="table-text-red">hide</span>`;
        ruleData?.availableOptions.find((option: any) => {
          if (action.revisionChoiceId === option.revisionChoiceId) {
            str = `<span class="table-txt-str">${option.choice}</span>`;
          }
        });
        if (result.length > 0) {
          result += `<p class="table-txt"> AND </p> ${isSelected} , ${isDisabled} , ${isVisible}  ${str}`;
        } else {
          result += `${isSelected}, ${isDisabled}, ${isVisible} ${str} `;
        }
      });
    }

    result = `Then  ${result}.`;
    return result;
  };

  const columns: Column<any>[] =
    [
    {
      Header: "Rule Id",
      accessor: "id",
      maxWidth: 40
    },
    {
      Header: "Conditions",
      accessor: "conditions",
      Cell: ({ row }: any) => (
        <p
          dangerouslySetInnerHTML={{ __html: createConditional(row.original) }}
        ></p>
      ),
      filter: (rows: any, columnIds: any, filterValue: any) => {

        return rows.filter((row: any) => {
          const entry = row.values[columnIds];

          if(entry === undefined) {
            return true;
          }

          var conditionalString = createConditional(row.original);
          
          if(conditionalString.toLowerCase().includes(filterValue.toLowerCase())) {
            return true;
          }

          return false;

        });

      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: any) => (
        <p
          dangerouslySetInnerHTML={{ __html: createActions(row.original) }}
        ></p>
      ),
      filter: (rows: any, columnIds: any, filterValue: any) => {

        return rows.filter((row: any) => {
          const entry = row.values[columnIds];

          if(entry === undefined) {
            return true;
          }

          var actionString = createActions(row.original);
          
          console.log(actionString);
          console.log(filterValue);

          if(actionString.toLowerCase().includes(filterValue.toLowerCase())) {
            return true;
          }

          return false;

        });

      }
    }
  ];

  const addRule = useCallback(
    (instance: TableInstance) => () => {
      setEditModelData(null);
      openEditModel();
    },
    []
  );

  const editRule = useCallback(
    (instance: TableInstance) => () => {
      const data: any = instance.selectedFlatRows[0].original;
      setEditModelData(data);
      openEditModel();
    },
    []
  );

  const customHandle = (event: SelectChangeEvent) => {
    const revisionName = (event.target as any).value;
    formik.setFieldValue("revision", revisionName);
  };
  const updateComponent = (revisionId: number) => {
    const result = apiStore.boatConfigApi.getRules(revisionId);
    result.then((response) => {
      setRuleData(response);
      const { rules } = response;
      setReactTableData(rules);
    });
  };

  useEffect(() => {
    updateComponent(revisionId);
  }, [update]);

  const onDelete = useCallback(
    (instance: TableInstance) => () => {
      openDeleteModel();
      console.log("all rows", instance.selectedFlatRows);
      const rules: any = instance.selectedFlatRows.map(
        (rule: any) => rule.original.id
      );
      console.log({ rules });
      // const data: any = instance.selectedFlatRows[0].original;
      // const { id } = data;

      setDeleteRuleId(rules);
    },
    []
  );

  const modalStyle = {
    width: { md: "80%" },
    height: { md: "auto" },
    overflow:"scroll"
  };

  const mainModalStyle = {
    width: "90%" ,
    height: "80%" ,
  };

  return (
    <>
      <ModalComponent
        open={deleteModel}
        handleClose={closeDeleteModel}
        customStyle={modalStyle}
      >
        <DeleteRuleModel
            closeModel={closeDeleteModel}
            deleteRuleId={deleteRuleId}
            updateComponent={setUpdate}
        />
      </ModalComponent>
      <ModalComponent open={editModel} handleClose={closeEditModel} customStyle={mainModalStyle}>
        <CreateRuleModel
          data={editModelData}
          closeModel={closeEditModel}
          revisionId={revisionId}
          updateComponent={setUpdate}
        />
      </ModalComponent>
      <form onSubmit={formik.handleSubmit}>
        <Grid container alignItems="center" spacing={4} mb={4}>
          <Grid item xs={12} sm={12} md={3}>
            <InputLabel htmlFor="order">Series *</InputLabel>
            <Select
              size="small"
              fullWidth
              placeholder="Select Series *"
              value={formik.values.series}
              onChange={handleChange}
            >
              <MenuItem disabled value="">
                Select Series *
              </MenuItem>

              {data &&
                data.map((item: any, index) => {
                  return (
                    <MenuItem key={`${index}`} value={item.seriesName}>
                      {item.seriesName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <InputLabel htmlFor="order">Revision *</InputLabel>
            <Select
              size="small"
              fullWidth
              placeholder="Select Series *"
              name="revision"
              value={formik.values.revision}
              onChange={customHandle}
            >
              <MenuItem disabled value="">
                Select Revision *
              </MenuItem>

              {revisionOptions &&
                revisionOptions.map((item: any, index) => {
                  return (
                    <MenuItem key={`${index}`} value={item}>
                      {item.revisionName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid item xs={12}>
            
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {visibility && (
        <Grid>
          <ReactTable
            name="user-list-table"
            serverOnChange={false}
            columns={columns}
            data={reactTableData ? reactTableData : []}
            isLoading={false}
            onAdd={addRule}
            onEdit={editRule}
            onDelete={onDelete}
          />
        </Grid>
      )}
    </>
  );
};

export default ModelRule;
