import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Box,
  IconButton,
} from "@mui/material";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { perspectiveOptions } from "../../models/BoatOrdering";
import { getSelectedModal } from "../../store/slices/BoatOrdering/BoatOrderingSelector";
import { useSelector } from "react-redux";
import stockimage from "../../_assets/images/Sea-Pro-Logo-Header.png";
import { apiStore } from "../../api/ApiFactory";
import { StandardFeatures } from "../../models/StandardFeatures";
import MainModal from "../../components/modal/MainModal";
import StandardFeaturesModal from "../../components/modal/StandardFeaturesModal";

interface IOverlayImages {
  boatModelId: number;
  boatOptionChoiceId: number;
  boatPerspectiveId: number;
  id: number;
  layerOrder: number;
  mediaUrl: string;
  perspectiveName: string;
}

const BoatCardComponent = () => {
  const selectedModal = useSelector(getSelectedModal);
  const [overLayImages, setOverLayImages] = useState<IOverlayImages[]>([]);

  const [perspective, setPerspective] = useState<String>("Side");

  const [openModal, setOpenModal] = useState(false);
  const [modalDetails, setModalDetails] = useState<StandardFeatures>();

  const[activeModelId, setActiveModelId] = useState<Number | undefined>();

  const primaryImage = selectedModal?.layeredMedia?.find(
    (media: any) => media?.perspectiveName === perspective
  );
  const secondaryImage = selectedModal?.layeredMedia?.find(
    (media: any) => media?.perspectiveName !== perspective
  );
  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (selectedModal) {
      let list: any = [];
      selectedModal?.sections?.forEach((section: any) => {
        section?.options?.forEach((option: any) => {
          // if (option.uiWidget === "Color Swatch Tray") {
          option.choices?.forEach((choice: any) => {
            if (choice?.preSelected) {
              list.push(...choice.layeredMedia);
            }
          });
          // }
        });
      });
      const filterList: any = list?.filter((el: any) => el);
      setOverLayImages(filterList);

      //Only call the API if the active model has changed or has never been set.
      if(activeModelId === undefined || activeModelId !== selectedModal.id) {

        setActiveModelId(selectedModal.id);

        apiStore.boatOrderingApi.getStandardFeatures(selectedModal.id)
        .then((response: StandardFeatures) => {
  
          setModalDetails(response);
          
        })
        .catch((error) => {
          console.error("Standard Features", error);
        });
      }
    }
  }, [selectedModal]);

  const _renderImagePreview = (type: String) => {
    return (
      <>
        {overLayImages
          ?.filter((item: IOverlayImages) => item.perspectiveName === type)
          ?.map((image: any, index: number) => {
            return (
              <img
                alt=""
                key={index}
                src={image?.mediaUrl}
                className="panel-image"
              />
            );
          })}
      </>
    );
  };

  const OpenStandardFeaturesModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <MainModal open={openModal} handleClose={closeModal}>
        <StandardFeaturesModal
          open={openModal}
          handleClose={closeModal}
          modelDetails={modalDetails}
        />
      </MainModal>
      <Card sx={{ minWidth: 300 }} className="step-active-box">
        <Grid container>
          <Grid xs={6} item>
            <Box >
              {modalDetails?.printInfo.standardEquipment != "" && (
                <IconButton className="icon-button" aria-label="Standard Features" sx={{fontSize: "60px"}}  onClick={OpenStandardFeaturesModal} >
                    <InfoOutlinedIcon fontSize="large" />
                </IconButton>
              )}
            </Box>
          </Grid>
          <Grid xs={6} item>
            <div className="active-step-thumb">
              <div
                className="primary-image"
                onClick={(e) =>
                  setPerspective(perspective === "Top" ? "Side" : "Top")
                }
              >
                <img
                  alt=""
                  className="boat-image"
                  src={secondaryImage?.mediaUrl ?? stockimage}
                />
                {_renderImagePreview(perspective === "Top" ? "Side" : "Top")}
              </div>
              
            </div>
          </Grid>
        </Grid>
        <Grid container className="active-step-image">
          <Grid xs={12} item>
            <div className="primary-image">
              <img
                alt=""
                className="boat-image"
                src={primaryImage?.mediaUrl ?? stockimage}
              />
              {_renderImagePreview(perspective)}
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default BoatCardComponent;
