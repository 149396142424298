import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumbs";
import { NavMode } from "../../helpers/enums/Layout";
import {
  MapRouteToActivePath,
  useLeadRoutePaths,
  useActiveRoutePaths
} from "../../services/RouteService";
import { useAppSelector } from "../../store/hooks/hook";

const ContentHeader = ({hide}:{hide:boolean}) => {

  const authorizedRoutes = useActiveRoutePaths();
  const leadRoutes = useLeadRoutePaths();


  const location = useLocation();
  const activeRoutePaths = MapRouteToActivePath(
    authorizedRoutes,
    location.pathname
  );

  const leadRoutePaths = MapRouteToActivePath(
    leadRoutes,
    location.pathname
  );

  const crumbAlignRight = false;
  const titleBottom = false;

  let itemTitle = "";

  if (activeRoutePaths.length > 0) {
    itemTitle = activeRoutePaths[0].title;
  }
  else if (leadRoutePaths.length > 0) {
    itemTitle = leadRoutePaths[0].title;
  }

  return (
    <Stack
      className="header-label-container"
      direction={crumbAlignRight ? "row" : "column"}
      justifyContent={crumbAlignRight ? "space-between" : "flex-start"}
      alignItems={crumbAlignRight ? "center" : "flex-start"}
      spacing={1}
    >
      {itemTitle && !hide && !titleBottom && (
        <Grid>
          <Typography variant="h5">{itemTitle}</Typography>
        </Grid>
      )}
    {
      !hide && (
        <Breadcrumbs
        routes={activeRoutePaths.length > 0 ? activeRoutePaths : leadRoutePaths}
        rightAlign={crumbAlignRight}
      ></Breadcrumbs>
      )
    }
      {itemTitle && titleBottom && (
        <Grid>
          <Typography variant="h5">{itemTitle}</Typography>
        </Grid>
      )}
    </Stack>
  );
};

export default ContentHeader;
