import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { apiStore } from "../../../api/ApiFactory";
import SubmitButtonGroup from "../../../components/button/SubmitButtonGroup";
import { StackLabel } from "../../../components/label/StackLabel";
import { MainCard } from "../../../components/surface/MainCard";
import { SectionBox } from "../../../components/surface/SectionBox";
import { UiWidget } from "../../../helpers/enums/UiWidget";
import { IOption, IOptionExtended, ISection } from "../../../models/Configuration";
import { formatDate } from "../../../helpers/utils/formatDate";
import ChoiceTableComponent from "./TableComponents/ChoiceTableComponent";

const ManageOption = () => {

    const location = useLocation();
    const [option, setOption] = useState<IOptionExtended>();
    const [sections, setSections] = useState<ISection[]>();
    const [searchParams, setSearchParams] = useSearchParams();
    let optionId = searchParams.get('id');

    const state: any = location.state ?? null;

    useEffect(() => {
        
        if(optionId) {
            const promise = apiStore.boatConfigApi.getOptionDetails(optionId);

            promise.then((response) => {
                setOption(response);
                getSection(response.modelId)
            })
            .catch((error) => {
    
            });
        }
    }, [optionId]);

    const getSection = (modelId: string) => {

        if(state) {
            setSections(state.data);
        }
        else {
            const promise = apiStore.boatConfigApi.getSectionList(modelId);
            promise.then((response) => {
                setSections(response);
            })
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            record: (option as IOption),
            submit: null
        },

        onSubmit: (values, { setSubmitting, setErrors }) => {
            var result = apiStore.boatConfigApi.updateOption(values.record);

            setSubmitting(true);
            result.then((response) => {
                
            })
            .catch((error) => {
                if(error.response?.data) {
                    setErrors({ submit: error.response?.data[0].errorMessage })
                } else {
                    setErrors({submit: "Uknown server error."})
                }
            })
            .finally(() => {
                setSubmitting(false);
            })
        },
    });

    if(option === undefined || sections === undefined) {
        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className="page-header-label">
                        {"Boat Option"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                    {formik.errors.submit && (
                        <Grid item xs={12}>
                            <FormHelperText sx={{position:"absolute"}} error id="submit-helper">{formik.errors.submit}</FormHelperText>
                        </Grid>
                    )}
                    <form onSubmit={formik.handleSubmit}>

                        <MainCard title="GENERAL INFORMATION" divider={true}>
                            <SectionBox>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <MainCard subcard title="Details">
                                            <SectionBox>
                                                <StackLabel title='Series' label={option?.seriesName ?? ""} />
                                                <StackLabel title='Model' label={option?.modelName ?? ""} />
                                                <StackLabel title='Model Year' label={option?.year ?? ""} />
                                                <StackLabel title='Option' label={option?.name ?? ""} />
                                            </SectionBox>
                                        </MainCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="displayName">Display Name *</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="displayName"
                                            {...formik.getFieldProps("record.displayName")}
                                            placeholder="Display Name *"
                                            error={Boolean(formik.touched.record?.displayName && formik.errors.record?.displayName )}
                                        />
                                        {formik.touched.record?.displayName && formik.errors.record?.displayName && (
                                            <FormHelperText error id="displayName-helper">
                                                {formik.errors.record?.displayName}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="order">Sort Order</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="order"
                                            {...formik.getFieldProps("record.order")}
                                            placeholder="Sort Order"
                                            error={Boolean(formik.touched.record?.order && formik.errors.record?.order )}
                                        />
                                        {formik.touched.record?.order && formik.errors.record?.order && (
                                            <FormHelperText error id="order-helper">
                                                {formik.errors.record?.order}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="description">Description *</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="description"
                                            {...formik.getFieldProps("record.description")}
                                            placeholder="Description *"
                                            error={Boolean(formik.touched.record?.description && formik.errors.record?.description )}
                                        />
                                        {formik.touched.record?.description && formik.errors.record?.description && (
                                            <FormHelperText error id="description-helper">
                                                {formik.errors.record?.description}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="uiWidget">UI Widget *</InputLabel>
                                        <Select
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue("record.uiWidget", e.target.value);
                                            }}
                                            size="small"
                                            fullWidth
                                            displayEmpty
                                            name="uiWidget"
                                            value={formik.values.record?.uiWidget ?? ""}
                                        >
                                            <MenuItem disabled value="">
                                                <em>Ui Widget *</em>
                                            </MenuItem>
                                            {
                                                Object.values(UiWidget).map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                        <em>{item}</em>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        {formik.touched.record?.uiWidget && formik.errors.record?.uiWidget && (
                                            <FormHelperText error id="uiWidget-helper">
                                                {formik.errors.record?.uiWidget}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="section">Section *</InputLabel>
                                        <Select
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue("record.sectionId", e.target.value);
                                            }}
                                            size="small"
                                            fullWidth
                                            displayEmpty
                                            name="sectionId"
                                            value={formik.values.record?.sectionId ?? ""}
                                        >
                                            <MenuItem disabled value="">
                                                <em>Assign Section *</em>
                                            </MenuItem>
                                            {sections?.map((value) =>
                                                <MenuItem key={value.id} value={value.id}>
                                                    {value.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                        {formik.touched.record?.sectionId && formik.errors.record?.sectionId && (
                                            <FormHelperText error id="section-helper">
                                                {formik.errors.record?.sectionId}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" >
                                                <FormControlLabel
                                                    labelPlacement="end"
                                                    label="Is Active"
                                                    onChange={formik.handleChange}
                                                    name="record.isActive"
                                                    value={formik.values.record.isActive}
                                                    control={<Checkbox checked={formik.values.record.isActive}/> }
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <SubmitButtonGroup disabled={formik.isSubmitting} />
                                {option?.modifiedDate && (
                                    <Typography sx={{fontSize:"9px"}}>Last Updated : {formatDate(option?.modifiedDate)}</Typography>
                                )}
                            </SectionBox>
                        </MainCard>
                    </form>
                </Grid>
                <Grid item xs={12} >
                    <MainCard title="OPTION CHOICES">
                        <SectionBox>
                            <ChoiceTableComponent />
                        </SectionBox>
                    </MainCard>
                </Grid>
            </Grid>
        
        
        </>
    )


}

export default ManageOption;