import { Toolbar } from "@mui/material";
import {
    PropsWithChildren,
    ReactElement,
    useCallback,
    useState,
    MouseEvent,
} from "react";
import { TableInstance } from "react-table";
import { TableMouseEventHandler } from "../../types/react-table-config";
import { TableIconActionButton } from "../button/SmallIconActionButton";

import { mdiPencilOutline } from "@mdi/js";
import { mdiDeleteOutline } from "@mdi/js";
import { mdiPlus } from "@mdi/js";
import { mdiPrinter } from "@mdi/js";
import { mdiEye } from "@mdi/js";
import { mdiFilter } from "@mdi/js";
import { TableLabelActionButton } from "../label/LabelActionButton";
import { FilterModal } from "./FilterModal";
import { Command } from "./ICommand";
import { mdiLockOutline, mdiLockOpenOutline } from "@mdi/js";

type TableToolbarProps<T extends Record<string, unknown>> = {
    instance: TableInstance<T>;
    onAdd?: TableMouseEventHandler<T>;
    onDelete?: TableMouseEventHandler<T>;
    onEdit?: TableMouseEventHandler<T>;
    onView?: TableMouseEventHandler<T>;
    onPrint?: TableMouseEventHandler<T>;
    inlineLock?: TableMouseEventHandler<T>;
    inlineLockState?: boolean;
    //extraCommands allows us to add any generic command we want.
    extraCommands?: Command<T>[];
};

export function TableToolbar<T extends Record<string, unknown>>({
    instance,
    onAdd,
    onDelete,
    onEdit,
    onView,
    onPrint,
    extraCommands = [],
    inlineLock,
    inlineLockState,
}: PropsWithChildren<TableToolbarProps<T>>): ReactElement | null {
    const { columns } = instance;

    const [anchorElement, setAnchorElement] = useState<Element | undefined>(
        undefined
    );
    const [columnsOpen, setColumnsOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);

    const hideableColumns = columns.filter(
        (column) => !(column.id === "_selector")
    );

    const handleColumnsClick = useCallback(
        (event: MouseEvent) => {
            setAnchorElement(event.currentTarget);
            setColumnsOpen(true);
        },
        [setAnchorElement, setColumnsOpen]
    );

    const handleFilterClick = useCallback(
        (event: MouseEvent) => {
            setAnchorElement(event.currentTarget);
            setFilterOpen(true);
        },
        [setAnchorElement, setFilterOpen]
    );

    const handleClose = useCallback(() => {
        setColumnsOpen(false);
        setFilterOpen(false);
        setAnchorElement(undefined);
    }, []);

    const showToolbar =
        !!onAdd ||
        !!onEdit ||
        !!onDelete ||
        !!onPrint ||
        !!extraCommands ||
        !!onView ||
        !!inlineLock;

    if (showToolbar) {
        return (
            <Toolbar className="table-toolbar">
                <div className="left-buttons">
                    {onAdd && (
                        <TableIconActionButton<T>
                            instance={instance}
                            icon={mdiPlus}
                            onClick={onAdd}
                            label="Add"
                            enabled={({ state }: TableInstance<T>) =>
                                !state.selectedRowIds ||
                                Object.keys(state.selectedRowIds).length === 0
                            }
                            variant="left"
                        />
                    )}
                    {onEdit && (
                        <TableIconActionButton<T>
                            instance={instance}
                            icon={mdiPencilOutline}
                            onClick={onEdit}
                            label="Edit"
                            enabled={({ state }: TableInstance<T>) =>
                                !state.selectedRowIds ||
                                Object.keys(state.selectedRowIds).length === 1
                            }
                            variant="left"
                        />
                    )}
                    {onDelete && (
                        <TableIconActionButton<T>
                            instance={instance}
                            icon={mdiDeleteOutline}
                            onClick={onDelete}
                            label="Delete"
                            enabled={({ state }: TableInstance<T>) =>
                                !state.selectedRowIds ||
                                Object.keys(state.selectedRowIds).length > 0
                            }
                            variant="left"
                        />
                    )}

                    {onPrint && (
                        <TableIconActionButton<T>
                            instance={instance}
                            icon={mdiPrinter}
                            onClick={onPrint}
                            label="Print"
                            enabled={({ state }: TableInstance<T>) =>
                                !state.selectedRowIds ||
                                (instance.name === "reports-table" &&
                                    Object.keys(state.selectedRowIds).length >= 1) ||
                                (instance.name !== "reports-table" &&
                                    Object.keys(state.selectedRowIds).length === 1)
                            }
                            variant="left"
                        />
                    )}

                    {onView && (
                        <TableIconActionButton<T>
                            instance={instance}
                            icon={mdiEye}
                            onClick={onView}
                            label="View"
                            enabled={({ state }: TableInstance<T>) =>
                                !state.selectedRowIds ||
                                Object.keys(state.selectedRowIds).length === 1
                            }
                            variant="left"
                        />
                    )}


                    {inlineLock && (
                        <TableIconActionButton<T>
                            instance={instance}
                            icon={inlineLockState? mdiLockOutline : mdiLockOpenOutline}
                            onClick={inlineLock}
                            label="Inline Edit"
                            variant="left"
                        />
                    )}

                    {extraCommands.map((c) => {
                        const { type = "icon" } = c;
                        return type === "icon" ? (
                            <TableIconActionButton<T>
                                key={`command-${c.label}`}
                                instance={instance}
                                icon={c.icon}
                                onClick={c.onClick}
                                label={c.label}
                                enabled={c.enabled}
                                variant="left"
                            />
                        ) : (
                            <TableLabelActionButton<T>
                                key={`command-${c.label}`}
                                instance={instance}
                                icon={c.icon}
                                onClick={c.onClick}
                                label={c.label}
                                enabled={c.enabled}
                            />
                        );
                    })}
                </div>
                <div className="right-buttons">
                    <FilterModal<T>
                        instance={instance}
                        onClose={handleClose}
                        show={filterOpen}
                        anchorElement={anchorElement}
                    />
                    <TableIconActionButton<T>
                        instance={instance}
                        icon={mdiFilter}
                        onClick={() => handleFilterClick}
                        label="Filter by columns"
                        variant="right"
                    />
                </div>
            </Toolbar>
        );
    } else {
        return <></>;
    }
}
