import { Box, Button, Grid, Typography } from "@mui/material"
import PrintOrderTable from "./PrintOrderTable"
import SeaproLogo from "../../../_assets/images/Sea-Pro-Logo-Header.png";
import BasicPrintOrderHeader from "./BasicPrintOrderHeader";
import BasicPrintLeadsHeader from "./BasicPrintLeadsHeader";
import { IOrderPrint, LayeredMediaEntity } from "../../../models/Print";
import { getAddressObject } from "../../../helpers/utils/getAddressObject";
import { AddressLabel } from "../../../components/label/AddressLabel";
import PerspectiveImage from "../PerspectiveImage";

type IProps = {
    data: IOrderPrint
    type: string
    isLead?: boolean
}

const BasicPrint = ({data, type, isLead}: IProps) => {

    const handlePrint = ()=>{
        window.print();
    }

    const getAddress = () => {
        return getAddressObject(
            data.dealerContact.address1,
            data.dealerContact.address2,
            data.dealerContact.city,
            data.dealerContact.state,
            data.dealerContact.country,
            data.dealerContact.zipCode
        );
    }

    return (
        <>
        <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
                <Button size="large"  variant="contained" color="success" onClick={handlePrint}>
                Print
                </Button>
            </Grid>
        </Grid>
        <Box id="print" className='print-container'>
            {
            data && (
                <>
                <Grid  container justifyContent="space-between">
                    <Grid item md={6}>
                        <Grid>
                            <img style={{ height: "40px" }} src={SeaproLogo} alt="seapro-logo" />
                            <Grid>
                                <Grid item className="table-header-header">
                                    <Typography>{data.dealerContact.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <AddressLabel address={getAddress()} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <PerspectiveImage data={data?.layeredMedia ?? []} />
                    
                </Grid>
                <Grid container justifyContent="space-between" alignItems="end" className="print-detail-container">
                        <div>
                            <Typography>
                                <b>{data.modelYear} Model: </b>{data.modelName}
                            </Typography>
                            {type === "dealerCost" && (
                                <Typography><b>Order Number: </b>{data.orderNumber}</Typography>
                            )}
                        </div>

                        <div>
                            {data.hin && (
                                <Typography>
                                    <b>Hull Number: </b>{data.hin}

                                </Typography>
                            )}
                            {type === "dealerCost" && data.termOption !== "" && (
                                <Typography><b>Terms: </b>{data.termOption}</Typography>
                            )}
                        </div>
                        
                </Grid>
                <Grid mt={2}>
                    <PrintOrderTable data={data} type={type} />
                </Grid>
                <Grid mt={2}>
                    <Typography className="disc-text ">*Standards and options are subject to change</Typography>
                </Grid>
                </>
            )
            }
        </Box>
        </>
    )
    }



export default BasicPrint;