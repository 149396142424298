import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

const Auth = () => {
  return (
    <>
      <Grid
        container
        className="auth-container"
        justifyContent="center"
        direction="column"
      >
        <Grid item xs={6} sm={6}>
          <Grid
            container
            className="auth-container"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              columnSpacing={{ xs: 0, sm: 3, md: 8, lg: 8, xl: 16 }}
            >
              <Grid item xs={12} md={7} sm={12} className="hide-on-xs"></Grid>
              <Grid item xs={12} md={5} sm={12}>
                <Outlet />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} sm={3}></Grid>
      </Grid>
    </>
  );
};

export default Auth;
