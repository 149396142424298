import React from "react";
import NumberFormat from "react-number-format";

interface IProps {
  value: number | string;
}

const CurrencyFormat = ({ value }: IProps) => {
  return (
    <NumberFormat
      value={value && Number(value)}
      className="foo"
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
};
export default CurrencyFormat;
