import { createSlice } from '@reduxjs/toolkit';
import { IDealer } from '../../../models/Dealer';
import { AppThunk } from '../../store';

interface DealerState {
    dealerList: Array<IDealer>
    dealer: IDealer | null
}

const initialState: DealerState = {
    dealerList: [],
    dealer: null
}


const dealerSlice = createSlice({
    name: "dealer",
    initialState,
    reducers: {
        setDealerListAction: (state, action) => {
            state.dealerList = action.payload;
        },
        setDealerAction: (state, action) => {
            state.dealer = action.payload;
        }
    }
})

export const { setDealerListAction, setDealerAction } = dealerSlice.actions;

export const setDealerList= (value: IDealer[]): AppThunk => (dispatch, getState) => {
        dispatch(setDealerListAction(value));
};

export const setDealer= (value: IDealer): AppThunk => (dispatch, getState) => {
    dispatch(setDealerAction(value));
};

export default dealerSlice.reducer;