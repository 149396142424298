import { TextField } from "@mui/material"
import React, { useEffect } from "react"
import { ColumnInstance, FilterProps } from "react-table"

const findFirstColumn = <T extends Record<string, unknown>>(columns: Array<ColumnInstance<T>>): ColumnInstance<T> =>
    columns[0].columns ? findFirstColumn(columns[0].columns) : columns[0]

export function DefaultColumnFilter<T extends Record<string, unknown>>({ columns, column }: FilterProps<T>) {
    const { id, filterValue, setFilter, render } = column
    const [value, setValue] = React.useState(filterValue || '')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }
    // ensure that reset loads the new value
    useEffect(() => {
    setValue(filterValue || '')
    }, [filterValue])

    const isFirstColumn = findFirstColumn(columns) === column
    return (
    <TextField
        name={id}
        label={render('Header')}
        InputLabelProps={{ htmlFor: id }}
        value={value}
        autoFocus={isFirstColumn}
        variant='standard'
        onChange={handleChange}
        onBlur={(e) => {
            setFilter(e.target.value || undefined);
        }}
    />
    )
}