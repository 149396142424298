
import { IAuthUser, IUserFormValues } from "../models/Auth";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";
import { setAuthState } from "../store/slices/Auth/AuthSlice";
import { ITableParams, ITableResult } from "../models/TableModel";

export default class TableApi {

    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
        this.apiFactory = apiFactory;
    }

    /*
    Required Values to pass in:
    api as string

    Possible Values Passed In:
    -PageSize
    -Page
    -Filters
    -Sort

    Response Criteria:
    -data as an Array
    -rowCount as a number
    */
    async getResults<T = any> (api: string, values: ITableParams) {
            
        try {
            //console.log(api);
            return await agent.api.getgeneric<T>(api, values);
        }
        catch (error) {
            throw error;
        }

    }

}
