import {
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { MainCard } from "../../components/surface/MainCard";
import { SectionBox } from "../../components/surface/SectionBox";
import SelectDropdown from "../../components/select/SelectDropdown";
import defaultImg from "../../_assets/images/Sea-Pro-Logo-Header.png";
import uploadFile from "../../features/config/utils/UploadFile";
import { apiStore } from "../../../src/api/ApiFactory";
import {
    SelectInterface,
    ISelectOption,
} from "../../models/BoatOrdering";
import { MarketingFormData } from "../../models/MarketingForm";
import { useNotification } from "../../../src/store/context/NotificationContext";
import * as Yup from "yup";

type IProps = {
    title?: string;
    intialFormData: MarketingFormData;
    closeModal: (formData: MarketingFormData) => any;
};

const ModelYearSelect: SelectInterface = {
    label: "Model Year",
    size: "small",
    name: "year",
};
const CategorySelect: SelectInterface = {
    label: "Category*",
    size: "small",
    name: "category",
};

const UpdateMarketingForm = (props: IProps) => {
    const { Toast } = useNotification();
    const [file, setFile] = useState<File>();
    const years: ISelectOption[] = [];
    const categoryList: ISelectOption[] = [
        { key: "Sales", value: "Sales" },
        { key: "Marketing", value: "Marketing" },
        { key: "Engineering", value: "Engineering" },
    ];
    const [selectedYear, setSelectedYear] = useState(
        props?.intialFormData.modelYear?.toString()
    );
    const [selectedCategory, setSelectedCategory] = useState(
        props?.intialFormData.category
    );
    const [options, setOptions] = useState<ISelectOption[]>([]);

    useEffect(() => {
        for (let year = 2001; year <= 2040; year++) {
            years.push({
                key: year,
                value: year.toString(),
            });
        }
        setOptions(years);
    }, []);

    const validationSchema = Yup.object().shape({
        record: Yup.object({
          description: Yup.string()
            .max(150, "Description is to long")
            .required("Description is required"),
          category: Yup.string().required("Category is required")
        })
      });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            record: {
                ...props.intialFormData,
            },
            file: file,
            submit: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, setErrors }) => {
            if (values.record !== null && values.record !== undefined) {
                setSubmitting(true);

                let form = new FormData();
                if (values.file) {
                    form.append("file", values.file);
                }
                if (values.record.description) {
                    form.append("Description", values.record.description);
                }
                if (values.record.category) {
                    form.append("Category", values.record.category);
                }
                if (values.record.modelYear) {
                    form.append("ModelYear", values.record.modelYear.toString());
                }

                if (values.record.id != 0) {
                    form.append("id", values.record.id.toString());
                    const result =
                        apiStore.marketingFormApi.updateMarketingFormList(form);
                    result
                        .then((response: MarketingFormData) => {
                            Toast.success("Update Successful");
                            props.closeModal(response);
                        })
                        .catch((error) => {
                            if (error.response?.data?.length > 0) {
                                setErrors({
                                    submit:
                                        error.response?.data[0].errorMessage ?? "Unexpected Error",
                                });
                            } else {
                                setErrors({ submit: "Unexpected Error" });
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                } else {
                    const result = apiStore.marketingFormApi.addMarketingForm(form);
                    result
                        .then((response: any) => {
                            Toast.success("Added Successfully");
                            props.closeModal(response);
                        })
                        .catch((error) => {
                            if (error.response?.data?.length > 0) {
                                setErrors({
                                    submit:
                                        error.response?.data[0].errorMessage ?? "Unexpected Error",
                                });
                            } else {
                                setErrors({ submit: "Unexpected Error" });
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }
            }
        },
    });

    const handleBrowseImage = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const result: any = await uploadFile(event);
        formik.setFieldValue("record.mediaUrl", result?.imageSrc);
        formik.setFieldValue("file", result?.file);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <MainCard>
                    
                    <SectionBox>
                        <Typography variant="h6">{"Please Upload File:"}</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <img
                                    style={{ maxWidth: "400px", maxHeight: "200px" }}
                                    src={formik.values.record?.mediaUrl || defaultImg}
                                    alt="slide-img"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="text" component="label">
                                    Browse File
                                    <input
                                        hidden
                                        accept="image/*, .pdf, .xls"
                                        type="file"
                                        onChange={(event) => handleBrowseImage(event)}
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="order">
                                    {props?.intialFormData.id ? "Description" : "Description *"}
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="order"
                                    {...formik.getFieldProps("record.description")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectDropdown
                                    selectData={CategorySelect}
                                    options={categoryList}
                                    value={selectedCategory}
                                    handleChange={(e: any) => {
                                        setSelectedCategory(e.target.value);
                                        formik.getFieldProps("record.category");
                                        formik.setFieldValue("record.category", e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectDropdown
                                    selectData={ModelYearSelect}
                                    options={options}
                                    value={selectedYear}
                                    handleChange={(e: any) => {
                                        setSelectedYear(e.target.value);
                                        formik.getFieldProps("record.modelYear");
                                        formik.setFieldValue("record.modelYear", e.target.value);
                                    }}
                                />
                            </Grid>
                            {formik.errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error id="submit-helper">
                                        {formik.errors.submit}
                                    </FormHelperText>
                                </Grid>
                            )}
                        </Grid>
                        <Stack justifyContent="flex-end" mt={3} direction="row" spacing={2}>
                            <Button
                                variant="outlined"
                                onClick={() => props.closeModal(formik.values.record)}
                                disabled={formik.isSubmitting}
                                color="secondary"
                            >
                                {" Close "}
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={formik.isSubmitting}
                            >
                                Save Changes
                            </Button>
                        </Stack>
                    </SectionBox>
                </MainCard>
            </form>
        </>
    );
};

export default UpdateMarketingForm;
