import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';

interface IProps {
  data: any;
}

const initialState : IProps = {
    data: null
}

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        fetchDashboardData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { fetchDashboardData } = dashboardSlice.actions;

export const getDashboardData = (value: any):
    AppThunk => (dispatch, getState) => {
        dispatch(fetchDashboardData(value));

    };

export default dashboardSlice.reducer;