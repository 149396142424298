import { useCallback, useMemo } from "react";
import { Column, TableInstance } from "react-table";
import { useNavigate } from "react-router-dom";
import { Table } from "../../components/table/Table";
import { IUserExtended } from "../../models/User";
import React from "react";
import { SelectColumnFilter } from "../../components/table/filters/SelectColumnFilter";

const UserManagementTable = () => {
  let navigate = useNavigate();
  const columns: Column<IUserExtended>[] = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        width: 180,
        minWidth: 300,
      },
      {
        Header: "Email",
        accessor: "email",
        width: 180
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Dealer",
        accessor: "dealerName",
        Filter: SelectColumnFilter
      },
      {
        Header: "Role",
        accessor: "role",
        width: 70,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Sales Rep Code",
        accessor: "salesRepCode",
        width: 100,
        Filter: SelectColumnFilter
      },
      {
        Header: "Rep Code 2",
        accessor: "secondarySalesRepCode",
        width: 100,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 70,
        Filter: SelectColumnFilter,
      }
    ],
    []
  );

  const AddRow = useCallback(
    (instance: TableInstance<IUserExtended>) => () => {
      navigate("/user/add", { state: { user: null } });
    },
    []
  );

  const ViewRow = useCallback(
    (instance: TableInstance<IUserExtended>) => () => {
      const user = instance.selectedFlatRows[0].original;
      navigate("/user/view", { state: { user: user } });
    },
    []
  );

  return (
    <div>
      <Table<IUserExtended>
        name="user-list-table"
        serverOnChange={false}
        columns={columns}
        apiGet="/usermanagement"
        onAdd={AddRow}
        onEdit={ViewRow}
      />
    </div>
  );
};

export default UserManagementTable;
