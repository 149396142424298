import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const ModalComponent = ({ open, handleClose, children, customStyle }: any) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: "translate(calc(35px - 50%), calc(40px - 50%))",
        width: "80%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        height: "70%",
        overflow: 'auto',
        borderRadius:"4px",
        ...customStyle
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="modal-container">
                {children}
            </Box>
        </Modal>

    )
}

export default ModalComponent;