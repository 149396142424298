import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, CardMedia, Typography } from "@mui/material";
import "../../_assets/styles/app/boatOrdering/seriesSlider.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import stockimage from "../../_assets/images/Sea-Pro-Logo-Header.png";
import CurrencyFormat from "../currencyFormat/CurrencyFormat";
import { useSelector } from "react-redux";
import { calculatePriceByType } from "../../helpers/utils/utils";
import { getDealer } from "../../store/slices/Dealer/DealerSelector";

const BoatSlider = (props: any) => {
  const { setSelectedModal, selectedModal, modelList, priceType, msrp } = props;

  const dealerDetails = useSelector(getDealer);


  function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <ChevronLeftIcon
        className="sliderControl sliderLeftControl"
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <ChevronRightIcon
        className="sliderControl sliderRightControl"
        onClick={onClick}
      />
    );
  }
  var totalSlides = props.modelList?.length;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: totalSlides <= 4 ? totalSlides : 4,
    slidesToScroll: 1,
    //centerMode:true,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: totalSlides <= 3 ? totalSlides : 3,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: totalSlides <= 2 ? totalSlides : 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div>
        <Slider {...settings} className="series-slider">
          {modelList?.map((item: any, index: any) => {
            const media = item?.layeredMedia?.find(
              (el: any) => el?.perspectiveName === "Side"
            );
            return (
              <Card
                className={
                  item?.id === selectedModal?.id
                    ? "seriesCard active"
                    : "seriesCard"
                }
                key={index}
                onClick={() => setSelectedModal(index.toString())}
              >
                <Typography className="slide-info">{item?.name}</Typography>
                <Typography style={{fontSize:"13px"}}>({item?.yearDisplayLabel})</Typography>
              
                <CardMedia
                  component="img"
                  height="140"
                  image={ media?.mediaUrl ?? stockimage }
                  alt={item?.name}
                  sx={{ objectFit: "contain", paddingLeft: 1, paddingRight: 1 }}
                />
                <p className="slide-info">
                  <CurrencyFormat value={
                    calculatePriceByType(
                      item?.price,
                      priceType,
                      priceType == "msrp"? msrp : dealerDetails?.salePriceMarkupModel
                    )
                  } />

                </p>

              </Card>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default BoatSlider;
