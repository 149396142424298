import { Formik } from "formik";
import React, { useEffect } from "react";
import { HeaderLabel } from "../../components/label/HeaderLabel";
import { MainCard } from "../../components/surface/MainCard";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { IUserFormValues } from "../../models/Auth";
import { apiStore } from "../../api/ApiFactory";
import { useLocation, useNavigate } from "react-router-dom";

const FormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPassword = () => {
  let navigate = useNavigate();
  const location = useLocation();

  return (
    <MainCard className="auth-card" elevation={4}>
      <Stack
        className="auth-container center-on-xs mb-3"
        justifyContent="center"
        alignItems="flex-start"
        onClick={() => navigate("/auth/login")}
      >
        <img className="auth-logo" alt=""></img>
      </Stack>
      <HeaderLabel
        title="Forgot your Password?"
        subtitle="Enter the email address associated with your account."
      />

      <Formik
        initialValues={{
          email: "",
          submit: null,
        }}
        validationSchema={FormValidationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          apiStore.authApi
            .forgotpassword(values.email)
            .then((response) => {
              navigate("/auth/confirmation");
            })
            .catch((error) => {
              if (error.response?.data) {
                setErrors({ submit: error.response?.data });
              } else {
                setErrors({ submit: "Unexpected Error." });
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ errors, handleSubmit, getFieldProps, isSubmitting, touched }) => (
          <form noValidate onSubmit={handleSubmit} className="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <TextField
                  fullWidth
                  id="email"
                  {...getFieldProps("email")}
                  placeholder="Email *"
                  size="small"
                  error={Boolean(touched.email && errors.email)}
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="email-helper">
                    {errors.email}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Enter your email to receive your password reset instructions.
                </Typography>
              </Grid>
              {errors.submit && (
                <Grid item xs={12} className="error-spacer">
                  <Grid item xs={12}>
                    <FormHelperText error id="submit-helper">
                      {errors.submit}
                    </FormHelperText>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={6}></Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Send Reset Code"
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" sx={{ color: "#888" }}>
                  Back to <Link to="/auth/login">Login</Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default ForgotPassword;
