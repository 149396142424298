import { Box, BoxProps } from "@mui/material";
import { SectionHeader } from "./SectionHeader";

type ExtensionProps = {
    title?: string;
    divider?: boolean;
    className?: string;
    onDelete?:()=>void;
}

export const SectionBox: React.FC<Partial<BoxProps> & ExtensionProps> = (
    {
        children,
        title,
        divider,
        className,
        onDelete,
        ...rest
    }

) =>
{
    return (

        <>
            <SectionHeader 
                title={title} 
                divider={divider} 
                subcard 
                className={className}
                onDelete={onDelete}
            />

            <Box sx={{ p: 2 }} {...rest}>
                {children}
            </Box>
        </>

        
    )
}