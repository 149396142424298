import { apiStore } from "../../../api/ApiFactory"
import { LayeredModel } from "../../../models/Configuration";

const uploadPerspectiveImage = (data: LayeredModel, file?: File | undefined ) => {

    const { boatPerspectiveId, boatModelId, boatOptionChoiceId, layerOrder, } = data;

    let form = new FormData();

    if (file) {
        form.append('file', file);
    }
    if (boatPerspectiveId) {
        form.append('boatPerspectiveId', boatPerspectiveId?.toString());
    }
    if (boatModelId) {
        form.append('boatModelId', boatModelId?.toString());
    }
    if (boatOptionChoiceId) {
        form.append('boatOptionChoiceId', boatOptionChoiceId?.toString());
    }
    if (layerOrder) {
        form.append("layerOrder", layerOrder?.toString());
    }
    const result = apiStore.boatConfigApi.uploadImage(form);
    return result;

}

export default uploadPerspectiveImage;