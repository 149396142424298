import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiStore } from "../../../api/ApiFactory";


export const fetchModels = createAsyncThunk('models', () => {
    return apiStore.boatConfigApi.getAllModels();
})

const modelSlice = createSlice({
    name: "models",
    initialState: {
        data: [],
        status: ""
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchModels.fulfilled, (state, { payload }) => {
            state.data = payload.data
            state.status = "success"
        })
        builder.addCase(fetchModels.rejected, (state, { payload }) => {
            state.status = 'failed'
        })
        builder.addCase(fetchModels.pending, (state, { payload }) => {
            state.status = 'loading'
        })
    },
})

export default modelSlice.reducer;

