import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IOrderPrint, LayeredMediaEntity } from "../../../models/Print";
import { apiStore } from "../../../api/ApiFactory";
import { Box, Button, Grid, Typography } from "@mui/material";
import SeaproLogo from "../../../_assets/images/Sea-Pro-Logo-Header.png";
import QRCode from "../../../_assets/images/qrcode.png";
import WindowStickerOptionTable from "./WindowStickerOptionTable";
import WindowStickerHighlights from "./WindowStickerHighlights";
import WindowStickerPricing from "./WindowStickerPricing";

const WindowSticker = () => {

    let { orderNum } = useParams();
    const [data, setData] = useState<IOrderPrint>();
    const[topPerspective, setTopPerspective] = useState<LayeredMediaEntity[]>();
    const[sidePerspective, setSidePerspective] = useState<LayeredMediaEntity[]>();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (orderNum) {
          setLoading(true);
          const result = apiStore.orderManagementApi.getPrintDetails({ orderNum: Number(orderNum) })
          result.then((response) => {
            setData(response);

            setLoading(false);
          })
        }
    }, []);

    useEffect(() => {
        if(data?.layeredMedia) {

            let options = data.items.filter(o => o.price !== 0);

            //if there are too many options being displayed we should hide the boat images.
            if(options.length <= 30) {
                let top = data.layeredMedia?.filter((item:LayeredMediaEntity)=>item.perspectiveName === "Top");
                setTopPerspective(top);
    
                let side = data.layeredMedia?.filter((item:LayeredMediaEntity)=>item.perspectiveName === "Side");
                setSidePerspective(side);
            }

        }
    }, [data]);

    const handlePrint = () => {
        window.print();
    }


    if (loading) {
        return <p>Loading....</p>
    }

    return (
        <>
        <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
                <Button size="large"  variant="contained" color="success" onClick={handlePrint}>
                Print
                </Button>
            </Grid>
        </Grid>
        <Box id="print" className="window-sticker-container">
            {data && (
                <>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <img style={{ height: "50px" }} src={SeaproLogo} alt="SeaPro-logo" />
                            <Typography variant="h6">{data.dealerContact.name}</Typography>
                        </Grid>
                        <Grid item>
                            <img style={{ height: "80px" }} src={QRCode} alt="https://seapromfg.com/" />
                        </Grid>
                    </Grid>
                    <Grid container className="window-sticker-body" >
                        <Grid container direction="column" justifyContent="space-between" item xs={7} className="body-section">
                            <WindowStickerOptionTable data={data} />
                            <WindowStickerHighlights data={data} />
                        </Grid>
                        <Grid container direction="column" justifyContent="space-between" item xs={5} className="body-section">
                            <WindowStickerPricing data={data}/>
                            {data.hin && (
                                <Typography align="right"><b>Hull Number: </b>{data.hin}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
        </>
        
    )
}

export default WindowSticker;