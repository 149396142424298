const useScroll = {
  scrollTop: (elementClassName:string) => {
    const element = document.getElementsByClassName(elementClassName)[0];
    if(element)
    {
      element.scrollTo({ top: 0, behavior: "smooth" });
    }
  },
};

export default useScroll;
