import { Box, Typography } from "@mui/material";
import pageLoading from "../../_assets/images/sea-pro-loading.gif";

interface IProps {
  loading: boolean;
}
const pageLoader = ({ loading }: IProps) => {
  return loading ? (
    <div className="loading-indicator">
      <Box className="progress-bar">
        <Box sx={{ textAlign: "center" }}>
          <img src={pageLoading} />
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            Processing...
          </Typography>
        </Box>
      </Box>
    </div>
  ) : null;
};
export default pageLoader;
