import { Autocomplete, AutocompleteRenderInputParams, FormHelperText, Grid, InputLabel, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { apiStore } from "../../../api/ApiFactory";
import Button from "../../../components/button/Button";
import { MainCard } from "../../../components/surface/MainCard";
import { SectionBox } from "../../../components/surface/SectionBox";
import { useAppSelector } from "../../../store/hooks/hook";
import ConfirmationModal from "../ConfirmationModal";
import { enumOrderType } from "../../../helpers/utils/utils";
import { useNotification } from "../../../store/context/NotificationContext";
import { Navigate, useNavigate } from "react-router-dom";
import { Country, State } from "../../../models/Lookup";
import PhoneInput from "../../../components/textInput/PhoneInput";
import NumericFormat from "react-number-format";

const FormValudationSchema = Yup.object().shape({
    form: Yup.object({
        lastName: Yup.string().required("Last Name is required"),
        firstName: Yup.string().required("First Name is required"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email Address is required"),
        zipcode: Yup.string().required("Zip Code is required")
    })
});

interface IProps {
    motor: any;
    selectedModel: any;
}

interface PageState {
    countrylist: Array<Country> | null;
}

const SubmitLeadDetails = ({motor, selectedModel} : IProps) => {

    const [openModal, setOpenModal] = useState<any>(false);

    const { Toast } = useNotification();
    const navigate = useNavigate();

    const setCurrentStep = (id: number) => {
        apiStore.boatOrderingApi.setCurrentStep(id);
    };
    
    const 
    {
        currentStep,
        formValues,
        lookups
    } = useAppSelector((state) => ( {
        currentStep: state.reducer.Boat.currentStep,
        formValues: state.reducer.Boat.leadsForm,
        lookups: state.reducer.Boat.lookupsData
    }));

    const initialState: PageState = {
        countrylist: lookups?.countryList
    };

    const[pageData, setPageData] = useState({
        countrylist: initialState.countrylist
    });

    const formik = useFormik({
        initialValues: {
            form: formValues,
            submit: null
        },
        validationSchema: FormValudationSchema,
        onSubmit: (values, {setSubmitting, setErrors}) => {

            let selectedOptions: any = {};

            selectedModel?.sections?.forEach((section: any) => {
                section?.options?.forEach((option: any) => {
                  option?.choices?.forEach((choice: any) => {
                    if (choice?.preSelected && choice?.isVisible) {
                      selectedOptions[choice.importKey] = choice.value;
                    }
                  });
                });
            });

            selectedOptions["rdoSeriesModel"] = selectedModel.value;

            const formData: any = {
                leads: {
                    firstName: values.form.firstName,
                    lastName: values.form.lastName,
                    email: values.form.email,
                    phone: values.form.phone,
                    address: {
                        addressLine1: values.form.address,
                        addressLine2: values.form.address2,
                        city: values.form.city,
                        state: values.form.state,
                        country: values.form.country,
                        zipCode: values.form.zipcode
                    }
                },
                order: {
                    revisionNum: selectedModel.year,
                    partNum: selectedModel.importConfigName,
                    orderDate: "2022-08-08T05:32:51.813Z",
                    requestDate: "2022-08-09T12:59:25.460Z",
                    boatBuildDate: "2022-08-08T05:32:51.813Z",
                    needByDate: "2022-08-08T05:32:51.813Z",
                    salesOrderStatus: enumOrderType.Leads,
                    selectedOptions: selectedOptions,
                    enginePartNum: motor?.partNumber,
                    enginePrice: motor?.price
                }
            }

            setOpenModal(formData);
        }
    });

    const handleSave = () => {

        apiStore.leadsApi.createLeadsOrder(openModal)
            .then((response) => {
                Toast.success({title: "Request submitted successfully."});
                navigate("/order");
            })
            .catch((error) => {
                Toast.error({title: "Network error processing request."})
            })
            .finally(() => {
                formik.setSubmitting(false);
            })
    }

    useEffect(() => {
        if (formik?.values.form && Object.values(formik?.values.form)?.length > 0) {
          apiStore.boatOrderingApi.setLeadsForm(formik?.values.form);
        }
      }, [formik?.values.form]);

    return (
        <>
            <ConfirmationModal
                open={openModal}
                handleClickYes={() => {
                    handleSave(); 
                    setOpenModal(false);
                }}
                handleClickCancel={() => {
                    formik.setSubmitting(false);
                    setOpenModal(false);
                }}
                title="Confirmation"
                description="Please confirm that you would like to submit this lead."
            />
            <form onSubmit={formik.handleSubmit} >
                {formik.errors.submit && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormHelperText error id="submit-helper">
                            {formik.errors.submit}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={6}>
                        <MainCard title="CONTACT INFORMATION" divider={true}>
                            <SectionBox>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="firstName">
                                            First Name *
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="firstName"
                                            {...formik.getFieldProps("form.firstName")}
                                            placeholder="First Name"
                                            error={Boolean(formik.touched.form?.firstName && formik.errors.form?.firstName)}
                                        />
                                        {formik.touched.form?.firstName && formik.errors.form?.firstName && (
                                            <FormHelperText error id="firstName-helper">
                                                {formik.errors.form?.firstName}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="lastName">
                                            Last Name *
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="lastName"
                                            {...formik.getFieldProps("form.lastName")}
                                            placeholder="Last Name"
                                            error={Boolean(formik.touched.form?.lastName && formik.errors.form?.lastName)}
                                        />
                                        {formik.touched.form?.lastName && formik.errors.form?.lastName && (
                                            <FormHelperText error id="lastName-helper">
                                                {formik.errors.form?.lastName}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputLabel htmlFor="lastName">
                                            Email *
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="email"
                                            {...formik.getFieldProps("form.email")}
                                            placeholder="Email"
                                            error={Boolean(formik.touched.form?.email && formik.errors.form?.email)}
                                        />
                                        {formik.touched.form?.email && formik.errors.form?.email && (
                                            <FormHelperText error id="email-helper">
                                                {formik.errors.form?.email}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputLabel htmlFor="phone">
                                            Phone
                                        </InputLabel>
                                        <NumericFormat
                                            id="phone"
                                            fullWidth
                                            size="small"
                                            {...formik.getFieldProps("form.phone")}
                                            value={formik.values.form.phone}
                                            format="(###)-###-####"
                                            customInput={TextField}
                                            placeholder="(###)-###-####"
                                        />
                                        {formik.touched.form?.phone && formik.errors.form?.phone && (
                                            <FormHelperText error id="phone-helper">
                                                {formik.errors.form?.phone}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </SectionBox>
                        </MainCard>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MainCard title="ADDRESS" divider={true}>
                            <SectionBox>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6} >
                                        <InputLabel htmlFor="address">
                                            Address
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="address"
                                            {...formik.getFieldProps("form.address")}
                                            placeholder="Address Line 1"
                                            error={Boolean(formik.touched.form?.address && formik.errors.form?.address)}
                                        />
                                        {formik.touched.form?.address && formik.errors.form?.address && (
                                            <FormHelperText error id="address-helper">
                                                {formik.errors.form?.address}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputLabel htmlFor="address2">
                                            Address 2
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="address2"
                                            {...formik.getFieldProps("form.address2")}
                                            placeholder="Address Line 2"
                                            error={Boolean(formik.touched.form?.address2 && formik.errors.form?.address2)}
                                        />
                                        {formik.touched.form?.address2 && formik.errors.form?.address2 && (
                                            <FormHelperText error id="address2-helper">
                                                {formik.errors.form?.address2}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4} >
                                        <InputLabel htmlFor="city">
                                            City
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="city"
                                            {...formik.getFieldProps("form.city")}
                                            placeholder="City"
                                            error={Boolean(formik.touched.form?.city && formik.errors.form?.city)}
                                        />
                                        {formik.touched.form?.city && formik.errors.form?.city && (
                                            <FormHelperText error id="city-helper">
                                                {formik.errors.form?.city}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    {pageData.countrylist !== undefined && (
                                        <>
                                            <Grid item xs={12} md={6} xl={4}  >
                                                <InputLabel htmlFor="country">
                                                    Country
                                                </InputLabel>
                                                <Autocomplete 
                                                    id="country"
                                                    size="small"
                                                    options={pageData.countrylist!}
                                                    defaultValue={
                                                        //pageData.countrylist == null  
                                                        pageData.countrylist!.find(
                                                        (x) => x.code == "US"
                                                    )
                                                    }
                                                    getOptionLabel={(option: Country) => option.name} 
                                                    renderInput={(params) => (
                                                        <TextField {...params} name={formik.values.form.country} />
                                                    )}
                                                    onChange={(event, value: Country | null) => {
                                                        formik.setFieldValue("form.country", value?.code);
                                                    }}                                        
                                                />
                                                {formik.touched.form?.country && formik.errors.form?.country && (
                                                    <FormHelperText error id="country-helper">
                                                        {formik.errors.form?.country}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4} >
                                                <InputLabel htmlFor="state">
                                                    State
                                                </InputLabel>
                                                <Autocomplete 
                                                    id="state"
                                                    size="small"
                                                    //Key is set to country in order to refresh this control if country changes.
                                                    key={formik.values.form.country}
                                                    options={
                                                        pageData.countrylist!.find(
                                                            (x) => x.code == formik.values.form.country
                                                        )!.states
                                                    }
                                                    fullWidth
                                                    getOptionLabel={(option: State) => option.name} 
                                                    renderInput={(params) => (
                                                        <TextField {...params} name={formik.values.form.state} />
                                                    )}
                                                    onChange={(event, value: State | null) => {
                                                        formik.setFieldValue("form.state", value?.value);
                                                    }}                                        
                                                />                                       
                                                {formik.touched.form?.state && formik.errors.form?.state && (
                                                    <FormHelperText error id="state-helper">
                                                        {formik.errors.form?.state}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} md={6} xl={4}  >
                                        <InputLabel htmlFor="zipcode">
                                            Zip Code *
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="zipcode"
                                            {...formik.getFieldProps("form.zipcode")}
                                            placeholder="Zip Code"
                                            error={Boolean(formik.touched.form?.zipcode && formik.errors.form?.zipcode)}
                                        />
                                        {formik.touched.form?.zipcode && formik.errors.form?.zipcode && (
                                            <FormHelperText error id="zipcode-helper">
                                                {formik.errors.form?.zipcode}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </SectionBox>                       
                        </MainCard>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            className="step-actions"
                            sx={{ mt: 2.5 }}
                        >
                            <Button
                                sx={{ mr: 1 }}
                                variant="contained"
                                onClick={() => setCurrentStep(currentStep - 1)}
                                >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={formik.isSubmitting}
                                loading={formik.isSubmitting}
                                > 
                                Submit
                            </Button>
                        </Stack>
                        </Grid>
                    </Grid>
            </form>
        </>

    )
}


export default SubmitLeadDetails;