import React, { useEffect } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Button, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiStore } from "../../api/ApiFactory";
import { IUserAuthPasswordReset } from "../../models/Auth";

interface IProp {
    current: string;
    handleChange(panel: string) : (event: React.SyntheticEvent, newExpanded: boolean) => any;
    onClose(): () => any;
}

const FormValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required."),
    newPassword: Yup.string().required("Password is required."),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });

const AppUserPasswordReset = ({ current, handleChange, onClose }: IProp) => {

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: FormValidationSchema,
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            submit: null
        },
        onSubmit: (values, {setSubmitting, setErrors}) => {

            let data: IUserAuthPasswordReset = {
                currentPassword: values.currentPassword ?? "",
                newPassword: values.newPassword ?? ""
            }

            apiStore.authApi.resetauthpassword(data)
                .catch((error) => {
                    console.log(error);
                    if(error.response?.data) {
                        setErrors({ submit: error.response?.data[0].errorMessage })
                    } else {
                        
                    }
                })
                .finally(() => {
                    setSubmitting(false);
                })
        }
    });

    useEffect(() => {
        formik.resetForm();
    }, [current]);


    return (
        <Accordion className="app-accordion" disableGutters elevation={0} square expanded={current === 'reset-password-panel'} 
            onChange={handleChange('reset-password-panel')}>

            <AccordionSummary className="app-accordion-summary" expandIcon={<ExpandMore/>} 
                aria-controls="user-profile-reset-password-content" >
            <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography className="app-accordion-header">
                            Password
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={10}>
                        <Typography className="app-accordion-label">
                            Click here to reset password.
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className="app-accordion-detail">
                <form onSubmit={formik.handleSubmit}>

                    {formik.errors.submit && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormHelperText error id="submit-helper">
                                {formik.errors.submit}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    </>
                    )}

                    <Grid container spacing={4}>
                        
                        <Grid item xs={12} md={4} lg={3} xl={2} >

                            <InputLabel htmlFor="currentPassword">
                                Current Password
                            </InputLabel>
                            <TextField 
                                fullWidth id="currentPassword" 
                                placeholder="Current Password" 
                                size="small"
                                type="password" 
                                {...formik.getFieldProps("currentPassword")}
                                error={Boolean(formik.touched.currentPassword && formik.errors.currentPassword)}
                            />

                            {formik.touched.currentPassword && formik.errors.currentPassword && (
                                <FormHelperText error id="currentpassword-helper">
                                    {formik.errors.currentPassword}
                                </FormHelperText>
                            )}

                        </Grid>

                        <Grid item xs={12} md={4} lg={3} xl={2}>

                            <InputLabel htmlFor="newPassword">
                                New Password
                            </InputLabel>
                            <TextField 
                                fullWidth id="newPassword" 
                                placeholder="New Password" 
                                size="small"
                                type="password" 
                                {...formik.getFieldProps("newPassword")}
                                error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                            />

                            {formik.touched.newPassword && formik.errors.newPassword && (
                                <FormHelperText error id="newpassword-helper">
                                    {formik.errors.newPassword}
                                </FormHelperText>
                            )}

                        </Grid>

                        <Grid item xs={12} md={4} lg={3} xl={2}>

                            <InputLabel htmlFor="confirmPassword">
                                Confirm Password
                            </InputLabel>
                            <TextField 
                                fullWidth id="confirmPassword" 
                                placeholder="Confirm Password" 
                                size="small"
                                type="password" 
                                {...formik.getFieldProps("confirmPassword")}
                                error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                            />

                            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                <FormHelperText error id="confirmpassword-helper">
                                    {formik.errors.confirmPassword}
                                </FormHelperText>
                            )}

                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={9} xl={6}>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                                sx={{ mt: 2.5 }}
                            >
                                <Button
                                    variant="outlined"
                                    disabled={formik.isSubmitting}
                                    onClick={ onClose() }
                                    color="secondary"
                                >
                                    {" "} Cancel{" "}
                                </Button>
                                <Button
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                    variant="contained"
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>



                </form>
                   
                
            </AccordionDetails>

        </Accordion>
    );
}

export default AppUserPasswordReset;