import React, { useEffect, useState } from 'react';
import { Grid, Typography, InputLabel, Checkbox, FormControlLabel, FormGroup, TextField, FormHelperText, Stack, Button } from "@mui/material"
import { useFormik } from 'formik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { apiStore } from '../../../api/ApiFactory';
import { MainCard } from '../../../components/surface/MainCard';
import { SectionBox } from '../../../components/surface/SectionBox';
import { IModelExtended } from '../../../models/Configuration';
import { formatDate } from '../../../helpers/utils/formatDate';
import { StackLabel } from '../../../components/label/StackLabel';
import SubmitButtonGroup from '../../../components/button/SubmitButtonGroup';
import LayeredMedia from './LayeredMedia/LayeredMedia';
import OptionTableComponent from './TableComponents/OptionTableComponent';

const EditConfiguration = () => {
    // Getting id from url
    const [searchParams] = useSearchParams();
    let boatModelId = searchParams.get('boatModelId');
    
    const navigate = useNavigate();
    const location = useLocation();
    const state:any  = location.state ?? null;
    
    const [modelDetails, setModelDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string | null>('');

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: modelDetails?.id ? modelDetails?.id : "",
            name: modelDetails?.name ? modelDetails?.name : "",
            seriesName: modelDetails?.seriesName ? modelDetails?.seriesName : "",
            year: modelDetails?.year ? modelDetails?.year : "",
            visibleToAdmin: modelDetails?.visibleToAdmin ? modelDetails?.visibleToAdmin : false,
            visibleToDealer: modelDetails?.visibleToDealer ? modelDetails?.visibleToDealer : false,
            visibleToConsumer: modelDetails?.visibleToConsumer ? modelDetails?.visibleToConsumer : false,
            archiveModel: modelDetails?.archiveModel ? modelDetails?.archiveModel : false,
            order: modelDetails?.order ? modelDetails?.order : 0,
            price: modelDetails?.price ? modelDetails?.price : 0.00,
            displayName: modelDetails?.displayName ? modelDetails?.displayName : "",
            detailUrl: modelDetails?.detailUrl ? modelDetails?.detailUrl : "",
            userDefinedFields: modelDetails?.userDefinedFields,
            importConfigName: modelDetails?.importConfigName,
            yearDisplayLabel: modelDetails?.yearDisplayLabel,
            submit: null
        },

        onSubmit: values => {
            values.order = values.order ? values.order : null;
            updateModelDetails(values);
        }
    })

    const updateModelDetails = (values: IModelExtended) => {
        var results = apiStore.boatConfigApi.updateModel(values)

        results.then((response) => {

        })
        .catch((error) => {
            if (error.response?.data?.length > 0) {
                formik.setErrors({ submit: error.response?.data[0].errorMessage ?? "Unexpected Error" })
            }
            else {
                formik.setErrors({ submit: "Unexpected Error" });
            }
        })
        .finally(() => {
            formik.setSubmitting(false);
        })

    }

    //Calling Model Details Api
    useEffect(() => {
        let mounted = true;
       if(mounted && boatModelId!=null)
       {
       
        const response:any = apiStore.boatConfigApi.getModelDetails(boatModelId);
        response.then((res:any) => {
            if (mounted) {
                setModelDetails(res);
                setIsLoading(false);
            }
        }).catch((error:any) => {
            setErrors("unable to fetch model");
            setIsLoading(false);
        });

        setIsLoading(true);
        
       }    
        return () => {
            mounted = false
        }
    }, [boatModelId])

    const managePrintDetails = () => {
        navigate("/config/modellist/printDetails?modelId="+boatModelId, { state: { data: modelDetails } });
    }

    if (isLoading) {
        return <p>Loading...</p>
    }
    if (errors) {
        return <p>{errors}</p> 
    }

    return (
        <>
            {modelDetails &&
                (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className="page-header-label">
                                    {"Boat Model"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={4}>
                                {formik.errors.submit && (
                                    <Grid item xs={12}>
                                        <FormHelperText sx={{position:"absolute"}} error id="submit-helper">{formik.errors.submit}</FormHelperText>
                                    </Grid>
                                )}
                                <form onSubmit={formik.handleSubmit}>
                                    <MainCard title="GENERAL INFORMATION" divider={true}>
                                        <SectionBox>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <MainCard subcard title="Details" divider={true}>
                                                        <SectionBox>
                                                            <StackLabel title='Series' label={modelDetails?.seriesName} />
                                                            <StackLabel title='Model' label={modelDetails?.displayName} />
                                                            <StackLabel title='Epicor Revision' label={modelDetails?.year} />
                                                            <StackLabel title='Import Config Name' label={modelDetails?.importConfigName} />
                                                            <StackLabel title='Price' label={modelDetails?.price} currency/>
                                                            <StackLabel title='Adjusted Price' label={modelDetails?.adjustedPrice} currency />
                                                        </SectionBox>
                                                    </MainCard>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormGroup>
                                                        <InputLabel>Visible To</InputLabel>
                                                        <Grid>
                                                            <FormControlLabel
                                                                value="visibleToAdmin"
                                                                onChange={formik.handleChange}
                                                                control={<Checkbox checked={formik.values.visibleToAdmin ? true : false} name="visibleToAdmin" />}
                                                                label="Admins" />
                                                            <FormControlLabel
                                                                value="visibleToDealers"
                                                                onChange={formik.handleChange}
                                                                control={<Checkbox
                                                                    checked={formik.values.visibleToDealer ? true : false} name="visibleToDealer" />}
                                                                label="Dealers" />
                                                            <FormControlLabel
                                                                value="visibleToConsumer"
                                                                onChange={formik.handleChange}
                                                                control={<Checkbox
                                                                    checked={formik.values.visibleToConsumer ? true : false} name="visibleToConsumer" />}
                                                                label="Consumers" />
                                                        </Grid>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="displayName">Display Label *</InputLabel>
                                                    <TextField
                                                        onChange={formik.handleChange}
                                                        value={formik.values.displayName}
                                                        name="displayName"
                                                        fullWidth
                                                        size="small"
                                                        id="displayName"
                                                        placeholder="Display Name *"

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    
                                                    <FormGroup aria-label="position" >
                                                        <FormControlLabel
                                                            labelPlacement="end"
                                                            label="Archive Model"
                                                            name="archiveModel"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.archiveModel }
                                                            control={<Checkbox checked={formik.values.archiveModel }/> }
                                                        />
                                                    </FormGroup>
                                                    <Typography sx={{fontSize:"11px"}}>Archived Models will prevent end users from creating or editing orders. They will only be able to view existing orders in read-only mode.</Typography>
                                                    
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="yearDisplayLabel">Model Year (Display) *</InputLabel>
                                                    <TextField
                                                        onChange={formik.handleChange}
                                                        value={formik.values.yearDisplayLabel}
                                                        name="yearDisplayLabel"
                                                        fullWidth
                                                        size="small"
                                                        id="yearDisplayLabel"
                                                        placeholder="Model Year Display Label *"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="order">Order *</InputLabel>
                                                    <TextField
                                                        onChange={formik.handleChange}
                                                        value={formik.values.order}
                                                        name="order"
                                                        fullWidth
                                                        size="small"
                                                        id="order"
                                                        placeholder="Order *"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="detailUrl">Detail Url *</InputLabel>
                                                    <TextField
                                                        onChange={formik.handleChange}
                                                        value={formik.values.detailUrl}
                                                        name="detailUrl"
                                                        fullWidth
                                                        size="small"
                                                        id="detailUrl"
                                                        placeholder="Detail Url *"

                                                    />
                                                </Grid>
                                            </Grid>
                                        </SectionBox> 
                                        <SectionBox>
                                            <SubmitButtonGroup disabled={formik.isSubmitting} />
                                            <Typography sx={{fontSize:"9px"}}>Last Updated : {formatDate(modelDetails.modifiedDate)}</Typography>
                                        </SectionBox>
                                    </MainCard>
                                </form>
                            </Grid>
                            <Grid item xs={12} lg={8}>

                                <Grid container spacing={4}>
                                    <Grid item xs={12} lg={6}>
                                        <MainCard title="MODEL - LAYERED MEDIA" divider={true}>
                                            <SectionBox>
                                                <LayeredMedia perspectives={modelDetails?.layeredMedia} />
                                            </SectionBox>
                                        </MainCard>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <MainCard title="PRINT CONFIGURATION" divider={true}>
                                            <SectionBox>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12}>
                                                        <MainCard subcard title="Standard Equipment" divider={true}>
                                                            <SectionBox>
                                                                <Typography variant="caption">{modelDetails?.printInfo?.standardEquipment}</Typography>
                                                            </SectionBox>
                                                        </MainCard>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <MainCard subcard title="Specifications" divider={true}>
                                                            <SectionBox>
                                                                {modelDetails?.printInfo?.specifications?.map((item:any, index:any) => 
                                                                    <StackLabel key={index} title={item.name} label={item.value} />
                                                                )}
                                                            </SectionBox>
                                                        </MainCard>
                                                    </Grid>
                                                </Grid>

                                                <Stack direction="row" mt={1}>
                                                    <Button onClick={managePrintDetails}>Manage Print Details</Button>
                                                </Stack>
                                            </SectionBox>
                                        </MainCard>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                            
                                <MainCard title="OPTIONS">
                                    <SectionBox>
                                        <OptionTableComponent data={modelDetails.sections} />
                                    </SectionBox>
                                </MainCard>
                        
                            </Grid>
                        </Grid>

                    </>
                )
            }
        </>
    )

}


export default EditConfiguration