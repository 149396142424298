import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import { apiStore } from '../../../api/ApiFactory';
import { useNavigate } from 'react-router-dom';
import BasicPrint from './BasicPrint';
import { IOrderPrint } from '../../../models/Print';

const PrintOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let [search, setSearchParam] = useSearchParams();
  let orderNum = search.get("orderNum");
  let type = search.get("type");
  const [data, setData] = useState<IOrderPrint>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {

    if (orderNum) {
      setLoading(true);
      const result = apiStore.orderManagementApi.getPrintDetails({ orderNum: Number(orderNum) })
      result.then((response) => {
        setData(response);
        setLoading(false);

      })
    }
  }, []);

  if (loading) {
    return <p>Loading....</p>
  }

  return (
    <BasicPrint data={data!} type={type ?? "msrp"} />
  )
}

export default PrintOrder