import React from "react";

const Empty = () => {

    return (
        <div>
            Empty Page.
        </div>
    )
}

export default Empty;