import { async } from "rxjs";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class LeadsApi {

    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
        this.apiFactory = apiFactory;
    }

    createLeadsOrder = async (body: any) => {
        try {
          await agent.api.post("/leads", body);
        }
        catch (error) {
          throw error;
        }
      }

    getList = async (params: any) => {
        try {
            const list = await agent.api.get('/leads/getlist', params);
            return list;
        }
        catch (error) {
            throw error;
        }
    }

    getPrintDetails = async (params: any) => {
        try {
            console.log(params);
            const result = await agent.api.get("/leads/getprint", params);
            return result;
        }
        catch (error) {
            throw error;
        }
    }
    
}