import { useEffect, useMemo, useState, useCallback } from "react";
import { Grid, Typography, Button, Stack, IconButton } from "@mui/material";
import { MainCard } from "../../components/surface/MainCard";
import { SectionBox } from "../../components/surface/SectionBox";
import MainModal from "../../components/modal/MainModal";

import { AddressLabel } from "../../components/label/AddressLabel";
import PrintIcon from "@mui/icons-material/Print";
import { ReactTable } from "../../components/table/ReactTable";
import { Column, TableInstance } from "react-table";
import DownloadIcon from "@mui/icons-material/Download";
import { apiStore } from "../../api/ApiFactory";
import { MarketingFormData } from "../../models/MarketingForm";
import UpdateMarketingForm from "../marketingForm/UpdateMarketingForm";

import { Table } from "../../components/table/Table";
import { LayeredModel } from "../../models/Configuration";
import { tab } from "@testing-library/user-event/dist/tab";
import { table } from "console";
import { useAuth } from "../../../src/store/hooks/useAuth";
import { Roles } from "../../helpers/enums/AuthConstants";
import { useNotification } from "../../store/context/NotificationContext";
import { formatDate } from "../../helpers/utils/formatDate";

const MarketingFormDetails = () => {
  const defaultFormData: MarketingFormData = {
    id: 0,
    description: "",
    category: "",
    modelYear: 0,
    fileName: "",
    fileType: "",
    dateUploaded: "",
    mediaUrl: "",
    mediaId: 0,
  };
  const [selectedFormData, setSelectedFormData] =
    useState<MarketingFormData>(defaultFormData);
  const [tableData, setTableData] = useState<MarketingFormData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const openDialog = () => {
    setOpenModal(true);
  };
  let isAdmin = useAuth().authuser?.user.role === Roles.ADMINISTRATOR;
  const { Toast } = useNotification();

  const downloadFile = async (rowData: any) => {
    try {
      await apiStore.marketingFormApi.getImage(rowData.id, rowData.fileType);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleClick = () => {
    const inputElement = document.getElementById("imageInput");
    if (inputElement) {
      inputElement.click();
    }
  };
  //Table Columns Structure
  const columns: Column<MarketingFormData>[] = [
    {
      Header: "Description",
      accessor: "description",
      width: 100,
    },
    {
      Header: "Category",
      accessor: "category",
      width: 200,
    },
    {
      Header: "Model Year",
      accessor: "modelYear",
      width: 120,
    },
    {
      Header: "File Name",
      accessor: "fileName",
    },
    {
      Header: "File Type",
      accessor: "fileType",
      width: 100,
    },
    {
      Header: "DateUploaded",
      accessor: "dateUploaded",
      Cell: (cell: any) => (cell.value ? formatDate(cell.value) : ""),
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ row }: any) => {
        const rowData = row.original;
        //console.log(rowData);
        return (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() => downloadFile(rowData)}
            >
              Download
            </Button>
          </>
        );
      },
    },
  ];

  const ViewRow = useCallback(
    (instance: TableInstance<MarketingFormData>) => () => {
      if (isAdmin) {
        const formDetails = instance.selectedFlatRows[0].original;
        setSelectedFormData(formDetails);
        openDialog();
      } else {
        Toast.failure("Dealer cannot edit a form.");
      }
    },
    []
  );

  const AddRow = useCallback(
    (instance: TableInstance<MarketingFormData>) => () => {
      setSelectedFormData(defaultFormData);
      openDialog();
    },
    []
  );
  useEffect(() => {
    const result = apiStore.marketingFormApi.getAllMarketingFormList();
    result
      .then((response: any) => {
        setTableData(response.data);
      })
      .catch((error) => {})
      .finally(() => {});
  }, []);

  const updateMarketingFormOnChange = (formData: MarketingFormData | null) => {

    if (formData !== null) {
      closeModal();
      setTableData((prevData) => 
      {
        let record = prevData.find(x => x.id === formData.id);

        if(record) {
          prevData = prevData.map((item) => {
            if (item.id === formData.id) {
              return { ...item, ...formData };
            }
            return item;
          })
        }
        else {
          prevData.push(formData);
        }
        
        return prevData;
      });
    }
  };

  return (
    <>
      <MainModal open={openModal} handleClose={closeModal}>
        <UpdateMarketingForm
          intialFormData={selectedFormData}
          closeModal={updateMarketingFormOnChange}
        ></UpdateMarketingForm>
      </MainModal>

      <ReactTable
        name="marketing-form-table"
        serverOnChange={false}
        columns={columns}
        data={tableData}
        isLoading={false}
        onEdit={isAdmin ? ViewRow : undefined}
        onAdd={isAdmin ? AddRow : undefined}
      />
    </>
  );
};

export default MarketingFormDetails;
