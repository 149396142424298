const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    return new Promise((resolve, reject) => {

        if (!event.target.files){
            reject("No file selected");
            return;
        };
        const selectedFile = event.target.files[0];
        const reader = new FileReader();
        let imageSrc: any = null;
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
            resolve( {imageSrc:reader.result as string,file:selectedFile});
        }
    })
}

export default uploadFile;