import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiStore } from "../../api/ApiFactory";
import CardList from "./CardList";
import ChartIndex from "./Charts/ChartIndex";
import { NameValueEntity } from "../../models/Common";
import { REFUSED } from "dns";
import { useSelector } from "react-redux";
import { getYearList } from "../../store/slices/Lookup/LookupSelector";

const Dashboard = () => {

  const [year, setYear] = useState("");
  const yearList = useSelector(getYearList);
  
  const removeBreadcrumb = () => {
    const header = document.querySelector(".header-label-container") as HTMLDivElement;

    if(header) {
      header.style.display = "none";
    }
    
  };

  const addBreadcrumb = () => {
    const header = document.querySelector(".header-label-container") as HTMLDivElement;

    if(header) {
      header.style.display = "block";
    }
    
  };

  useEffect(() => {
    if(yearList.length > 0) {
      setYear(yearList[yearList.length - 1].name);
    }
    
  }, [yearList]);

  useEffect(() => {
    if (year != "") {
      const result = apiStore.dashboardApi.getDashboardData(Number(year));
    }
  }, [year]);

  useEffect(() => {
    removeBreadcrumb();
    return () => {
      addBreadcrumb();
    };
  }, [year]);

  const handleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  return (
    <div className="dashboard-container">
      <section className="model-year">
        <Stack
          direction="row"
          mb={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="page-title" style={{ fontSize: 25 }}>
            Dashboard
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <InputLabel>Year</InputLabel>
            <FormControl sx={{ width: "150px" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                onChange={handleChange}
                size="small"
              >
                <MenuItem disabled key="-1">
                  <em>Year</em>
                </MenuItem>
                {yearList.map((item, index) => (
                  <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </section>
      <section className="card-list-section">
        <CardList />
      </section>
      
      <section className="report">
          <ChartIndex/>
      </section>
    </div>
  );
};

export default Dashboard;
