import React, { useState, useEffect } from "react";
import { Grid, Divider } from "@mui/material";
import { IBreadCrumb } from "../../models/BoatOrdering";
import SelectDropdown from "../../components/select/SelectDropdown";
import { SelectInterface } from "../../models/BoatOrdering";
import CurrencyFormat from "../../components/currencyFormat/CurrencyFormat";
import { calculatePriceByType } from "../../helpers/utils/utils";
import { useSelector } from "react-redux";
import { selectAuthUser } from "../../store/slices/Auth/AuthSelector";
import { DefaultCostType, Roles } from "../../helpers/enums/Common";
import { useAppSelector } from "../../store/hooks/hook";
import { NavMode } from "../../helpers/enums/Layout";
import navScroll from "../../helpers/utils/navScroll";
import { HasPermission } from "../../services/AuthService";
import { Permissions } from "../../helpers/enums/AuthConstants";

interface IBreadCrumbProps {
  breadCrumb: IBreadCrumb;
  motorPrice?: number | undefined;
  salePriceMarkupModel?: any;
  setBreadCrumb: (...arg: any) => void;
}

const BoatOrderingBreadCrumb = ({
  breadCrumb,
  setBreadCrumb,
  motorPrice,
  salePriceMarkupModel,
}: IBreadCrumbProps) => {
  const { price, name, priceType, modelYear, msrp } = breadCrumb;

  const { navMode, auth } = useAppSelector((state) => ({
    navMode: state.reducer.Layout.navMode,
    auth: state.reducer.Auth.authuser,
  }));

  const dropdownOptions: { key: string; value: string }[] = DefaultCostType;

  const [options, setOptions] = useState(dropdownOptions);

  const dropdown: SelectInterface = {
    size: "small",
    classes: "standard",
  };

  useEffect(() => {
    if (navMode === NavMode.LEADS) {
      const list = options?.filter((el) => el.key === "msrp");
      setOptions([...list]);
    } else if (auth?.user?.role !== Roles.dealer) {
      const list = options?.filter((el) => el.key !== "salesPrice");
      setOptions([...list]);
    } else if (auth?.user?.role === Roles.dealer) {
      //I could probably do an else here but I want to be explicit.
      const hasPermission = HasPermission(
        auth?.user,
        Permissions.UseDealerCost,
        Roles.dealer
      );

      if (!hasPermission) {
        const list = options?.filter((el) => el.key !== "dealerCost");
        setOptions([...list]);
      }
    }
  }, [auth]);

  useEffect(() => {
    navScroll();
  });

  let amount: string | number = price;
  if (motorPrice) {
    amount = motorPrice + price;
  }
  return (
    <>
      <div className="subTitleHeader">
        <h4 className="title">
          {name} ({modelYear})
        </h4>
        <Divider />
        <h5 className="title-with-select">
          <CurrencyFormat
            value={calculatePriceByType(
              amount,
              priceType,
              priceType == "msrp" ? msrp : salePriceMarkupModel
            )}
          />
          <SelectDropdown
            selectData={dropdown}
            options={options}
            value={priceType}
            handleChange={(e: any) => setBreadCrumb(e.target.value)}
          />
        </h5>
      </div>
    </>
  );
};
export default BoatOrderingBreadCrumb;
