import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { apiStore } from "../../api/ApiFactory";
import BasicPrint from "../print/Order/BasicPrint";
import { IOrderPrint } from "../../models/Print";

const PrintLead = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<IOrderPrint>();
    let [search, setSearchParam] = useSearchParams();
    
    let orderNumber = search.get("order");

    const location = useLocation();

    console.log(location);

    useEffect(() => {
    
        console.log(orderNumber);
        if (orderNumber) {
          const result = apiStore.leadsApi.getPrintDetails({ leadNumber: orderNumber })
          result.then((response) => {

            setData(response);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
          })
        }
    }, []);

    const handlePrint = ()=>{
        window.print();
    }

    const handleCancel = ()=>{
        window.close();
    }

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <>
            <BasicPrint data={data!} type={"msrp"} isLead={true} />
        </>
    )

}

export default PrintLead;

