import { Box, Grid } from "@mui/material";
import React from "react";
import CardComponent from "./CardComponent";
import { useSelector } from "react-redux";
import { dashboardIcons } from "./Icons/Icons";
import { Roles } from "../../helpers/enums/AuthConstants";
import { useAuth } from "../../store/hooks/useAuth";
import { getDashboardData } from "../../store/slices/Dashboard/DashboardSelector";


const CardList = () => {
  let userData = useAuth().authuser;

  const dashboard = useSelector(getDashboardData) ?? {};

  const { openOrders, orders, quotes, dealers, deliveryInventory, leads, retailSold } = dashboard;
  const { role } = userData?.user!;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container gap={"10px"} justifyContent="space-between">
          <CardComponent
            title={"Boat Orders"}
            icon={dashboardIcons?.BOAT_ORDERS}
            stats={orders}
          />

        {role === Roles.ADMINISTRATOR  && ( 
          <CardComponent
            title={"Open Orders"}
            icon={dashboardIcons?.OPEN_ORDERS}
            stats={openOrders}
          />
        )}

          <CardComponent
            title={"Quotes"}
            icon={dashboardIcons?.QUOTES}
            stats={quotes}
          />
          <CardComponent
            title={"Delivery Inventory"}
            icon={dashboardIcons.DEALER_INVENTORY}
            stats={deliveryInventory}
          />
          <CardComponent
            title={"Leads"}
            icon={dashboardIcons.LEADS}
            stats={leads}
          />
          <CardComponent
            title={"Retail Sold"}
            icon={dashboardIcons.RETAIL_SOLD}
            stats={retailSold}
          />
          {role === Roles.SALESREP || role === Roles.ADMINISTRATOR ? (
            <CardComponent
              title={"Dealers"}
              icon={dashboardIcons.DEALERS}
              stats={dealers}
            />
          ) : null}
        </Grid>
      </Box>
    </>
  );
};

export default CardList;
