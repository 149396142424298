import { MarketingFormData } from "../models/MarketingForm";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class MarketingFormApi {
    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
        this.apiFactory = apiFactory;
    }

    getAllMarketingFormList = async () => {
        try {
            const list = await agent.api.get(
                `/MarketingForm/GetAllMarketingFormList`,
                null
            );
            return list;
        } catch (error) {
            throw error;
        }
    };
    addMarketingForm = async (formData: FormData) => {
        try {
            const list = await agent.api.uploadImage(
                `/MarketingForm/AddMarketingFormList`,
                formData
            );
            return list;
        } catch (error) {
            throw error;
        }
    };
    updateMarketingFormList = async (formData: FormData) => {
        try {
            const list = await agent.api.uploadImage(
                `/MarketingForm/UpdateMarketingFormList`,
                formData
            );
            return list;
        } catch (error) {
            throw error;
        }
    };
    getMarketingFormById = async (id: number) => {
        try {
            const list = await agent.api.get(
                `/MarketingForm/GetMarketingFormList?id=${id}`,
                null
            );
            return list;
        } catch (error) {
            throw error;
        }
    };
    getImage = async (id: number, fileType: string) => {
        try {
            await agent.api.downloadImage(`/MarketingForm/GetBlobImage?id=${id}`, fileType);
        }
        catch (error) {
            throw error;
        }
    };
}
