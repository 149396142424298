import { Box } from "@mui/material";
import ChartWrapper from "../Charts/ChartWrapper";
import LeadsTableComponent from "./Table/LeadsTableComponent";

type LeadsType = {
  hasScroll?: boolean;
  height?: string;
  minHeight?: string;
  dashboardData: any;
};

const Leads = ({ hasScroll, height, minHeight, dashboardData }: LeadsType) => {
  return (
    <>
      <ChartWrapper
        title="Recent Leads"
        hasScroll={hasScroll}
        height={height}
        minHeight={minHeight}
      >
        <Box className="table-container">
          <LeadsTableComponent dashboard={dashboardData} />
        </Box>
      </ChartWrapper>
    </>
  );
};

export default Leads;
