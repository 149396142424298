import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDefinedFieldType } from "../../helpers/enums/Types";
import { IUserDefinedField } from "../../models/Common";
import { IDealer } from "../../models/Dealer";
import { useAppSelector } from "../../store/hooks/hook";
import * as Yup from "yup";
import { Button, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { MainCard } from "../../components/surface/MainCard";
import { SectionBox } from "../../components/surface/SectionBox";
import { StackLabel } from "../../components/label/StackLabel";
import { FieldArray, Formik, FormikErrors } from "formik";
import { apiStore } from "../../api/ApiFactory";
import SubmitButtonGroup from "../../components/button/SubmitButtonGroup";
import { useDispatch } from "react-redux";
import { getValueByKey } from "../../helpers/utils/getEnumValueKey";
import { DefaultCostType } from "../../helpers/enums/Common";
import { setDealerList } from "../../store/slices/Dealer/DealerSlice";

interface IProps {
    data?: IDealer
}

const AdditionalPricing = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();

    const {
        dealers
    } = useAppSelector((state) => ({
        dealers: state.reducer.Dealers.dealerList
    }))

    const [pageData, setData] = useState<IDealer>();
    const [currentIndex, setCurrentIndex] = useState<number>(-1);

    useEffect(() => {
    
        if (id == undefined || dealers.length <= 0) {
            navigate('/dealer');
        }
        else {
            setPageData(Number(id));
        }
    }, []);

    const getEmptySpec = () => {
        const item: IUserDefinedField = {
            name: "",
            value: "",
            type: UserDefinedFieldType.ADDITIONALDEALERPRICE
        }

        return item;
    }

    const setPageData = (id: number) => {
        const index = dealers.findIndex((item) => item.id === id);
        const data = { ...dealers[index] };
    
        setCurrentIndex(index);
        setData(data);
    }

    const ValidationSchema = Yup.object().shape({
            record: Yup.array()
            .of(
                Yup.object().shape(
                    {
                        name: Yup.string().required("Name is required"),
                        value: Yup.number()
                            .typeError("Value must be numeric")
                            .required("Value is required"),
                        order: Yup.number()
                            .integer("Order must be an integer")
                            .typeError("Order must be numeric")
                    }
                )
            )

    });

    if(pageData === undefined) {
        return (
          <>Loading...</>
        )
      }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={5} xl={4}>
                    <MainCard title='DEALER INFORMATION' divider={true}>
                        <SectionBox>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {pageData && ( 
                                        <MainCard subcard title="Details" divider={true}>
                                            <SectionBox>
                                                <StackLabel title="Dealer Name" label={pageData.name} />
                                                <StackLabel title="Default Cost Type" label={pageData.defaultCostType != "" ? getValueByKey(pageData.defaultCostType!, DefaultCostType) : "Not Set" } />
                                                <StackLabel title="Sale Price Markup - Model" label={pageData.salePriceMarkupModel ? Math.trunc(pageData?.salePriceMarkupModel * 100) + "%" : "Not Set"} />
                                                <StackLabel title="Sale Price Markup - Option" label={pageData.salePriceMarkupOption ? Math.trunc(pageData?.salePriceMarkupOption * 100) + "%" : "Not Set"} />
                                                <StackLabel title="Available for Leads" label={pageData.isAvailableForLeads ? "Yes" : "No"} />
                                            </SectionBox>
                                        </MainCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} sx={{display:"flex", justifyContent:"center", mt:3}}>
                                    <Typography className='info-emphasis-lg' component="span" sx={{display:"flex"}}>
                                        {"Fields configured here will be used on the Window Sticker as additional price line items."}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SectionBox>
                    </MainCard>
                </Grid>
                <Grid item xs={12} md={6} lg={7} xl={8}>
                    <MainCard title="ADDITIONAL PRICING" divider={true}>
                        <SectionBox>
                            <Formik
                                initialValues={{
                                    record: pageData.additionalPricing ?? [],
                                    submit: null
                                }}
                                validationSchema={ValidationSchema}
                                onSubmit={(values, {setErrors, setSubmitting}) => {

                                    const savedata: IDealer = {
                                        ...pageData,
                                        additionalPricing: values.record,
                                    }

                                    apiStore.dealerManagementApi.saveDealer(savedata)
                                        .then((response) => {

                                            const data = [...dealers];
                                            data[currentIndex] = response

                                            dispatch(setDealerList(data));
                                            navigate(`/dealer/view/${response.id}`);
                                        })
                                        .catch((error) => {

                                            if(error.response?.data?.length > 0) {
                                                setErrors({ submit: error.response?.data[0].errorMessage ?? "Unexpected Error" });
                                            }
                                            else {
                                                setErrors({ submit: "Unexpected Error" });
                                            }
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                        })
                                }}
                            >

                                {({
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    getFieldProps,
                                    isSubmitting,
                                    setFieldValue,
                                    touched,
                                    setFieldTouched,
                                    values,
                                    status,
                                }) => (
                                    <form noValidate onSubmit={handleSubmit}>

                                        <Grid container spacing={2}>
                                            {errors.submit && (
                                                <Grid item xs={12}>
                                                    <FormHelperText error id="submit-helper">
                                                    {errors.submit}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <FieldArray name="record">
                                                    {({remove, push}) => (
                                                        <>
                                                            {values.record.length > 0 && (

                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}> 
                                                                        <InputLabel>Line Items</InputLabel>
                                                                    </Grid>
                                                                    {values.record.map((item, index) =>
                                                                        <Grid item xs={12} key={index}>
                                                                            <MainCard>
                                                                                <SectionBox onDelete={()=>remove(index)} title={`Line Item # ${index+1}`} divider={true} className="header-panel-color">
                                                                                    <Grid container spacing={2}>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <InputLabel>Name *</InputLabel>
                                                                                            <TextField
                                                                                                value={item.name}
                                                                                                name={`record.${index}.name`}
                                                                                                fullWidth
                                                                                                onBlur={() => setFieldTouched(`record.${index}.name`, true)}
                                                                                                onChange={handleChange} 
                                                                                                size="small"
                                                                                                id={`record.${index}.name`}
                                                                                                placeholder="Name *"
                                                                                                error={Boolean(
                                                                                                    touched.record?.at(index)?.name &&
                                                                                                    (errors.record?.at(index) as FormikErrors<IUserDefinedField>)?.name
                                                                                                )}
                                                                                            />
                                                                                            {touched.record?.at(index)?.name && 
                                                                                                (errors.record?.at(index) as FormikErrors<IUserDefinedField>)?.name && (
                                                                                                <FormHelperText error id={`record.${index}.name-error`}>
                                                                                                    {(errors.record?.at(index) as FormikErrors<IUserDefinedField>).name}
                                                                                                </FormHelperText>
                                                                                            )}
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <InputLabel>Value *</InputLabel>
                                                                                            <TextField
                                                                                                value={item.value}
                                                                                                name={`record.${index}.value`}
                                                                                                fullWidth
                                                                                                onBlur={() => setFieldTouched(`record.${index}.value`, true)}
                                                                                                onChange={handleChange}
                                                                                                size="small"
                                                                                                id={`record.${index}.value`}
                                                                                                placeholder="Value *"
                                                                                                error={Boolean(
                                                                                                    touched.record?.at(index)?.value &&
                                                                                                    (errors.record?.at(index) as FormikErrors<IUserDefinedField>)?.value
                                                                                                )}
                                                                                            />
                                                                                            {touched.record?.at(index)?.value && 
                                                                                                (errors.record?.at(index) as FormikErrors<IUserDefinedField>)?.value && (
                                                                                                <FormHelperText error id={`record.${index}.value-error`}>
                                                                                                    {(errors.record?.at(index) as FormikErrors<IUserDefinedField>).value}
                                                                                                </FormHelperText>
                                                                                            )}
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4} >
                                                                                            <InputLabel>Order</InputLabel>
                                                                                            <TextField
                                                                                                value={item.order}
                                                                                                name={`record.${index}.order`}
                                                                                                fullWidth
                                                                                                onBlur={() => setFieldTouched(`record.${index}.order`, true)}
                                                                                                onChange={handleChange}
                                                                                                size="small"
                                                                                                id={`record.${index}.order`}
                                                                                                placeholder="Order"
                                                                                                error={Boolean(
                                                                                                    touched.record?.at(index)?.order &&
                                                                                                    (errors.record?.at(index) as FormikErrors<IUserDefinedField>)?.order
                                                                                                )}
                                                                                            />
                                                                                            {touched.record?.at(index)?.order && 
                                                                                                (errors.record?.at(index) as FormikErrors<IUserDefinedField>)?.order && (
                                                                                                <FormHelperText error id={`record.${index}.order-error`}>
                                                                                                    {(errors.record?.at(index) as FormikErrors<IUserDefinedField>).order}
                                                                                                </FormHelperText>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                </SectionBox>
                                                                            </MainCard>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>

                                                            )}
                                                            <Stack direction="row" mt={1}>
                                                                <Button onClick={() => push(getEmptySpec())}>Add Pricing</Button>
                                                            </Stack>
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </Grid>
                                        </Grid>
                                        <SectionBox>
                                            <SubmitButtonGroup disabled={isSubmitting} />
                                        </SectionBox>
                                    </form>
                                )}


                                
                            </Formik>
                        </SectionBox>
                    </MainCard>
                </Grid>
            </Grid>

        
        
        </>
    )
}

export default AdditionalPricing;