import React,  { useState, useEffect, useRef, EffectCallback, DependencyList } from 'react';

const useEffectSkipInitialRender = (callback: EffectCallback, deps?: DependencyList) => {

    const isInitialRender = useRef(true);// in react, when refs are changed component dont re-render 

    useEffect(() => {

        if(isInitialRender.current){// skip initial execution of useEffect
            isInitialRender.current = false;// set it to false so subsequent changes of dependency arr will make useEffect to execute
            return;
        }
        else {
            let callbackReturn = callback();

            if(callbackReturn && typeof callbackReturn === "function") {
                return callbackReturn;
            }
        }
        
    }, deps);

};

export default useEffectSkipInitialRender;