import {
  alpha,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  useTheme,
  Typography,
} from "@mui/material";
import React from "react";
import { CSSProperties, PropsWithChildren } from "react";
import {
  HeaderProps,
  useColumnOrder,
  useFilters,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { MainPaper } from "../surface/MainPaper";
import { DefaultColumnFilter } from "./filters/DefaultColumnFilter";
import { TableToolbar } from "./TableToolbar";
import { selectionHook } from "./SelectionHook";
import { TablePagination } from "./TablePagination";
import { ReactTableProps } from "./TableProps";
import { FilterChipBar } from "./FilterChipBar";
import { RowsPerPageOptions } from "../../helpers/constants/TableConfig";
import { SmallScreenRowAction } from "./SmallScreenRowAction";
import EditableCell from "./columns/EditableCell";

const DefaultHeader: React.FC<HeaderProps<any>> = ({ column }) => (
  <>{column.id.startsWith("_") ? null : column.id}</>
);

const defaultColumn = {
  Filter: DefaultColumnFilter,
  Header: DefaultHeader,
  // When using the useFlexLayout:
  minWidth: 30, // minWidth is only used as a limit for resizing
  width: 150, // width is used for both the flex-basis and flex-grow
  maxWidth: 200, // maxWidth is only used as a limit for resizing
};

const hooks = [
  useColumnOrder,
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
  selectionHook,
  useFlexLayout,
];

export function ReactTable<T extends Record<string, unknown>>(
  props: PropsWithChildren<ReactTableProps<T>>
) {
  const {
    className,
    columns,
    isLoading,
    onAdd,
    onDelete,
    onEdit,
    onView,
    extraCommands,
    onPrint,
    inlineLock,
    inlineLockState = true,
    onInlineEdit,
  } = props;

  const theme = useTheme();

  const instance = useTable<T>(
    {
      ...props,
      columns,
      defaultColumn,
    },
    ...hooks
  );

  const { getTableProps, headerGroups, getTableBodyProps, page, prepareRow } = instance;

  const { ...tableProps } = getTableProps();
  const { ...tableBodyProps } = getTableBodyProps();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <MainPaper className="table-bg">
      <Paper sx={{ boxShadow: "none" }} className="tableMainWrapper">
        <TableContainer>
          <TableToolbar 
                      instance={instance}
                      {...{
                          inlineLock,
                          inlineLockState,
                          // Pass other actions only if inlineLockState is false
                          ...(inlineLockState
                              ? { onAdd,
                                onEdit,
                                onDelete,
                                onView,
                                extraCommands,
                                onPrint,} 
                              : {                                 
                              }),
                      }}
          />
          <FilterChipBar instance={instance} />
          <Table {...tableProps} size="small" className={className}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                          if (!inlineLockState && column.id === "_selector") {
                              return null;
                          }
                    const style = {
                      textAlign: column.align ? column.align : "left ",
                      fontWeight: 700,
                      fontSize: ".92em",
                    } as CSSProperties;

                    const {
                      key: headerKey,
                      role: headerRole,
                      ...customHeaderProps
                    } = column.getHeaderProps();
                    const { title: sortTitle = "", ...columnSortByProps } =
                      column.getSortByToggleProps();

                    return (
                      <TableCell key={headerKey} {...customHeaderProps}
                        className={
                          (typeof headerKey !== "string" && "hide") || ""
                        }
                        scope={typeof headerKey !== "string" ? "Action" : headerKey !== "header_action" ? 'col' : headerKey }
                      >
                        {column.canSort ? (
                          <Tooltip title={sortTitle}>
                            <TableSortLabel
                              active={column.isSorted}
                              direction={column.isSortedDesc ? "desc" : "asc"}
                              {...columnSortByProps}
                              style={style}
                            >
                              {column.render("Header")}
                            </TableSortLabel>
                          </Tooltip>
                        ) : (
                          <div style={style}>{column.render("Header")}</div>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...tableBodyProps}>
              {page.map((row) => {
                prepareRow(row);
                const { ...rowProps } = row.getRowProps();

                return (
                  <TableRow
                    {...rowProps}
                    onClick={!inlineLockState ? undefined : () => row.toggleRowSelected()}
                    sx={{
                      cursor: "pointer",
                      bgcolor: row.isSelected
                        ? alpha(theme.palette.primary.light, 0.1)
                        : "inherit",
                    }}
                  >
                        {row.cells.map((cell, headerKey) => {
                            const { ...getCellProps } = cell.getCellProps();
                            const { Header: scope }: any = headerGroups[0]?.headers[headerKey];
                            const column = headerGroups[0]?.headers[headerKey];

                            // Determine if the column is editable
                            const InLineEdit = column.InLineEdit || false;
                            const inputType = column.inputType || 'text'; // Default to 'text' if not specified

                            if (!inlineLockState && column.id === "_selector") {
                                return null;
                            }

                            return (
                                <TableCell
                                    {...getCellProps}
                                    sx={{
                                        fontSize: ".92em",
                                        textAlign: column.align ? column.align : "left ",
                                        justifyContent: column.align ? column.align : "left ",
                                        width: "100%",
                                        pr: column.align ? (column.align !== "left" ? "42px" : "16px") : "16px",
                                        ...(!InLineEdit && !inlineLockState && {
                                            lineHeight: '4.1em',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }),
                                    }}
                                    className={
                                        (typeof column.Header !== "string" && "hide") || ""
                                    }
                                    scope={typeof column.Header !== "string" ? "Action" : column.Header}
                                >
                                    {InLineEdit && !inlineLockState ? (
                                        <EditableCell
                                            value={cell.value}
                                            columnId={cell.column.id}
                                            rowId={row.original.id}
                                            isEditable = {InLineEdit}
                                            inputType={inputType}
                                            onInlineEdit={onInlineEdit}
                                            instance={instance}
                                        />
                                    ) : (
                                        cell.render("Cell")
                                    )}
                                </TableCell>
                            );
                        })}
                    <SmallScreenRowAction<T> 
                      instance={instance}
                      row={row}
                            {...{ onAdd, onDelete, onEdit, onView, onPrint, extraCommands, inlineLock }}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination<T> instance={instance} />
        </TableContainer>
      </Paper>
    </MainPaper>
  );
}
