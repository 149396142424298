const navScroll = () => {

    const rootContainer = document.querySelector(".app-content");
    const priceHeader = document.querySelector(".subTitleHeader");
    if (rootContainer ) {
        rootContainer.addEventListener("scroll", () => {
            if (rootContainer.scrollTop > 300) {
                priceHeader!.classList.add("active-scroll")
            }
            else
            {
                priceHeader!.classList.remove("active-scroll")
            }
        })
    }
}

export default navScroll