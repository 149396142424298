import {
  Grid,
  IconButton,
  Button,
  InputLabel,
  Autocomplete,
  TextField,
  Menu,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Column, TableInstance } from "react-table";
import { useState, useEffect } from "react";
import { apiStore } from "../../api/ApiFactory";
import { useFormik } from "formik";
import { ReactTable } from "../../components/table/ReactTable";
import { useNavigate } from "react-router-dom";
import { getLookupsDetails } from "../../store/slices/BoatOrdering/BoatOrderingSelector";
import { formatDate } from "../../helpers/utils/formatDate";
import { IWarrantyHistory } from "../../models/WarrantyManagement";
import { useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { enumWarrantyType } from "../../helpers/utils/utils";
import {  IDealer, IDealerLocation } from "../../models/Dealer";
import { getAllDealers } from "../../store/slices/Dealer/DealerSelector";
import useEffectSkipInitialRender from "../../helpers/utils/useEffect";
import * as Yup from "yup";

const WarrantyManagement = () => {
  const navigate = useNavigate();
  const [reactTabelData, setReactTableData] = useState<Array<IWarrantyHistory>>([]);
  const [initialDataSearch, setInitialDataSearch] = useState<boolean>(false);

  //const lookups = useSelector(getLookupsDetails);

  const [dealerOptions, setDealerOptions] = useState<Array<IDealer>>();
  const [location, setLocation] = useState<Array<IDealerLocation>>();

  const state_dealerList = useSelector(getAllDealers);

  useEffect(() => {

    const obj: IDealer = {id:-1, name: "All"};
    const state = [obj, ...state_dealerList];
    setDealerOptions(state);
    createLocationList(createLocationAllOption([]));

  }, [state_dealerList]);

  //Function to create a location record list with one "All" option.
  const createLocationAllOption = (data: IDealerLocation[]) => {
    var locations = data;

    locations.unshift({
        customerID: "",
        name: "All",
    } as IDealerLocation);

    return locations;
  }

  const createLocationList = (data: IDealerLocation[] | undefined) => {
    var locations: Array<IDealerLocation> = [];
    if (!!data) {
      data.map((item) => {
        locations.push(item);
      });
    }

    if (data && data.length > 0) {
      if (data.length > 1) {
        createLocationAllOption(locations);
        formik.setFieldValue("locations", { id: "", customerID: "", name: "All",});
      } 
      else {
        formik.setFieldValue("locations", { ...locations[0] });
      }
    } 
    else {
      formik.setFieldValue("locations", { id: "", customerID: "", name: "" });
    }
    setLocation(locations);
  };

  //Type defination for formik Initial Values
  type dealerOptionsType = {
    id: number;
    name: string;
  };

  
  const FormValidationSchema = Yup.object().shape({
    locations: Yup.object()
      .when("hin", {
        is: (hin:string | undefined | null) => hin === undefined || hin === null || hin === "",
        then: Yup.object().required(),
        otherwise: Yup.object().nullable()
      }),
    hin: Yup.string()
      .when("locations", {
        is: (locations: IDealerLocation | null | undefined) => locations === undefined || locations === null || locations.name === "",
        then: Yup.string().required(),
        otherwise: Yup.string()
      })
  }, [['locations', 'hin']])
  

  //Formik initial values
  const initialValues = {
    dealerOptions: {id: -1, name: "All"} as dealerOptionsType,
    locations: { name: "All", customerID: "" } as IDealerLocation | null,
    hin: "",
    showformfilters: true,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: FormValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      getOrderHistory(values, setSubmitting);
    },
  });

  //Function to get order history based on dropdown options
  const getOrderHistory = (values: any, setSubmitting: any) => {
      
    let { locations, hin } = values;

    let params = new URLSearchParams();

    if(locations !== null) {

      if(locations.customerID === "" && locations.name === "All") {
        params.append("CustomerID", "All");
      }
      else {
        params.append("CustomerID", locations.customerID)
      }
    }

    if(hin !== "") {
      params.append("hin", hin);
    }
     
    apiStore.warrantyApi
      .getWarrantyHistory(params)
      .then((response: any) => {
        setReactTableData(response);
        setInitialDataSearch(true);
      })
      .catch((error: any) => {
        console.log(error);
        setInitialDataSearch(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  //Function to reset form values

  //Function to edit table row
  const onView = useCallback(
    (instance: TableInstance<IWarrantyHistory>) => () => {
      const warranty = instance.selectedFlatRows[0].original;
      const { hin } = warranty;
      navigate(`/warranty/view/${hin}`);
    },
    []
  );

  //Table Columns Structure
  const columns: Column<IWarrantyHistory>[] = useMemo(
    () => [
      {
        Header: "Boat Series",
        accessor: "boatSeries",
      },
      {
        Header: "Boat Model",
        accessor: "boatModel",
      },
      {
        Header: "HIN",
        accessor: "hin",
      },
      {
        Header: "Model Year",
        accessor: "modelYear",
      },
      {
        Header: "Location",
        accessor: "customerId",
        Cell: ({ row }: any) => {
          return row.original?.dealerName;
        },
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        // Cell: (cell: any) => (cell.value ? formatDate(cell.value) : ""),
      },
      {
        Header: "Delivery Date",
        accessor: "deliveryDate",
        Cell: (cell: any) => (cell.value ? formatDate(cell.value) : ""),
      },
      {
        Header: "Warranty Type",
        accessor: "warrantyType",
        Cell: (cell: any) => {
          let type = enumWarrantyType?.find(
            (item: any) => item.key === cell?.value
          );
          return type?.value ?? "N/A";
        },
      }
    ],
    []
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={4}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel>Dealers</InputLabel>
            <Autocomplete
              onChange={(event, value: any) => {
                formik.setFieldValue("dealerOptions", value);
                if (dealerOptions && value) 
                {

                  if(value.id === -1 && value.name === 'All') {
                    createLocationList(createLocationAllOption([]));
                  }
                  else {
                    const locations = dealerOptions!.find((item: IDealer) => item?.id === value?.id)?.locations;
                    createLocationList(locations);
                  }
                } 
                else if (!value) {
                  if(value === "All") {
                    createLocationList(createLocationAllOption([]));
                }
                else {
                    createLocationList([]);
                }
                }
              }}
              size="small"
              disablePortal
              id="dealerOptions"
              getOptionLabel={(option: IDealer) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id || value.name === "" || value.name === "All"
              }
              options={dealerOptions ? dealerOptions : []}
              value={formik?.values.dealerOptions}
              renderInput={(params) => (
                <TextField {...params} name="dealerOptions" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel>Locations</InputLabel>
            <Autocomplete
              // key={formik.values.dealerOptions}fullWidth
              size="small"
              fullWidth
              disablePortal
              onChange={(event, value) => {
                formik.setFieldValue("locations", value);
              }}
              id="locations"
              getOptionLabel={(option: IDealerLocation) =>
                option.customerID !== ""
                  ? option.customerID + " - " + option.name
                  : option.name
              }
              isOptionEqualToValue={(option, value) =>
                option.customerID === value.customerID || value.customerID === "" || value.name === "All"
              }
              value={formik?.values?.locations}
              options={location ? location : []}
              renderInput={(params) => (
                <TextField {...params} name="values.locations" error={Boolean(formik.touched.locations && formik.errors.locations)} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel>HIN #</InputLabel>
            <TextField
                fullWidth
                size="small"
                id="hin"
                {...formik.getFieldProps("hin")}
                placeholder="HIN #"
                error={Boolean(
                  formik.touched.hin && formik.errors.hin
                )}
              />
          </Grid>
          {((formik.touched.hin && formik.errors.hin) || (formik.touched.locations && formik.errors.locations)) && (
            <Grid item xs={12}>
                  <FormHelperText error id="error-message">
                    {"Location or HIN is required."}
                  </FormHelperText>
            </Grid>
          )}
          <Grid item xs={12} mb={4}>
            <Button type="submit" disabled={formik.isSubmitting} variant="contained">
              Get Warranties
            </Button>
          </Grid>
        </Grid>
      </form>
      
      {initialDataSearch && ( 
        <ReactTable<IWarrantyHistory>
        name="warranty-table"
        columns={columns}
        serverOnChange={false}
        data={reactTabelData ? reactTabelData : []}
        isLoading={false}
        onView={onView}
        // onAdd={onAdd}
        />
      )}
      
    </>
  );
};
export default WarrantyManagement;
