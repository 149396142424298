import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { apiStore } from "./ApiFactory";
import { useNotification } from "../store/context/NotificationContext";
import { interceptorMessageHandler } from "../helpers/intercepterMessageHandler/intercepterMessageHandler";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

const responseBody = (response: AxiosResponse) => response.data;
const api = {
  getgeneric<T = any>(url: string, values: any): Promise<T> {
    if (!!values) {
      return axiosInstance.get<T>(url, { params: values }).then(
        //(response: AxiosResponse) => response.data[0]
        responseBody
      );
    } else {
      return axiosInstance.get<T>(url).then(responseBody);
    }
  },

  get: (url: string, values: any) => {
    if (!!values) {
      return axiosInstance.get(url, { params: values }).then(responseBody);
    } else {
      return axiosInstance.get(url).then(responseBody);
    }
  },

  post: (url: string, body: {}) =>
    axiosInstance
      .post(url, body, { headers: { "Content-type": "application/json" } })
      .then(responseBody),

  del: (url: string) => axiosInstance.delete(url).then(responseBody),

  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);

    return axiosInstance
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
  uploadImage: (url: string, values: any) => {
    return axiosInstance
      .post(url, values, { headers: { "Content-type": "multipart/form-data" } })
      .then(responseBody);
  },

  postFormData: (url: string, values: any) => {
    return axiosInstance
      .post(url, values, { headers: { "Content-type": "multipart/form-data" } })
      .then(responseBody);
  },
  downloadImage : (url: string, fileType: string) => {
        return axiosInstance
            .get(url, { responseType: 'blob' })
            .then(response => {
                const imageUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = imageUrl;
                link.download = `media.${fileType}`;
                link.click();
                window.URL.revokeObjectURL(imageUrl);
            })
    },
};

export const WithAxios = (props: any) => {
  const { Toast } = useNotification();
  useEffect(() => {
    createAxiosInstance();
  }, []);

  const createAxiosInstance = () => {
    axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        try {
          document.body.classList.add("loading-indicator");

          var token = apiStore.store.getState().reducer.Auth.authuser?.token;

          if (!!token) {
            config.headers = { Authorization: `Bearer ${token}` };
          }
        } catch {}
        return config;
      },
      function (error) {
        //Do something with request error
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      async (response) => {
        document.body.classList.remove("loading-indicator");

        if(response?.data?.errors?.length > 0) {
          Toast.error({ title: "Error submitting request!" });
        }
        else {
          interceptorMessageHandler(response, Toast);
        }


        
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        document.body.classList.remove("loading-indicator");
        if (error.message === "Network Error" && !error.response) {
          Toast.error({ title: "Network error. Make sure API is running!" });
          console.log("Network error. Make sure API is running!");
        }

        if (error.response) {
          const { status, data, config, headers } = error.response;

          if (status === 404) {
           
            console.log("/notfound");
          }

          if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
              apiStore.authApi.refresh();
              return axiosInstance(originalRequest);
            } catch (_error: any) {
              if (_error.response && _error.response.data) {
                return Promise.reject(_error.response.data);
              }
              return Promise.reject(_error);
            }
          }
          if(status === 400 && config.method === "post")
          {
            return Promise.reject(data);
          }

          console.log(error.response);

          if (
            status === 400 &&
            config.method === "get" &&
            data.errors.hasOwnProperty("id")
          ) {
            console.log("This is a 400 error");
          }
          if (status === 500) {
            Toast.error({
              title: "Server error - check the terminal for more info!",
            });

            console.log("Server error - check the terminal for more info!");
          }
        }
        return Promise.reject(error);
      }
    );
  };

  return props.children;
};
export default { api };
