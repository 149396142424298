import { RepeatOneSharp } from '@mui/icons-material';
import { Box, InputLabel, Grid, TextField, MenuItem, Button, Select, SelectChangeEvent } from '@mui/material'
import { Formik } from 'formik';
import React, { FormEvent, useEffect, useState } from 'react'
import { apiStore } from '../../../api/ApiFactory';
import { MainCard } from '../../../components/surface/MainCard';
import { SectionBox } from '../../../components/surface/SectionBox';
import { ConfigureOptions } from '../../../helpers/constants/MasterConfig';
import AssignOptionToSection from './AssignOptionToSection';
import ChoiceLayerdMedia from './ChoiceLayerdMedia';
import ConfigureChoice from './ConfigureChoice';
import DisplayModelList from './DisplayModelList';
import LayeredMedia from './LayeredMedia';
import ModelConfig from './ModelConfig';
import ModelLayeredMedia from './ModelLayeredMedia';
import Options from './Options';
const MasterConfigIndex = () => {
    const [isConfigurationVisible, setIsConfigurationVisible] = useState(false);
    const [configurType, setConfigureType] = useState<string>("");
    const [seriesList, setSeriesList] = useState<any>([]);
    const [currentSeries, setCurrentSeries] = useState<any>('');
    const [currentRevision, setCurrentRevision] = useState<any>("");
    const [revisionList, setRevisionList] = useState<any>([]);
    const [configureData, setConfigureData] = useState<any>({});
    const [selectedModelList, setSelectedModelList] = useState<any>([])
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const response = apiStore.boatConfigApi.getSeriesRevision();
            response.then((json: any) => {
                setSeriesList(json);
            })
        }
        return () => {
            mounted = false;
        }
    }, [])

    const renderComponent = (configurType: string) => {
        switch (configurType) {
            case ConfigureOptions.Model: return <ModelConfig
                selectedModelList={selectedModelList} />;
            case ConfigureOptions.ModelLayeredMedia: return <ModelLayeredMedia selectedModelList={selectedModelList}/>
            case ConfigureOptions.AssignOptions: return <AssignOptionToSection
                selectedModelList={selectedModelList}
                options={configureData.options ?? []}
                sections={configureData?.sections ?? []} />
            case ConfigureOptions.Options: return <Options
                options={configureData?.options} 
                selectedModelList={selectedModelList}
                />
            case ConfigureOptions.ConfigureChoices: return <ConfigureChoice
                options={configureData?.options}
                selectedModelList={selectedModelList}
            />
            case ConfigureOptions.ChoiceLayeredMedia: return <ChoiceLayerdMedia options={configureData?.options} selectedModelList={selectedModelList} />
            default: return null
        }

    }

    const handleSeriesChange = (event: SelectChangeEvent) => {
        const { revisions } = JSON.parse(event.target.value);
        setRevisionList(revisions);
        setCurrentSeries(event.target.value as string)
        if (revisions.length === 1) {
            setCurrentRevision(revisions[0].revisionId)
        }

    }
    const handleRevisionChange = (event: SelectChangeEvent) => {
        setCurrentRevision(event.target.value)
    }
    const handleChange = (event: SelectChangeEvent) => {
        setConfigureType(event.target.value as string);
        console.log(event.target.value);
    };

    const handleOnSubmit = async (event: FormEvent) => {
        event.preventDefault();
        const response = await apiStore.boatConfigApi.getMasterConfigData(currentRevision);
        console.log(response);
        if (response?.models.length === 1) {
            const { id } = response?.models[0];
            setSelectedModelList([id]);
        }
        setConfigureData(response);
        setIsConfigurationVisible(true);
    }

    return (
        <>
            <form onSubmit={handleOnSubmit}>
                <Grid container spacing={4}>
                    <Grid item lg={4} xs={12}>
                        <InputLabel htmlFor="series"> Series *</InputLabel>
                        <Select
                            fullWidth
                            size="small"
                            id="series"
                            value={currentSeries}
                            onChange={handleSeriesChange}
                        >
                            <MenuItem disabled><em>Select Series</em></MenuItem>
                            {
                                seriesList && seriesList.map((series: any, index: number) => <MenuItem value={JSON.stringify(series)} key={index} >{series.seriesName}</MenuItem>)
                            }
                        </Select>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <InputLabel htmlFor="revision"> Revision *</InputLabel>
                        <Select
                            name="revision"
                            fullWidth
                            size="small"
                            id="revision"
                            onChange={handleRevisionChange}
                            value={currentRevision}
                        >
                            <MenuItem disabled><em>Select Revision</em></MenuItem>
                            {
                                revisionList.length > 0 && revisionList.map(({ revisionId, revisionName }: any, index: number) => <MenuItem value={revisionId} key={index}>{revisionName}</MenuItem>)

                            }
                        </Select>
                    </Grid>
                    <Grid item alignSelf="end" xs={12} lg={4}>
                        <Button type="submit" variant="contained" >Submit</Button>
                    </Grid>
                </Grid>
            </form>
            {
                isConfigurationVisible && (
                    <Box mt={4}>
                        <Grid container mb={2} spacing={4} >
                            {selectedModelList.length > 1 && (<Grid item md={4}>
                                <MainCard>
                                    <SectionBox>
                                        <DisplayModelList
                                            modelList={configureData.models ?? []}
                                            selectedModelList={selectedModelList}
                                            setSelectedModelList={setSelectedModelList}
                                        />
                                    </SectionBox>
                                </MainCard>
                            </Grid>)
                            }
                            <Grid item xs={12} >
                                <Grid container direction="column">
                                    <MainCard>
                                        <SectionBox>
                                            <Grid item md={6}>
                                                <InputLabel htmlFor='configureType'>Configure Type *</InputLabel>
                                                <Select
                                                    value={configurType}
                                                    name="configureType"
                                                    fullWidth
                                                    size="small"
                                                    id="configureType"
                                                    displayEmpty
                                                    onChange={handleChange}
                                                >
                                                    {
                                                        Object.entries(ConfigureOptions).map(([key, value], index: any) => <MenuItem key={index} value={value}>{value}</MenuItem>)
                                                    }
                                                </Select>
                                            </Grid>
                                            <Grid item mt={4} md={12}>
                                                {
                                                    renderComponent(configurType)
                                                }
                                            </Grid>
                                        </SectionBox>
                                    </MainCard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )
            }

        </>

    )
}

export default MasterConfigIndex;