import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import MainModal from "../../../components/modal/MainModal";
import { useAppSelector } from "../../../store/hooks/hook";
import RefreshConfirmation from "../../../components/version/RefreshConfirmation";
import { changeAllowRefresh } from "../../../store/slices/System/SystemSlice";

const AppFooter = ({hide}:{hide:boolean}) => {
    
    const [refreshModal, setRefreshModal] = useState<boolean>(false);
    const closeModal = () => setRefreshModal(false);

    const location = useLocation();
    const dispatch = useDispatch();

    const { currentVersion, allowRefresh } = useAppSelector((state) => ({
        currentVersion: state.reducer.System.currentVersion,
        allowRefresh: state.reducer.System.allowRefresh
      }));

    useEffect(() => {

        if(allowRefresh) {

            fetch('/meta.json', {
                headers: {
                  'Cache-Control': 'no-cache, no-store, must-revalidate',
                  'Pragma': 'no-cache',
                  'Expires': '0'
                }
            })
            .then((response) => response.json())
            .then((result) => {
                const latestVersion = result?.version ?? "";
                const triggerRefresh = latestVersion !== currentVersion;
    
                if(triggerRefresh) {

                    console.log("Latest", latestVersion);
                    console.log("Current", currentVersion);

                    setRefreshModal(true);
                }
            })
        }
    
      }, [location.pathname]);

    const RefreshCacheAndReload = async () => {
        
        if(caches) {

            const names = await caches.keys();
            await Promise.all(names.map(name => caches.delete(name)));
        }
        window.location.reload();
    }
    
    const refreshConfirmation = (confirmRefresh: boolean) => {

        closeModal();
        if(confirmRefresh) {
            RefreshCacheAndReload();
        }
        else {

            //This will make it so if the user decides to ignore the refresh it will not show them the confirmation again.
            dispatch(changeAllowRefresh(false));
        }
    }

    return (
        <>
            {!hide && (
            <footer className="app-footer">
                    <MainModal open={refreshModal} customStyle={{maxWidth: "800px"}}>
                        <RefreshConfirmation refreshConfirm={refreshConfirmation} />
                    </MainModal>
                     <Typography className="footer-text">v{currentVersion}</Typography>
            </footer>
            )}
        </>
    )
}


export default AppFooter;