import { Button, Stack, Typography } from "@mui/material";
import { MainCard } from "../surface/MainCard";
import { SectionBox } from "../surface/SectionBox";

type IProps = {
    cancelClick: () => void;
    confirmClick: () => void;
    cancelTitle?: string;
    confirmTitle?: string;
    title?: string;
    message?: string;
    disable?: boolean;
}

const ConfirmationBox = (props: IProps) => {

    const cancel = props.cancelTitle ? props.cancelTitle : "Cancel";
    const confirm = props.confirmTitle ? props.confirmTitle : "Confirm";
    const message = props.message ? props.message : "Are you sure you want to perform this action?"

    return (
        <MainCard>
            <SectionBox>
                <Typography variant="h6" >{message}</Typography>
                <Stack justifyContent="center" mt={3} direction="row" spacing={2} >
                    <Button variant="outlined" onClick={props.cancelClick} color="secondary" disabled={props.disable}>
                        {" " + cancel + " "}
                    </Button>
                    <Button variant="contained" onClick={props.confirmClick} disabled={props.disable}>{confirm}</Button>
                </Stack>
            </SectionBox>
        </MainCard>
    );
 
}

export default ConfirmationBox;