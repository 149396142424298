import { Grid, Typography, Stack, Button, FormControlLabel, Checkbox, Select, MenuItem, Box, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { apiStore } from '../../../api/ApiFactory';
import { IOption, IOptions, ISection } from '../../../models/Configuration';
import { useNotification } from '../../../store/context/NotificationContext';


type assignOptionToSectionProps = {
  options: IOption[],
  sections: string[],
  selectedModelList: []
}



const AssignOptionToSection = ({ options, sections, selectedModelList }: assignOptionToSectionProps) => {

  const { Toast } = useNotification();


  //Option List state with checked and value property to manage checkbox and select dropdown
  const [optionList, setOptionList] = useState<any>([]);
  const [selectAllCheckbox, setSelectAllCheckBox] = useState<boolean>(false);


  useEffect(() => {
    const tempState = options.map((option: IOption) => { return { ...option, checked: false, value: "" } });
    setOptionList(tempState)
  }, [])


  const handleChange = (event: any, index: number) => {
    const tempState = [...optionList];
    tempState[index] = { ...tempState[index], checked: event.target.checked };
    console.log(tempState);
    setOptionList(tempState);
  }

  const handleSelectChangeEvent = (event: any, optionId: number | string, index: number) => {
    const tempState: any = [...optionList];
    const section: any = event.target.value;
    tempState[index] = { ...tempState[index], value: section };
    console.log(tempState)
    setOptionList(tempState);
  }

  const handleSelectAllCheckbox = () => {
    let isAllCheckboxSelected = selectAllCheckbox ? true : false;
    let tempState: any = [...optionList];
    tempState = tempState.map((option: any) => { return { ...option, checked: !isAllCheckboxSelected } });
    setSelectAllCheckBox(!isAllCheckboxSelected);
    setOptionList(tempState);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const optionassignments: any = [];
    optionList.forEach((option: any) => {
      if (option?.checked && option?.value) {
        optionassignments.push({ id: option?.id, value: option?.value });
      }
    });
    const formData: any = { models: selectedModelList, optionassignments };
    try {
      const response = await apiStore.boatConfigApi.saveMasterConfigAssignoptions(formData);
      Toast.success({ title: "Saved Successfully" })
    }
    catch (error) {
      Toast.success({ title: "Error Occured" })
    }
  }

  return (
    <>
      <Grid container mb={2}>
        <Grid item md={6}>
          <Typography>Select Fields To Update <Button onClick={handleSelectAllCheckbox}>{selectAllCheckbox ? "(Clear All)" : "(Select All)"}</Button></Typography>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {
            optionList.length > 0 && optionList.map((option: any, index: number) => (
              <Grid item md={6} xs={12}>
                  <FormControlLabel onChange={(event: any) => handleChange(event, index)} control={<Switch value={option.id} checked={option.checked} />} label={`${option?.displayName}`} />
                {
                  option?.checked && (
                      <Select
                        fullWidth
                        size="small"
                        id="series"
                        value={option.value}
                        disabled={!option.checked}
                        onChange={(event: any) => handleSelectChangeEvent(event, option?.id, index)}
                      >
                        <MenuItem disabled><em>Select Section</em></MenuItem>
                        {
                          sections?.length > 0 && sections.map((section: string) => <MenuItem key={`${section}-${option?.id}`} value={section}>{section}</MenuItem>)
                        }
                      </Select>
                  )
                }
            </Grid>))
          }

        </Grid>

        <Stack alignItems="end" mt={2}>
          <Button onClick={handleSubmit} type="submit" variant="contained"> Save Changes</Button>
        </Stack>
      </form>

    </>
  )
}

export default AssignOptionToSection