import { Button, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";


interface IProps {
  backlabel?: string;
  submitlabel?:string;
  disabled?: boolean;
  hideBack?: boolean;
}

const SubmitButtonGroup = ({ backlabel, submitlabel, disabled, hideBack }: IProps) => {

    const navigate = useNavigate();
    const handleClose = () => {
        navigate(-1);
    };

    const back = backlabel ? backlabel : 'Back';
    const submit = submitlabel ? submitlabel: "Save Changes";

     return (
        <Stack justifyContent="flex-end" mt={3} direction="row" spacing={2} >
            
            {(hideBack === undefined || (hideBack === false)) && (
                <Button
                variant="outlined"
                onClick={handleClose}
                color="secondary"
                >
                    {" " + back + " "}
                </Button>
            )}
            
            <Button variant="contained" type="submit" disabled={disabled}>{submit}</Button>
            
        </Stack>
  );
};
export default SubmitButtonGroup;
