import React, { useEffect } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Button, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { IUserExtended } from "../../models/User";
import * as Yup from "yup";
import { useFormik } from "formik";
import { apiStore } from "../../api/ApiFactory";

interface IProp {
    current: string;
    user: IUserExtended | undefined;
    handleChange(panel: string) : (event: React.SyntheticEvent, newExpanded: boolean) => any;
    onClose(): () => any;
}

const AppValidationSchema = Yup.object().shape({
    user: Yup.object({
        email: Yup.string().email("Invalid email address").required("Email is required"),
    })
})

const AppUserContact = ({ current, user, handleChange, onClose }: IProp) => {

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: AppValidationSchema,
        initialValues:  {
            user: user!,
            submit: null
        },
        onSubmit: (values, {setSubmitting, setErrors}) => {
            apiStore.userApi.updateContact(values.user)
            .catch((error) => {
                console.log(error);
                if(error.response?.data) {
                    setErrors({ submit: error.response?.data[0].errorMessage })
                } else {
                    
                }
            })
            .finally(() => {
                setSubmitting(false);
            })
        }
    });

    useEffect(() => {
        formik.resetForm();
    }, [current]);

    return (
        <Accordion className="app-accordion" disableGutters elevation={0} square expanded={current === 'contact-panel'} onChange={handleChange('contact-panel')}>

            <AccordionSummary className="app-accordion-summary" aria-controls="user-profile-content-content" expandIcon={<ExpandMore/>} >
                <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography className="app-accordion-header">
                            Contact
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={10}>
                        <Typography className="app-accordion-label">
                            {user?.email}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className="app-accordion-detail">
                <form onSubmit={formik.handleSubmit}>

                    {formik.errors.submit && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormHelperText error id="submit-helper">
                                {formik.errors.submit}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    </>
                    )}

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <InputLabel htmlFor="email">
                            Email *
                            </InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                id="email"
                                {...formik.getFieldProps("user.email")}
                                placeholder="Email*"
                                error={Boolean(formik.touched.user?.email && formik.errors.user?.email )}
                            />
                            {formik.touched.user?.email && formik.errors.user?.email && (
                            <FormHelperText error id="email-helper">
                                {formik.errors.user?.email}
                            </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            sx={{ mt: 2.5 }}
                        >
                            <Button
                                variant="outlined"
                                disabled={formik.isSubmitting}
                                onClick={ onClose() }
                                color="secondary"
                            >
                                {" "} Cancel{" "}
                            </Button>
                            <Button
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Stack>
                        </Grid>
                    </Grid>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}

export default AppUserContact;