import { matchPath, PathMatch } from "react-router-dom";
import { NavMode } from "../helpers/enums/Layout";
import { ActiveRoutePath, RouteProps } from "../models/Route";
import { leadRoutes, protectedRoutes } from "../routes/Routes";
import { useAppSelector } from "../store/hooks/hook";
import { GetUserPermissions, IsLegacyDealerUser } from "./AuthService";

export const MapRouteToActivePath = (
    props: RouteProps[], 
    locationPathname: string
): ActiveRoutePath[] => {

    const activeRoutePaths: ActiveRoutePath[] = [];

    props.forEach((prop, index) => {

        if(!prop.path) {
            return;
        }

        const match = matchPath({ path: prop.path, end: false }, locationPathname);

        if(!match) {
            return;
        }

        if(isResolvedAsActive(match.pathname, locationPathname, prop)) {

            if(prop.title) {

                const activeRoutePath: ActiveRoutePath = {
                    title: prop.title,
                    match: match,
                    prop: prop
                }

                addActiveRoutePathIfPossible(activeRoutePaths, activeRoutePath);
            }
        }

        if (prop.children) {

            const nestedMatches = MapRouteToActivePath(
                prop.children,
                locationPathname
            );
    
            nestedMatches.forEach((activePath) => {
                addActiveRoutePathIfPossible(activeRoutePaths, activePath);
            });
        }
    });

    return activeRoutePaths;
}

function addActiveRoutePathIfPossible(activeRoutePaths: ActiveRoutePath[], activePath: ActiveRoutePath) {
    
    if (canBeAddedToActiveRoutes(activeRoutePaths, activePath.match)) {
        activeRoutePaths.push(activePath);
    }
  }

function isPathActiveForLocation(pathName: string, locationPathname: string) {
    return (
        locationPathname === pathName 
        ||
        (
            locationPathname.startsWith(pathName) 
            && 
            locationPathname.charAt(pathName.length) === "/"
        )
    );
}
  
function isResolvedAsActive(toPathname: string, locationPathname: string, prop: RouteProps) {
    return isPathActiveForLocation(toPathname, locationPathname) && isNotCatchAll(prop.path || "");
}
  
function canBeAddedToActiveRoutes(activeRoutePaths: ActiveRoutePath[], match: PathMatch<string>) {
    
    return (
      isNotSameAsPreviousMatch(activeRoutePaths, match) &&
      isMoreSpecificThanPreviousMatch(activeRoutePaths, match.pathname)
    );
}
  
function getPreviousMatch(previousMatches: ActiveRoutePath[]): ActiveRoutePath | undefined {
    return previousMatches[previousMatches.length - 1];
}
  
function isNotSameAsPreviousMatch(previousMatches: ActiveRoutePath[], match: PathMatch<string>): boolean {
    const previousMatchedPathname = getPreviousMatch(previousMatches)?.match.pattern ?? "";
    return previousMatchedPathname !== match.pattern;
}
  
function isMoreSpecificThanPreviousMatch(previousMatches: ActiveRoutePath[], toPathname: string): boolean {
    const previousMatchedPathname = getPreviousMatch(previousMatches)?.match.pathname ?? "";
    return toPathname.length > previousMatchedPathname.length;
}
  
function isNotCatchAll(path: string) {
    return path !== "*";
}

export const useActiveRoutePaths = () =>
{
    const routes = protectedRoutes.filter(r => r.permissions!! || r.authuser!! );

    const authRoutes = getAuthorizedRoutes(routes);
    return authRoutes;
}

export const useLeadRoutePaths = () => {
    return leadRoutes;
}

const getAuthorizedRoutes = (routes: RouteProps[]): RouteProps[] =>
{
    const permissions = GetUserPermissions();
    const isLegacy = IsLegacyDealerUser();

    const authRoutes = routes
        .filter( r => 
            r.permissions?.find(rp => permissions?.find(up => up === rp)) 
            || (r.authuser!! && !(r.hidelegacy === true && isLegacy === true) )
        );

    return authRoutes;
}