import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class ReportsApi {
    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
        this.apiFactory = apiFactory;
    }

    getReportsData = async () => {
        try {
            const list = await agent.api.get(
                `/Reports/GetReports`,
                null
            );
            return list;
        } catch (error) {
            throw error;
        }
    };
}
