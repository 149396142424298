import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CustomizedSnackbars from "../../components/snackbar/Snackbar";
import { NavMode } from "../../helpers/enums/Layout";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hook";
import { changeNavType } from "../../store/slices/Layout/LayoutSlice";
import AppFooter from "./footer/AppFooter";
import ContentHeader from "./ContentHeader";
import AppHeader from "./header/AppHeader";

const LeadsLayout = () => {

    const { navMode } = useAppSelector((state) => ({
        navMode: state.reducer.Layout.navMode,
      }));

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(changeNavType(NavMode.LEADS));


    }, []);

return (
    <React.Fragment>
      <div className="app-wrapper">
            {navMode === NavMode.LEADS && (
                <AppHeader navMode={navMode} />
            )}
            <div className="app-content">
                <Box className="progress-bar">
                    <CircularProgress />
                </Box>
                <ContentHeader hide={false} />
                <CustomizedSnackbars />
                <Outlet />
            </div>
          
        </div>
    </React.Fragment>
  );
};

export default LeadsLayout;
