import BarChart from "./BarChart/BarChart";
import LineChart from "./LineChart/LineChart";
import PieChart from "./PieChart/PieChart";
import Leads from "../Leads/Leads";
import { useSelector } from "react-redux";
import { Grid, useMediaQuery } from "@mui/material";
import { getDashboardData } from "../../../store/slices/Dashboard/DashboardSelector";

const ChartIndex = () => {

  const dashboardData = useSelector(getDashboardData);

  return (
    <Grid container spacing={2} mt={2}>
      <Grid item md={7} xs={12} sx={{ xs: { display: "none" } }}>
        <LineChart data={dashboardData?.orderCount.length ? dashboardData?.orderCount : []} hasScroll={true}  />
      </Grid>
      <Grid item md={5} xs={12}>
        <PieChart data={ dashboardData?.orderCountBySeries?.length > 0 ? dashboardData?.orderCountBySeries : [] } hasScroll={true} />
      </Grid>
      <Grid item md={7} xs={12}>
        <Leads height="100%" minHeight="320px" dashboardData={dashboardData} />
      </Grid>
      <Grid item md={5} xs={12}>
        <BarChart height="320px" data={ dashboardData?.leadCount.length > 0 ? dashboardData?.leadCount : [] } hasScroll={true} />
      </Grid>
    </Grid>
  );
};

export default ChartIndex;
