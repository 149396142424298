import React, { forwardRef } from "react";
import { FormControl, OutlinedInput, FormHelperText } from "@mui/material";
interface Props {
  placeholder: string;
  error?: string;
  label: string;
  id?: string;
  type?: "text" | "number";
  variant?: "text" | "outlined" | "contained" | undefined;
  onChange?: any;
  handleBlur?: any;
  disabled?: boolean;
  value: string;
  color?: "outlined" | "filled";
}
export type Ref = HTMLInputElement;
const TextInput = forwardRef<Ref, Props>(
  (
    {
      placeholder,
      error,
      label,
      id,
      value,
      variant,
      onChange,
      handleBlur,
      type,
      disabled,
      color,
      ...props
    },
    ref
  ) => {
    return (
      <div className="form-group">
        <label>{label}</label>
        <FormControl
          sx={{ m: 1 }}
          variant="outlined"
          fullWidth
          error={error ? true : false}
        >
          <OutlinedInput
            id={id}
            type={type ? type : "text"}
            disabled={disabled}
            value={value}
            onChange={onChange}
            notched={true}
            onBlur={handleBlur}
            placeholder={placeholder}
            aria-describedby="outlined-weight-helper-text"
          />
          {error && (
            <FormHelperText id="outlined-weight-helper-text">
              {error}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    );
  }
);

export default TextInput;
