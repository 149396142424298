import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import CustomizedSnackbars from "../../components/snackbar/Snackbar";
import { useAppSelector } from "../../store/hooks/hook";
import ContentHeader from "./ContentHeader";
import { Box, CircularProgress, Typography } from "@mui/material";
import { selectAuthUser } from "../../store/slices/Auth/AuthSelector";
import { useSelector } from "react-redux";
import { apiStore } from "../../api/ApiFactory";
import { Roles } from "../../helpers/enums/Common";
import pageLoading from "../../_assets/images/sea-pro-loading.gif";

//Layout Components
import AppHeader from "./header/AppHeader";
import Navbar from "./navbar/Navbar";
import AppFooter from "./footer/AppFooter";

//Layout used for Authorized Users
const AppLayout = () => {
  const auth = useSelector(selectAuthUser);

  const { navMode, defaultPage } = useAppSelector((state) => ({
    navMode: state.reducer.Layout.navMode,
    defaultPage: state.reducer.Layout.defaultPath,
  }));

  const [hide, setHide] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.pathname.includes("/print") && !window.location.pathname.includes("/config")) {
      setHide(true);
    }

    apiStore.dealerApi.getDealer({dealerId: auth?.user?.dealerId});
    apiStore.dealerApi.getDealerList({includeInactive: true});
    apiStore.lookupApi.getBoatSeriesList();
    apiStore.lookupApi.getYearList();

  }, []);

  return (
    <React.Fragment>
      <div className="app-wrapper">
        {!hide && (
          <>
            <AppHeader navMode={navMode} />
            <Navbar type={navMode} />
          </>
        )}
        <div className={`app-content ${hide && "expand-content"} `}>
          <Box className="progress-bar">
            {/* <CircularProgress /> */}
            <Box sx={{ textAlign: "center" }}>
              <img src={pageLoading} />
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                Loading...
              </Typography>
            </Box>
          </Box>
          <ContentHeader hide={hide} />
          <CustomizedSnackbars />
          <Outlet />
          <AppFooter hide={hide}/>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppLayout;
