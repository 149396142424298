import React from 'react'
import { Grid, Typography, InputLabel, Stack, TextField, Button, Box } from '@mui/material'
import defaultImg from "../../../_assets/images/Sea-Pro-default-placeholder.png";
import uploadFile from '../utils/UploadFile';
import { useFormik } from 'formik';
import { ISliderImage } from '../../../models/Configuration';
import { apiStore } from '../../../api/ApiFactory';
import { useSearchParams } from 'react-router-dom';
import {useState} from "react";

type propType = {
    imageInfo: null | ISliderImage,
    closeModel:()=>void,
    updateSeriesHandle:any,
    series:any
}

const UploadMediaSliderImage = ({ imageInfo,closeModel ,series ,updateSeriesHandle }: propType) => {

    const [queryParam , setQueryParam] = useSearchParams();
    const seriesId = queryParam.get("seriesId");
    const handleBrowseImage = async(event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("handle")
        const result:any = await uploadFile(event);
        formik.setFieldValue("mediaUrl", result?.imageSrc)
        formik.setFieldValue("file",result?.file);
    }

    const saveSliderImage = async (values:any)=>{
        const formData = new FormData();
        formData.append("file",values.file);
        formData.append("order",values.order);
        // if id is not present it means we are adding new image
        if(values.id == "")
        {
            formData.append("seriesId",values.seriesId);
            const response = await apiStore.boatConfigApi.addSeriesSliderImage(formData);
            const updateSliderMedia = [...series?.sliderMedia];
            updateSliderMedia.push(response);
            const updateSeries = {...series,sliderMedia:updateSliderMedia};
            updateSeriesHandle(updateSeries);
            
        }
        else
        {
            formData.append("id",values.id);
            const response = await apiStore.boatConfigApi.updateSeriesSliderImage(formData);
            const updateSliderMedia = [...series?.sliderMedia];
            const indexOfUpdateSliderImage = updateSliderMedia.findIndex((sliderImage)=>sliderImage.id === values.id);
            updateSliderMedia[indexOfUpdateSliderImage] = {...response};
            const updateSeries = {...series,sliderMedia:updateSliderMedia};
            updateSeriesHandle(updateSeries);
            console.log({response,updateSeries , updateSliderMedia});
        }
       
        
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: imageInfo?.id ? imageInfo?.id : "",
            seriesId: seriesId,
            mediaUrl: imageInfo?.mediaUrl ? imageInfo?.mediaUrl : "",
            order: imageInfo?.order ?  imageInfo?.order : "",
            file:""
        },
        onSubmit: (values:any) => {
            closeModel();
            saveSliderImage(values)    
        },
    }
    )

    return (
        <>
         <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4" textAlign="center">Upload Media Image</Typography>
            <Grid container spacing={4} mt={1} justifyContent="center" >
                <Grid item md={4} xs={12} >
                    <Stack alignItems="center" >
                        <img style={{ maxWidth: "250px", borderRadius: "5px" }} src={formik.values.mediaUrl || defaultImg} alt="slide-img" />
                        <Box mt={1}>
                            <Button variant="contained" component="label" sx={{background:"#465c8e",color:"#fff !important"}}>
                                Browse Image
                                <input hidden accept="image/*" multiple type="file" onChange={(event) => handleBrowseImage(event)} />
                            </Button>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item md={6} xs={12} sm={12}>
                    <InputLabel htmlFor="user.username">
                        Order *
                    </InputLabel>
                    <TextField
                        fullWidth
                        size="small"
                        id="order"
                        value={formik.values.order}
                        onChange={formik.handleChange}
                    />
                    <Box mt={2}>
                        <Button variant="contained" type="submit">SAVE CHANGES</Button>
                    </Box>
                </Grid>
            </Grid>
            </form>
        </>
    )
}

export default UploadMediaSliderImage