import { useCallback, useEffect, useMemo, useState } from "react";
import { Column, TableInstance } from "react-table";
import { useNavigate, useOutletContext } from "react-router-dom";
import {Checkbox,} from "@mui/material";
import { Table } from "../../../components/table/Table";
import { IModel, IModelExtended } from "../../../models/Configuration";
import { SelectColumnFilter } from "../../../components/table/filters/SelectColumnFilter";
import { apiStore } from "../../../api/ApiFactory";
import { convertValue } from "../../../helpers/utils/valueConverter";

const ModelConfiguration = () => {
  let navigate = useNavigate();

  //Table Columns
  const columns: Column<IModel>[] = useMemo(
    () => [
      {
        Header: "Model",
        accessor: "displayName",
        width: 200,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Series",
        accessor: "seriesName",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Epicor Revision",
        accessor: "year",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Model Year",
        accessor: "yearDisplayLabel"
      },
      {
        Header: "Config Name",
        accessor: "importConfigName",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Visible To Admin",
        accessor: "visibleToAdmin",
        InLineEdit: true,
        inputType: 'checkbox',
        Cell: (cell: any) => {
          return (
            <Checkbox
              className="row-checkbox"
              disabled
              checked={cell.value}
              value={cell.value}
            />
          );
        },
      },
      {
        Header: "Visible To Dealer",
        accessor: "visibleToDealer",
        InLineEdit: true,
        inputType: 'checkbox',
        Cell: (cell: any) => {
          return (
            <Checkbox
              className="row-checkbox"
              disabled
              checked={cell.value}
              value={cell.value}
            />
          );
        },
      },
      {
        Header: "Visible To Consumer",
        accessor: "visibleToConsumer",
        InLineEdit: true,
        inputType: 'checkbox',
        Cell: (cell: any) => {
          return (
            <Checkbox
              className="row-checkbox"
              disabled
              checked={cell.value}
              value={cell.value}
            />
          );
        },
      },
      {
        Header: "Is Model Archived",
        accessor: "archiveModel",
        InLineEdit: true,
        inputType: 'checkbox',
        Cell: (cell: any) => {
          return (
            <Checkbox
              className="row-checkbox"
              disabled
              checked={cell.value}
              value={cell.value}
            />
          );
        },
      },
      {
        Header: "Sort Order",
        accessor: "order",
        InLineEdit: true,
        inputType: 'text',
      }
    ],
    []
    );

    const [isLocked, setIsLocked] = useState<boolean>(true); 

    const handleToggle = useCallback(
        (instance: TableInstance<IModel>) => () => {
            setIsLocked(prevState => !prevState);
        },
        [isLocked]
    );


    const updateModelDetails = async (values: IModel): Promise<boolean> => {
        try {
            await apiStore.boatConfigApi.inlineUpdateModel(values)
            console.log('Update successful');
            return true;
        } catch (error) {
            console.error('Update failed:', error);
            return false;
        }
    };

    const onInlineEdit = useCallback(
        async (rowId: string | number, columnId: keyof IModel, newValue: any, row: IModel) => {
          if (row) {
                const oldValue = row[columnId];
                const newValueConverted = convertValue(oldValue, newValue);

                // Only update if the new value is different from the old value
                if (oldValue !== newValueConverted) {
                    const updatedValues: IModel = {
                        ...row,
                        [columnId]: newValueConverted,
                    };
                    
                    const result = await updateModelDetails(updatedValues);

                  if (result) {
                      return true;
                  }
              } else {
                  console.log('No change detected. Update skipped.');
              }
          } else {
              console.error('Row not found:', rowId);
          }
          return false;
      },
      []
  );

  const ViewRow = useCallback(
    (instance: TableInstance<IModel>) => () => {
      const user = instance.selectedFlatRows[0].original;
      const { id, name, seriesName } = user;
      const modelName = `${seriesName}+${name}`;
      navigate(`modellist?boatModelId=${id}&modelName=${modelName}`, {
        state: { user: user },
      });
    },
    []
  );

  return (
    <>
      <Table<IModel>
        name="model-list-table"
        serverOnChange={false}
        columns={columns}
        apiGet="/configuration/modellist"
        onEdit={ViewRow}
        inlineLock={handleToggle}
        inlineLockState={isLocked}
        onInlineEdit={onInlineEdit}
      />
    </>
  );
};

export default ModelConfiguration;
