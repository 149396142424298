export const getValueByKey =(value: string, type: any) => {

    const result: any = Object.values(type).filter((x:any) => {
        return x.key === value;
    })

    if(result.length > 0) {
        return result[0].value;
    }

    return "";
}