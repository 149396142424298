import { Box, Card, CardContent, CardHeader, CardTypeMap, Divider, Typography} from "@mui/material";
import React from "react";
import { SectionBox } from "./SectionBox";
import { InitialConfig } from "./../../helpers/constants/AppConfig"
import { SectionHeader } from "./SectionHeader";
type ExtProps = {
    title?: string;
    divider?: boolean;
    subcard?: boolean;
    className?: string;
    elevation?: number;
    onClose?:()=>void;
}

export const MainCard: React.FC<Partial<CardTypeMap> & ExtProps> = (
    {
        children,
        title,
        divider,
        subcard,
        className,
        elevation,
        onClose,
        ...rest
    }
) => 
{
    const cn = !!className ? className : 'main-card'; 
    const elev = !!elevation ? elevation : InitialConfig.elevation;

    return (
        <Card className={cn} elevation={elev} {...rest}>

            {/*Card Header*/}
            <SectionHeader title={title} divider={divider} subcard={subcard} onClose={onClose} centerlabel/>
            
            {children}
            
        </Card>
    )

}

