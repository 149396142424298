
import { IAuthUser, IUserAuthPasswordReset, IUserFormValues } from "../models/Auth";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";
import { setAuthState } from "../store/slices/Auth/AuthSlice";

export default class AuthApi {

    private apiFactory: ApiFactory;
    constructor(apiFactory: ApiFactory) {
        this.apiFactory = apiFactory;
    }

    login = async (values: IUserFormValues) => {

        try {
            const user: IAuthUser = await agent.api.post('/account', values);
            this.apiFactory.store.dispatch(setAuthState(user));
        }
        catch (error) {
            throw error;
        }
    }

    logout = async () => {
        try {
            await agent.api.post('/account/logout', {});
            this.apiFactory.store.dispatch(setAuthState(null));
        }
        catch (error) {
            throw error;
        }
    }

    refresh = async () => {
        try {

            let user: IAuthUser = await agent.api.post('/account/refreshtoken', {});

            this.apiFactory.store.dispatch(setAuthState(user));
            return user;
        }

        catch (error) {
            this.apiFactory.store.dispatch(setAuthState(null));
            throw error;
        }
    }

    forgotpassword = async (params: any) => {
        try {
            let result = await agent.api.post('/account/forgotpassword', params);
            return result;
        }
        catch (error) {
            throw error;
        }
    }

    resetpassword = async (params: any) => {
        try {
            let result: IAuthUser = await agent.api.post('/account/resetpassword', params);
            this.apiFactory.store.dispatch(setAuthState(result));
        }
        catch (error) {
            throw error;
        }
    } 

    resetauthpassword = async (params: IUserAuthPasswordReset) => {
        try {
            await agent.api.post('/user/resetpassword', params);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

}
