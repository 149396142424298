import { IconButton } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Column, TableInstance } from "react-table";
import { Configurator } from "../../../models/Configuration";
import { ReactTable } from "../../../components/table/ReactTable";

interface IProp {
    configurators: Array<Configurator>;
    seriesId: string | number;
}

const ConfiguratorList = ({ configurators, seriesId }: IProp) => {

    let navigate = useNavigate();

    const columns: Column<Configurator>[] = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Import Config Name",
                accessor: "importConfigName",
            }
        ],
        []
    );

    const AddRow = useCallback(
        (instance: TableInstance<Configurator>) => () => {
          navigate("/config/series/configurator/add", { state: { record: null, seriesId: seriesId } });
        },
        []
    );
    
    const ViewRow = useCallback(
        (instance: TableInstance<Configurator>) => () => {
          const record = instance.selectedFlatRows[0].original;
          navigate("/config/series/configurator/view", { state: { record: record, seriesId: seriesId } });
        },
        []
    );

    return (
        <>
            <ReactTable
                name="configurator-table"
                columns={columns}
                data= {configurators}
                onAdd={AddRow}
                onEdit={ViewRow}
                isLoading={false}
            />
        </>
    )
}

export default ConfiguratorList;