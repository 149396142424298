import { Checkbox, Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Column, TableInstance } from "react-table";
import { MainCard } from "../../components/surface/MainCard";
import { ReactTable } from "../../components/table/ReactTable";
import { Roles } from "../../helpers/enums/AuthConstants";
import { IDealer, IDealerLocation } from "../../models/Dealer";
import { useAuth } from "../../store/hooks/useAuth";

type IProp = {
    dealer: IDealer;
}

const DealerLocationTable = (data: IProp ) => {

    const navigate = useNavigate();
    let authuser = useAuth().authuser;

    //Table Columns
    const columns: Column<IDealerLocation>[] = useMemo(
        () => [
            {
            Header: "Customer Id",
            accessor: "customerID"
            },
            {
            Header: "Name",
            accessor: "name"
            },
            {
            Header: "Sales Rep Code",
            accessor: "salesRepCode"
            },
            {
            Header: "Is Active",
            accessor: "isActive",
            Cell: (cell: any) => <Checkbox className='row-checkbox' disabled checked={cell.value ? true : false} />
            },
        ], []
    );

    const AddRow = useCallback(
        (instance: TableInstance<IDealerLocation>) => () => {
          navigate(`/dealer/view/${data.dealer.id}/location`, { state: { dealerLocation: null } });
        },
        []
      );
    
      const ViewRow = useCallback(
        (instance: TableInstance<IDealerLocation>) => () => {
          const dealerLocation = instance.selectedFlatRows[0].original;
          navigate(`/dealer/view/${data.dealer.id}/location`, { state: { dealerLocation } });
        },
        []
      );

    return (
        <Grid item md={12} mt={4}>
            <MainCard title='DEALER LOCATION'>
              <ReactTable
                name="order-table"
                columns={columns}
                serverOnChange={false}
                data={data?.dealer?.locations ? data?.dealer?.locations : []}
                isLoading={false}
                onAdd={authuser?.user.role === Roles.DEALER ? undefined : AddRow}
                onEdit={ViewRow}
              />
            </MainCard>
          </Grid>
    )

}

export default DealerLocationTable;