import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { apiStore } from "../../../../api/ApiFactory"
import { MainMediaCard } from "../../../../components/media/MainMediaCard"
import ConfirmationBox from "../../../../components/modal/ConfirmationBox"
import MainModal from "../../../../components/modal/MainModal"
import { MainCard } from "../../../../components/surface/MainCard"
import { SectionBox } from "../../../../components/surface/SectionBox"
import { LayeredModel } from "../../../../models/Configuration"
import UpdatePerspective from "./UpdatePerspective"

type IProps = {
    perspectives: LayeredModel[],
    sidebyside?: boolean
}

const LayeredMedia = (props: IProps) => {

    const [sideView, setSideView] = useState<LayeredModel | null>(null);
    const [topView, setTopView] = useState<LayeredModel | null>(null);
    const [activePerspective, setActivePerspective] = useState<LayeredModel | null>(null);

    const [updateImageModal, setUpdateImageModal] = useState(false);
    const openUpdateImageModal = () => setUpdateImageModal(true);
    const closeUpdateImageModal = () => setUpdateImageModal(false);

    const size = props.sidebyside ? 6 : 12;
    const maxSize = props.sidebyside ? 600 : 400;

    const [deleteImageModal, setDeleteImageModal] = useState(false);
    const openDeleteImageModal = () => setDeleteImageModal(true);
    const closeDeleteImageModal = () => setDeleteImageModal(false);

    const getLayer = (type: string) => {
        return props.perspectives.filter((x: any) => x?.perspectiveName === type);
    }

    const setLayer = (type: string) => {
        var value = getLayer(type);

        if(value) {
            
            if(type === "Top") {
                setTopView(value[0]);
            }
            else {
                setSideView(value[0]);
            }
        }
    }

    useEffect(() => {
        setLayer("Top");
        setLayer("Side");

    }, []);

    const updateActivePerspective = (type: string) => {
        if(type == "Top") {
            setActivePerspective(topView);
        }
        else {
            setActivePerspective(sideView);
        }
    }

    const updatePerspective = (type: string) => {
        updateActivePerspective(type);        
        openUpdateImageModal();
    }

    const updatePerspectiveOnChange = (perspective: LayeredModel | null) => {

            if(perspective !== null) {
                if(perspective.perspectiveName === "Top") {
                    setTopView(null);
                    setTopView(perspective);
                }
                else {
                    setSideView(null);
                    setSideView(perspective);
                }
            
                closeUpdateImageModal();
            }
    }

    const deletePerspective = (type: string) => {
        updateActivePerspective(type);  
        openDeleteImageModal();
    }

    const deletePerspectiveOnConfirm = () => {

        let active = activePerspective;

        if(active !== null) {
            var results = apiStore.boatConfigApi.removeImage(active?.id);

            results.then((response: any) => {

                if(active !== null) {
                    active.id = "";
                    active.layerOrder = 1;
                    active.mediaUrl = null;
                    
                    if(active.perspectiveName == "Top") {
                        setTopView(null);
                        setTopView(active);
                    }
                    else {
                        setSideView(null);
                        setSideView(active);
                    }
                }
                
            })
        }
        

        if(active !== null) {
            active.id = "";
        }

        closeDeleteImageModal();
    }

    return (

        <>

            <MainModal open={deleteImageModal} handleClose={closeDeleteImageModal}>
                <ConfirmationBox 
                    cancelClick={closeDeleteImageModal} 
                    confirmClick={deletePerspectiveOnConfirm} 
                    message="Are you sure you want to delete this image?" />
            </MainModal>
            
            <MainModal open={updateImageModal} handleClose={closeUpdateImageModal}>
                {activePerspective && (
                    <UpdatePerspective perspective={activePerspective} closeModal={updatePerspectiveOnChange} />
                )}
            </MainModal>

            <Grid container spacing={2}>

                <Grid item xs={12} md={size}>
                    <MainCard subcard title="Top View" divider={true} >
                        <SectionBox>
                            {topView && (
                                <MainMediaCard 
                                    imageUrl={topView.mediaUrl} 
                                    onUpdate={updatePerspective}
                                    onDelete={deletePerspective}
                                    value="Top"
                                    className="image-perspective"
                                    infoTag={"Order: " + topView.layerOrder}
                                    maxSize={maxSize}
                                    />
                            )}
                        </SectionBox>
                    </MainCard>
                </Grid>
                <Grid item xs={12} md={size}>
                    <MainCard subcard title="Side View" divider={true} >
                        <SectionBox>
                            {sideView && (
                                <MainMediaCard 
                                    imageUrl={sideView.mediaUrl} 
                                    onUpdate={updatePerspective}
                                    onDelete={deletePerspective}
                                    value="Side"
                                    className="image-perspective"
                                    infoTag={"Order: " + sideView.layerOrder}
                                    maxSize={maxSize}
                                    />
                            )}
                        </SectionBox>
                    </MainCard>
                </Grid>
            </Grid>

        </> 
    )
}

export default LayeredMedia;