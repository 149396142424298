import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { MainCard } from '../../../components/surface/MainCard'
import { SectionBox } from '../../../components/surface/SectionBox'


type ChartWrapperProps = {
    height?: string,
    minHeight?: string,
    children?: ReactNode
    title:string
    hasScroll?:boolean
}


const ChartWrapper = ({ children, height, title, minHeight, hasScroll=false }: ChartWrapperProps) => {

    const defaultProp = {
        height: height ?? "320px",
        minHeight: minHeight ?? (height ?? "320px")
    }

    return (
        <MainCard>
            <SectionBox>
                <Typography className="chart-title" variant="h6">{title}</Typography>
                <Box sx={defaultProp} className={hasScroll?"chart-container" : ""}>
                    {children}
                </Box>
            </SectionBox>
        </MainCard>
    )
}

export default ChartWrapper