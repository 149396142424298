import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { convertToObject } from 'typescript';
import { PropTypes } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTabValue } from '../../store/slices/Tabs/TabSlice';
import { getCurrentTabValue } from '../../store/slices/Tabs/TabSelector';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb: 3 }}>
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type propType = {
    children?: React.ReactNode,
    tabs: {
        label: string,
        Component: React.ReactNode
    }[]

}

export default function BasicTabs(props: any) {

    const { tabs, ...rest }: propType = props;
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const currentTabValue = useSelector(getCurrentTabValue);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        dispatch(setCurrentTabValue(newValue));
    };
    React.useEffect(()=>{
        setValue(currentTabValue.currentTab);
    },[])

    return (

        <Box sx={{ width: "100%" }}>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

                <Tabs
                    {...rest}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {tabs.map(({ label }, i: number) => (
                        <Tab label={label} key={i} />
                    ))}
                </Tabs>

            </Box>

            {
                tabs.map(({ Component }, i: number) => (
                    <TabPanel value={value} index={i} key={i}>
                        {Component}
                    </TabPanel>
                ))
            }

        </Box >

    );
}
