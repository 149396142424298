import { Grid, Box, Button, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BoatCardComponent from "./BoatCardComponent";
import { apiStore } from "../../api/ApiFactory";
import {
  getCurrentStep,
  getLookupsDetails
} from "../../store/slices/BoatOrdering/BoatOrderingSelector";
import { useSelector } from "react-redux";
import CurrencyFormat from "../../components/currencyFormat/CurrencyFormat";
import { calculatePriceByType } from "../../helpers/utils/utils";
import { DefaultCostType } from "../../helpers/enums/Common";
import { getDealer } from "../../store/slices/Dealer/DealerSelector";

type stepProps = {
  priceType: string;
  model: any;
  salePriceMarkup?: any;
  msrp: number;
};

const BoatOrderingPreview = ({
  model,
  priceType,
  salePriceMarkup,
  msrp,
}: stepProps) => {
  const setCurrentStep = (id: number) => {
    apiStore.boatOrderingApi.setCurrentStep(id);
  };

  const currentStep = useSelector(getCurrentStep);
  const dealerDetails = useSelector(getDealer);

  const priceTypeName = DefaultCostType.find((x) => x.key === priceType);
  const totalPrice = calculatePriceByType(
    model.totalPrice,
    priceType,
    priceType == "msrp" ? msrp : salePriceMarkup
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      className="step-active-wrapper step-wrapper"
      direction={{ md: "row", xs: "column" }}
      alignItems={{ md: "flex-start", xs: "stretch" }}
    >
      <Grid lg={6} md={12} item className="card-component-sm">
        <BoatCardComponent />
      </Grid>
      <Grid lg={5} md={12} item>
        {model?.sections?.map((section: any, sectionIdx: number) => {
          let hasChoices: number = 0;
          section?.options?.forEach((option: any) => {
            if (hasChoices) return;
            hasChoices = option?.choices?.filter(
              (el: any) => el.preSelected && el?.isVisible
            )?.length;
          });
          return (
            <Box className="checkbox-list specification-list" key={sectionIdx}>
              <div className="list-head">
                <h4>{section?.name}</h4>
                <IconButton
                  aria-label="upload picture"
                  onClick={() => setCurrentStep(sectionIdx)}
                >
                  <EditIcon />
                </IconButton>
              </div>
              <div className="specificationBox">
                {hasChoices > 0 ? (
                  section?.options?.map((option: any, index: number) => {
                    const choices = option?.choices?.filter(
                      (choice: any) => choice.preSelected && choice?.isVisible
                    );
                    if (choices?.length > 0) {
                      return (
                        <Grid
                          container
                          key={index}
                          justifyContent="space-between"
                          sx={{
                            marginBottom: "10px",
                          }}
                        >
                          <Grid item xs={4}>
                            <div className="specificationType">
                              {option?.name}
                            </div>
                          </Grid>
                          <Grid item xs={8}>
                            {option?.choices?.map(
                              (choice: any, idx: number) => {
                                if (choice.preSelected) {
                                  let amount = calculatePriceByType(
                                    choice?.adjustedPrice ?? choice.price,
                                    priceType,
                                    priceType == "msrp"
                                      ? msrp
                                      : dealerDetails?.salePriceMarkupOption
                                  );

                                  return (
                                    <div
                                      className="specification-container"
                                      key={idx}
                                    >
                                      <div className="specificationDetail">
                                        <div>{`${choice?.name}`}</div>
                                        {option?.uiWidget ===
                                          "Color Swatch Tray" && (
                                          <span
                                            className="colorBox"
                                            style={{
                                              background: choice.hexcode,
                                            }}
                                          ></span>
                                        )}
                                      </div>
                                      <div className="specificationAmt">
                                        <CurrencyFormat value={amount} />
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )}
                          </Grid>
                        </Grid>
                      );
                    }
                  })
                ) : (
                  <Typography variant="subtitle1" align="center">
                    No selections found.
                  </Typography>
                )}
              </div>
            </Box>
          );
        })}
        <Box className="checkbox-list specification-list">
          <div className="list-head">
            <h4>Pricing</h4>
          </div>
          <div className="specificationBox">
            <Grid
              container
              justifyContent="space-between"
              sx={{ marginBottom: "10px" }}
            >
              <Grid item xs={4}>
                <div className="specificationType">
                  {priceTypeName?.value ?? "Price"}
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="specification-container">
                  <div className="specificationDetail"></div>
                  <div className="specificationAmt">
                    <CurrencyFormat value={totalPrice} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>

        <Box className="step-actions" sx={{ textAlign: "right" }}>
          <Button
            color="inherit"
            onClick={() => setCurrentStep(currentStep - 1)}
            sx={{ mr: 1 }}
            variant="contained"
          >
            Back
          </Button>
          <Button
            onClick={() => setCurrentStep(currentStep + 1)}
            variant="contained"
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
export default BoatOrderingPreview;
