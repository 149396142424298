
import {
  FinanceTermLookup,
  IChoice,
  IModel,
  IModelExtended,
  IModelPrintInfo,
  IOption,
  ISection,
  Lookup,
} from "../models/Configuration";
import { fetchRules } from "../store/slices/BoatConfiguration/BoatRules/RuleSlice";
import { fetchSeriesData } from "../store/slices/BoatConfiguration/SeriesSlice";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class BoatConfigureApi {
  private apiFactory: ApiFactory;
  constructor(apiFactory: ApiFactory) {
    this.apiFactory = apiFactory;
  }

  //Model Configuration Api's
  getAllModels = async () => {
    try {
      const list = await agent.api.get(`/configuration/modellist`, null);
      return list;
    } 
    catch (error) {
      throw error;
    }
  };

  getModelDetails = async (id: string | null) => {
    try {
      const list = await agent.api.get(`/configuration/modeldetail?Id=${id}`, null);
      return list;
    } 
    catch (error) {
      throw error;
    }
  };

  getSeriesList = async () => {
    try {
      const list = await agent.api.get("/configuration", null);
      this.apiFactory.store.dispatch(fetchSeriesData(list));
      return list;
    } 
    catch (error) {
      throw error;
    }
  };

  getSectionList = async (modelId: string | null) => {
    try {
      const list = await agent.api.get(`/configuration/sectionlist?modelId=${modelId}`, null);
      return list;
    } 
    catch (error) {
      throw error;
    }
  };


  getOptionDetails = async (id: string | null) => {
    try {
      const list = await agent.api.get( `/configuration/optiondetail?Id=${id}`, null);
      return list;
    } 
    catch (error) {
      throw error;
    }
  };



  getChoiceDetails = async (id: string | null) => {
    try {
      const choiceDetails = await agent.api.get(`/configuration/choicedetail?Id=${id}`,null );
      return choiceDetails;
    } 
    catch (error) {
      throw error;
    }
  };

  getOptionList = async (id: string | number) => {
    if (!id) return;
    try {
      const list = await agent.api.get(`/configuration/optionlist?boatSectionId=${id}`,null );
      return list;
    } 
    catch (error) {
      throw error;
    }
  };

  addSeriesSliderImage = async (values:any)=>{
    try {
      const response = await agent.api.postFormData(`configuration/addseriesimageslider`, values);
      return response;
    } 
    catch (error) {
      throw error;
    }
  }

  deleteSeriesSliderImage = async (id: number) => {
    try {
      const response = await agent.api.post(`configuration/removeseriesimageslider?id=${id}`, {});
      return response;
    } 
    catch (error) {
      throw error;
    }
  }

  updateSeriesSliderImage =async (values:any) => {
    try {
      const response = await agent.api.post(`configuration/updateseriesimageslider`, values);
      return response;
    } 
    catch (error) {
      throw error;
    }
  }

  // Rules Api's
  getRuleRevisionOptions = async () => {
    try {
      const response = await agent.api.get(`/rules/seriesrevision`, null);
      return response;
    } 
    catch (error) {
      throw error;
    }
  };

  getRules = async (id: number | string) => {
    try {
      const response = await agent.api.get(`/rules?revisionId=${id}`, null);
      this.apiFactory.store.dispatch(fetchRules(response));
      return response;
    } 
    catch (error) {
      throw error;
    }
  };
  saveRule = async (values: any) => {
    try {
      const response = await agent.api.post(`/rules`, values);
      return response;
    } 
    catch (error) {
      throw error;
    }
  };

  deleteRule = async (id: string | number) => {
    try {
      const response = await agent.api.del(`/rules?ruleId=${id}`);
      return response;
    } 
    catch (error) {
      throw error;
    }
  };

  updateChoice = async (values: IChoice) => {
    try {
      await agent.api.post(`/configuration/updatechoice`, values);
    } 
    catch (error) {
      throw error;
    }
  };

  updateOption = async (values: IOption) => {
    try {
      await agent.api.post(`/configuration/updateoption`, values);
    } 
    catch (error) {
      throw error;
    }
  };

  updateModel = async (values: IModelExtended) => {
    try {
      await agent.api.post(`/configuration/updatemodel`, values);
    } 
    catch (error) {
      throw error;
    }
    };

    inlineUpdateModel = async (values: IModel) => {
        try {
            await agent.api.post(`/configuration/updatemodel`, values);
        }
        catch (error) {
            throw error;
        }
    };

  updateSeries = async (values: any) => {
    try {
      await agent.api.post(`/configuration/updateseries`, values);
    } 
    catch (error) {
      throw error;
    }
  };

  removeImage = async (id: string | number) => {
    try {
      await agent.api.post(`/configuration/removeimage?id=${id}`, {});
    } 
    catch (error) {
      throw error;
    }
  };

  removeChoiceImage = async (id: string | number) => {
    try {
      await agent.api.post(
        `/configuration/removechoiceimage?boatOptionChoiceId=${id}`,
        {}
      );
    } 
    catch (error) {
      throw error;
    }
  };

  uploadImage = async (values: any) => {
    try {
      const response = await agent.api.uploadImage(
        `/configuration/updateimage`,
        values
      );
      return response;
    } 
    catch (error) {
      throw error;
    }
  };

  uploadOrderHistoryImage = async (values: any, orderNumber: number) => {
    try {
      const response = await agent.api.uploadImage( `/ordermanagement/UploadOrderMedia?orderNum=${orderNumber}`, values );
      return response;
    } 
    catch (error) {
      throw error;
    }
  };
 
  getImage = async (id: number, orderNum: number, fileType: string) => {
      try {
          await agent.api.downloadImage(`/ordermanagement/GetBlobImage?orderNum=${orderNum}&id=${id}`, fileType);
      }
      catch (error) {
      throw error;
    }
  };

  uploadChoiceImage = async (data: any) => {
    try {
      await agent.api.uploadImage(`/configuration/importchoiceimage`, data);
    } 
    catch (error) {
      throw error;
    }
  };

  
  updateLayerOrder = async (data: any) => {
    try {
      const response = await agent.api.postFormData(
        `/configuration/updateimage`,
        data
      );
      return response;
    } 
    catch (error) {
      throw error;
    }
  };
  

  updateConfigurator = async (data: any) => {
    try {
      const response = await agent.api.post(`/configuration/updateconfigurator`, data);
      return response;
    } 
    catch (error) {
      throw error;
    }
  };

  importConfigurator = async (data: any) => {
    try {
      const response = await agent.api.post(`/importdata`, data);
      return response;
    } 
    catch (error) {
      throw error;
    }
  };


  //Lookup Api's

  getLookups = async () => {
    try {
      const response = await agent.api.get("/lookup", null);
      return response;
    } 
    catch (error) {
      throw error;
    }
  };

  saveDeliveryMethod = async (formData: Lookup) => {
    try {
      const response = await agent.api.post(
        `/lookup/savedeliverymethod`,
        formData
      );
      return response;
    } 
    catch (error) {
      throw error;
    }
  }

  saveFreightMethod = async (formData: Lookup) => {
    try {
      const response = await agent.api.post(`/lookup/savefreightmethod`,  formData);
      return response;
    } 
    catch (error) {
      throw error;
    }
  }
  saveFinanceTerm = async (formData: FinanceTermLookup) => {
    try {
      const response = await agent.api.post( `/lookup/savefinanceterm`,formData );
      return response;
    } 
    catch (error) {
      throw error;
    }
  }

  updateSeriesImage = async (values: any) => {
    try {
      const response = await agent.api.post(`/configuration/updateseriesimage`, values );
      return response;
    } 
    catch (error) {
      throw error;
    }
  }

  updatePrint = async(data: IModelPrintInfo) => {
    try {
      const response = await agent.api.post('/configuration/updateprint', data);
      return response;
    }
    catch (error) {
      throw error;
    }
  }

  //Master Config Api's
  
  getSeriesRevision = async () => {
    try {
      const response = await agent.api.get("/configuration/seriesrevision", null);
      return response;
    } 
    catch (error) {
      throw error;
    }
  };

  getMasterConfigData = async (revisionId:number)=>{
    try{
      const response = await agent.api.get(`/configuration/masterconfigindex?revisionId=${revisionId}`, null);
      return response;
    }
    catch(error) {
      throw error
    }
  }

  saveMasterConfigModels = async (values:any)=>{
    try {
      const response = await agent.api.post(
        `/configuration/masterconfigmodels`, values
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  saveMasterConfigAssignoptions = async (values:any)=>{
    try {
      const response = await agent.api.post( `/configuration/masterconfigassignoptions`, values);
      return response;
    } 
    catch (error) {
      throw error;
    }
  }
  saveMasterConfigOptions = async (values:any)=>{
    try {
      const response = await agent.api.post(`/configuration/masterconfigoptions`, values);
      return response;
    } 
    catch (error) {
      throw error;
    }
  }
  saveMasterConfigchoices = async (values:any)=>{
    try {
      const response = await agent.api.post(`/configuration/masterconfigchoices`, values
      );
      return response;
    } 
    catch (error) {
      throw error;
    }
  }
  saveMasterConfigImage = async (values : any) =>{
    try {
      const response = await agent.api.postFormData( `/configuration/masterconfigimage`, values
      );
      console.log({response})
      return response;
    } 
    catch (error:any) {
      console.log(error);
      throw error;
    }
  }
}
