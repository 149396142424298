
import { getOrder } from "../store/slices/BoatOrdering/BoatOrderingSlice";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class OrderManagementApi {
    private apiFactory: ApiFactory;

    constructor(apiFactory: ApiFactory) {
        this.apiFactory = apiFactory;
    }

    getOrder = async (params: any) => {
        try {
          const res = await agent.api.get("/ordermanagement/GetOrderDetails", params);
          
          this.apiFactory.store.dispatch(getOrder(res));
          return res;
        } catch (error) {
          throw error;
        }
      };
    
    getPrintDetails = async (params: any) => {
      try {
        const res = await agent.api.get("/ordermanagement/GetPrintDetails", params);
        this.apiFactory.store.dispatch(getOrder(res));
        return res;
      } catch (error) {
        throw error;
      }
    };

    cancelOrder = async (orderNumber: number) => {
      try {
        await agent.api.get(`/ordermanagement/CancelOrder?orderNumber=${orderNumber}`, null);
      } 
      catch (error) {
        throw error;
      }
    };

    getOrderHistory = async (params: any) => {
      try {
        const response = await agent.api.get(`/ordermanagement/GetOrderHistory`, params);
        return response;
      } 
      catch (error) {
        throw error;
      }
    };

    getOrderHistoryImages = async (orderNumber: number) => {
    
      try {
        const list = await agent.api.get(`/ordermanagement/GetAllOrderMedia?orderNum=${orderNumber}`, null);
        return list;
      } 
      catch (error) {
        throw error;
      }
    };

    removeOrderHistoryImage = async (orderNumber: number, id: string | number) => {
      try {
        await agent.api.post(`/ordermanagement/RemoveOrderMedia?orderNum=${orderNumber}&id=${id}`, {});
      } 
      catch (error) {
        throw error;
      }
    };

}