import {
  Grid,
  InputLabel,
  TextField,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MainCard } from "../../../components/surface/MainCard";
import { SectionBox } from "../../../components/surface/SectionBox";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { apiStore } from "../../../api/ApiFactory";
import { ISeries } from "../../../models/Configuration";
import ConfiguratorList from "./ConfiguratorList";
import MediaSlider from "./MediaSlider";
import SubmitButtonGroup from "../../../components/button/SubmitButtonGroup";

interface IProps {
  record: ISeries | null;
}

const UpdateSeries = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const seriesId: string | null = searchParams.get("seriesId");
  const location = useLocation();

  const state: any = location.state ?? null;
  let { record }: IProps = state ?? {};

  const [series, setSeries] = useState<ISeries>();
  //const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  //Calling Series Api
  useEffect(() => {
    if (!seriesId && !record) {
      navigate("/config");
    } else {
      //let mounted = true;
      //setLoading(true)
      const result = apiStore.boatConfigApi.getSeriesList();
      result.then((response) => {
        //if (mounted) {
        const { data } = response;
        const id = seriesId ? seriesId : (record!.id as string);
        console.log({ record });
        const currentSeries = filterData(data, id);

        setSeries(currentSeries);
        //setLoading(false);
        //}
      });
    }
  }, []);

  const filterData = (data: ISeries[], id: string | null) => {
    if (!id) return;
    const result = data.filter((item: ISeries) => item.id === parseInt(id));
    return result[0];
  };

  //Formik values
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: series?.id ? series?.id : "",
      name: series?.name ? series?.name : "",
      order: series?.order ? series?.order : null,
      description: series?.description ? series?.description : "",
      msrp: series?.msrp ? series?.msrp : null,
      mediaUrl: series?.mediaUrl ? series?.mediaUrl : "",
      sliderMedia: series?.sliderMedia ? series?.sliderMedia : [],
      key: series?.key ? series?.key : "",
      configurators: series?.configurators ? series?.configurators : [],
      modelCount: series?.modelCount ? series?.modelCount : 0,
    },

    onSubmit: (values: ISeries, { resetForm }) => {
      values.order = values.order ? values.order : null;
      updateSeries(values);
    },
  });

  const updateSeries = (values: ISeries) => {
    const response = apiStore.boatConfigApi.updateSeries(values);
    response
      .then(() => {
        navigate(-1);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("called");
    if (!event.target.files) return;
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const src: string = reader.result as string;
      formik.setFieldValue("mediaUrl", src);
    };
    reader.readAsDataURL(selectedFile);
    const formData = new FormData();
    if (series) {
      formData.append("file", selectedFile);
      formData.append("boatSeriesId", series.id as string);
      const response = apiStore.boatConfigApi.updateSeriesImage(formData);
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="page-header-label">
              {"Boat Series"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <MainCard title="GENERAL INFORMATION" divider={true}>
              <SectionBox>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="name">Name *</InputLabel>
                    <TextField
                      onChange={formik.handleChange}
                      value={formik.values.name ? formik.values.name : ""}
                      name="name"
                      id="name"
                      size="small"
                      placeholder="Name *"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="order">Order</InputLabel>
                    <TextField
                      onChange={formik.handleChange}
                      value={formik.values.order ? formik.values.order : ""}
                      name="order"
                      id="order"
                      size="small"
                      placeholder="Order *"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="Description">Description *</InputLabel>
                    <TextField
                      onChange={formik.handleChange}
                      value={
                        formik.values.description
                          ? formik.values.description
                          : ""
                      }
                      name="description"
                      id="Description"
                      size="small"
                      placeholder="Description *"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="msrp">MSRP *</InputLabel>

                    <TextField
                      onChange={formik.handleChange}
                      value={formik.values.msrp ? formik.values.msrp : ""}
                      name="msrp"
                      id="msrp"
                      size="small"
                      placeholder="MSRP"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <SubmitButtonGroup disabled={formik.isSubmitting} />
              </SectionBox>
            </MainCard>
          </Grid>
          <Grid item xs={12} lg={8}>
            <MainCard title="MEDIA" divider={true}>
              <SectionBox>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(event) => uploadFile(event)}
                      />
                      <Button variant="text" component="span">
                        Update Image
                      </Button>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    {formik.values.mediaUrl && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <img
                            src={formik.values.mediaUrl}
                            style={{ maxHeight: "250px", borderRadius: "5px" }}
                            alt="img"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </SectionBox>
            </MainCard>
          </Grid>
        </Grid>
        <Grid container spacing={4} mt={1}>
          <Grid item xs={12} lg={4}>
            {series !== undefined && (
              <MainCard title="CONFIGURATORS" divider={true}>
                <SectionBox>
                  <ConfiguratorList
                    configurators={series!.configurators}
                    seriesId={series.id}
                  />
                </SectionBox>
              </MainCard>
            )}
          </Grid>
          <Grid item xs={12} lg={8}>
            <MainCard title="MEDIA SLIDER" divider={true}>
              <SectionBox>
                <MediaSlider series={series} updateSeriesHandler={setSeries} />
              </SectionBox>
            </MainCard>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UpdateSeries;
