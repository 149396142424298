import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { useState } from "react";
import ModalComponent from '../../../components/modal/ModalComponent';
import UploadMediaSliderImage from './UploadMediaSliderImage';
import { ISeries } from '../../../models/Configuration';
import { apiStore } from '../../../api/ApiFactory';
import DeleteSliderImage from './DeleteSliderImage';

const MediaSlider = ({ series, updateSeriesHandler }: any) => {

    //Upload Image Model
    const [uploadModel, setUploadModel] = useState(false);
    const openUploadModel = () => setUploadModel(true);
    const closeUploadModel = () => setUploadModel(false);

    //Delete Image Model
    const [deleteModel, setDeleteModel] = useState(false);
    const openDeleteModel = () => setDeleteModel(true);
    const closeDeleteModel = () => setDeleteModel(false);

    //Delete Id
    const [imageIdToBeDeleted, setImageIdToBeDeleted] = useState(-1);

    //State to store image information
    const [imageInfo, setImageInfo] = useState(null);

    const handleUploadModel = (data: any) => {
        setImageInfo(data);
        openUploadModel();
    }

    const setDeleteImageId = async (id: number) => {
        // const response = await apiStore.boatConfigApi.deleteSeriesSliderImage(id);
        // console.log({ id, response })
        setImageIdToBeDeleted(id);
        openDeleteModel();
    }

    const handleConfirmImage = (id: number) => {
        setImageIdToBeDeleted(id);
        openDeleteModel();
    }

    const deleteModelStyle = {
        width: { md: "40%" },
        height: { md: "30%" },
        overflow:"hidden"
      };

      const uploadModelStyle = {
        width:"60%",
        height:"60%"
      }

    return (
        <>
            <ModalComponent open={deleteModel} handleClose={closeDeleteModel} customStyle={deleteModelStyle}>
               <DeleteSliderImage series={series} updateSeriesHandle={updateSeriesHandler} imageIdToBeDeleted={imageIdToBeDeleted} closeDeleteModel={closeDeleteModel}/>
            </ModalComponent>
            <Box mb={2}>
                <Button onClick={() => handleUploadModel(null)}>UPLOAD IMAGE</Button>
            </Box>
            <ModalComponent open={uploadModel} handleClose={closeUploadModel} customStyle=              {uploadModelStyle} >
                <UploadMediaSliderImage imageInfo={imageInfo} series={series} updateSeriesHandle={updateSeriesHandler} closeModel={closeUploadModel} />
            </ModalComponent>
            <Grid container spacing={2}>
                {
                    series?.sliderMedia.length>0 && series?.sliderMedia.map((slider: any) => (
                        <Grid item key={slider.id} xs={12} sm={12} md={3}>
                            <Card sx={{ xs: { maxWidth: 300 }, md: { maxWidth: 200 } }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={slider.mediaUrl}
                                    alt="green iguana"
                                />
                                <CardActions sx={{justifyContent:"space-between"}}>
                                    <Button size="small" onClick={() => setDeleteImageId(slider.id)}>Delete</Button>
                                    <Button size="small" onClick={() => handleUploadModel(slider)}>Update</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </>

    )
}

export default MediaSlider