import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Configurator } from "../../../models/Configuration";
import * as Yup from "yup";
import { Button, FormHelperText, Grid, InputLabel, Link, Stack, TextField, Typography } from "@mui/material";
import { MainCard } from "../../../components/surface/MainCard";
import { SectionBox } from "../../../components/surface/SectionBox";
import { apiStore } from "../../../api/ApiFactory";
import { useState } from "react";
import MainModal from "../../../components/modal/MainModal";
import ModalComponent from "../../../components/modal/ModalComponent";
import ImportConfigurator from "./ImportConfigurator";
import SubmitButtonGroup from "../../../components/button/SubmitButtonGroup";

interface IProps {
    record: Configurator | null;
    seriesId: string | number;
}

const AppValidationSchema = Yup.object().shape({
    record: Yup.object({
        name: Yup.string().required("Name is required"),
        importConfigName: Yup.string().required("Import Config Name is required")
    })
})

const ConfiguratorEdit = () => {
    
    const [importModal, setMode] = useState(false);
    const openModal = () => setMode(true);
    const closeModal = () => setMode(false);

    let navigate = useNavigate();
    const location = useLocation();
    let isNew: boolean = false;
    const state: any = location.state ?? null;
    let { record, seriesId }: IProps = state ?? {};

    if(record == null) {
        isNew = true;
        record = {
            id: null,
            name: "",
            importConfigName: "",
            seriesId: seriesId
        }
    }

    const formik = useFormik({
       validationSchema: AppValidationSchema,
       initialValues: {
            record: record,
            submit: null
       },
       onSubmit: (values, {setSubmitting, setErrors}) => {
            //api call.
            const response = apiStore.boatConfigApi.updateConfigurator(values.record);
            response.then(() => {
                navigate(-1);
            })
            .catch((error) => {
                
                if(error.length > 0) {
                    setErrors({submit: error[0].errorMessage});
                }
            })
            .finally(() => {
                formik.setSubmitting(false);
            })
       }
    });

    const handleClose = () => {
        navigate(-1);
    };

    return (
        <>
            <MainModal open={importModal} handleClose={closeModal}>
                <ImportConfigurator record={record} closeModal={closeModal}></ImportConfigurator>
            </MainModal>


            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className="page-header-label">
                            {"Configurator"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <MainCard>
                            <SectionBox>

                                <Grid container spacing={4}>
                                    {!isNew && (
                                        <Grid item xs={12}>
                                            <Stack justifyContent="flex-end" direction="row">
                                                <Typography>
                                                    <Link
                                                        sx={{ cursor: "pointer" }}
                                                        underline="none"
                                                        onClick={() => {
                                                            openModal();
                                                        }}
                                                    >
                                                    {"Import Data"}
                                                    </Link>
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    )}
                                    {formik.errors.submit && (
                                        <Grid item xs={12}>
                                            <FormHelperText sx={{position:"absolute"}} error id="submit-helper">{formik.errors.submit}</FormHelperText>
                                        </Grid>
                                    )}
                                    
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="name">Name *</InputLabel>
                                        <TextField
                                            id="name"
                                            {...formik.getFieldProps("record.name")}
                                            size="small"
                                            placeholder="Name *"
                                            fullWidth
                                            error={Boolean(formik.touched.record?.name && formik.errors.record?.name )}
                                        />
                                        {formik.touched.record?.name && formik.errors.record?.name && (
                                            <FormHelperText error id="name-helper">
                                                {formik.errors.record?.name}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="name">Import Config Name *</InputLabel>
                                        <TextField
                                            id="importConfigName"
                                            {...formik.getFieldProps("record.importConfigName")}
                                            size="small"
                                            placeholder="Import Config Name *"
                                            fullWidth
                                            error={Boolean(formik.touched.record?.importConfigName && formik.errors.record?.importConfigName )}
                                        />
                                        {formik.touched.record?.importConfigName && formik.errors.record?.importConfigName && (
                                            <FormHelperText error id="importConfigName-helper">
                                                {formik.errors.record?.importConfigName}
                                            </FormHelperText>
                                        )}
                                    </Grid> 
                                </Grid>
                                <SubmitButtonGroup disabled={formik.isSubmitting} />
                            </SectionBox>
                        </MainCard>
                    </Grid>
                </Grid>


            </form>
        </>
    )

}

export default ConfiguratorEdit;