import React from 'react'
import { Outlet } from "react-router-dom";
const DealerIndex = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

export default DealerIndex