import { createSlice } from '@reduxjs/toolkit';
import { IAuthUser } from '../../../models/Auth';
import { AppThunk } from '../../store';
import { selectAppLoadComplete, selectAuthUser } from './AuthSelector';

interface AuthState {
    authuser: IAuthUser | null,
    appLoadComplete: boolean 
}

const initialState: AuthState = {
    authuser: null,
    appLoadComplete: false 
}

const slice = createSlice({
    name: 'auth',
    initialState, 
    reducers: {
        authSuccessAction: (state, action) => {
            state.authuser = action.payload;
            state.appLoadComplete = true;
        },

        appLoadCompleteAction: (state, action) => {
            state.appLoadComplete = action.payload;
        }
    }
});

export const { authSuccessAction, appLoadCompleteAction } = slice.actions;

//Thunks
export const setAuthState = (value: IAuthUser | null): 
    AppThunk => (dispatch, getState) => 
{
    const currentvalue = (selectAuthUser(getState()));
    if(currentvalue !== value)
    {
        dispatch(authSuccessAction(value))
    }
};


export default slice.reducer;