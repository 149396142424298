import React, { Component } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Series from "./Series/Series";
import ModelConfiguration from "./ModelConfiguration/ModelConfiguration"
import BasicTabs from "../../components/tabs/BasicTabs"
import ModelRule from "./ModelRules/ModelRule";
import Lookups from "./Lookups/Lookups";
import MasterConfigIndex from "./MasterConfig/MasterConfigIndex";
const Configuration = () => {


  //Tab to display
  const tabs = [
    {
      label: "Series",
      Component: <Series />
    },
    {
      label:"Master Config",
      Component:<MasterConfigIndex/>
    },
    {
      label: "Model Configuration",
      Component: <ModelConfiguration />
    },
    {
      label: "Model Rules",
      Component: <ModelRule />
    },
    {
      label: "Lookups",
      Component: <Lookups />
    },  
  ];

  return (
    <div>
      <BasicTabs tabs={tabs} />
    </div>
  );
}

export default Configuration;