import { Box, Stack } from "@mui/material"

type CustomTooltipType = {
  label: any,
  value: any,
  color: string,
}

const CustomTooltip = ({ label, value, color }: CustomTooltipType) => {
  
  return (
    <Stack className="tooltip" direction="row" spacing={1} alignItems="center">
      <Box sx={{ background: color, height: "12px", width: "12px" }}></Box>
      <Box>
        <span className="tooltip_label">{label}</span> : <span className="tooltip_value">{value}</span>
      </Box>
    </Stack>
  )
}

export default CustomTooltip