import { Alert, makeStyles, Snackbar } from "@mui/material";
import React, { SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/slices/Snackbar/Snackbar";

import { AppState } from "../../store/store";


const CustomizedSnackbars = () => {
    const dispatch = useDispatch();
    const snackbarOpen = useSelector((state: AppState) => state.reducer.Snackbar.snackbarOpen);
    const snackbarType = useSelector((state: AppState) => state.reducer.Snackbar.snackbarType);
    const snackbarMessage = useSelector((state: AppState) => state.reducer.Snackbar.snackbarMessage);
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(setSnackbar(
            { snackbarOpen: false, snackbarType, snackbarMessage }));
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                key={"bottom" + "right"}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}

                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CustomizedSnackbars;
