import {
  Grid,
  Button,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Column, TableInstance } from "react-table";
import { useState, useEffect } from "react";
import { apiStore } from "../../api/ApiFactory";
import { useFormik } from "formik";
import { ReactTable } from "../../components/table/ReactTable";
import { IOrderHistory } from "../../models/OrderManagement";
import { LookupExtended, NameValueEntity } from "../../models/Common";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/utils/formatDate";
import { SelectColumnFilter } from "../../components/table/filters/SelectColumnFilter";
import {IDealer, IDealerLocation } from "../../models/Dealer";
import MainModal from "../../components/modal/MainModal";
import PrintModal from "./PrintModal";
import { mdiContentCopy } from "@mdi/js";
import { Command } from "../../components/table/ICommand";
import ImageIcon from "@mui/icons-material/Image";
import UploadImageModal from "../../components/modal/UploadImageModal";
import ConfirmationBox from "../../components/modal/ConfirmationBox";
import { useAuth } from "../../store/hooks/useAuth";
import { GetAuthStatus } from "../../services/AuthService";
import { Permissions } from "../../helpers/enums/AuthConstants";
import { useSelector } from "react-redux";
import { getAllDealers } from "../../store/slices/Dealer/DealerSelector";
import { getBoatSeriesList, getYearList } from "../../store/slices/Lookup/LookupSelector";

const OrderManagement = () => {
  const navigate = useNavigate();
  const [reactTabelData, setReactTableData] = useState<Array<IOrderHistory>>([]);

  const [open, setOpenModal] = useState(false);
  const openModel = () => setOpenModal(true);
  const closeModel = () => setOpenModal(false);
  const [orderNumber, setOrderNumber] = useState<number>(-1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  let authuser = useAuth().authuser;
  let viewBoatOrdering = GetAuthStatus([Permissions.ViewBoatOrdering]).isAuthorized;

  const handleDialogOpen = (orderNum: any) => {
    setOrderNumber(orderNum);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const [searchBarVisibility, setSearchBarVisibility] = useState<boolean>(false);

  const [location, setLocation] = useState<Array<IDealerLocation>>();

  const [formValues, setformValues] = useState<any>();
  const [cancelOrderModal, setcancelOrderModal] = useState(false);
  const openCancelOrderModal = () => setcancelOrderModal(true);
  const closeCancelOrderModal = () => setcancelOrderModal(false);
  const [disableControls, setDisableControls] = useState(false); 

  const dealerList = useSelector(getAllDealers);
  const yearList = useSelector(getYearList);
  const seriesList = useSelector(getBoatSeriesList);

  //Function to create Loaction List
  const createLocationList = (data: IDealerLocation[] | undefined) => {
    var locations: Array<IDealerLocation> = [];
    if (!!data) {
      data.map((item) => {
        locations.push(item);
      });
    }
    if (data && data.length > 0) {
      if (data.length > 1) {
        locations.unshift({
          customerID: "",
          name: "All",
        } as IDealerLocation);
        formik.setFieldValue("locations", {
          customerID: "",
          name: "All",
        });
      } else {
        formik.setFieldValue("locations", { ...locations[0] });
      }
      formik.setFieldValue("series", { id: "", name: "All" });
      formik.setFieldValue("year", { ...yearList![yearList!.length! - 1] });
    } 
    else {
      formik.setFieldValue("locations", { id: "", customerID: "", name: "" });
      formik.setFieldValue("series", { id: "", name: "" });
      formik.setFieldValue("year", { name: "", value: "" });
    }
    setLocation(locations);
  };

  //Formik initial values
  const initialValues = {
    dealerOptions: { name:""} as IDealer,
    locations: { name: "", customerID: "" } as IDealerLocation,
    series: { id: "", key: "", name: "" } as LookupExtended,
    year: { name: "", value: "" } as NameValueEntity,
    showformfilters: true,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: any, { setSubmitting }: any) => {
      setformValues(values);
      getOrderHistory(values);
    },
  });
  const refreshGrid = useCallback(() => {
    getOrderHistory(formValues);
  }, [formValues]);

  //Function to get order history based on dropdown options
  const getOrderHistory = (values: any) => {
    let { customerID } = values.locations;
    const year = values.year.value;
    let model: any = values.series.key;

    let params = new URLSearchParams();
    if (customerID.length <= 0 && location?.length! > 1) {
      location?.forEach((item) => {
        if (item.customerID.length > 0) {
          params.append("CustomerID", item.customerID);
        }
      });
    } else {
      params.append("CustomerID", customerID);
    }
    if (model) {
      params.append("model", model);
    }
    params.append("year", year);

    const result = apiStore.orderManagementApi.getOrderHistory(params);

    result
      .then((response) => {
        setReactTableData(
          response.map((item: { orderStatus: string }) => ({
            ...item,
            orderStatus:
              item.orderStatus === "CAN" ? "Cancelled" : item.orderStatus,
          }))
        );
        setSearchBarVisibility(true);
      })
      .catch((error) => {
        console.log(error);
        setSearchBarVisibility(false);
      });
  };

  //Function to clone order
  const onClone = useCallback(
    (instance: TableInstance<IOrderHistory>) => () => {
      const order = instance.selectedFlatRows[0].original;
      const { orderNum } = order;

      navigate(`/boat/order/clone/${orderNum}`);
    },
    []
  );

  const enableCommand = (instance: TableInstance<IOrderHistory>) => {
    return instance.selectedFlatRows.length === 1;
  };

  const extraCommands: Command<IOrderHistory>[] = useMemo(
    () => [
      {
        label: "Clone",
        onClick: onClone,
        icon: mdiContentCopy,
        enabled: enableCommand,
        type: "icon",
      },
    ],
    []
  );

  //Function to edit table row
  const onEdit = useCallback(
    (instance: TableInstance<IOrderHistory>) => () => {
      const order = instance.selectedFlatRows[0].original;
      const { orderNum } = order;
      navigate(`/boat/order/view/${orderNum}`);
    },
    []
  );

  const onPrint = useCallback(
    (instance: TableInstance<IOrderHistory>) => () => {
      const order = instance.selectedFlatRows[0].original;
      const { orderNum } = order;
      setOrderNumber(orderNum);
      openModel();
      
    },
    []
  );

  //Function to reset form values
  const handleReset = () => {
    setSearchBarVisibility(false);
    formik.resetForm();
  };

  const handleClick = (row: any) => {
    navigate(`/warranty/create/${row.orderNum}`);
  };

  const handleCancelOrderBtnClick = (row: any) => {
    setOrderNumber(row.orderNum);
    openCancelOrderModal();
  };

  const cancelOrderOnConfirm = (formValues: any) => {

    setDisableControls(true);
    const result = apiStore.orderManagementApi.cancelOrder(orderNumber);
    result
      .then(() => {
        setDisableControls(false);
        closeCancelOrderModal();
        getOrderHistory(formValues);
      })
      .catch((error) => {
        console.log(error);
        setDisableControls(false);
      });
  };

  //Table Columns Structure
  const columns: Column<IOrderHistory>[] = useMemo(
    () => [
      {
        Header: "Actions",
        accessor: "warranty",
        width: 180,
        Cell: ({ row }: any) => {

          const { orderNum, orderStatus } = row.original;
          const rowData = row.original;
          return (
            <>
              <ImageIcon
                sx={{
                  color: rowData.mediaCount != 0 ? "#0474C2" : "#A4B1B9",
                  marginRight: "12px",
                }}
                onClick={() => handleDialogOpen(orderNum)}
              />
              {orderStatus === "Invoiced" && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleClick(row.original)}
                >
                Warranty
                </Button>
              )}
              
              {(orderStatus === "Dealer Order" || orderStatus === "Quote") && authuser?.user.isLegacyDealer === false && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleCancelOrderBtnClick(row.original)}
                  style={{ backgroundColor: "#cc3300" }}
                >
                  Cancel Order
                </Button>
              )}
              
            </>
          );
        },
      },
      {
        Header: "Dealer Name",
        accessor: "dealerName",
        width: 200,
      },
      {
        Header: "Sales Order",
        accessor: "orderNum",
        width: 120,
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        width: 150,
        Cell: (cell: any) => (cell.value ? formatDate(cell.value) : ""),
      },
      {
        Header: "Model",
        accessor: "boatModel",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Purchase Order",
        accessor: "poNum",
      },
      {
        Header: "Requested Delivery",
        accessor: "requestedDelivery",
      },
      {
        Header: "Status",
        accessor: "orderStatus",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Scheduled Week",
        accessor: "buildWeek",
      },
      {
        Header: "EC Date",
        accessor: "ecDate",
        Cell: (cell: any) => (cell.value ? formatDate(cell.value) : "")
      },
      {
        Header: "HIN Number",
        accessor: "hullNumber",
      },
      {
        Header: "Model Year",
        accessor: "modelYear",
        width: 100,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Last Update By",
        accessor: "lastUpdatedBy",
        Cell: (cell: any) => (
          <span style={{wordWrap:"break-word"}}>
            {cell.value}
          </span>
        )
      },
      {
        Header: "Last Update",
        accessor: "lastUpdateDate",
        width: 150,
        Cell: (cell: any) => (cell.value ? formatDate(cell.value) : ""),
      },
    ],
    []
  );

  return (
    <>
      <MainModal
        open={open}
        handleClose={closeModel}
        customStyle={{ maxWidth: "600px" }}
      >
        <PrintModal
          orderNum={orderNumber}
          handleClose={closeModel}
          label="Order"
        />
      </MainModal>
      <MainModal open={cancelOrderModal} handleClose={closeCancelOrderModal}>
        <ConfirmationBox
          confirmTitle="Proceed"
          cancelTitle="Close"
          cancelClick={closeCancelOrderModal}
          confirmClick={() => cancelOrderOnConfirm(formValues)}
          disable={disableControls}
          message="Are you sure you want to cancel this order?"
        />
      </MainModal>
      <MainModal
        open={isDialogOpen}
        handleClose={handleDialogClose}
        customStyle={{ maxWidth: "600px" }}
      >
        <UploadImageModal
          onClose={handleDialogClose}
          refreshGrid={refreshGrid}
          orderNumber={orderNumber}
        />
      </MainModal>
      <form onSubmit={formik.handleSubmit}>
        {!searchBarVisibility && (
          <Grid
            container
            spacing={4}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={6} md={3} xl={2}>
              <InputLabel>Dealers *</InputLabel>
              <Autocomplete
                onChange={(event, value: any) => {
                  formik.setFieldValue("dealerOptions", value);
                  if (dealerList && value) {
                    const locations = dealerList!.find(
                      (item: IDealer) => item?.id === value?.id
                    )?.locations;
                    createLocationList(locations);
                  } else if (!value) {
                    createLocationList([]);
                  }
                }}
                size="small"
                fullWidth
                disablePortal
                id="dealerOptions"
                getOptionLabel={(option: IDealer) => option.name}
                isOptionEqualToValue={(option, value) => 
                  option.id === value.id || value.name === ""
                }
                options={dealerList ? dealerList : []}
                renderInput={(params) => (
                  <TextField {...params} name="dealerOptions" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2}>
              <InputLabel>Locations *</InputLabel>
              <Autocomplete
                // key={formik.values.dealerOptions}
                size="small"
                fullWidth
                disablePortal
                value={formik.values.locations}
                onChange={(event, value) => {
                  formik.setFieldValue("locations", value);
                }}
                id="locations"
                getOptionLabel={(option: IDealerLocation) =>
                  option.customerID !== ""
                    ? option.customerID + " - " + option.name
                    : option.name
                }
                isOptionEqualToValue={(option, value) => 
                  option.customerID === value.customerID || value.customerID === ""
                }
                options={location ? location : []}
                renderInput={(params) => (
                  <TextField {...params} name="values.locations" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2}>
              <InputLabel>Model *</InputLabel>
              <Autocomplete
                size="small"
                fullWidth
                disablePortal
                value={formik.values.series}
                id="series"
                onChange={(event, value) => {
                  formik.setFieldValue("series", value);
                }}
                getOptionLabel={(option: LookupExtended) => option.name}
                isOptionEqualToValue={(option, value) => 
                  option.id === value.id || value.id === ""
                }
                options={seriesList ? seriesList : []}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2}>
              <InputLabel>Year *</InputLabel>
              <Autocomplete
                size="small"
                fullWidth
                disablePortal
                id="year"
                value={formik.values.year}
                onChange={(event, value) => {
                  formik.setFieldValue("year", value);
                }}
                getOptionLabel={(option: NameValueEntity) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.value == value.value || value.value === ""
                }
                options={yearList ? yearList : []}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                Get Orders
              </Button>
            </Grid>
          </Grid>
        )}
      </form>

      {searchBarVisibility && (
        <Grid
          container
          spacing={4}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel htmlFor="name"> Dealers *</InputLabel>
            <TextField
              disabled
              value={formik.values?.dealerOptions?.name}
              name="name"
              fullWidth
              size="small"
              id="name"
              placeholder="Name *"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel htmlFor="name"> Locations *</InputLabel>
            <TextField
              disabled
              value={formik.values?.locations?.name}
              name="name"
              fullWidth
              size="small"
              id="name"
              placeholder="Location *"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel htmlFor="name"> Series *</InputLabel>
            <TextField
              disabled
              value={formik.values?.series?.name}
              name="name"
              fullWidth
              size="small"
              id="name"
              placeholder="Series *"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <InputLabel htmlFor="name"> Year *</InputLabel>
            <TextField
              disabled
              value={formik.values?.year?.name}
              name="name"
              fullWidth
              size="small"
              id="name"
              placeholder="Year *"
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" type="submit" onClick={handleReset}>
              Reset
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid mt={2}>
        {searchBarVisibility && (
          <ReactTable<IOrderHistory>
            className="show-action-column"
            name="order-table"
            columns={columns}
            serverOnChange={false}
            data={reactTabelData ? reactTabelData : []}
            isLoading={false}
            onEdit={viewBoatOrdering === true ? onEdit : undefined}
            onPrint={onPrint}
            extraCommands={viewBoatOrdering === true ? extraCommands : undefined}
          />
        )}
      </Grid>
    </>
  );
};
export default OrderManagement;
