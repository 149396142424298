import { IWarrantyHistoryRes } from "../models/WarrantyManagement";
import agent from "./agent";
import { ApiFactory } from "./ApiFactory";

export default class WarrantyApi {
  private apiFactory: ApiFactory;
  constructor(apiFactory: ApiFactory) {
    this.apiFactory = apiFactory;
  }

  createWarranty = async (body: any) => {
    try {
      await agent.api.post("/Warranty", body);
    } catch (error) {
      throw error;
    }
  };

  getWarrantyHistory = async (params: any) => {
    try {
      const res = await agent.api.get("/Warranty/GetWarrantyHistory", params);
      return res;
    } catch (error) {
      throw error;
    }
  };

  getWarrantyDetails = async (params: any) => {
    try {
      const res = await agent.api.get("/Warranty/GetWarrantyDetails", params);
      return res;
    } catch (error) {
      throw error;
    }
  };
  getFormData = async (params: any) => {
    try {
        const res: IWarrantyHistoryRes = await agent.api.get("/Warranty/GetFormData", params);
      return res;
    } catch (error) {
      throw error;
    }
  };
}
