import { Autocomplete, Box, Button, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import { FormEvent, FormEventHandler, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { apiStore } from '../../../api/ApiFactory';
import { MainCard } from '../../../components/surface/MainCard';
import { SectionBox } from '../../../components/surface/SectionBox';
import { RuleAction, RuleCondition, RuleModel } from '../../../models/Configuration';
import { getRules } from "../../../store/slices/BoatConfiguration/BoatRules/RuleSelector";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type PropType = {
    data: RuleModel | null,
    closeModel:()=>void ,
    revisionId:string |  number,
    updateComponent:()=>void
}

const EditRuleModel = ({data, closeModel , revisionId, updateComponent}:PropType) => {

    const [options, setOptions] = useState();
    const [conditions, setConditions] = useState<RuleCondition[]>([]);
    const [actions, setActions] = useState<RuleAction[]>([])
    const [submitBtn , setSubmitBtn] = useState<boolean>(true);
    const rules: any = useSelector(getRules);
    const { availableOptions } = rules;

    const GetEmptyCondition = () => {
        const item: RuleCondition = {
            id: null,
            importKey: null,
            isSelected: null,
            revisionChoiceId: null
        }
        return item;
    }


    const GetEmptyAction = () => {
        const item: RuleAction = {
            id: null,
            importKey: null,
            isSelected: null,
            isDisabled: null,
            isVisible: null,
            revisionChoiceId: null
        }
        return item;
    }


    
    useEffect(() => {
        createOptions(rules);

        setConditions( data?.conditions ?? [GetEmptyCondition()]);
        setActions(data?.actions ?? [GetEmptyAction()]);

    }, [])

    
    const createOptions = (data: any) => {
        const { availableOptions } = data;
        const list = availableOptions.map((item: any) => {
            return { label: item.choice, ...item }
        })

        setOptions(list);
    }


    
    const handleConditionChange = (event: any, value: any, index: number) => {
        if (!value) {
            return
          }
        
        const condition = [...conditions];
        const { revisionChoiceId } = value;
        condition[index] = { ...condition[index], revisionChoiceId };
        setConditions(condition);

    }

    
    const handleConditionSelectionChange = (event: SelectChangeEvent, index: any) => {
        const condition = [...conditions];
        const isSelected = event.target.value === "true" ? true : false
        condition[index] = { ...condition[index], isSelected };
        setConditions(condition);
    }

    
    const removeConditionById = (id:number)=>{

        let state = [...conditions];
        state.splice(id,1);

        setConditions(state);
    }

    const removeActionById = (id:number) => {
        const state = [...actions];
        state.splice(id,1);
        setActions(state);
    }

    
    const handleActionSelectionChange = (name: string, value: any, index: number) => {
        const boolValue = value === "true" ? true : false;
        const action = [...actions];
        action[index] = { ...action[index], [name]: boolValue };
        setActions(action);
    }

    
    const handleActionChange = (event: any, value: any, index: number) => {
        if (!value) {
            return;
        }
        const action = [...actions];
        const { revisionChoiceId } = value;
       // action[index] = { ...action[index], id: null, revisionChoiceId, };
        action[index] = { ...action[index], revisionChoiceId };
        setActions(action);

    }

    const setConditonDefaultValue = (condition: any) => {
        if (!condition.revisionChoiceId) {
          return "";
        }
        const result = availableOptions.find((item: any) => item.revisionChoiceId === condition.revisionChoiceId);
        return { label: result.choice, ...result };
    }

    const getSelectedItem = (action: any) => {
        if (!action.revisionChoiceId) {
          return "";
        }
        const list = availableOptions.find((item: any) => item.revisionChoiceId === action.revisionChoiceId)
        return { label: list.choice, ...list }
    
      }

    
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitBtn(false);

        const formData = {
            id: data?.id ?? null,
            revisionId,
            actions,
            conditions
        }

        console.log(formData);

        const response =  apiStore.boatConfigApi.saveRule(formData);
        response.then(() => {
            updateComponent();
            closeModel();
        })
        .catch((error) => {
            setSubmitBtn(true);
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} xl={5}>
                        <MainCard title="Conditions" divider={true}>
                            <SectionBox>
                                {conditions && conditions.map((item, index) =>
                                    <>
                                        <div key={index}>
                                            <Typography component="div">{index === 0 ? "If" : "And"}</Typography>
                                            <Grid container spacing={1} mb={3} key={index}>
                                                <Grid item xs={12} sx={{display:"flex"}}>
                                                    <Typography component="div">{"("}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={7} lg={8} sx={{display:"flex"}}> 
                                                    <Autocomplete
                                                        onChange={(event, value: any) => {
                                                            handleConditionChange(event, value, index)
                                                        }}
                                                        id="conditions"
                                                        size="small"
                                                        fullWidth
                                                        disablePortal
                                                        options={options ? options : []}
                                                        value={setConditonDefaultValue(item)}
                                                        isOptionEqualToValue={(option, value) => option.revisionChoiceId === value.revisionChoiceId || value.revisionChoiceId === null}
                                                        renderInput={(params) => (
                                                            <TextField {...params} name="conditions" />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={5} lg={4} sx={{display:"flex"}}>
                                                    <Typography component="div" mt={1} mr={1}>Is</Typography>
                                                    <Select
                                                        size="small"
                                                        fullWidth
                                                        displayEmpty
                                                        name=""
                                                        defaultValue={""}
                                                        value={item.isSelected === null ? "" : item.isSelected === true ? "true" : "false" }
                                                        onChange={(event: SelectChangeEvent) => handleConditionSelectionChange(event, index)}
                                                    >
                                                        <MenuItem disabled value=""> <em></em> </MenuItem>
                                                        <MenuItem value="true"> <em>Selected</em> </MenuItem>
                                                        <MenuItem value="false"> <em>Not Selected</em></MenuItem>
                                                    </Select>
                                                    <IconButton onClick={()=>removeConditionById(index)}><DeleteOutlineIcon/></IconButton>
                                                </Grid>
                                                <Grid item xs={12} sx={{display:"flex"}}>
                                                    <Typography component="div">{")"}</Typography>
                                                </Grid>
                                            </Grid>   
                                        </div>
                                            
                                    </>
                                                           
                                )}
                                <Stack direction="row" mt={2}>
                                    <Button onClick={() => setConditions(current => [...current, GetEmptyCondition()])}>Add Condition</Button>
                                </Stack>
                            </SectionBox>
                        </MainCard>
                    </Grid>
                    <Grid item xs={12} xl={7}>
                        <MainCard title="Actions" divider={true}>
                            <SectionBox>
                                {actions && actions.map((action: any, index: number) => 
                                    <>
                                        <div key={index}>
                                            <Typography component="div">{index == 0 ? "Then," : "And"}</Typography>
                                            <Grid container spacing={1} mb={3} key={index}>
                                                <Grid item xs={12} sx={{display:"flex"}}>
                                                    <Typography component="div">{"("}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={3} xl={2} sx={{display:"flex"}}>
                                                    <Select
                                                        size="small"
                                                        fullWidth
                                                        displayEmpty
                                                        name=""
                                                        defaultValue=''
                                                        value={action.isSelected === null ? "" : action.isSelected === true ? "true" : "false" }
                                                        onChange={(event) => handleActionSelectionChange("isSelected", event?.target?.value, index)}
                                                    >
                                                        <MenuItem disabled value="">
                                                            <em></em>
                                                        </MenuItem>
                                                        <MenuItem value="true">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        <MenuItem value="false">
                                                            <em>Unselect</em>
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={3} xl={2} sx={{display:"flex"}}>
                                                    <Typography component="div" mt={1} mr={1} ml={1}> And </Typography>
                                                    <Select
                                                        size="small"
                                                        fullWidth
                                                        displayEmpty
                                                        name=""
                                                        defaultValue=''
                                                        value={action.isDisabled === null ? "" : action.isDisabled === true ? "true" : "false" }
                                                        onChange={(event) => handleActionSelectionChange("isDisabled", event?.target?.value, index)}
                                                    >
                                                        <MenuItem disabled value="">
                                                            <em></em>
                                                        </MenuItem>
                                                        <MenuItem value="false">
                                                            <em>Enable</em>
                                                        </MenuItem>
                                                        <MenuItem value="true">
                                                            <em>Disable</em>
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={2} xl={2} sx={{display:"flex"}}>
                                                    <Typography component="div" mt={1} mr={1} ml={1}> And </Typography>
                                                    <Select
                                                        size="small"
                                                        fullWidth
                                                        displayEmpty
                                                        name="Action"
                                                        defaultValue=''
                                                        value={action.isVisible === null ? "" : action.isVisible === true ? "true" : "false" }
                                                        onChange={(event) => handleActionSelectionChange("isVisible", event?.target?.value, index)}
                                                    >
                                                        <MenuItem disabled value="">
                                                            <em></em>
                                                        </MenuItem>
                                                        <MenuItem value="true">
                                                            <em>Show</em>
                                                        </MenuItem>
                                                        <MenuItem value="false">
                                                            <em>Hide</em>
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4} xl={6} sx={{display:"flex"}}>
                                                    <Autocomplete
                                                            onChange={(event, value: any) => {
                                                                handleActionChange(event, value, index);
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            disablePortal
                                                            options={options ? options : []}
                                                            value={getSelectedItem(action)}
                                                            isOptionEqualToValue={(option, value) => option.revisionChoiceId === value.revisionChoiceId || value.revisionChoiceId === null}
                                                            renderInput={(params) => (
                                                                <TextField {...params} name="actions" />
                                                            )}
                                                    />
                                                    <IconButton onClick={()=>removeActionById(index)}><DeleteOutlineIcon/></IconButton>
                                                </Grid>
                                                <Grid item xs={12} sx={{display:"flex"}}>
                                                    <Typography component="div"> {")"} </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>

                                    </>
                                )}
                                <Stack direction="row" mt={2}>
                                    <Button onClick={() => setActions(current => [...current, GetEmptyAction()])}>Add Action</Button>
                                </Stack>
                            </SectionBox>                                
                        </MainCard>

                    </Grid>
                </Grid>
                <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                    <Button variant="contained" type="submit" disabled={!submitBtn}>Save</Button>
                    <Button variant="contained" onClick={closeModel}>Cancel</Button>
                </Stack>
            </form>
        </>
    )
}

export default EditRuleModel;