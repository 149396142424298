import { Paper, PaperTypeMap } from "@mui/material";
import React from "react";
import { InitialConfig } from "../../helpers/constants/AppConfig";

type ExtProps = {
    className?: string;
}

export const MainPaper: React.FC<Partial<PaperTypeMap> & ExtProps> = (
    {
        children, 
        className,
        ...rest
    }) => {

    const cn = !!className ? className : 'main-paper'; 

    return (
        <Paper className={cn} elevation={InitialConfig.elevation} {...rest}>
            {children}
        </Paper>
    )

}

