
import {Grid } from "@mui/material";
import React from "react";
import { MainCard } from "../../components/surface/MainCard";
import { MainPaper } from "../../components/surface/MainPaper";
import { useAppSelector } from "../../store/hooks/hook";
import AppUserContact from "./UserContact";
import AppUserName from "./UserName";
import AppUserPasswordReset from "./UserPasswordReset";

interface IProps {
    expanded: boolean;
}


const AppUserProfile = () => {

    const [current, setExpanded] = React.useState<string>('panel1');

    const { user } = useAppSelector((state) => ({
        user: state.reducer.Auth.authuser?.user
    }));

    const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : '');
    };

    const handleClose = 
    () => () => {
        setExpanded('');
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MainCard title="Profile Settings" divider={true}>
                        
                        <MainPaper>
                            <AppUserName current={current} user={user} handleChange={handleChange} onClose={handleClose} />
                            <AppUserContact current={current} user={user} handleChange={handleChange} onClose={handleClose} />
                            <AppUserPasswordReset current={current} handleChange={handleChange} onClose={handleClose} />
                        </MainPaper>

                    </MainCard>
                </Grid>
            </Grid>
        </div>
  );
};

export default AppUserProfile;
