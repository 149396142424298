import React, { useState } from 'react'
import { Card, CardMedia, CardActions, Button, Grid, TextField, Stack, InputLabel, FormControlLabel, Switch, Typography } from "@mui/material";
import defaultImg from "../../../_assets/images/Sea-Pro-default-placeholder.png";
import { Formik } from 'formik';
import uploadFile from "../utils/UploadFile"
import { apiStore } from '../../../api/ApiFactory';
import { useNotification } from '../../../store/context/NotificationContext';
type LayeredMediaProps = {
    choiceId?: string | number,
    selectedModelList: []
}

const LayeredMedia = ({ choiceId, selectedModelList }: LayeredMediaProps) => {

    const { Toast } = useNotification();
    const initialValues = {
        top: {
            checked: false,
            file: "",
            imageSrc: "",
            layerOrder: "",
            boatPerspectiveId: "1",
            choiceId: choiceId ?? null,
            models: selectedModelList
        },
        side: {
            checked: false,
            file: null,
            imageSrc: "",
            layerOrder: "",
            boatPerspectiveId: "2",
            choiceId: choiceId ?? null,
            models: selectedModelList
        },
    }
    const handleBrowseImage = async (event: any, type: string, setFieldValue: any) => {
        const { imageSrc, file }: any = await uploadFile(event);
        setFieldValue(`${type}[file]`, file);
        setFieldValue(`${type}[imageSrc]`, imageSrc);
    }

    const createFormData = (values: any) => {
        const formData = new FormData();
        const { file, layerOrder, boatPerspectiveId, choiceId, models } = values;
        formData.append("layerOrder", layerOrder ?? null);
        formData.append("boatPerspectiveId", boatPerspectiveId);
        if (file) {
            formData.append("file", file);
        }
        if (choiceId) {
            formData.append("choiceId", choiceId)
        }
        models.forEach((model: any) => {
            formData.append("models", model);
        });
        return formData;
    }

    const handelSubmit = async (values: any) => {
        const { top, side, } = values;
        const formDataList: any = [];
        if (top.checked) {
            const result = createFormData(top);
            formDataList.push(result);
        }
        if (side.checked) {
            const result = createFormData(side);
            formDataList.push(result);
        }
        try {
            const result = await Promise.all(formDataList.map((formData: any) => apiStore.boatConfigApi.saveMasterConfigImage(formData)))
            Toast.success("Saved Successfully");
        }
        catch (error: any) {
            const { errorMessage } = error[0]
            Toast.error({ title: errorMessage });
        }
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values: any) => {
                    console.log(values);
                    handelSubmit(values);
                }}
            >
                {
                    ({
                        handleSubmit,
                        handleChange,
                        values,
                        setFieldValue,
                        resetForm,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Typography mt={2} mb={2}> Select Fields To Update </Typography>
                            <Grid container >
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        onChange={(event) => handleChange(event)}
                                        name="top.checked"
                                        control={<Switch value="1" />}
                                        label={`Top View `} />
                                    {
                                        values?.top?.checked && (
                                            <Grid container spacing={4} mt={1} mb={1} >
                                                <Grid item md={6} style={{ paddingTop: "5px" }}>
                                                    <Card sx={{ maxWidth: 345 }}>
                                                        <CardMedia
                                                            component="img"
                                                            height="140"
                                                            image={values?.top?.imageSrc.length > 0 ? values?.top?.imageSrc : defaultImg}
                                                            alt="Top view"
                                                        />
                                                        <CardActions sx={{ justifyContent: "center", background: "#f8fafb" }} >
                                                            <Button component="label">BROWSE
                                                                <input hidden accept="image/*" multiple type="file" onChange={(event) => handleBrowseImage(event, "top", setFieldValue)} />
                                                            </Button>
                                                        </CardActions>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={6} >
                                                    <InputLabel>Order</InputLabel>
                                                    <TextField
                                                        size="small"
                                                        name="top.layerOrder"
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        onChange={(event) => handleChange(event)}
                                        name="side.checked"
                                        control={<Switch value="2" />}
                                        label={`Side View `} />
                                    {
                                        values?.side?.checked && (
                                            <Grid container spacing={4} mt={1} >
                                                <Grid item md={6} style={{ paddingTop: "5px" }}>
                                                    <Card sx={{ maxWidth: 345 }}>
                                                        <CardMedia
                                                            component="img"
                                                            height="140"
                                                            image={values?.side?.imageSrc.length > 0 ? values?.side?.imageSrc : defaultImg}
                                                            alt="Side View"
                                                        />
                                                        <CardActions sx={{ justifyContent: "center", background: "#f8fafb" }} >
                                                            <Button component="label">BROWSE
                                                                <input hidden accept="image/*" multiple type="file" onChange={(event) => handleBrowseImage(event, "side", setFieldValue)} />
                                                            </Button>
                                                        </CardActions>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <InputLabel>Order</InputLabel>
                                                    <TextField
                                                        size="small"
                                                        name="side.layerOrder"
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Stack alignItems="end">
                                <Button type="submit" variant="contained">Save Changes</Button>
                            </Stack>
                        </form>
                    )
                }
            </Formik>
        </>
    )
}

export default LayeredMedia