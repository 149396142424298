import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { SectionBox } from "../../components/surface/SectionBox";
import { MainCard } from "../../components/surface/MainCard";
import { useEffect } from "react";
import { apiStore } from "../../api/ApiFactory";
import {
  IUserExtended,
  IUserFormFields,
  Feature,
  PermissionType,
} from "../../models/User";
import React from "react";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";

import { GetAuthStatus } from "../../services/AuthService";
import { Permissions, Roles } from "../../helpers/enums/AuthConstants";
import { useAuth } from "../../store/hooks/useAuth";
import { IAuthUser } from "../../models/Auth";
import { IDealer, IDealerLocation } from "../../models/Dealer";

interface UserProps {
  user: IUserExtended | null;
}

interface UserState {
  user: IUserExtended;
  formFields: IUserFormFields | null;
  defaultLocations: Array<IDealerLocation> | undefined;
  initialDealerId: number | null;
}

const GetEmptyUser = (authuser : IAuthUser) => {

  const user: IUserExtended = {
    username: "",
    email: "",
    id: "",
    isActive: true,
    role: authuser.user.dealerId ? authuser.user.role : "",
    firstName: "",
    lastName: "",
    salesRepCode: null,
    secondarySalesRepCode: null,
    dealerId: authuser.user.dealerId,
    dealerName: "",
    dealerLocations: null,
    isLegacyDealer: authuser.user.isLegacyDealer
  };

  return user;
};

const UserEdit = () => {
  let navigate = useNavigate();
  const location = useLocation();
  let authuser =  useAuth().authuser!;

  const state: any = location.state;
  let { user }: UserProps = state ?? null;
  let isNew: boolean = false;
  let canEdit: boolean = GetAuthStatus([Permissions.EditUser]).isAuthorized;

  if (user === null) {
    user = GetEmptyUser(authuser);
    isNew = true;

    if (!canEdit) {
      navigate(-1);
    }
  }

  const handleClose = () => {
    navigate(-1);
  };

  const ViewEditToggle = () => {
    if (canEdit && !isNew) {
      setPageData((prevState) => ({
        ...prevState,
        isEdit: !data.isEdit,
      }));
    }
  };

  const initialState: UserState = {
    user: user,
    formFields: null,
    defaultLocations: undefined,
    initialDealerId: user.dealerId ?? (authuser?.user.dealerId) ?? null,
  };

  const [data, setPageData] = React.useState({
    //Initial State Values
    user: initialState.user,
    formFields: initialState.formFields,
    initialDealerId: initialState.initialDealerId,
    defaultLocations: initialState.defaultLocations,
    isLoading: true,
    isEdit: isNew,
  });

  useEffect(() => {
    apiStore.userApi
      .getFormData()
      .then((response) => {

        var locations: Array<IDealerLocation> = [];

        if (!!user?.dealerId) {
          var userlocations = response.dealers.find((d) => user?.dealerId == d.id)?.locations ?? undefined;

            if (userlocations && user.dealerLocations) {
              user.dealerLocations.map((locid) => {
                userlocations?.map((userloc) => {
                  if (userloc.id === locid) {
                    locations.push(userloc);
                  }
                });
              });
            } 
            else if(userlocations && isNew) {
              if(userlocations.length === 1) {

                if(typeof userlocations[0].id  === 'number') {
                  user.dealerLocations = [userlocations[0].id];
                }

                locations.push(userlocations[0]);
              }

              user.permissions = getDefaultPermissions(user.role, response, user.dealerId);
            }
        }

        setPageData((prevState) => ({
          ...prevState,
          user: user!,
          formFields: response,
          isLoading: false,
          defaultLocations: locations,
        }));
      })
      .catch((error) => {
        console.error("User Edit", error);
      });
  }, []);

  const AppValidationSchema = Yup.object().shape({
    user: Yup.object({
      username: Yup.string()
        .min(4, "Username is to short")
        .max(120, "Username is to long")
        .required("Username is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      role: Yup.string().required("Role is required"),
      dealerId: Yup.string()
        .nullable()
        .when("role", {
          is: "Dealer",
          then: (AppValidationSchema) =>
            AppValidationSchema.required("Dealer is required"),
        }),
      salesRepCode: Yup.string()
        .nullable()
        .when("role", {
          is: "Sales Rep",
          then: (AppValidationSchema) => 
            AppValidationSchema.required("Sales rep code is required")
        }),
      dealerLocations: Yup.array()
        .nullable()
        .when("role", {
          is: "Dealer",
          then: (AppValidationSchema) =>
            AppValidationSchema.required("Dealer Location is required"),
        }),
      permissions: Yup.array().min(1, "At least one permission is required."),
    }),
  });

  const isLegacyDealer = (dealers: IDealer[] , dealerId: number | null) => {
    var isLegacyDealer = false;

    if(dealerId != null) {
      var dealer = dealers.find((d) => dealerId == d.id);
      isLegacyDealer = dealer!.isLegacy!;
    }

    return isLegacyDealer;
  }

  const isAvailableFeature = (element: Feature, role: string, dealerId: number | null): boolean => {

    var isLegacy = isLegacyDealer(data.formFields!.dealers, dealerId);

    if(role === Roles.SALESREP) {
      return element.permissions.filter(x => x.availableForSalesRep).length > 0;
    }

    if(role == Roles.DEALER) {
      return element.permissions.filter(x => x.availableForDealer && (isLegacy === false || x.availableForLegacyDealer)).length > 0;
    }

    return true;
  }

  const isAvailablePermission = (element: PermissionType, role: string, dealerId: number | null): boolean => {

    var isLegacy = isLegacyDealer(data.formFields!.dealers, dealerId);

    if(role == Roles.SALESREP) {
      return element.availableForSalesRep;
    }

    if(role == Roles.DEALER) {
      return element.availableForDealer && (isLegacy === false || element.availableForLegacyDealer);
    }

    if(role == Roles.ADMINISTRATOR) {
      if(element.name == "Receive Email") {
        return false;
      }
    }

    return true;
  }

  const getDefaultPermissions = (role: string, formFields: IUserFormFields, dealerId: number | null): string[] => {

    var isLegacy = isLegacyDealer(formFields!.dealers, dealerId);

    var permissions: string[] = [];
    formFields.features.map((feature) => {
      feature.permissions.map((permission) => {
        if ( permission.defaultForDealer && permission.availableForDealer && role == Roles.DEALER
            && (isLegacy === false || permission.availableForLegacyDealer )) {
            permissions.push(permission.id.toString());
        } 
        else if (permission.defaultForAdmin && role == Roles.ADMINISTRATOR) {
          permissions.push(permission.id.toString());
        } 
        else if ( permission.defaultForSalesRep && permission.availableForSalesRep &&role === Roles.SALESREP) {
          permissions.push(permission.id.toString());
        }
      });
    });

    return permissions;
  }

  const checkPermission = (
    value: string | number,
    permissions: string[] | undefined
  ): boolean => {

    if (permissions !== undefined) {
      const result = permissions.find((x) => x === value);

      if (result !== undefined && result.length > 0) {
        return true;
      }
    }
    return false;
  };

  if (data.isLoading) {
    return <p></p>;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="page-header-label">
            {isNew && "Add User"}
            {!isNew && data.isEdit && "Edit User"}
            {!isNew && !data.isEdit && "View User"}
            {!isNew && canEdit && (
              <>
                <Link
                  variant="subtitle2"
                  sx={{ pl: 1, cursor: "pointer" }}
                  underline="none"
                  onClick={ViewEditToggle}
                >
                  {!data.isEdit && "(Edit)"}
                  {data.isEdit && "(View)"}
                </Link>
              </>
            )}
          </Typography>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          user: data.user,
          submit: null,
        }}
        validationSchema={AppValidationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {

          apiStore.userApi
            .saveFormData(values.user)
            .then((response) => {
              if (response.errors.length > 0) {
                setErrors({ submit: response.errors[0] });
              } else {
                //Success. Return to List page
                navigate(-1);
              }
            })
            .catch((error) => {
              setErrors({ submit: "Unexpected Error" });
            })
            .finally(() => {
              setSubmitting(false);
            });
            
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          getFieldProps,
          isSubmitting,
          setFieldValue,
          touched,
          values,
          status,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormHelperText error id="submit-helper">
                      {errors.submit}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <MainCard title="User Information" divider={true}>
                  <SectionBox>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="user.username">
                          Username *
                        </InputLabel>
                        <TextField
                          disabled={!data.isEdit}
                          fullWidth
                          size="small"
                          id="username"
                          {...getFieldProps("user.username")}
                          placeholder="Username *"
                          error={Boolean(
                            touched.user?.username && errors.user?.username
                          )}
                        />
                        {touched.user?.username && errors.user?.username && (
                          <FormHelperText error id="username-helper">
                            {errors.user.username}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor="user.email">Email *</InputLabel>
                        <TextField
                          disabled={!data.isEdit}
                          fullWidth
                          size="small"
                          id="email"
                          {...getFieldProps("user.email")}
                          placeholder="Email *"
                          error={Boolean(
                            touched.user?.email && errors.user?.email
                          )}
                        />
                        {touched.user?.email && errors.user?.email && (
                          <FormHelperText error id="email-helper">
                            {errors.user.email}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor="user.lastname">
                          Last Name
                        </InputLabel>
                        <TextField
                          disabled={!data.isEdit}
                          fullWidth
                          size="small"
                          id="lastName"
                          {...getFieldProps("user.lastName")}
                          placeholder="Last Name"
                          error={Boolean(
                            touched.user?.lastName && errors.user?.lastName
                          )}
                        />
                        {touched.user?.lastName && errors.user?.lastName && (
                          <FormHelperText error id="lastName-helper">
                            {errors.user.lastName}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor="user.firstName">
                          First Name
                        </InputLabel>
                        <TextField
                          disabled={!data.isEdit}
                          fullWidth
                          size="small"
                          id="firstName"
                          {...getFieldProps("user.firstName")}
                          placeholder="First Name"
                          error={Boolean(
                            touched.user?.firstName && errors.user?.firstName
                          )}
                        />
                        {touched.user?.firstName && errors.user?.firstName && (
                          <FormHelperText error id="firstName-helper">
                            {errors.user.firstName}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </SectionBox>
                </MainCard>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={8} xl={9}>
                <MainCard title="Authorization" divider={true}>
                  <SectionBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={!data.isEdit}
                              id="isActive"
                              name="user.isActive"
                              checked={values.user.isActive}
                              onChange={handleChange}
                            />
                          }
                          label="Active"
                        />
                      </Grid>
                    </Grid>
                  </SectionBox>
                  <SectionBox title="Role" divider={true}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={6} xl={4}>
                        <InputLabel htmlFor="user.role">Role *</InputLabel>
                        <Select
                          disabled={!data.isEdit}
                          size="small"
                          fullWidth
                          displayEmpty
                          name="user.role"
                          onChange={(e) => {
                            handleChange(e);

                            var dealer = data.formFields!.dealers.length == 1 ? data.formFields!.dealers[0] : null;
                            var location = dealer?.locations?.length == 1 ? dealer.locations.map((x) => x.id) : null;

                            var defaultpermissions = getDefaultPermissions(e.target.value, data.formFields!, dealer?.id ?? null);

                            setFieldValue("user.permissions", defaultpermissions);
                            setFieldValue("user.dealerId", dealer?.id);
                            setFieldValue("user.dealerLocations", location);

                          }}
                          value={values.user.role}
                        >
                          <MenuItem disabled value="">
                            <em>Role</em>
                          </MenuItem>

                          {data.formFields?.roles.map((role) => (
                            <MenuItem key={role.id} value={role.name}>
                              {role.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.user?.role && errors.user?.role && (
                          <FormHelperText error id="role-helper">
                            {errors.user.role}
                          </FormHelperText>
                        )}
                      </Grid>
                      {values.user.role == Roles.DEALER && (
                        <>
                          <Grid item xs={12} md={12} lg={6} xl={4}>
                            <InputLabel htmlFor="user.dealerId">
                              Dealer *
                            </InputLabel>
                            <Autocomplete
                              disabled={!data.isEdit}
                              disablePortal
                              size="small"
                              fullWidth
                              id="user.dealerId"
                              options={data.formFields!.dealers}
                              value={
                                data.formFields!.dealers.find(
                                  (x) => x.id === values.user.dealerId
                                ) ?? (
                                  data.formFields!.dealers.length === 1 ? data.formFields!.dealers[0] : null
                                )
                              }
                              getOptionLabel={(option: IDealer) => option.name}
                              onChange={(
                                event: any,
                                newValue: IDealer | null
                              ) => {

                                setFieldValue("user.dealerId", newValue?.id);

                                var locations = data.formFields?.dealers.find(
                                  (d) => newValue?.id === d.id
                                )?.locations;

                                //If there is only one location then assign the location to dealer by default.
                                if (!!locations && locations.length === 1) {
                                  setFieldValue(
                                    "user.dealerLocations",
                                    locations.map((x) => x.id)
                                  );
                                } else {
                                  setFieldValue("user.dealerLocations", null);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField {...params} name="user.dealerId" />
                              )}
                            />
                            {touched.user?.dealerId && errors.user?.dealerId && (
                              <FormHelperText error id="dealerId-helper">
                                {errors.user.dealerId}
                              </FormHelperText>
                            )}
                          </Grid>

                          {!!values.user.dealerId && (
                            <Grid item xs={12} md={12} lg={6} xl={4}>
                              <InputLabel htmlFor="user.dealerLocations">
                                Dealer Locations *
                              </InputLabel>
                              <Autocomplete
                                //Key is set to dealerId in order to refresh this control if dealer choice changes.
                                key={values.user.dealerId}
                                disabled={!data.isEdit}
                                defaultValue={
                                  data.initialDealerId === values.user.dealerId
                                    ? data.defaultLocations
                                    : data.formFields?.dealers.find((d) => values.user.dealerId === d.id)?.locations?.length === 1 
                                      ?
                                      data.formFields?.dealers.find((d) => values.user.dealerId === d.id)?.locations
                                      :
                                      undefined
                                }
                                multiple
                                disablePortal
                                size="small"
                                fullWidth
                                id="user.dealerLocations"
                                disableCloseOnSelect
                                options={
                                  data.formFields!.dealers.find(
                                    (x) => x.id === values.user.dealerId
                                  )!.locations!
                                }
                                getOptionLabel={(option: IDealerLocation) =>
                                  option.name
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name="user.dealerLocations"
                                  ></TextField>
                                )}
                                onChange={(
                                  event: any,
                                  newValue: IDealerLocation[]
                                ) => {
                                  setFieldValue(
                                    "user.dealerLocations",
                                    newValue.map((x) => x.id)
                                  );
                                }}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.name}
                                  </li>
                                )}
                              />
                              {touched.user?.dealerLocations &&
                                errors.user?.dealerLocations && (
                                  <FormHelperText
                                    error
                                    id="dealerLocations-helper"
                                  >
                                    {errors.user.dealerLocations}
                                  </FormHelperText>
                                )}
                            </Grid>
                          )}
                        </>
                      )}
                      {values.user.role === Roles.SALESREP && (
                        <>
                          <Grid item xs={12} md={12} lg={6} xl={4}>
                            <InputLabel htmlFor="user.salesRepCode">
                              Sales Rep Code *
                            </InputLabel>
                            <TextField
                              disabled={!data.isEdit}
                              fullWidth
                              size="small"
                              id="salesRepCode"
                              {...getFieldProps("user.salesRepCode")}
                              placeholder="Sales Rep Code *"
                              error={Boolean(
                                touched.user?.salesRepCode && errors.user?.salesRepCode
                              )}
                            />
                            {touched.user?.salesRepCode && errors.user?.salesRepCode && (
                              <FormHelperText error id="salesrepcode-helper">
                                {errors.user.salesRepCode}
                              </FormHelperText>
                            )}
                          </Grid>

                          <Grid item xs={12} md={12} lg={6} xl={4}>
                            <InputLabel htmlFor="user.secondarySalesRepCode">
                              Secondary Sales Rep Code
                            </InputLabel>
                            <TextField
                              disabled={!data.isEdit}
                              fullWidth
                              size="small"
                              id="secondarySalesRepCode"
                              {...getFieldProps("user.secondarySalesRepCode")}
                              placeholder="Secondary Sales Rep Code"
                              error={Boolean(
                                touched.user?.secondarySalesRepCode && errors.user?.secondarySalesRepCode
                              )}
                            />
                            {touched.user?.secondarySalesRepCode && errors.user?.secondarySalesRepCode && (
                              <FormHelperText error id="secondarySalesRepCode-helper">
                                {errors.user.secondarySalesRepCode}
                              </FormHelperText>
                            )}
                          </Grid>
                        
                        </>
                      )}
                    </Grid>
                  </SectionBox>
                  {values.user.role !== "" && (
                    <SectionBox title="Permissions" divider={true}>
                      <Grid container spacing={2}>
                        {touched.user?.permissions && errors.user?.permissions && (
                          <Grid item xs={12}>
                            <FormHelperText error id="permission-helper">
                              {errors.user.permissions}
                            </FormHelperText>
                          </Grid>
                        )}

                        {data.formFields?.features.filter(x => isAvailableFeature(x, values.user.role, values.user.dealerId)).map((feature) => (
                          <Grid key={feature.id} item xs={12} md={6} lg={4}>
                            <MainCard subcard title={feature.name} divider={true}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  {feature.permissions.filter(x => isAvailablePermission(x, values.user.role, values.user.dealerId)).map((permission) => (
                                    <div key={permission.id}>
                                      <FormControl component="fieldset">
                                        <FormGroup aria-label="position">
                                          <FormControlLabel
                                            labelPlacement="end"
                                            label={permission.name}
                                            sx={{ ml: 1 }}
                                            control={
                                              <Checkbox
                                                disabled={!data.isEdit}
                                                value={permission.id}
                                                name="user.permissions"
                                                onChange={handleChange}
                                                checked={checkPermission(
                                                  permission.id,
                                                  values.user.permissions
                                                )}
                                              />
                                            }
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    </div>
                                  ))}
                                </Grid>
                              </Grid>
                            </MainCard>
                          </Grid>
                        ))}
                      </Grid>
                    </SectionBox>

                  )}

                </MainCard>
              </Grid>
            </Grid>
            {data.isEdit && canEdit && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 2.5 }}
                  >
                    <Button
                      variant="outlined"
                      disabled={isSubmitting}
                      onClick={handleClose}
                      color="secondary"
                    >
                      {" "}
                      Cancel{" "}
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserEdit;
