import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MenuItemProps, useMenuItems } from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import { changeNavType } from "../../../store/slices/Layout/LayoutSlice";
import { NavMode } from "../../../helpers/enums/Layout";
import { selectNavMode } from "../../../store/slices/Layout/LayoutSelector";
import { Icon, Menu, MenuItem } from "@mui/material";
import { ChevronRight } from '@mui/icons-material';

const NavbarItem = (item: MenuItemProps) => {
  const selectNav = useSelector(selectNavMode);
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [itemActive, setItemActive] = React.useState<boolean>(false);
  const [displaySubMenu, setDisplaySubMenu] = React.useState<boolean>(false);

  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(changeNavType(NavMode.COMPACT));
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {

    if(selectNav === NavMode.COMPACT) {
      setAnchorEl(event.currentTarget);
    }
    else {
      setDisplaySubMenu(!displaySubMenu);
    }
    
  };

  useEffect(() => {

    if(item.link === location.pathname) {
      setItemActive(true);
    }
    else if(item.isHeader === true && item.link === undefined && item.children !== undefined) {
      var exists = item.children.some(x => x.link === location.pathname);
      setItemActive(exists);
    }
    else if (itemActive === true) {
      setItemActive(false);
    }

    if (selectNav === NavMode.DEFAULT) {
      dispatch(changeNavType(NavMode.COMPACT));
    }
    
  }, [location.pathname])


  const handleClose = () => {
    setAnchorEl(null);
  };

  const _renderSubMenu = () => {
    return item?.children?.map((el: any, index: any) => (
      <MenuItem
        key={index}
        disableRipple
        disableGutters
        disableTouchRipple
      >
        <NavLink
          key={index}
          to={el?.link!}
          onClick={handleClick}
          className={({ isActive }) => isActive ? "menu-item active-nav-link" : " menu-item nav-link" }
          replace={true}
        >
          {selectNav !== NavMode.COMPACT && (
            <div>
              <span>{el.label}</span>
            </div>

          )}
          {selectNav === NavMode.COMPACT && el.label}
          
        </NavLink>
      </MenuItem>
    ));
  };



  return (
    <React.Fragment>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        className="sub-menu"
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {_renderSubMenu()}
      </Menu>

      {item.isHeader === true && item.link === undefined ? (

        <a href="#" className={itemActive ? "menu-item active-nav-link" : "menu-item nav-link"}>
          <div onClick={item.children ? handleClickMenu : undefined}>
            <img src={item.icon} className="nav-icon inactive" />
            <img src={item.activeIcon} className="nav-icon active" />
            <div className="label">
              <div>{item.label}</div>
              {selectNav !== NavMode.COMPACT && (
                <ChevronRight className={displaySubMenu ? "toggle down" : "toggle"} />
              )}
            </div>
          </div>
        </a>
      ) : (
        <NavLink
          to={item.link!}
          className={({ isActive }) => isActive ? "menu-item active-nav-link" : " menu-item nav-link"}
          replace={true}>
          <div onClick={item.children ? handleClickMenu : undefined}>
            <img src={item.icon} className="nav-icon inactive" />
            <img src={item.activeIcon} className="nav-icon active" />
            <div className="label">
              <div>{item.label}</div>
            </div>
          </div>
        </NavLink>
      )}
      


      {selectNav !== NavMode.COMPACT && (
        <div className="sub-menu default">
            {displaySubMenu ? _renderSubMenu() : <></>}
        </div>
      )}

    </React.Fragment>
  );
};

const NavbarContent = (props: any) => {
  const items = useMenuItems();
  return (
    <React.Fragment>
      {items.map((item, key) => (
        <NavbarItem key={key} {...item} />
      ))}
    </React.Fragment>
  );
};

export default NavbarContent;


