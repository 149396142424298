import { Grid, Stack, Button, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { apiStore } from "../../../api/ApiFactory";
import { IChoiceExtended } from "../../../models/Configuration";
import uploadChoiceImage from "../utils/uploadChoiceImage";
import defaultImg from "../../../_assets/images/Sea-Pro-default-placeholder.png";
import { MainMediaCard } from "../../../components/media/MainMediaCard";
const MediaChoiceImage = (props: any) => {
    
    type dataType = {
        data: IChoiceExtended
    }
    const [imgSrc, setImageSrc] = useState<string | null | undefined>();
    const { data }: dataType = props;

    useEffect(() => {
        if (data.mediaUrl) {
            setImageSrc(data?.mediaUrl);
        }
    }, [])

    //Function to upload file
    const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (!event.target.files) return;
        const reader = new FileReader();
        const selectedFile = event.target.files[0];
        reader.onload = () => {
            if (reader.readyState === 2) {
                const src: string = reader.result as string;

                setImageSrc(src);
            }
        }
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
        if (selectedFile && data) {
            uploadChoiceImage(selectedFile, data)
        }
    }

    //Function to rmove file
    const removeFile = () => {
        if (data) {
            const { id } = data;
            var promise = apiStore.boatConfigApi.removeChoiceImage(id);

            promise.then((response) => {
                setImageSrc(null);
            })
        }

    }

    return (
        <MainMediaCard 
            imageUrl={imgSrc} 
            onUpload={uploadFile}
            onDelete={removeFile}
        />
    )
}

export default MediaChoiceImage;