import React, { useEffect } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Button, FormHelperText, Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { IUserExtended } from "../../models/User";
import { useAppSelector } from "../../store/hooks/hook";
import { Formik, useFormik } from "formik";
import { apiStore } from "../../api/ApiFactory";
import * as Yup from "yup";

interface IProp {
    current: string;
    user: IUserExtended | undefined;
    handleChange(panel: string) : (event: React.SyntheticEvent, newExpanded: boolean) => any;
    onClose(): () => any;
}

const AppValidationSchema = Yup.object().shape({
    user: Yup.object({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required")
    })
})

const AppUserName = ({ current, handleChange, onClose, user }: IProp) => {

    const formik = useFormik({
        enableReinitialize: true,
        initialValues:  {
            user: user!,
            submit: null
        },
        onSubmit: (values, {setSubmitting, setErrors}) => {
            apiStore.userApi.updateName(values.user)
            .catch((error) => {
                console.log(error);
                if(error.response?.data) {
                    setErrors({ submit: error.response?.data[0].errorMessage })
                } else {
                    
                }
            })
            .finally(() => {
                setSubmitting(false);
            })
        }
    });

    useEffect(() => {
        formik.resetForm();
    }, [current]);

    return (

        <Accordion className="app-accordion" disableGutters elevation={0} square expanded={current === 'user-name-panel'} 
            TransitionProps={{ unmountOnExit: true }}
            onChange={handleChange('user-name-panel')}>

            <AccordionSummary className="app-accordion-summary" expandIcon={<ExpandMore/>}
                aria-controls="user-profile-name-content">
                <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography className="app-accordion-header">
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={10}>
                        <Typography className="app-accordion-label">
                            {user?.firstName} {user?.lastName}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className="app-accordion-detail">
                <form onSubmit={formik.handleSubmit}>

                    {formik.errors.submit && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormHelperText error id="submit-helper">
                                {formik.errors.submit}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    </>
                    )}

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <InputLabel htmlFor="firstname">
                            First Name *
                            </InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                id="firstname"
                                {...formik.getFieldProps("user.firstName")}
                                placeholder="First Name *"
                                error={Boolean(formik.touched.user?.firstName && formik.errors.user?.firstName )}
                            />
                            {formik.touched.user?.firstName && formik.errors.user?.firstName && (
                            <FormHelperText error id="firstname-helper">
                                {formik.errors.user?.firstName}
                            </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <InputLabel htmlFor="lastname">
                            Last Name *
                            </InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                id="lastName"
                                {...formik.getFieldProps("user.lastName")}
                                placeholder="Last Name *"
                                error={Boolean(formik.touched.user?.lastName && formik.errors.user?.lastName )}
                            />
                            {formik.touched.user?.lastName && formik.errors.user?.lastName && (
                            <FormHelperText error id="lastName-helper">
                                {formik.errors.user?.lastName}
                            </FormHelperText>
                            )}
                        </Grid>
                        
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={8} xl={6}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            sx={{ mt: 2.5 }}
                        >
                            <Button
                                variant="outlined"
                                disabled={formik.isSubmitting}
                                onClick={ onClose() }
                                color="secondary"
                            >
                                {" "} Cancel{" "}
                            </Button>
                            <Button
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Stack>
                        </Grid>
                    </Grid>
                </form>
            </AccordionDetails>

        </Accordion>
    );
};

export default AppUserName;