import { CardHeader, CardHeaderTypeMap, Divider, Grid, IconButton } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';

type ExtensionProps = {
    title?: string;
    divider?: boolean;
    subcard?: boolean;
    centerlabel?: boolean;
    className?: string;
    onDelete?:()=>void;
    onClose?:()=>void; 
}


export const SectionHeader: React.FC<Partial<CardHeaderTypeMap> & ExtensionProps> = (
    {
        title,
        divider,
        subcard,
        centerlabel,
        className,
        onDelete,
        onClose,
        ...rest
    }

) =>
{
    var _className = (className ?? "") + " " + (subcard && !centerlabel ? "sub-header" : "main-header")

    return (
        <>
            {/*Card Header*/}
            {title && (
                
                <CardHeader
                    className={ _className }
                    title={title}
                    titleTypographyProps={{variant: 'subtitle1', className: subcard ? "sub-header-label" : "card-header-label"}}
                    action= {onDelete && <IconButton onClick={onDelete}><DeleteOutlineIcon/></IconButton>
                             || onClose && <IconButton onClick={onClose}><CloseIcon/></IconButton>
                            }  
                    {...rest }
                    
                />
            )}
            {title && divider && <Divider className="header-divider" />}
        </>
    )
}