import { Formik } from "formik";
import React, { useEffect } from "react";
import { HeaderLabel } from "../../components/label/HeaderLabel";
import { MainCard } from "../../components/surface/MainCard";
import * as Yup from "yup";
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IUserFormValues } from "../../models/Auth";
import { apiStore } from "../../api/ApiFactory";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Confirmation = () => {
  return (
    <MainCard className="auth-card" elevation={4}>
      <Stack
        className="auth-container center-on-xs mb-3"
        justifyContent="center"
        alignItems="flex-start"
      >
        <img className="auth-logo" alt=""></img>
      </Stack>
      <HeaderLabel title="Confirmation" />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>Check your email for instructions.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            If you do not receive an email within a reasonable time please
            contact the customer service department at (803) 694-2644.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center" sx={{ color: "#888" }}>
            Go to <Link to="/auth/login">Login</Link>
          </Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Confirmation;
