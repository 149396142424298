import { createSlice } from "@reduxjs/toolkit"
import { Country, DesignationType } from "../../../models/Lookup"
import { AppThunk } from "../../store";
import { LookupExtended, NameValueEntity } from "../../../models/Common";
import { IDealer } from "../../../models/Dealer";

interface IProps  {
    countryList: Array<Country>,
    boatSeriesList: Array<LookupExtended>,
    yearList: Array<NameValueEntity>,
    revisionList: Array<string>,
    designationTypes: Array<DesignationType>
}

const initialState: IProps = {
    countryList: [],
    boatSeriesList: [],
    yearList: [],
    revisionList: [],
    designationTypes: []
}

const slice = createSlice({
    name: "lookup",
    initialState,
    reducers: {
        setCountryListAction: (state, action) => {
            state.countryList = action.payload;
        },
        setBoatSeriesListAction: (state, action) => {
            state.boatSeriesList = action.payload;
        },
        setYearListAction: (state, action) => {
            state.yearList = action.payload;
        },
        setRevisionListAction: (state, action) => {
            state.revisionList = action.payload;
        },
        setDesignationTypesAction: (state, action) => {
            state.designationTypes = action.payload;
        }

    }
});

export const { 
    setCountryListAction,
    setBoatSeriesListAction,
    setYearListAction,
    setRevisionListAction,
    setDesignationTypesAction 
} = slice.actions;

//Thunks
export const setCountryList = (value: Country[]): AppThunk => (dispatch, getState) => {
    dispatch(setCountryListAction(value));
}

export const setBoatSeriesList = (value: LookupExtended[]): AppThunk => (dispatch, getState) => {
    dispatch(setBoatSeriesListAction(value));
}

export const setYearList = (value: NameValueEntity[]): AppThunk => (dispatch, getState) => {
    dispatch(setYearListAction(value));
}

export const setRevisionList = (value: string[]): AppThunk => (dispatch, getState) => {
    dispatch(setRevisionListAction(value));
}

export const setDesignationTypes = (value: DesignationType[]): AppThunk => (dispatch, getState) => {
    dispatch(setDesignationTypesAction(value));
}

export default slice.reducer;