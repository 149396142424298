import React from "react";

const Unauthorized = () => {

    return (
        <div>
            Unauthorized
        </div>
    )
}

export default Unauthorized;