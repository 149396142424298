import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import {
  IOptions,
  SelectInterface,
  ISelectOption,
} from "../../models/BoatOrdering";
import BoatCardComponent from "../../features/boatOrdering/BoatCardComponent";
import UIWidget from "./UIWidget";
import { apiStore } from "../../api/ApiFactory";
import {
  getLookupsDetails,
  getMotor,
} from "../../store/slices/BoatOrdering/BoatOrderingSelector";
import { useSelector } from "react-redux";
import { calculatePriceByType, reactNumber } from "../../helpers/utils/utils";

interface IComponentProps {
  priceType: string;
  isView: boolean;
  currentOptions?: IOptions[];
  stepHeading: String;
  salePriceMarkupOption?: any;
  salePriceMarkupMotor?: any;
  msrp: number;
}

const IDealer: SelectInterface = {
  label: "Motor (Optional)",
  size: "small",
  name: "motor",
};

const StepComponent = ({
  priceType,
  currentOptions,
  stepHeading,
  isView,
  salePriceMarkupOption,
  salePriceMarkupMotor,
  msrp,
}: IComponentProps) => {
  const handleUpdateSelectedModal = (val: any, optionIdx: number): void => {
    apiStore.boatOrderingApi.updateSelectedModal({ val, optionIdx });
  };
  const lookups = useSelector(getLookupsDetails);
  const motor = useSelector(getMotor);
  const [moterOption, setMotorOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (lookups?.motorList) {
      let list: any[] = [{ key: "", value: "Select" }];
      lookups?.motorList?.forEach((item: any) => {
        const price: any = calculatePriceByType(
          item.price,
          priceType,
          priceType == "msrp" ? msrp : salePriceMarkupMotor
        );
        const name = `${item.name} ($${reactNumber(price)})`;
        list.push({ key: item?.partNumber, value: name });
      });
      setMotorOptions(list);
    }
  }, [lookups, priceType]);

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      className="step-active-wrapper"
    >
      <Grid
        item
        lg={6}
        md={12}
        xs={12}
        className="card-component-sm"
      >
        <BoatCardComponent />
      </Grid>
      <Grid item lg={5} md={12} xs={12} sx={{ mb: 2 }}>
        <Box className="checkbox-list">
          <div className="list-head">
            <h4>{stepHeading}</h4>
          </div>
          <div className="option-container">
            {currentOptions &&
              Object.values(currentOptions)?.map(
                (option: any, index: number) => {
                  var choicesVisible = option?.choices.filter(
                    (c: any) => c?.isVisible
                  );
                  var className = choicesVisible.length
                    ? "checkbox-list"
                    : "checkbox-list-hide";

                  return (
                    <Box className={className} key={index}>
                      <ul>
                        <Typography
                          variant="body1"
                          sx={{ my: 1, px: 1, color: "#000" }}
                          className="furnishingControlLabel"
                        >
                          {option?.name}
                        </Typography>
                        <Divider />
                        <UIWidget
                          salePriceMarkupOption={salePriceMarkupOption}
                          priceType={priceType}
                          isView={isView}
                          checkBoxData={option?.choices}
                          onChange={(id, choice) =>
                            handleUpdateSelectedModal(id, index)
                          }
                          uiWidget={option?.uiWidget}
                          optionName={option?.name}
                          msrp={msrp}
                        />
                      </ul>
                    </Box>
                  );
                }
              )}
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};
export default StepComponent;
