import React, { useEffect } from "react";
import { PropsWithChildren, useMemo } from "react";
import { apiStore } from "../../api/ApiFactory";
import { RowsPerPageOptions } from "../../helpers/constants/TableConfig";
import { ITableParams, ITableResult } from "../../models/TableModel";
import { IUser } from "../../models/User";
import { makeData, UserData } from "../../tests/makeData";
import { ReactTable } from "./ReactTable";
import { TableProps } from "./TableProps";

export function Table<T extends Record<string, unknown>>(
  props: PropsWithChildren<TableProps<T>>
) {
    const {
        columns,
        name,
        apiGet,
        serverOnChange,
        onAdd,
        onEdit,
        onDelete,
        extraCommands,
        onPrint,
        rowsPerPage,
        className,
        inlineLock,
        inlineLockState,
        onInlineEdit,
  } = props;

    const setPageSize = () => {

        let value = RowsPerPageOptions[0];
        if(rowsPerPage) {
            if(RowsPerPageOptions.filter((x => x == rowsPerPage)).length > 0) {
                value = rowsPerPage;
            }
        }

        return value;
    }


    const [data, setPageData] = React.useState({
        rows: [] as any,
        isLoading: true,
        pageCount: 0
    });

    const [pageDef, setPageDef] = React.useState({
        currentPage: 0,
        pageSize: setPageSize()
    });

    useEffect(() => {

        //this useEffect will trigger everytime the table page changes. We only want this block of code to trigger
        //if serverOnChange is set to true or data.isLoading is set to true. 
        //So, if serverOnChange is false we only want to trigger the api call once.
        if(serverOnChange || data.isLoading) {

            setPageData((prevState) => ({
                ...prevState,
                rows: [] as any,
                isLoading: true,
            }));
    
            let values: ITableParams = 
            { 
                pageSize: pageDef.pageSize, 
                page: pageDef.currentPage 
            }
    
            const startRow = pageDef.pageSize * pageDef.currentPage;
            const endRow = startRow + pageDef.pageSize;
    
            apiStore.tableApi.getResults<ITableResult<T>>(apiGet, values).then(
                (response) => {
    
                    var pageCount = Math.ceil(response.rowCount / pageDef.pageSize);
    
                    setPageData({
                        rows: response.data,
                        isLoading: false,
                        pageCount: pageCount
                    });
                }
            );
        }

    }, [pageDef]);

    const findRecordById = (rows: any[], id: string | number) => {
        return rows.find(row => row.id === id);
    };

    const handleInlineEdit = async (rowId: string | number, columnId: string, newValue: any) => {
        if (onInlineEdit) {
            
            const record = findRecordById(data.rows, rowId);
            const result = await onInlineEdit(rowId, columnId, newValue, record);

            if (result) {

                setPageData((prevState) => ({
                    ...prevState,
                    rows: prevState.rows.map((row : any) =>
                        row.id === rowId ? { ...row, [columnId]: newValue } : row
                    ),
                }));
            }
        }
    };

    //Support for server operations on changes like pagination, sorting, and filtering has not been completed.
    //Calling component should set serverOnChange to false until this feature is complete.
    const manualOp: boolean = serverOnChange === undefined ? true : serverOnChange;
    const lockStatus: boolean = inlineLockState === undefined ? true : inlineLockState;

    return (
        <ReactTable<T>
            name={name}
      		className={className}
            data={data.rows}
            columns={columns}
            manualPagination={manualOp}
            isLoading={data.isLoading}
            pageCount={manualOp ? data.pageCount : undefined}
            pageChangeHandler={setPageDef}
            initialState={{ pageIndex: pageDef.currentPage, pageSize: pageDef.pageSize }}
            onAdd={onAdd}
            onDelete={onDelete}
            onEdit={onEdit}
            inlineLock={inlineLock}
            inlineLockState={lockStatus}
            onInlineEdit={handleInlineEdit }
            extraCommands={extraCommands}
            onPrint={onPrint}
        />
    )
}