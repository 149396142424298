import {useState} from "react";
import {  Typography, TypographyProps } from "@mui/material";


type SmartTextProps = TypographyProps & {
    char:number,
    text:string,
}

const SmartText = ({text, char ,...props}:SmartTextProps) => {
const [isReadMore, setIsReadMore] = useState<boolean>(true);
const toggleReadMore = ()=>{
    setIsReadMore(!isReadMore);
}
  return (
    <>
    <Typography component="span" {...props}>{isReadMore ? text.slice(0,char) :text}</Typography>
    <Typography component="span" 
     sx={{cursor:"pointer"}}
     color="primary"  
     onClick={toggleReadMore}>{isReadMore ? " ...read more" : " show less"}</Typography>
    </>
  )
}

export default SmartText