import React from "react";
import { Route, Routes } from "react-router-dom";
import AppLayout from "../features/layout/AppLayout";
import AuthLayout from "../features/layout/AuthLayout";
import { RouteProps } from "../models/Route";
import { useAuth } from "../store/hooks/useAuth";
import AuthRequired from "./AuthRequired";
import { protectedRoutes, authRoutes, leadRoutes } from "./Routes";
import LeadsLayout from "../features/layout/LeadsLayout";

const AuthMiddleware = () => {

    const apploaded = useAuth().apploaded;
    if(apploaded)
    {
      return (
        <Routes>
            <Route element={<AuthRequired><AppLayout/></AuthRequired>}>
              {protectedRoutes.map((route, idx) => (

                <Route 
                  key={idx}
                  path={route.path}
                  element= { route.element } 
                >
                  {
                    ChildrenRoutes(route.children)
                  }

                </Route>
              ))}
            </Route>
            <Route element={<AuthLayout/>}>
              {authRoutes.map((route, idx) => (
                
                <Route 
                  key={idx}
                  path={route.path}
                  element= { route.element } 
                >
                  {
                    ChildrenRoutes(route.children)
                  }
                </Route>

              ))}
            </Route>
            <Route element={<LeadsLayout/>}>

                {leadRoutes.map((route, idx) => (
                  <Route 
                    key={idx}
                    path={route.path}
                    element= { route.element } 
                  >
                    {
                      ChildrenRoutes(route.children)
                    }
                </Route>
            ))}
            </Route>
        </Routes>
      );
    }
    else {
      return (<div></div>);
    }
    
}

const ChildrenRoutes = (children: RouteProps[] | undefined) => {

  return (
      
      children?.map((route, idx) => (
      <Route
        index = { route.index }
        key={ idx }
        path={ route.path }
        element = { route.element }
      >
        {
          ChildrenRoutes(route.children)
        }
      </Route>

    ))
  )
}

export default AuthMiddleware;