import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ConnectableObservable } from "rxjs";
import { apiStore } from "../../../api/ApiFactory";
import SubmitButtonGroup from "../../../components/button/SubmitButtonGroup";
import { StackLabel } from "../../../components/label/StackLabel";
import { MainMediaCard } from "../../../components/media/MainMediaCard";
import { MainCard } from "../../../components/surface/MainCard";
import { SectionBox } from "../../../components/surface/SectionBox";
import { IChoiceExtended, IChoice } from "../../../models/Configuration";
import { formatDate } from "../../../helpers/utils/formatDate";
import LayeredMedia from "./LayeredMedia/LayeredMedia";
import MediaChoiceImage from "./MediaChoiceImage";

const ManageChoices = () => {

    const location = useLocation();
    
    const [searchParams, setSearchParams] = useSearchParams();
    const choiceId = searchParams.get('id');

    const [choice, setChoice] = useState<IChoiceExtended>();

    useEffect(() => {
        
        if(choiceId) {
            const promise = apiStore.boatConfigApi.getChoiceDetails(choiceId);

            promise.then((response) => {
                
                setChoice(response);
            })
            .catch((error) => {
    
            });
        }
    }, [choiceId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            record: ( choice as IChoice),
            submit: null
        },
        onSubmit: (values, { setSubmitting, setErrors }) => {
            var result = apiStore.boatConfigApi.updateChoice(values.record);

            setSubmitting(true);
            result.then((response) => {
                
            })
            .catch((error) => {
                if(error.response?.data) {
                    setErrors({ submit: error.response?.data[0].errorMessage })
                } else {
                    setErrors({submit: "Uknown server error."})
                }
            })
            .finally(() => {
                setSubmitting(false);
            })
        },
    });

    if(formik.values.record === undefined) {
        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className="page-header-label">
                        {"Option Choice"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                    {formik.errors.submit && (
                        <Grid item xs={12}>
                            <FormHelperText sx={{position:"absolute"}} error id="submit-helper">{formik.errors.submit}</FormHelperText>
                        </Grid>
                    )}
                    <form onSubmit={formik.handleSubmit}>
                        <MainCard title="GENERAL INFORMATION">
                            <SectionBox>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <MainCard subcard title="Details">
                                            <SectionBox>
                                                <StackLabel title='Series' label={choice?.seriesName ?? ""} />
                                                <StackLabel title='Model' label={choice?.modelName ?? ""} />
                                                <StackLabel title='Model Year' label={choice?.year ?? ""} />
                                                <StackLabel title='Section' label={choice?.sectionName ?? ""} />
                                                <StackLabel title='Option' label={choice?.optionName ?? ""} />
                                                <StackLabel title='Choice' label={choice?.displayName ?? ""} />
                                                <StackLabel title='Import Key' label={choice?.importKey ?? ""} />
                                                <StackLabel title='Import Value' label={choice?.importValue ?? "N/A"} />
                                                <StackLabel title='Price' label={choice?.price !== null && choice?.price !== undefined ? (choice?.price as string) : ""} currency />
                                                <StackLabel title='Adjusted Price' label={choice?.adjustedPrice?.toString()} currency />
                                            </SectionBox>
                                        </MainCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="displayName">Display Name *</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="displayName"
                                            {...formik.getFieldProps("record.displayName")}
                                            placeholder="Display Name *"
                                            error={Boolean(formik.touched.record?.displayName && formik.errors.record?.displayName )}
                                        />
                                        {formik.touched.record?.displayName && formik.errors.record?.displayName && (
                                            <FormHelperText error id="displayName-helper">
                                                {formik.errors.record?.displayName}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="order">Sort Order</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="order"
                                            {...formik.getFieldProps("record.order")}
                                            placeholder="Sort Order"
                                            error={Boolean(formik.touched.record?.order && formik.errors.record?.order )}
                                        />
                                        {formik.touched.record?.order && formik.errors.record?.order && (
                                            <FormHelperText error id="order-helper">
                                                {formik.errors.record?.order}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="description">Description *</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="description"
                                            {...formik.getFieldProps("record.description")}
                                            placeholder="Description *"
                                            error={Boolean(formik.touched.record?.description && formik.errors.record?.description )}
                                        />
                                        {formik.touched.record?.description && formik.errors.record?.description && (
                                            <FormHelperText error id="description-helper">
                                                {formik.errors.record?.description}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="hexCode">Hex Code</InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            id="hexcode"
                                            {...formik.getFieldProps("record.hexcode")}
                                            placeholder="Hex Code"
                                            error={Boolean(formik.touched.record?.hexcode && formik.errors.record?.hexcode )}
                                        />
                                        {formik.touched.record?.hexcode && formik.errors.record?.hexcode && (
                                            <FormHelperText error id="description-helper">
                                                {formik.errors.record?.hexcode}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" >
                                                <FormControlLabel
                                                    labelPlacement="end"
                                                    label="Pre Selected"
                                                    onChange={formik.handleChange}
                                                    name="record.preSelected"
                                                    value={formik.values.record.preSelected}
                                                    control={<Checkbox value={formik.values.record.preSelected} checked={formik.values.record.preSelected}/>}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" >
                                                <FormControlLabel
                                                    labelPlacement="end"
                                                    label="Is Active"
                                                    onChange={formik.handleChange}
                                                    name="record.isActive"
                                                    value={formik.values.record.isActive}
                                                    control={<Checkbox checked={formik.values.record.isActive}/> }
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <SubmitButtonGroup disabled={formik.isSubmitting} />
                                {choice?.modifiedDate && (
                                    <Typography sx={{fontSize:"9px"}}>Last Updated : {formatDate(choice?.modifiedDate)}</Typography>
                                )}
                            </SectionBox>
                        </MainCard>
                    </form>
                </Grid>
                <Grid item xs={12} lg={8}>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <MainCard title="MEDIA" divider={true}>
                                <SectionBox>
                                    <MediaChoiceImage data={choice} />
                                </SectionBox>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12}>
                            <MainCard title="CHOICE - LAYERED MEDIA" divider={true}>
                                <SectionBox>
                                    <LayeredMedia sidebyside perspectives={choice!.layeredMedia} />
                                </SectionBox>
                            </MainCard>
                        </Grid>

                    </Grid>

                </Grid>

            </Grid>
        </>
    )

}

export default ManageChoices;