import React from 'react'
import { Typography, Select, MenuItem, Grid, InputLabel, FormControl, TextField, FormControlLabel, Checkbox,Box, Switch, Button, Stack, FormHelperText } from "@mui/material"
import { Formik } from 'formik';
import { apiStore } from '../../../api/ApiFactory';
import { IChoice, IOption } from '../../../models/Configuration';
import { useNotification } from '../../../store/context/NotificationContext';
import * as Yup from "yup";


type configureChoiceProps = {
  options: IOption[],
  selectedModelList: []
}


const ConfigureChoice = ({ options, selectedModelList }: configureChoiceProps) => {

  const { Toast } = useNotification();

  const initialValues = {
    option: {} as IOption,
    choiceList: [],
    choiceId: "",
    name: "",
    description: "",
    sortOrder: "",
    hexcode: "",
    preSelected: false,
    isActive: false,
    models: selectedModelList,
    nameChk: false,
    descriptionChk: false,
    sortOrderChk: false,
    hexcodeChk: false,
    preSelectedChk: false,
    isActiveChk: false,
  }


  const validationSchema = Yup.object({
    option: Yup.object().shape({
      id: Yup.number().required("Please select an option")
    }),
    choiceId: Yup.string()
      .required("Please select a choice")
  })



  const handleFormSubmit = async (values: any) => {
    const { choiceId, name, description, hexcode, sortOrder, preSelected, preSelectedChk, isActive, isActiveChk, models } = values;
    const formData = {
      choiceId,
      name: name.length > 0 ? name : null,
      description: description.length > 0 ? description : null,
      hexcode: hexcode.length > 0 ? hexcode : null,
      sortOrder: sortOrder.length > 0 ? sortOrder : null,
      preSelected: preSelectedChk ? preSelected : null,
      isActive: isActiveChk ? isActive : null,
      models
    }
    console.log(formData);
    try {
      const response = await apiStore.boatConfigApi.saveMasterConfigchoices(formData);
      Toast.success({ title: "Saved Successfully" })
    }
    catch (error) {
      Toast.error({ title: "Error Occured" })
    }
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any) => {
          handleFormSubmit(values)
        }}
      >
        {
          ({
            handleChange,
            handleSubmit,
            resetForm,
            values,
            setFieldValue,
            touched,
            errors
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4} mb={4}>
                <Grid item md={6} xs={12}>
                  <Typography>Select Option</Typography>
                  <Select
                    fullWidth
                    size="small"
                    id="series"
                    name="option"
                    value={values?.option}
                    error={Boolean(
                      touched?.option?.id && errors?.option?.id
                    )}
                    onChange={(event: any) => {
                      resetForm();
                      handleChange(event)
                      console.log(values?.option)
                      const { choices } = event.target.value;
                      setFieldValue("choiceList", choices);

                    }}
                  >
                    <MenuItem disabled><em>Select Option</em></MenuItem>
                    {
                      options?.length > 0 && options.map((option: any) => <MenuItem key={option.id} value={option}>{option?.displayName}</MenuItem>)
                    }
                  </Select>
                  {touched?.option && errors?.option?.id && (
                    <FormHelperText error id="option-helper">
                      {errors?.option?.id}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography>Select Choice</Typography>
                  <Select
                    fullWidth
                    size="small"
                    id="series"
                    name="choiceId"
                    value={values?.choiceId}
                    onChange={handleChange}
                    disabled={!values?.option?.id}
                    error={Boolean(
                      touched?.choiceId && errors?.choiceId
                    )}
                  >
                    <MenuItem disabled><em>Select Choice</em></MenuItem>
                    {
                      values?.choiceList?.length > 0 && values?.choiceList.map((choice: IChoice) => <MenuItem key={choice?.id} value={choice?.id}>{choice?.description} - ({choice?.importKey})</MenuItem>)
                    }
                  </Select>
                  {touched?.choiceId && errors?.choiceId && (
                    <FormHelperText error id="choice-helper">
                      {errors?.choiceId}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Typography mb={2}>Select Fields To Update</Typography>
              <Grid container mb={2} spacing={2}>
                <Grid item md={6} xs={12} >
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("name", "")
                    }}
                    name="nameChk"
                    control={<Switch checked={values?.nameChk ? true : false} />}
                    label={`Display Name`} />
                  {
                    values?.nameChk && (<TextField
                      onChange={handleChange}
                      disabled={!values?.nameChk}
                      name="name"
                      fullWidth
                      size="small"
                      id="name"
                      placeholder="Display Name *"
                      value={values?.name}
                    />)
                  }
                </Grid>
                <Grid item md={6} xs={12} >
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("description", "")
                    }}
                    name="descriptionChk"
                    control={<Switch checked={values?.descriptionChk ? true : false} />}
                    label={`Description`} />

                  {
                    values?.descriptionChk && (
                      <TextField
                        disabled={!values?.descriptionChk}
                        fullWidth
                        name="description"
                        size="small"
                        id="username"
                        placeholder="Description *"
                        value={values?.description}
                        onChange={handleChange}
                      />
                    )
                  }
                </Grid>

                <Grid item md={6} xs={12} >
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("hexcode", "")
                    }}
                    name="hexcodeChk"
                    control={<Switch checked={values?.hexcodeChk ? true : false} />}
                    label={`Hexcode`} />

                  {
                    values?.hexcodeChk && (
                      <TextField
                        disabled={!values?.hexcodeChk}
                        name="hexcode"
                        fullWidth
                        size="small"
                        id="hexcode"
                        placeholder="Hexcode "
                        value={values?.hexcode}
                        onChange={handleChange}
                      />
                    )
                  }
                </Grid>
                <Grid item md={6} xs={12} >
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("sortOrder", "")
                    }}
                    name="sortOrderChk"
                    control={<Switch checked={values?.sortOrderChk ? true : false} />}
                    label={`Sort Order`} />

                  {
                    values?.sortOrderChk && (
                      <TextField
                        disabled={!values?.sortOrderChk}
                        name="sortOrder"
                        fullWidth
                        size="small"
                        id="sortOrder"
                        placeholder="Sort Order *"
                        value={values?.sortOrder}
                        onChange={handleChange}
                      />
                    )
                  }
                </Grid>
                <Grid item md={6} xs={12} >
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("preSelected", false)
                    }}
                    name="preSelectedChk"
                    control={<Switch checked={values?.preSelectedChk ? true : false} />}
                    label={`Preselected`} />
                  {
                    values?.preSelectedChk && (
                     <Box>
                       <FormControl component="fieldset">
                        <FormControlLabel
                          disabled={!values?.preSelectedChk}
                          labelPlacement="end"
                          label="preSelected"
                          name="preSelected"
                          id="preSelected"
                          onChange={handleChange}
                          control={
                            <Checkbox
                              checked={values?.preSelected ? true : false}
                            />
                          }
                        />
                      </FormControl>
                     </Box>
                    )
                  }
                </Grid>
                <Grid item md={6} xs={12} >
                  <FormControlLabel
                    onChange={(event: any) => {
                      handleChange(event);
                      setFieldValue("isActive", false)
                    }}
                    name="isActiveChk"
                    control={<Switch checked={values?.isActiveChk ? true : false} />}
                    label={`isActive`} />

                  {
                    values?.isActiveChk && (
                      <Box>
                        <FormControl component="fieldset">
                        <FormControlLabel
                          disabled={!values?.isActiveChk}
                          labelPlacement="end"
                          label="isActive"
                          control={
                            <Checkbox
                              checked={values?.isActive ? true : false}
                              onChange={handleChange}
                              name="isActive"
                            />
                          }
                        />
                      </FormControl>
                      </Box>
                    )
                  }
                </Grid>
              </Grid>
              <Stack alignItems="end">
                <Button type="submit" variant="contained">Save Changes</Button>
              </Stack>
            </form>
          )
        }
      </Formik>
    </>
  )
}
export default ConfigureChoice