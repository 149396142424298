import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store }  from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setupApiFactory } from './api/ApiFactory';
import { disableDevtools, setupAuthInitialState } from './services/SetupService';

setupApiFactory(store);
setupAuthInitialState();
disableDevtools();

ReactDOM.render(
    <Provider store={ store }>
      <BrowserRouter basename='/'>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')

);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
