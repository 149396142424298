import { Button, FormControlLabel, FormHelperText, Grid, InputLabel, Stack, Switch, TextField } from '@mui/material'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainCard } from '../../../components/surface/MainCard'
import { SectionBox } from '../../../components/surface/SectionBox'
import * as Yup from "yup";
import { FinanceTermLookup, Lookup } from '../../../models/Configuration'
import { apiStore } from '../../../api/ApiFactory'
import { useNotification } from "../../../store/context/NotificationContext";
import { useAppSelector } from '../../../store/hooks/hook'
import SelectDropdown from '../../../components/select/SelectDropdown'
import { ISelectOption, SelectInterface } from '../../../models/BoatOrdering'


const IDesignationType: SelectInterface = {
  label: "Designation",
  size: "small",
  name: "designationTypeValue"
}

const EditFinanceTerms = () => {

  const { Toast } = useNotification();
  const location = useLocation();
  const state: any = location.state;
  const { option } = state;
  const data: FinanceTermLookup = { ...option.data }
  const navigate = useNavigate();

  const [designationTypeList, setDesignationTypeList] = useState<Array<ISelectOption>>();

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(120, "Name is to long")
      .required("Name is required"),
    displayName: Yup.string()
      .max(120, "Name is to long"),
    description: Yup.string()
      .max(120, "Name is to long"),
    isActive: Yup.string()
      .max(120, "Name is to long")
      .required("Name is required"),
  });

  useEffect(() => {
    apiStore.lookupApi.getDesignationType()
    .then((result) => {

      let data = result.map(v => { return {value: v.name, key: v.value}}) as ISelectOption[];

      setDesignationTypeList(data);

    });
  }, [])

  console.log(data);

  const initalValues: FinanceTermLookup = {
    id: data?.id ? data.id : null,
    name: data?.name ? data.name : "",
    description: data.description ? data?.description : "",
    displayName: data?.displayName ? data?.displayName : "",
    isActive: data?.isActive,
    designationTypeValue: data.designationTypeValue,
    designationTypeName: data.designationTypeName
  }

  const saveLookupOption = async (formData: FinanceTermLookup) => {
    let title="Record updated successfully.";
    try {

        await apiStore.boatConfigApi.saveFinanceTerm(formData);
        navigate(-1);

      Toast.success({title})
    }catch(error)
    {
      Toast.error({
        title: "Error occured",
      });
    }
  }


  return (

    <Formik
      enableReinitialize
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={((values: FinanceTermLookup) => {
        saveLookupOption(values);
      })}
    >
      {
        (
          { errors,
            handleChange,
            handleSubmit,
            getFieldProps,
            isSubmitting,
            setFieldValue,
            touched,
            values,
            status, }
        ) => {
          return <form onSubmit={handleSubmit}>
            <MainCard title={option.name} divider={true}>
              <SectionBox>
                <Grid container spacing={4} >
                  <Grid item md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="isActive"
                          name="isActive"
                          checked={values.isActive}
                          defaultChecked={values.isActive}
                          value={values.isActive}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                    />
                  </Grid>
                  <Grid item mb={3} md={6} xs={12}>
                    <InputLabel htmlFor="name">Name *</InputLabel>
                    <TextField
                      id="name"
                      size="small"
                      placeholder="Name  *"
                      fullWidth
                      {...getFieldProps("name")}
                      error={Boolean(
                        touched?.name && errors?.name
                      )}
                    />
                    {touched?.name && errors?.name && (
                      <FormHelperText error id="username-helper">
                        {errors?.name}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item mb={3} md={6} xs={12}>
                    <InputLabel htmlFor="name">Display Name *</InputLabel>
                    <TextField
                      id="displayName"
                      size="small"
                      placeholder="Display Name  *"
                      fullWidth
                      {...getFieldProps("displayName")}
                      error={Boolean(
                        touched?.displayName && errors?.displayName
                      )}
                    />
                    {touched?.displayName && errors?.displayName && (
                      <FormHelperText error id="username-helper">
                        {errors?.displayName}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item mb={3} md={6} xs={12}>
                    <InputLabel htmlFor="name">Description *</InputLabel>
                    <TextField
                      id="description"
                      size="small"
                      placeholder="Description *"
                      fullWidth
                      {...getFieldProps("description")}
                      error={Boolean(
                        touched?.description && errors?.description
                      )}
                    />
                    {touched?.description && errors?.description && (
                      <FormHelperText error id="username-helper">
                        {errors?.description}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>

                  </Grid>
                  <Grid item md={6} xs={12}>

                      <SelectDropdown
                        selectData={IDesignationType}
                        options={designationTypeList}
                        value={values.designationTypeValue}
                            handleChange={handleChange}
                            error={ errors?.designationTypeValue && touched?.designationTypeValue 
                              ? errors?.designationTypeValue: "" }
                      />

                      {touched?.designationTypeValue && errors?.designationTypeValue && (
                          <FormHelperText error>
                            {errors?.designationTypeValue}
                          </FormHelperText>
                      )}

                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                      sx={{ mt: 2.5 }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={()=>navigate(-1)}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </SectionBox>
            </MainCard>
          </form>
        }
      }
    </Formik>

  )
}

export default EditFinanceTerms