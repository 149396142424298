import Icon from "@mdi/react"
import { Button } from "@mui/material"
import { MouseEventHandler, ReactElement } from "react"
import { TableInstance } from "react-table"
import { TableMouseEventHandler } from "../../types/react-table-config"

interface TableActionButton<T extends Record<string, unknown>> {
    instance: TableInstance<T>
    icon?: string
    onClick: TableMouseEventHandler<T>
    enabled?: (instance: TableInstance<T>) => boolean
    label: string
    variant?: 'right' | 'left'
  }

export const TableLabelActionButton = <T extends Record<string, unknown>>({
    instance,
    icon,
    onClick,
    label,
    enabled = () => true,
  }: TableActionButton<T>): ReactElement => (
    <Button variant='outlined' color='primary' onClick={onClick(instance)} disabled={!enabled(instance)}>
      {icon && (
        <Icon path={icon} size="24px" />
      )}
      &nbsp;
      {label}
    </Button>
  )