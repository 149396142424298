import { Box, Button, Grid } from "@mui/material";
import React from "react";
import SeaproLogo from "../../../_assets/images/Sea-Pro-Logo-Header.png";
import PrintReportTable from "../Report/PrintReportTable";

const PrintReport = () => {
    const data = JSON.parse(localStorage.getItem("selectedReportData") ?? "[]");

    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            <style>
                {`
                    @media print {
                        body {
                            margin: 0; 
                            padding: 0; 
                        }
                        .print-container {
                            width: 100%; 
                            height: auto; 
                        }
                    }

                `}
            </style>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button
                        size="large"
                        variant="contained"
                        color="success"
                        onClick={handlePrint}
                    >
                        Print
                    </Button>
                </Grid>
            </Grid>
            <Box id="print" className="print-container">
                {data && (
                    <>
                        <Grid container justifyContent="space-between">
                            <Grid item md={6}>
                                <Grid>
                                    <img
                                        style={{ height: "40px" }}
                                        src={SeaproLogo}
                                        alt="seapro-logo"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid mt={2}>
                            <PrintReportTable reportData={data} />
                        </Grid>
                    </>
                )}
            </Box>
        </>
    );
};

export default PrintReport;
