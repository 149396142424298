import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { apiStore } from "../../../api/ApiFactory";
import Button from "../../../components/button/Button";
import { NavMode } from "../../../helpers/enums/Layout";
import { useAppSelector } from "../../../store/hooks/hook";
import { selectAuthUser } from "../../../store/slices/Auth/AuthSelector";
import { getLookupsDetails, getMotor, getSelectedModal } from "../../../store/slices/BoatOrdering/BoatOrderingSelector";
import SubmitDetails from "./SubmitDetails";
import SubmitLeadDetails from "./SubmitLeadDetails";


interface IProps {
    isView: boolean;
  }

const SubmitIndex = ({ isView }: IProps) => {

    const setCurrentStep = (id: number) => {
        apiStore.boatOrderingApi.setCurrentStep(id);
    };

    const 
    { 
        navMode,
        motor,
        selectedModel,
        auth
    } = useAppSelector((state) => ({
        navMode: state.reducer.Layout.navMode,
        motor: state.reducer.Boat.motor,
        selectedModel: state.reducer.Boat.selectedModal,
        auth: state.reducer.Auth.authuser
      }));

    if(navMode === NavMode.LEADS) {
         return <SubmitLeadDetails selectedModel={selectedModel} motor={motor} />
    }
    if(auth != null && auth.user != null) {
        return <SubmitDetails isView={isView} selectedModel={selectedModel} motor={motor} />
    }
    else {
        return <Navigate replace to="/unauthorized" />;
    }
}

export default SubmitIndex;