import { Grid, Box } from "@mui/material";
import { LayeredMediaEntity } from "../../models/Print";


type PerspectiveImageProps = {
    data:LayeredMediaEntity[]
}


const PerspectiveImage = ({ data }: PerspectiveImageProps) => {
    const topPerspective:LayeredMediaEntity[] = data.filter((item:LayeredMediaEntity)=>item.perspectiveName === "Top");
    const sidePerspective:LayeredMediaEntity[] = data.filter((item:LayeredMediaEntity)=>item.perspectiveName === "Side");

    return (
        data && (
            <>
            <Grid item xs={3}>
                {topPerspective && (
                    <Grid className="image-perspective-container">
                        <Grid className="layered-media-container">
                            { topPerspective.map((image:LayeredMediaEntity, index:number)=>
                                <Box key={index} className="image-wrapper right" >
                                    <img src={image?.mediaUrl}/>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                )}
                
            </Grid>
            <Grid item xs={3}>
                {sidePerspective && (
                    <Grid className="image-perspective-container">
                        <Grid className="layered-media-container">
                            { sidePerspective.map((image:LayeredMediaEntity, index:number)=>
                                <Box key={index} className="image-wrapper right" >
                                    <img src={image?.mediaUrl}/>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                )}
                
            </Grid>
            
            </>
        )
    )
}

export default PerspectiveImage