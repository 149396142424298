import { FormControlLabel, Select, MenuItem, Grid, InputLabel, Stack, Button, Switch, Typography } from '@mui/material'
import { Formik } from 'formik'
import React from 'react'
import { apiStore } from '../../../api/ApiFactory'
import { useNotification } from "../../../store/context/NotificationContext";

type modelConfigType = {
  selectedModelList: []
}
const ModelConfig = ({ selectedModelList }: modelConfigType) => {
  const { Toast } = useNotification();
  const initalValues = {
    availableForAdmin: "null",
    availableForDealer: "null",
    availableForConsumer: "null",
    models: selectedModelList,
    availableForAdminChk: false,
    availableForDealerChk: false,
    availableForConsumerChk: false,
  }
  const handleSubmit = async (values: any) => {
    const { availableForAdmin, availableForDealer, availableForConsumer, models } = values;
    const formData = {
      availableForAdmin: JSON.parse(availableForAdmin),
      availableForDealer: JSON.parse(availableForDealer),
      availableForConsumer: JSON.parse(availableForConsumer),
      models
    }
    console.log(formData);
    try {
      const response = await apiStore.boatConfigApi.saveMasterConfigModels(formData);
      Toast.success({ title: "Saved Successfully" })
    }
    catch (error) {
      Toast.success({ title: "Error occured" })
    }
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography mt={1} mb={2}> Select Fields To Update </Typography>
      </Grid>
      <Grid item >
        <Formik
          enableReinitialize
          initialValues={initalValues}
          onSubmit={((values: any) => {
            handleSubmit(values)
          })}
        >
          {
            ({
              errors,
              handleChange,
              handleSubmit,
              getFieldProps,
              isSubmitting,
              setFieldValue,
              touched,
              values,
              status,
            }
            ) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FormControlLabel
                      onChange={(event: any) => {
                        handleChange(event);
                        setFieldValue("availableForAdmin", "null")
                      }}
                      control={<Switch />}
                      label={`Admin`}
                      name="availableForAdminChk"
                    />

                    {
                      values?.availableForAdminChk && (
                        <Select
                          disabled={!values?.availableForAdminChk}
                          size="small"
                          fullWidth
                          displayEmpty
                          id="availableForAdmin"
                          name="availableForAdmin"
                          value={values.availableForAdmin}
                          onChange={handleChange}
                        >
                          <MenuItem disabled value="null">
                            <em>Select Visibility </em>
                          </MenuItem>
                          <MenuItem value="true">
                            Show
                          </MenuItem>
                          <MenuItem value="false">
                            hide
                          </MenuItem>

                        </Select>
                      )
                    }
                  </Grid>
                  <Grid item md={6}>
                    <FormControlLabel
                      onChange={(event: any) => {
                        handleChange(event);
                        setFieldValue("availableForDealer", "null")
                      }}
                      control={<Switch />}
                      label={`Dealer`}
                      name="availableForDealerChk"
                    />

                    {
                      values?.availableForDealerChk && (

                        <Select
                          disabled={!values?.availableForDealerChk}
                          size="small"
                          fullWidth
                          displayEmpty
                          id="availableForDealer"
                          name="availableForDealer"
                          value={values.availableForDealer}
                          onChange={handleChange}
                        >
                          <MenuItem disabled value="null">
                            <em>Select Visibility</em>
                          </MenuItem>
                          <MenuItem value="true">
                            Show
                          </MenuItem>
                          <MenuItem value="false">
                            hide
                          </MenuItem>
                        </Select>

                      )
                    }
                  </Grid>
                  <Grid item md={6}>
                    <FormControlLabel
                      onChange={(event: any) => {
                        handleChange(event);
                        setFieldValue("availableForConsumer", "null")
                      }}
                      control={<Switch />}
                      label={`Consumer`}
                      name="availableForConsumerChk"
                    />
                  
                  {
                    values?.availableForConsumerChk && (
                      <Select
                        disabled={!values?.availableForConsumerChk}
                        size="small"
                        fullWidth
                        displayEmpty
                        id="availableForConsumer"
                        name="availableForConsumer"
                        value={values.availableForConsumer}
                        onChange={handleChange}
                      >
                        <MenuItem disabled value="null">
                          <em>Select Visibility </em>
                        </MenuItem>
                        <MenuItem value="true">
                          Show
                        </MenuItem>
                        <MenuItem value="false">
                          hide
                        </MenuItem>
                      </Select>

                    )
                  }
                  </Grid>
                </Grid>
                <Stack alignItems="end">
                  <Button variant="contained" type="submit">Save Changes</Button>
                </Stack>
              </form>
            )
          }
        </Formik>
      </Grid>
    </Grid>
  )
}

export default ModelConfig