import { Button, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { apiStore } from '../../../api/ApiFactory';

const DeleteSliderImage = ({ series, imageIdToBeDeleted, updateSeriesHandle, closeDeleteModel }: any) => {


  const deleteImage = async () => {
    const response = await apiStore.boatConfigApi.deleteSeriesSliderImage(imageIdToBeDeleted);
    const updatedSliderMedia = [...series?.sliderMedia];
    const indexOfDeletedImage = updatedSliderMedia.findIndex((sliderImage) => sliderImage.id == response);
    updatedSliderMedia.splice(indexOfDeletedImage, 1);
    const updatedSeries = { ...series, sliderMedia: updatedSliderMedia };
    updateSeriesHandle(updatedSeries);
    closeDeleteModel();
  }
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" sx={{height:"100%"}}>
        <Typography variant="h5" textAlign="center">Do you wish to delete the image</Typography>
        <Stack direction="row" justifyContent="center" spacing={2} mt={2} alignItems="center">
          <Button variant="outlined" color="secondary" onClick={closeDeleteModel}>cancel</Button>
          <Button onClick={deleteImage} variant="contained">DELETE</Button>
        </Stack>
      </Grid>
    </>
  )
}

export default DeleteSliderImage