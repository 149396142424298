import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";

const initialState = {
    snackbarOpen: false,
    snackbarType: "success",
    snackbarMessage: ""
}

const slice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setSnackbar: (state, action) => {
            state.snackbarOpen = action.payload.snackbarOpen;
            state.snackbarType = action.payload.snackbarType;
            state.snackbarMessage = action.payload.snackbarMessage;
        }
    }
})


export const snackbar =
    (type = "success", message: string,): AppThunk => (dispatch, getState) => {
        dispatch(setSnackbar({ snackbarOpen: true, snackbarType: type, snackbarMessage: message }))
    }
export const { setSnackbar } = slice.actions;
export default slice.reducer;