import { apiStore } from "../api/ApiFactory";

export const setupAuthInitialState = () => {
    apiStore.authApi.refresh();
}

export const disableDevtools = () => {
  
    if (process.env.REACT_APP_ENV === "production") {
      const body = document.querySelector("body");
      body?.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      })
      document.onkeydown = function (e) {
        console.log({ code: e.code })
        if (e.code === "F12") {
          return false;
        }
        if(e.ctrlKey && e.shiftKey && e.code == 'KeyI') {
          return false;
       }
      }
    }
  }