import { Grid, Typography } from '@mui/material';
import { IOrderPrint, ItemsEntity, LayeredMediaEntity } from '../../../models/Print';

const WindowStickerOptionTable = ({ data }: { data: IOrderPrint }) => {

    let sections: string[] = [];
    let options: ItemsEntity[] = [];

    const createTableData = (data: IOrderPrint) => {
        const sectionData = getUniqueBy(data.items, "sectionName");
        sections = sectionData.filter(o => o !== "Model");
        options = data.items;
    }

    const topPerspective = data.layeredMedia?.filter((item:LayeredMediaEntity)=>item.perspectiveName === "Top");
    const sidePerspective = data.layeredMedia?.filter((item:LayeredMediaEntity)=>item.perspectiveName === "Side");

    const getUniqueBy = (arr: any[], prop:any) => {
        const set = new Set<string>;
        arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));

        return Array.from(set);
      };

    createTableData(data);

    return (
        <Grid>
            {data.specifications.length > 0 && (
                <Grid className="specifications">
                    <Typography className="window-sticker-header">Specifications</Typography>
                    {data.specifications.map((spec, index) => {

                        return ( 
                            <Typography className="line-item">{spec.name}:  <b>{spec.value}</b></Typography>
                        )
                    })}   
                </Grid>
            )}

            {data.standardEquipment && (
                <Grid className="standard-equipment">
                    <Typography className="window-sticker-header">Standard Equipment</Typography>
                    <Typography className="line-item">{data.standardEquipment}</Typography>
                </Grid>
            )}

            <Grid className='option-container'>

                {sections && sections.map((section: string, index: number) => {
                    let sectionOptions = options.filter(o => o.sectionName === section && o.price === 0);

                    if (sectionOptions.length === 0) {
                        return <></>;
                    }
                    
                    return (
                        <>
                            <Typography className="window-sticker-header" key={index}>{section}</Typography>
                            {sectionOptions && sectionOptions.map((option: ItemsEntity, optionIndex: number) => {

                                return (
                                    <>
                                        <Typography className="line-item" key={optionIndex}>
                                            - {option.optionName === option.sectionName ? option.name : option.optionName + ":" + option.name }
                                        </Typography>
                                        
                                    </>
                                )
                            })}
                        </>
                    )
                })}

            </Grid>
        
        </Grid>
    )

}

export default WindowStickerOptionTable