import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Card,
  CardMedia,
  CardActions,
  Grid,
  Stack,
} from "@mui/material";

import { useParams } from "react-router-dom";
import StepWizard from "../../components/stepWizard/StepWizard";
import { useSelector } from "react-redux";
import {
  getMotor,
  getModelList,
  getCurrentStep,
  getOrderDetails,
  getSelectedModal,
  getLookupsDetails,
  getSeriesList,
} from "../../store/slices/BoatOrdering/BoatOrderingSelector";
import { apiStore } from "../../api/ApiFactory";
import BoatSlider from "../../components/slider/Slider";
import { IOptions, IBreadCrumb } from "../../models/BoatOrdering";
import BoatOrderingSummary from "./BoatOrderingSummary";
import BoatOrderingBreadCrumb from "./BoatOrderingBreadCrumb";
import useScroll from "../../helpers/utils/useScroll";
import ConfirmationModal from "./ConfirmationModal";
import CurrencyFormat from "../../components/currencyFormat/CurrencyFormat";
import PageLoader from "../../components/loader/PageLoader";
import { enumOrderType, calculatePriceByType } from "../../helpers/utils/utils";
import { selectAuthUser } from "../../store/slices/Auth/AuthSelector";
import { Roles } from "../../helpers/enums/Common";
import SubmitIndex from "./SubmitForm/SubmitIndex";
import { useAppSelector } from "../../store/hooks/hook";
import { NavMode } from "../../helpers/enums/Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ISeries } from "../../models/Configuration";
import stockimage from "../../_assets/images/Sea-Pro-Logo-Header.png";
import { HasPermission } from "../../services/AuthService";
import { Permissions } from "../../helpers/enums/AuthConstants";
import { IAuthUser } from "../../models/Auth";
import SmartText from "../../components/smartText/SmartText";
import MainModal from "../../components/modal/MainModal";
import PrintModelModal from "../print/Model/PrintModelModal";
import PrintModel from "../print/Model/PrintModel";
import { getDealer } from "../../store/slices/Dealer/DealerSelector";
import CSVExportModel from "../print/Model/CSVExportModel";

const BoatOrderingModel = () => {
  const { navMode, msrp } = useAppSelector((state) => ({
    navMode: state.reducer.Layout.navMode,
    msrp: state.reducer.Boat.selectedSeriesMsrp
  }));

  const params = useParams();
  const motor = useSelector(getMotor);
  const details = useSelector(getOrderDetails);
  const modelList = useSelector(getModelList);
  const lookups = useSelector(getLookupsDetails);
  const seriesList = useSelector(getSeriesList);
  const [stepHeading, setStepHeading] = useState<String[]>([]);
  const [currentOptions, setCurrentOptions] = useState<IOptions[]>([]);
  const [openModal, setOpenModal] = useState<any>(false);
  const [breadCrumb, setBreadCrumb] = useState<IBreadCrumb>({
    price: 0,
    name: "",
    modelYear: "",
    priceType: navMode === NavMode.LEADS ? "msrp" : "dealerCost",
    msrp: 0.0,
  });
  const [loading, setLoading] = useState<Boolean>(true);
  const [isView, setIsView] = useState<boolean>(false);
  const [selectedSeriesInfo, setSelectedSeriesInfo] = useState<ISeries>();
  const selectedModal = useSelector(getSelectedModal);
  const currentStep = useSelector(getCurrentStep);
  const auth: IAuthUser | null = useSelector(selectAuthUser);
  const dealerDetails = useSelector(getDealer);

  const [open, setPrintModal] = useState(false);
  const [modelForPrint, setModelForPrint] = useState<any>(null);
  const [typeForPrint, setTypeForPrint] = useState<any>(null);
  const [printSheet, setPrintSheet] = useState<boolean>(false);
  const [exportSheet, setExportSheet] = useState<boolean>(false);

  const setSelectedModal = (id: number) => {
    apiStore.boatOrderingApi.setSelectedModal({ id: id, isView: isView });
  };

  // Changed default cost dropdown value if logged in user is dealer
  useEffect(() => {
    const hasPermission = HasPermission(auth?.user, Permissions.UseDealerCost,Roles.dealer);

    //It is possible to be logged in and on the leads page.
    if (navMode !== NavMode.LEADS && auth?.user?.role === Roles.dealer) {
      var newCostType = dealerDetails?.defaultCostType ?? "dealerCost";

      if (!hasPermission && newCostType === "dealerCost") {
        newCostType = "msrp";
      }

      setBreadCrumb({
        ...breadCrumb,
        priceType: newCostType,
      });
    }
  }, [dealerDetails]);

  useEffect(() => {
    apiStore.boatOrderingApi.resetBoatStates();
    if (
      window.location.pathname?.includes("/boat/order/view/") ||
      window.location.pathname?.includes("/boat/order/clone/")
    ) {
      apiStore.orderManagementApi
        .getOrder({ OrderNum: Number(params.id) })
        .then((response: any) => {

          if (seriesList.length > 0 && response.seriesId !== undefined) {
            const data = seriesList.find((series: any) => series.id == response.seriesId);
            setSelectedSeriesInfo(data);
          }

          let view =
            response.salesOrderStatus === "DO" ||
            response.salesOrderStatus === "Sched" ||
            response.salesOrderStatus === "Quote"
              ? false
              : true;
          if ((auth?.user?.role === Roles.salesRep && response.salesOrderStatus === "DO") || response.modelArchived === true) {
            view = true;
          }

          if (window.location.pathname?.includes("/boat/order/clone/")) {
            setIsView(false);
          } else {
            setIsView(view);
          }
          apiStore.boatOrderingApi.getLookups({
            isLeads: navMode === NavMode.LEADS,
          });
          handleGetModelList(response.seriesId, response.modelId);
        })
        .catch((error) => {
          console.log("order details error", error);
        });
    } else {
      apiStore.boatOrderingApi.getLookups({
        isLeads: navMode === NavMode.LEADS,
      });
      handleGetModelList(params?.id, null);
    }
  }, []);

  const handleGetModelList = (seriesId: any, modelId: any) => {
    apiStore.boatOrderingApi
      .getModelList({
        boatSeriesId: seriesId,
        isConsumer: navMode === NavMode.LEADS,
        modelId: modelId
      })
      .then((listRes: any) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log("Model list error", err);
      });

    if (navMode !== NavMode.LEADS && auth?.user?.role === Roles.dealer) {
      apiStore.dealerApi.getDealer({
        dealerId: auth?.user?.dealerId,
      });
    }
  };

  useEffect(() => {
    if (details?.selectedOptions?.rdoSeriesModel && modelList?.length) {
      modelList.forEach((model: any, index: number) => {
        if (model.id === details?.modelId) {
          setSelectedModal(index);
        }
      });
      if (details?.enginePartNum) {
        apiStore.boatOrderingApi.setMotor(details?.enginePartNum);
      }

      let dealer = "";
      lookups?.dealerList?.forEach((dealeritem: any) => {
        dealeritem?.locations?.forEach((location: any) => {
          if (location.customerID == details.customerId) {
            dealer = dealeritem.id;
          }
        });
      });

      let orderType = Object.entries(enumOrderType)?.filter(
        (el) => el[0] === details?.salesOrderStatus
      )[0][1];

      apiStore.boatOrderingApi.setBoatForm({
        dealer: dealer,
        location: details?.customerId,
        orderType: orderType,
        delivery: details?.requestedDelivery,
        term: details?.terms,
        freight: JSON.parse(JSON.stringify(details?.shipToCode)),
        customerPO: details?.poNum,
        customerNotes: details?.comments,
        showDate: details?.showDate,
        retailCustomer: details?.retailCustomer,
        trailer: details?.trailer,
        trailerType: details?.trailerType,
      });
    }
  }, [details?.selectedOptions, details?.revisionNumber, modelList, lookups]); // for Preview order

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (modelList?.length && currentStep < modelList[0]?.sections?.length) {
        if (selectedModal?.name) {
          const { options }: any = selectedModal?.sections[currentStep];
          setCurrentOptions(options);
        } else {
          const { options }: any = modelList[0]?.sections[currentStep];
          setCurrentOptions(options);
        }
      }
      useScroll.scrollTop("app-content");
    }
    return () => {
      mounted = false;
    };
    // useScroll.scrollTop("option-container");
  }, [currentStep, modelList]);

  useEffect(() => {
    if (selectedModal?.name) {
      let steps: String[] = [];
      const list: any[] = modelList.filter(
        (item: { id: any }) => item.id == selectedModal.id
      );
      const { options }: any = selectedModal?.sections[currentStep];

      setBreadCrumb({
        ...breadCrumb,
        price: selectedModal?.totalPrice,
        name: selectedModal?.name,
        modelYear: list[0].yearDisplayLabel,
        msrp: msrp,
      });

      setCurrentOptions(options);

      selectedModal?.sections?.forEach((el: any) => {
        steps.push(el.name);
      });
      setStepHeading([...steps]);
    }
  }, [selectedModal]);

  useEffect(() => {
    if (seriesList.length === 0) {
      apiStore.boatOrderingApi.getSeriesList();
    }

    if (!(window.location.pathname?.includes("/boat/order/view/") && window.location.pathname?.includes("/boat/order/clone/"))
    ) {
      const data = seriesList.find((series: any) => series.id == params.id);
      setSelectedSeriesInfo(data);
    }

  }, [seriesList]);

  const handleConfirmation = (item: any): void => {
    setOpenModal(item);
  };

  const handleViewDetails = (url: string) => {
    window.open(url, "_blank");
  };

  const OpenPrintModal = (model: any) => {
    setModelForPrint(model);
    setTypeForPrint(null);
    setPrintModal(true);
  };

  const closePrintModal = () => {
    setModelForPrint(null);
    setTypeForPrint(null);
    setPrintModal(false);
  };

  const handlePrint = (type: string, format : string) => {
    setTypeForPrint(type);
    setPrintModal(false);
    format === 'print' ? setPrintSheet(true) : setExportSheet(true);
  };

  useEffect(() => {
    if (printSheet === true) {
      window.print();
      setPrintSheet(false);
    }
  }, [printSheet]);

    const handleExportComplete = () => {
        setExportSheet(false);
    };

  if (loading) {
    return <PageLoader loading={true} />;
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
          {printSheet && <PrintModel model={modelForPrint} type={typeForPrint} series={selectedSeriesInfo} dealer={dealerDetails} />}
          {exportSheet && <CSVExportModel data={modelForPrint} type={typeForPrint} series={selectedSeriesInfo} dealer={dealerDetails} onExportComplete={handleExportComplete} />}

      <MainModal
        open={open}
        handleClose={closePrintModal}
        customStyle={{ maxWidth: "600px" }}
      >
        <PrintModelModal
          model={modelForPrint}
          handleClose={closePrintModal}
          handlePrint={handlePrint}
          label="Model Options"
        />
      </MainModal>
      <ConfirmationModal
        open={openModal}
        handleClickYes={() => {
          setSelectedModal(openModal);
          setOpenModal(false);
        }}
        handleClickCancel={() => setOpenModal(false)}
        title="Are you sure want to leave this model?"
        description="If you click Yes, options selected will be lost."
      />
      {!selectedModal && !isView && (
        <>
          <Grid container mb={4} spacing={4} className="slider-container">
            {selectedSeriesInfo && (
              <>
                <Grid item md={4} xl={3} sm={12}>
                  <Slider {...sliderSettings} className="img-slider">
                    {selectedSeriesInfo?.sliderMedia?.map(
                      (sliderImage, index) => (
                        <div key={index}>
                          <img src={sliderImage.mediaUrl} alt="slider-img" />
                        </div>
                      )
                    )}
                  </Slider>
                </Grid>
                <Grid item md={5} lg={6}>
                  <Typography mb={1} className="series-header">
                    {selectedSeriesInfo?.name}
                  </Typography>
                  <Typography mb={1}>
                    {selectedSeriesInfo?.modelCount}+ Models
                  </Typography>
                  <Typography className="desc">
                    <SmartText
                      text={selectedSeriesInfo.description}
                      char={400}
                    />
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {modelList?.map((model: any, index: any) => {

              const media = model?.layeredMedia?.find(
                (el: any) => el?.perspectiveName === "Side"
              );
              return (
                <Grid item xs={8} sm={4} md={3} key={index}>
                  <Card className="seriesCard">
                    <Typography
                      gutterBottom={false}
                      variant="h6"
                      component="div"
                      align="center"
                      className="seriesCardHeading"
                    >
                      {model?.yearDisplayLabel} - {model?.name}
                    </Typography>
                    <CardMedia
                      component="img"
                      height="140"
                      image={media?.mediaUrl ?? stockimage}
                      alt={model?.name}
                      sx={{
                        objectFit: "contain",
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    />
                    <Typography align="center">
                      <CurrencyFormat
                        value={ calculatePriceByType(
                          model.price,
                          breadCrumb?.priceType,
                          breadCrumb?.priceType == "msrp" ? msrp : dealerDetails?.salePriceMarkupModel
                        )}
                      />
                    </Typography>
                    <CardActions sx={{ justifyContent: "space-between" }}>
                      <Button
                        onClick={() => handleViewDetails(model?.detailUrl)}
                      >
                        View Details
                      </Button>
                      <Stack direction="row" spacing={2}>
                        {navMode !== NavMode.LEADS && (
                          <Button
                            size="small"
                            color="success"
                            variant="contained"
                            onClick={() => OpenPrintModal(model)}
                          >
                            Print
                          </Button>
                        )}
                        <Button
                          size="small"
                          variant="contained"
                          className="configureCardButton"
                          onClick={() => setSelectedModal(index?.toString())}
                        >
                          Configure
                        </Button>
                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}

      {selectedModal && (
        <div className="step-wrapper">
          {currentStep < selectedModal?.sections?.length && !isView && (
            <BoatSlider
              modelList={modelList}
              selectedModal={selectedModal}
              priceType={breadCrumb?.priceType}
              setSelectedModal={handleConfirmation}
              msrp={breadCrumb.msrp}
            />
          )}

          <BoatOrderingBreadCrumb
            salePriceMarkupModel={dealerDetails?.salePriceMarkupModel}
            breadCrumb={breadCrumb}
            motorPrice={motor?.price}
            setBreadCrumb={(priceType) =>
              setBreadCrumb({ ...breadCrumb, priceType })
            }
          />
          {currentStep < selectedModal?.sections?.length ? (
            <StepWizard
              selectedModal={selectedModal}
              priceType={breadCrumb?.priceType}
              isView={isView}
              stepHeading={stepHeading}
              currentOptions={currentOptions}
              salePriceMarkupOption={dealerDetails?.salePriceMarkupOption}
              salePriceMarkupMotor={dealerDetails?.salePriceMarkupOption}
              msrp={breadCrumb.msrp}
            />
          ) : currentStep === selectedModal?.sections?.length ? (
            <BoatOrderingSummary
              model={selectedModal}
              priceType={breadCrumb?.priceType}
              msrp={breadCrumb.msrp}
            />
          ) : (
            <SubmitIndex isView={isView} />
          )}
        </div>
      )}
    </div>
  );
};
export default BoatOrderingModel;
