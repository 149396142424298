import { createSlice } from '@reduxjs/toolkit';
import packageJson from '../../../../package.json';
import { AppThunk } from '../../store';
import { selectAllowRefresh } from './SystemSelector';

const initialState = {
    currentVersion: packageJson.version,
    allowRefresh: true
}

const slice = createSlice({
    name: 'system',
    initialState, 
    reducers: {
        changeAllowRefreshAction: (state, action) => {
            state.allowRefresh = action.payload;
        }
    }
});

export const { changeAllowRefreshAction } = slice.actions;

//Thunks;
export const changeAllowRefresh = (value: boolean): AppThunk => (dispatch,  getState) => {
    
    const currentValue = (selectAllowRefresh(getState()));
    if(currentValue !== value)
    {
        dispatch(changeAllowRefreshAction(value));
    }
};


export default slice.reducer;