import {
  Backdrop,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { MainCard } from "../surface/MainCard";
import { SectionBox } from "../surface/SectionBox";
import { StackLabel } from "../label/StackLabel";

const StandardFeaturesModal = ({ open, handleClose, modelDetails }: any) => {
  return (
    <Grid item xs={12} lg={12} sx={{ maxHeight: "80vh", overflowY: "auto" }}>
      <MainCard title="STANDARD FEATURES" divider={true} onClose={handleClose}>
        <SectionBox>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <MainCard subcard title="Standard Equipment" divider={true}>
                <SectionBox>
                  <Typography variant="caption">
                    {modelDetails?.printInfo?.standardEquipment}
                  </Typography>
                </SectionBox>
              </MainCard>
            </Grid>
            <Grid item xs={12} md={12}>
              <MainCard subcard title="Specifications" divider={true}>
                <SectionBox>
                  {modelDetails?.printInfo?.specifications?.map(
                    (item: any, index: any) => (
                      <StackLabel
                        key={index}
                        title={item.name}
                        label={item.value}
                      />
                    )
                  )}
                </SectionBox>
              </MainCard>
            </Grid>
          </Grid>
        </SectionBox>
      </MainCard>
    </Grid>
  );
};

export default StandardFeaturesModal;
