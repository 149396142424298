import { Checkbox } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Column, TableInstance } from "react-table";
import { apiStore } from "../../../../api/ApiFactory";
import { Table } from "../../../../components/table/Table";
import { convertValue } from "../../../../helpers/utils/valueConverter";
import { IOption, ISection } from "../../../../models/Configuration";

const OptionTableComponent = ({ data }: any) => {

    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let modelId = searchParams.get("boatModelId");

    const [isLocked, setIsLocked] = useState<boolean>(true);

    //Table Columns
    const columns: Column<IOption>[] = useMemo(
        () => [
            {
                Header: "Label",
                accessor: "displayName",
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Section",
                accessor: "sectionName",
            },
            {
                Header: "UI Widget",
                accessor: "uiWidget",
            },
            {
                Header: "Sort Order",
                accessor: "order",
                InLineEdit: true,
                inputType: 'text',
            },
            {
                Header: "Is Active",
                accessor: "isActive",
                Cell: ({ cell }: any) => {
                    return (
                        <Checkbox className={isLocked ? "row-checkbox" : "row-checkbox-disabled"} disabled checked={cell.value} />
                    )
                }
            },
        ], [isLocked]
    );      

    const handleToggle = useCallback(
        (instance: TableInstance<IOption>) => () => {
            setIsLocked(prevState => !prevState);
        },
        []
    );

    const updateOption = async (values: IOption): Promise<boolean> => {
        try {
            await apiStore.boatConfigApi.updateOption(values);
            console.log('Update successful');
            return true;
        } catch (error) {
            console.error('Update failed:', error);
            return false;
        }
    };

    const onInlineEdit = useCallback(
        async (rowId: string | number, columnId: keyof IOption, newValue: any, row: IOption) => {
            if (row) {
                const oldValue = row[columnId];
                const newValueConverted = convertValue(oldValue, newValue);

                // Only update if the new value is different from the old value
                if (oldValue !== newValueConverted) {
                    const updatedValues: IOption = {
                        ...row,
                        [columnId]: newValueConverted,
                    };

                    const result = await updateOption(updatedValues);
                    if (result) {
                        return true;
                    }
                } else {
                    console.log('No change detected. Update skipped.');
                }
            } else {
                console.error('Row not found:', rowId);
            }
            return false;
        },
        []
    );

    //Function to edit row
    const ViewRow = useCallback((instance: TableInstance<IOption>) => () => {

            const option = instance.selectedFlatRows[0].original;
            const { id } = option;
            navigate(`/config/modellist/manageOption?id=${id}`, {state: data});
        },
        []
    );

    return (
        <>
            {modelId && (
                <Table<IOption>
                    name='option-table'
                    serverOnChange={false}
                    columns={columns}
                    apiGet={`/configuration/optionlist?modelId=${modelId}`}
                    rowsPerPage={50}
                    onEdit={ViewRow}
                    inlineLock={handleToggle}
                    inlineLockState={isLocked}
                    onInlineEdit={onInlineEdit}
                />
            )}
            
        </>
        
        
    )
}

export default OptionTableComponent;