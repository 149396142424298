import React, { useEffect } from "react";
import "./_assets/styles/app.scss";
import AuthMiddleware from "./routes/AuthMiddleware";
import ThemeCustomization from "./theme/ThemeCustomization";
import { NotificationProvider } from "./store/context/NotificationContext";

function App() {

  return (
    <ThemeCustomization>
      <NotificationProvider>
        <AuthMiddleware />
      </NotificationProvider>
    </ThemeCustomization>
  );
}

export default App;
