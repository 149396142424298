import { useEffect, useState } from 'react';
import CurrencyFormat from '../../../components/currencyFormat/CurrencyFormat';
import { calculatePriceByType} from "../../../helpers/utils/utils"
import { Grid, Typography } from '@mui/material';

const PrintModelTable = ({ data , type, msrp, dealer }: { data: any, type:string | null, msrp: number, dealer: any }) => {

    const getPriceLabel = ()=>{
        if(type === "salesPrice")
        { 
            return "Sales Price"
        }
        else if(type === "msrp")
        {
            return "MSRP"
        }
        else if(type === "map")
        {
            return "MAP" 
        }
       return "Dealer Price"; 
    }

    console.log(data, type, msrp, dealer);

    const getPercentMarkup = (section: string) => {
        if(type === "salesPrice" && dealer != null) {
            if(section.toUpperCase() === "MODEL") {
                return dealer.salePriceMarkupModel;
            }

            return dealer.salePriceMarkupOption;
        }

        if(type === "msrp") {
            return msrp;
        }

        return 0;
    }

    return (
        <Grid className='option-container'>

            <Grid className='emphasized-box small'>
                <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                    <Typography sx={{fontWeight: "bold"}}>{data.name}</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Typography><b>{getPriceLabel()}:</b> <CurrencyFormat value={calculatePriceByType(data.price, type, getPercentMarkup("MODEL"))}/></Typography>
                </Grid>
                                    
                </Grid>
            </Grid>

            {data.sections && data.sections.map((section:any, sindex: number) => {

            let sectionOptions = section?.options?.filter((o: any) => o.name !== "Rigging / Prop");

            return (
                <Grid key={sindex}>
                    
                    {sectionOptions && sectionOptions.map((option:any, oindex: number) => {

                        return (
                            <Grid key={oindex}>
                                <Grid className="print-section-header">
                                        <Grid container justifyContent="space-between">
                                            <Typography className="group-label">{option.name} ({section.name})</Typography>
                                            <Typography className="group-label">Cost</Typography>
                                        </Grid>
                                </Grid>
                                {option.choices && option.choices.map((choice:any, cindex: number) => {
                                    return (
                                        <Grid key={cindex} container justifyContent="space-between">
                                            <Typography className="item-label">{choice.name}</Typography>
                                            <Typography><CurrencyFormat value={calculatePriceByType(choice.price, type, getPercentMarkup(section))} /></Typography>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )
                    })}
                </Grid>
            )
        })}
        </Grid>
    )
}

export default PrintModelTable