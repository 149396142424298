import { Chip } from '@mui/material'
import { ReactElement, useCallback } from 'react'
import type { ColumnInstance, FilterValue, IdType, TableInstance } from 'react-table'

interface FilterChipBarProps<T extends Record<string, unknown>> {
  instance: TableInstance<T>
}

const getFilterValue = (column: ColumnInstance<any>, filterValue: FilterValue) => {
  switch (column.filter) {
    case 'between':
      const min = filterValue[0]
      const max = filterValue[1]
      return min ? (max ? `${min}-${max}` : `>=${min}`) : `<=${max}`
  }
  return filterValue
}

export function FilterChipBar<T extends Record<string, unknown>>({
  instance,
}: FilterChipBarProps<T>): ReactElement | null {

  const {
    allColumns,
    setFilter,
    state: { filters },
  } = instance
  const handleDelete = useCallback(
    (id: string | number) => {
      setFilter(id as IdType<T>, undefined)
    },
    [setFilter]
  )

  return Object.keys(filters).length > 0 ? (
    <div className="chip-zone">
      <span className="filters-active-label">Active filters:</span>
      {filters &&
        allColumns.map((column) => {
          const filter = filters.find((f) => f.id === column.id)
          const value = filter?.value
          return (
            value && (
              <Chip
                className="filter-chip"
                key={column.id}
                label={
                  <>
                    <span className="chip-label">{column.render('Header')}: </span>
                    {getFilterValue(column, value)}
                  </>
                }
                onDelete={() => handleDelete(column.id)}
                variant='outlined'
              />
            )
          )
        })}
    </div>
  ) : null
}
