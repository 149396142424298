import { Button, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

interface IProps {
    refreshConfirm: (ignoreRefresh: boolean) => void
}

const RefreshConfirmation = (props: IProps) => {

    return (
        <>
        
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Typography fontSize="2rem" align="center">
                        App Update Available.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography fontSize="1rem" color="gray" align="center">
                        Your browser is caching an older version of the application.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                     <Typography fontSize="1rem" align="center">
                        <b>Refresh</b> to update. Unsaved changes will be lost. 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            sx={{ mt: 2.5 }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => props.refreshConfirm(false)}
                                color="secondary"
                            >
                                {" Ignore " }
                            </Button>
                             <Button
                                variant="contained"
                                onClick={() => props.refreshConfirm(true)}
                            >
                                Refresh
                            </Button>

                        </Stack>
                </Grid>
            </Grid>
        
        </>
    )

}

export default RefreshConfirmation;