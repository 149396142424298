import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SelectDropdown from "../../../components/select/SelectDropdown";
import { SelectInterface, ISelectOption } from "../../../models/BoatOrdering";
import { useSelector } from "react-redux";
import {
  getLookupsDetails,
  getCurrentStep,
  getBoatForm,
  getOrderDetails,
} from "../../../store/slices/BoatOrdering/BoatOrderingSelector";
import { apiStore } from "../../../api/ApiFactory";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "../../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import DatePicker from "../../../components/datePicker/DatePicker";
import TextInput from "../../../components/textInput/TextInput";
import { useNotification } from "../../../store/context/NotificationContext";
import { differenceInDays } from "date-fns";
import { enumOrderType } from "../../../helpers/utils/utils";
import { Roles } from "../../../helpers/enums/Common";
import { selectAuthUser } from "../../../store/slices/Auth/AuthSelector";

const FormValidationSchema = Yup.object().shape({
  dealer: Yup.string().required("Dealer option is required."),
  location: Yup.string().required("Location option is required."),
  orderType: Yup.string(),
  delivery: Yup.string().required("Request delivery option is required."),
  term: Yup.string().required("Term option is required."),
  freight: Yup.string().required("Freight option is required."),
  customerPO: Yup.string().required("Customer PO is required."),
  customerNotes: Yup.string(),
  showDate: Yup.string().when("delivery", {
    is: "Boat Show (Must Include Expected Delivery Date)",
    then: Yup.string().required("Boat show date is required.").nullable(),
  }),
  retailCustomer: Yup.string().when("delivery", {
    is: "ASAP/Retail Sold",
    then: Yup.string().required("Retail customer name is required."),
  }),
  trailer: Yup.string().required("Select Yes or No."),
  trailerType: Yup.string().when("trailer", {
    is: "Yes",
    then: Yup.string().required("Trailer type is required.")
  })
});

interface IProps {
  isView: boolean;
  motor: any;
  selectedModel: any;
}

const SubmitDetails = ({ isView, motor, selectedModel }: IProps) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const lookups = useSelector(getLookupsDetails);
  //const motor = useSelector(getMotor);
  const [dealerOptions, setDealerOptions] = useState<ISelectOption[]>([]);
  const [locationOptions, setLocationOptions] = useState<ISelectOption[]>([]);
  const [orderTypeOptions, setOrderTypeOptions] = useState<ISelectOption[]>([]);
  const [deliveryOptions, setDeliveryOptions] = useState<ISelectOption[]>([]);
  const [termOptions, setTermOptions] = useState<ISelectOption[]>([]);
  const [freightOptions, setFreightOptions] = useState<ISelectOption[]>([]);
  const [openModal, setOpenModal] = useState<any>(false);
  const [disabled, setDisabled] = useState(false);
  const [isLegacyDealer, setIsLegacyDealer] = useState(false);
  const { Toast } = useNotification();
  const auth: any = useSelector(selectAuthUser);

  const setCurrentStep = (id: number) => {
    apiStore.boatOrderingApi.setCurrentStep(id);
  };
  const currentStep = useSelector(getCurrentStep);
  //const selectedModal = useSelector(getSelectedModal);
  const formValues = useSelector(getBoatForm);
  const details = useSelector(getOrderDetails);

  const setInitialFormValue = () => {

    return window.location?.pathname.includes("/boat/order/clone")
      ? {
          dealer: "",
          location: "",
          orderType: "",
          delivery: "",
          term: "",
          freight: "",
          customerPO: "",
          customerNotes: "",
          showDate: "",
          retailCustomer: "",
          trailer: "",
          trailerType: ""
        }
      : formValues;
  };


  const formik = useFormik({
    initialValues: setInitialFormValue(),
    validationSchema: FormValidationSchema,
    validate(values) {
      if (!window.location.pathname?.includes("/boat/order/view/")) {
        const errors: any = {};

        if (values?.delivery === "Boat Show (Must Include Expected Delivery Date)") 
        {
          if (!values?.showDate) {
            errors.showDate = "Show date is required.";
          } 
          else if (values?.showDate === "invalid") {
            errors.showDate = "Invalid date.";
          } 
          else if (
            values?.showDate &&
            differenceInDays(new Date(values?.showDate), new Date()) < 0
          ) {
            errors.showDate = "Date should be future date.";
          }
        }
        return errors;
      }
    },
    onSubmit: (values) => {
      let selectedOptions: any = {};
      let salesOrderStatus: any = Object.entries(enumOrderType)?.filter(
        (el) => el[1] === values?.orderType
      )[0][0];
      const formData: any = {
        revisionNum: selectedModel.year,
        partNum: selectedModel.importConfigName,
        custId: values?.location?.toString(),
        webId: 1,
        poNum: values?.customerPO,
        shipToCode: "", // ask
        orderDate: "2022-08-08T05:32:51.813Z",
        requestDate: "2022-08-09T12:59:25.460Z",
        terms: values?.term,
        shipVia: values?.freight,
        finance: "TERMS", //finance + terms merged
        discount: 0,
        hin: "",
        comments: values?.customerNotes,
        boatBuildDate: "2022-08-08T05:32:51.813Z",
        needByDate: "2022-08-08T05:32:51.813Z",
        dictdiscount: "",
        regSalesRepEpicorKey: "",
        salesOrderStatus: salesOrderStatus,
        requestedDelivery: formik.values.delivery,
        showDate: values?.delivery === "Boat Show (Must Include Expected Delivery Date)" ? values?.showDate: "",
        retailCustomer: values?.delivery === "ASAP/Retail Sold" ? values?.retailCustomer : "",
        trailer: values?.trailer ?? "",
        trailerType: values?.trailer === "Yes" ? (values?.trailerType ?? "") : ""
      };

      if (params?.id && window.location.pathname?.includes("/boat/order/view/")) {
        formData["orderNumber"] = params?.id;
      }

      if (motor?.partNumber) {
        formData["enginePartNum"] = motor.partNumber;
        formData["enginePrice"] = motor.price;
      }

      selectedModel?.sections?.forEach((section: any) => {
        section?.options?.forEach((option: any) => {
          option?.choices?.forEach((choice: any) => {
            if (choice?.preSelected && choice?.isVisible) {
              selectedOptions[choice.importKey] = choice.value;
            }
          });
        });
      });

      selectedOptions["rdoSeriesModel"] = selectedModel.value;

      if (selectedOptions) formData["selectedOptions"] = selectedOptions;
      setOpenModal(formData);
    },
  });

  const handleSave = () => {
    apiStore.boatOrderingApi
      .createOrder(openModal)
      .then((response) => {
        let title = "Order updated successfully.";
        if (window.location.pathname?.includes("/boat/model"))
          title = "Order created successfully.";
        Toast.success({ title });
        navigate("/boat");
        setSubmitLoading(false);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.error(error);
        Toast.error({
          title: "Network error processing request.",
        });
      });
  };

  const setOrderType = () => {
    if (window.location.pathname.includes("/boat/order/clone")) {
      return false;
    } else {
      return (
        isView ||
        details?.salesOrderStatus === "DO" ||
        details?.salesOrderStatus === "Sched" ||
        auth?.user?.role === Roles.salesRep
      );
    }
  };

  useEffect(() => {
    if (formik?.values && Object.values(formik?.values)?.length > 0) {
      apiStore.boatOrderingApi.setBoatForm(formik?.values);
    }
  }, [formik?.values]);

  const IDealer: SelectInterface = {
    label: "Dealer *",
    size: "small",
    name: "dealer",
  };

  const ILocation: SelectInterface = {
    label: "Ship to Location *",
    size: "small",
    name: "location",
  };

  const IOrderType: SelectInterface = {
    label: "Order Type *",
    size: "small",
    name: "orderType",
  };

  const IOrderStatus: SelectInterface = {
    label: "Order Status *",
    size: "small",
    name: "orderType",
  };

  const IRequestDelivery: SelectInterface = {
    label: "Request Delivery *",
    size: "small",
    name: "delivery",
  };

  const ITerm: SelectInterface = {
    label: "Term Options *",
    size: "small",
    name: "term",
  };

  const IFreight: SelectInterface = {
    label: "Freight *",
    size: "small",
    name: "freight",
  };

  const ITrailer: SelectInterface = {
    label: "Trailer *",
    size: "small",
    name: "trailer",
  };

  const ITrailerType: SelectInterface = {
    label: "Trailer Type *",
    size: "small",
    name: "trailerType",
  };

  useEffect(() => {
    if (lookups?.dealerList) {

      let list: any[] = [];
      lookups?.dealerList?.forEach((item: any) => {
        list.push({ key: item.id, value: item.name });
      });
      setDealerOptions(list);
    }

    if (lookups?.orderTypeList) {
      let list: any[] = [];
      let useOrderTypeList = true;

      if (params?.id && formik?.values?.orderType) {
        let orderType = lookups.orderTypeList.filter((item: any) => item.key === formik?.values?.orderType)[0];

        if(!orderType) {
          
          let salesOrderStatus = Object.entries(enumOrderType)?.filter(
            (el) => el[1] === formik?.values?.orderType
          )[0][1];

          if(salesOrderStatus) {
            useOrderTypeList = false;
            list.push({ key: salesOrderStatus, value: salesOrderStatus })
          }
        }
      }

      if(useOrderTypeList) {
        lookups?.orderTypeList?.forEach((item: any) => {
          list.push({ key: item.key, value: item.name });
        });
      }
      setOrderTypeOptions(list);
    }

    if (lookups?.deliveryMethodList) {
      let list: any[] = [];
      lookups?.deliveryMethodList?.forEach((item: any) => {
        list.push({ key: item.key, value: item.name });
      });
      setDeliveryOptions(list);
    }

    if (lookups?.financeTermList) {
      let list: any[] = [];
      lookups?.financeTermList?.forEach((item: any) => {
        list.push({ key: item.key, value: item.displayName });
      });
      setTermOptions(list);
    }

    if (lookups?.freightMethodList) {
      let list: any[] = [];
      lookups?.freightMethodList?.forEach((item: any) => {
        list.push({ key: item.key, value: item.displayName });
      });
      setFreightOptions(list);
    }
  }, [lookups]);

  useEffect(() => {
    if (formik.values.dealer) {
      let list: any[] = [];

      const dealer = lookups?.dealerList?.find((el: any) => {
        return el.id === formik.values.dealer;
      });

      manageTermOptions(dealer.designationTypeValue);
      setIsLegacyDealer(dealer?.isLegacy ?? false);

      const locations = dealer?.locations;

      locations?.forEach((item: any) => {
        list.push({ key: item.customerID, value: item.name });
      });
      setLocationOptions(list);
    }
  }, [formik.values.dealer]);

  const manageTermOptions = (designationType: string) => {

    if (lookups?.financeTermList) {
      let list: any[] = [];

      console.log(lookups.financeTermList);

      lookups?.financeTermList?.forEach((item: any) => {


        if(item.designationType === designationType || item.designationType === "STD" || item.designationType === null) {

          console.log(item, designationType);

          list.push({ key: item.key, value: item.displayName });
        }

      });

      setTermOptions(list);
    }
  }

  // Reset the input fields
  const resetValues = () => {
    formik.setFieldValue("retailCustomer", "");
    formik.setFieldValue("showDate", "");
  };

  useEffect(() => {
    setDisabled(
      window.location?.pathname.includes("/boat/order/view/") ? true : false
    );
    if (!params?.id || !formik?.values?.orderType) {
      let salesOrderStatus = Object.entries(enumOrderType)?.filter(
        (el) => el[0] === lookups?.orderStatus
      )[0][1];
      formik?.setFieldValue("orderType", salesOrderStatus);
      if (auth?.user?.role === Roles.salesRep) {
        formik?.setFieldValue("orderType", enumOrderType.Quote);
      }
    }
  }, []);

  return (
    <>
      <ConfirmationModal
        open={openModal}
        handleClickYes={() => {
          handleSave();
          setOpenModal(false);
        }}
        handleClickCancel={() => setOpenModal(false)}
        title="Confirmation"
        description={"Please acknowledge that this is a firm " + (formik?.values?.orderType === enumOrderType.Quote ? "quote" : "order") + "."}
      />
      <Grid
        container
        className="step-wrapper"
        direction={{ md: "row", xs: "column" }}
        alignItems={{ md: "flex-start", xs: "stretch" }}
      >
        <Grid sm={12} item>
          <form onSubmit={formik.handleSubmit}>
            <Box className="step-wrapper mb-5">
              <Box className="checkbox-list">
                <div className="list-head">
                  <h4>General Information</h4>
                </div>
                <div className="">
                  <Grid container direction={{ xs: "column", md: "row" }}>
                    <Grid md={6} sm={12} item>
                      <div className="form-group">
                        <SelectDropdown
                          disabled={isView || disabled}
                          selectData={IDealer}
                          options={dealerOptions}
                          handleChange={formik.handleChange}
                          value={formik.values.dealer}
                          handleBlur={formik.handleBlur}
                          error={ formik.errors?.dealer && formik?.touched?.dealer ? formik.errors?.dealer : "" }
                        />
                        {isLegacyDealer && (
                          <>
                            <Typography variant="caption" color="#d32f2f" fontWeight="700" >Warning: </Typography>
                            <Typography variant="caption" color="#d32f2f" >This is a Legacy Dealer.</Typography>
                          </>
                          
                        )}
                      </div>
                    </Grid>
                    <Grid md={6} sm={12} item>
                      <div className="form-group">
                        <SelectDropdown
                          disabled={isView || disabled || formik.values.dealer === "" }
                          selectData={ILocation}
                          options={locationOptions}
                          handleChange={formik.handleChange}
                          value={formik.values.location}
                          handleBlur={formik.handleBlur}
                          error={ formik.errors?.location && formik?.touched?.location ? formik.errors?.location : "" }
                        />
                      </div>
                    </Grid>
                    <Grid md={6} sm={12} item>
                      <div className="form-group">
                        <SelectDropdown
                          disabled={setOrderType()}
                          selectData={orderTypeOptions.length > 1 ? IOrderType : IOrderStatus}
                          options={orderTypeOptions}
                          handleChange={formik.handleChange}
                          value={formik.values.orderType}
                          handleBlur={formik.handleBlur}
                          error={ formik.errors?.orderType && formik?.touched?.orderType ? formik.errors?.orderType : "" }
                        />
                      </div>
                    </Grid>
                    <Grid md={6} sm={12} item>
                      <div className="form-group">
                        <SelectDropdown
                          disabled={isView}
                          selectData={IRequestDelivery}
                          options={deliveryOptions}
                          handleChange={(e: any) => {
                            formik.handleChange(e);
                            resetValues();
                          }}
                          value={formik?.values?.delivery}
                          handleBlur={formik.handleBlur}
                          error={ formik.errors?.delivery && formik?.touched?.delivery ? formik.errors?.delivery: "" }
                        />
                      </div>
                    </Grid>
                    {formik?.values?.delivery === "ASAP/Retail Sold" && (
                      <Grid md={6} sm={12} item>
                        <TextInput
                          disabled={isView}
                          placeholder="Type In"
                          id="retailCustomer"
                          label="Retail Customer Name *"
                          onChange={formik.handleChange}
                          value={formik.values.retailCustomer}
                          handleBlur={formik.handleBlur}
                          error={ formik.errors?.retailCustomer && formik?.touched?.retailCustomer ? formik.errors?.retailCustomer : ""
                          }
                        />
                      </Grid>
                    )}
                    {formik?.values?.delivery ===
                      "Boat Show (Must Include Expected Delivery Date)" && (
                      <Grid md={6} sm={12} item>
                        <DatePicker
                          disablePast={true}
                          disabled={isView}
                          label="Boat show date *"
                          value={formik.values.showDate}
                          id="showDate"
                          onChange={(date: any) =>
                            formik.setFieldValue("showDate", date)
                          }
                          handleBlur={formik.handleBlur}
                          error={ formik.errors?.showDate && formik?.touched?.showDate ? formik.errors?.showDate : "" }
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Box>
            </Box>

            <Box className="step-wrapper mb-5">
              <Box className="checkbox-list">
                <div className="list-head">
                  <h4>Trailer</h4>
                </div>
                <div>
                  <Grid container direction={{xs: "column", md: "row"}}>
                    <Grid md={6} sm={12} item>
                      <div className="form-group">

                        <SelectDropdown
                            disabled={isView}
                            selectData={ITrailer}
                            options={[
                              { key: "Yes", value: "Yes"},
                              { key: "No", value: "No"}
                            ]}
                            handleChange={formik.handleChange}
                            value={formik.values.trailer}
                            handleBlur={formik.handleBlur}
                            error={ formik.errors?.trailer && formik?.touched?.trailer ? formik.errors?.trailer : "" }
                        />

                      </div>
                      
                    </Grid>
                    {formik?.values?.trailer === "Yes" && (
                      <Grid md={6} sm={12} item>
                        <div className="form-group">
                          <SelectDropdown
                            disabled={isView}
                            selectData={ITrailerType}
                            options={[
                              { key: "Standard", value: "Standard"},
                              { key: "Deluxe", value: "Deluxe"}
                            ]}
                            handleChange={formik.handleChange}
                            value={formik.values.trailerType}
                            handleBlur={formik.handleBlur}
                            error={ formik.errors?.trailerType && formik?.touched?.trailerType ? formik.errors?.trailerType : "" }
                          />
                        </div>
                        
                      
                      </Grid>
                    )}
                    
                  </Grid>
                </div>
              </Box>
            </Box>

            <Box className="step-wrapper mb-5">
              <Box className="checkbox-list">
                <div className="list-head">
                  <h4>Finance Details</h4>
                </div>

                <Grid container direction={{ xs: "column", md: "row" }}>
                  <Grid md={6} sm={12} item>
                    <div className="form-group">
                      <SelectDropdown
                        disabled={isView}
                        selectData={ITerm}
                        options={termOptions}
                        handleChange={formik.handleChange}
                        value={formik.values.term}
                        handleBlur={formik.handleBlur}
                        error={ formik.errors?.term && formik?.touched?.term ? formik.errors?.term: "" }
                      />
                    </div>
                  </Grid>
                  <Grid md={6} sm={12} item>
                    <div className="form-group">
                      <SelectDropdown
                        disabled={isView}
                        selectData={IFreight}
                        options={freightOptions}
                        handleChange={formik.handleChange}
                        value={formik.values.freight}
                        handleBlur={formik.handleBlur}
                        error={ formik.errors?.freight && formik?.touched?.freight ? formik.errors?.freight : "" }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="step-wrapper mb-5">
              <Box className="checkbox-list">
                <div className="list-head">
                  <h4>Customer Details</h4>
                </div>

                <Grid container direction={{ xs: "column", md: "row" }}>
                  <Grid md={6} sm={12} item>
                    <TextInput
                      disabled={isView}
                      id="customerPO"
                      label="Customer PO *"
                      placeholder="Type In"
                      onChange={formik.handleChange}
                      value={formik.values.customerPO}
                      handleBlur={formik.handleBlur}
                      error={ formik.errors?.customerPO && formik?.touched?.customerPO ? formik.errors?.customerPO : "" }
                    />
                  </Grid>
                  <Grid md={6} sm={12} item>
                    <TextInput
                      disabled={isView}
                      id="customerNotes"
                      placeholder="Type In"
                      label="Customer Notes"
                      onChange={formik.handleChange}
                      value={formik.values.customerNotes}
                      handleBlur={formik.handleBlur}
                      error={ formik.errors?.customerNotes && formik?.touched?.customerNotes ? formik.errors?.customerNotes : "" }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                className="step-actions mt-3"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  pt: 2,
                }}
              >
                <Button
                  sx={{ mr: 1 }}
                  variant="contained"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Back
                </Button>
                <Button
                  disabled = {isView}
                  variant="contained"
                  type="submit"
                  loading={submitLoading}
                > 
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
};
export default SubmitDetails;
