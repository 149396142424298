export const calculatePriceByType = (price, type, pct = 1) => {
  let amount = 0;
  switch (type) {
    case "msrp":
      amount = parseInt(price / (1 - pct * 0.01));
      break;
    case "map":
      amount = parseInt(price / 0.82);
      break;
    case "salesPrice":
      const salePriceMarkup = pct === 1 ? 0.999 : pct;
      amount = parseInt(price / (1 - salePriceMarkup));
      break;
    case "markdown":
      //assuming pct is in decimal form 
      amount = parseInt(price - (pct * price));
      break;
    
    default:
      amount = price;
  }
  return amount;
};

export const enumOrderType = {
  ANC: "Accepted No Changes",
  CS: "Consumer Sold",
  DO: "Order",
  Invc: "Invoiced",
  Quote: "Quote",
  Sched: "Scheduled",
  Leads: "Leads"
};

export const enumWarrantyType = [
  { key: "consumer", value: "Consumer Warranty - 5 years Warranty" },
  { key: "rental", value: "Rental Warranty – No Warranty" },
  { key: "club", value: "Club Warranty - 1 year Warranty" },
];

export const reactNumber = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
