

export type IWarrantyHistory = {
    warrantyId: string,
    boatModel: string;
    boatSeries: string;
    city: string;
    country: string;
    customerId: string;
    dealerName: string;
    deliveryDate: Date | string;
    effectiveDate: Date | string;
    email: string;
    engineMake: string;
    expirationDate: Date | string;
    firstName: string;
    hin: string;
    lastName: string;
    orderNum: string | number;
    phone: string;
    postalCode: string;
    purchaseDate: Date | string;
    rowIdent: string;
    serialNumber: string;
    state: string;
    streetAddress1: string;
    streetAddress2: string;
    warrantyType: string;
    revisionNum: string;
    isTransferRequest: boolean;

    action?: any; //added
    modelYear: string; //added

    //key3 and dateRegistered missing
};
export interface IWarrantyHistoryRes extends IWarrantyHistory {
    dealerName: string
}

export const defaultIWarrantyHistory: IWarrantyHistory = {
    warrantyId: "",
    dealerName: "",
    purchaseDate: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    boatModel: "",
    boatSeries: "",
    engineMake: "",
    warrantyType: "",
    serialNumber: "",
    hin: "",
    modelYear: "",
    deliveryDate: "",
    expirationDate: "",
    effectiveDate: "",
    customerId: "",
    orderNum: "",
    rowIdent: "",
    revisionNum: "",
    isTransferRequest: false,
}
