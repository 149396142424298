import React, { ChangeEvent, useState } from 'react';
import { Checkbox, TableCell, TextField } from '@mui/material';
import { TableInstance } from 'react-table';

type EditableCellProps<T extends Record<string, unknown>> = {
    value: any;
    columnId: string;
    rowId: any;
    isEditable: boolean;
    inputType?: 'text' | 'checkbox';
    onInlineEdit: (rowId: string | number, columnId: string, value: any, instance: TableInstance<T>) => void;
    instance: TableInstance<T>;
};

const EditableCell = <T extends Record<string, unknown>>({
    value,
    columnId,
    rowId,
    isEditable,
    inputType,
    onInlineEdit,
    instance,
}: EditableCellProps<T>) => {
    const [editingValue, setEditingValue] = useState(value);
    const [isEditing, setIsEditing] = useState(false);   

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = inputType === 'checkbox' ? event.target.checked : event.target.value;
        setEditingValue(newValue);
        if(inputType !== 'text')
        {
            if (onInlineEdit) {
                onInlineEdit(rowId, columnId, newValue, instance);
            }           
        }        
    };

    const handleBlur = () => {
        if(inputType === 'text'){
        if (onInlineEdit) {
            onInlineEdit(rowId, columnId, editingValue, instance);
        }
    }
    };


    return (
        <TableCell
            onClick={() => isEditable && setIsEditing(true)}
            style={{ cursor: isEditable ? 'pointer' : 'default' }}
        >
            {isEditable ? (
                inputType === 'checkbox' ? (
                    <Checkbox
                        checked={editingValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                ) : (
                    <TextField
                        value={editingValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                    />
                )
            ) : (
                value
            )}
        </TableCell>
    );
};

export default EditableCell;
