import { Box, Grid, Typography } from "@mui/material";
import { IOrderPrint, ItemsEntity, LayeredMediaEntity } from "../../../models/Print";
import { useEffect, useState } from "react";
import { calculatePriceByType } from "../../../helpers/utils/utils";
import CurrencyFormat from "../../../components/currencyFormat/CurrencyFormat";

const WindowStickerHighlights = ({ data }: { data: IOrderPrint}) => {

    const [totalCost, setTotalCost] = useState<number>();
    const [msrpTotal, setMSRPTotal] = useState<number>();
    const [mapTotal, setMAPTotal] = useState<number>();
    const [additionalTotal, setAdditionalTotal] = useState<number>();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<{
        [key: string]: boolean;
    }>({});
    
    useEffect(() => {

        const storedCheckboxes = sessionStorage.getItem("selectedCheckboxes");
        if (storedCheckboxes) {
          setSelectedCheckboxes(JSON.parse(storedCheckboxes));
        }

        updateData(data);
    }, [])

    useEffect(() => {
        let exists = data.additionalItems.filter((item) => selectedCheckboxes.hasOwnProperty(item.name)).length > 0
        
        let additionalTotal = calculateTotal(data.additionalItems.filter((item) => selectedCheckboxes.hasOwnProperty(item.name)));
        setAdditionalTotal(additionalTotal > 0 ? additionalTotal : undefined);

      }, [selectedCheckboxes]);

    const updateData = (data: IOrderPrint) => {

        let options = [...data.items];
        let {salePriceMarkupModel, salePriceMarkupOption} = data.dealerContact;
        //salePriceMarkup = salePriceMarkup ? salePriceMarkup : 0;
        options = options.filter(o => o.price !== 0); 

        let msrpOptions = options.map((item)=>{return {...item,price:calculatePriceByType(item.price, "msrp", data.msrp) }});
        let mapOptions = options.map((item)=>{
    
            let salepricemarkup = item.sectionName.toUpperCase() === "MODEL" ? salePriceMarkupModel : salePriceMarkupOption;
            return {...item,price:calculatePriceByType(item.price, "map", salepricemarkup) }
        });

        let mapTotal = calculateTotal(mapOptions);
        let msrpTotal = calculateTotal(msrpOptions);


        setMSRPTotal(msrpTotal);
        setMAPTotal(mapTotal);
       
        setTotalCost(mapTotal);
    }

    const topPerspective = data.layeredMedia?.filter((item:LayeredMediaEntity)=>item.perspectiveName === "Top");
    const sidePerspective = data.layeredMedia?.filter((item:LayeredMediaEntity)=>item.perspectiveName === "Side");

    const calculateTotal = (options: ItemsEntity[]) => {
        const totalCost = options.map((item) => Number(item.price)).reduce((sum, current) => sum + current, 0);
        return totalCost;
    }

    return (
        <Grid>

            {(topPerspective && sidePerspective) && (
                <Grid container className="window-sticker-image-container">
                    <Grid item xs={6}>
                        
                        {topPerspective && (
                            <Grid className="image-perspective-container small">
                                <Grid className="layered-media-container">
                                    { topPerspective.map((image:LayeredMediaEntity, index:number)=>
                                        <Box key={index} className="image-wrapper left" >
                                            <img src={image?.mediaUrl}/>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        
                    </Grid>    
                    
                    <Grid item xs={6}>
                        {sidePerspective && (
                            <Grid className="image-perspective-container small">
                                <Grid className="layered-media-container">
                                    { sidePerspective.map((image:LayeredMediaEntity, index:number)=>
                                        <Box key={index} className="image-wrapper right" >
                                            <img src={image?.mediaUrl}/>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                     </Grid>   
                </Grid>   
            )}

            <Grid className="window-sticker-model">
                <Grid container justifyContent="space-between">
                    <Grid item xs={6}>
                        <Box sx={{display:"flex", alignItems:"center", gap: ".5rem"}}>
                            <Typography>Year:</Typography>
                            <Typography sx={{fontWeight:"bold"}}>{data.modelYear}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <Box sx={{display:"flex", justifyContent:"flex-end", alignItems:"center", gap: ".5rem"}}>
                            <Typography>Model:</Typography>
                            <Typography sx={{fontWeight:"bold"}}>{data.modelName}</Typography>
                        </Box>
                    </Grid>

                </Grid>

                
            </Grid>

            {msrpTotal !== undefined && (
                
                <Grid className="emphasized-box">
                    
                    <Grid container justifyContent="space-between">

                        <Grid item xs={8}>
                            <Typography>MSRP</Typography>
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                            <Typography sx={{fontWeight: "bold"}}>
                                <CurrencyFormat value={msrpTotal} />
                            </Typography>
                        </Grid>
                        {mapTotal !== undefined && (
                            <>
                                <Grid item xs={8}>
                                    <Typography>Discount</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="right">
                                    <Typography sx={{fontWeight: "bold"}}>
                                        <CurrencyFormat value={-(msrpTotal - mapTotal)} />
                                    </Typography>
                                    
                                </Grid>
                                
                                <Grid item xs={8}>
                                    <Typography > {(totalCost && !additionalTotal) ? "Total" : "Sales Price"}</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="right">
                                    <Typography sx={{fontWeight: "bold"}}>
                                        <CurrencyFormat value={mapTotal} /> 
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {additionalTotal != undefined && totalCost != undefined && (
                            <>
                                <Grid item xs={8}>
                                    <Typography>Additional Options & Considerations</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="right">
                                    <Typography sx={{fontWeight: "bold"}}>
                                        <CurrencyFormat value={additionalTotal} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>Total</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="right">
                                    <Typography sx={{fontWeight: "bold"}}>
                                        <CurrencyFormat value={totalCost + additionalTotal} />
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        
                    </Grid>
                </Grid>
            )}
            

        </Grid>
        
        
    )

}

export default WindowStickerHighlights;