import { Order } from "./types/TableType";

export const getStylesBystatusCode =(statusCode:any)=> {
    const style = {background:"", color:""};
    if(statusCode =="Submitted")
    {
        style.background = "#EAF9FD";
        style.color="#26C0E2"
    }
    else if(statusCode == "Quote")
    {
        style.background = "#FAEFFF"
        style.color="#CB5BFF"
    }
    else if(statusCode == "Ordered")
    {
        style.background = "#DEF0EA"
        style.color="#019B63"
    }
    return style
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }