import { MenuItem, TextField } from "@mui/material"
import React from "react"
import { FilterProps } from "react-table"

export function SelectColumnFilter<T extends Record<string, unknown>>({
    column: { filterValue, render, setFilter, preFilteredRows, id }}: FilterProps<T>) 
{

    const options = React.useMemo(() => {
      const options = new Set<any>()
      preFilteredRows.forEach((row) => {
        options.add(row.values[id])
      })

      console.log(options);

      return [...Array.from(options.values())]
    }, [id, preFilteredRows])
  
    console.log(options);

    return (
      <TextField
        select
        label={render('Header')}
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
        variant='standard'
      >
        <MenuItem value={''}>All</MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    )
  }