import React from "react";
import NavbarContent from "./NavbarContent";

interface NavbarProps {
    type: string;
}

const Navbar = (props: NavbarProps) => {

    return (
        <div className="nav-sidebar">

            <div className="h-100">
                
                <NavbarContent />

            </div>
            
        </div>
    )
}

export default Navbar;