export const interceptorMessageMapping = [
  {
    method: "post",
    url: "/configuration/updatechoice",
    message: "Choice updated successfully.",
  },
  {
    method: "post",
    url: "/configuration/updateoption",
    message: "Option updated successfully.",
  },
  {
    method: "post",
    url: "/configuration/updatemodel",
    message: "Model updated successfully.",
  },
  {
    method: "post",
    url: "/configuration/updateseries",
    message: "Series updated successfully.",
  },
  {
    method: "post",
    url: "/configuration/updateconfigurator",
    message: "Series updated successfully.",
  },
  {
    method: "post",
    url: "/configuration/updateprint",
    message: "Print configuration updated successfully.",
  },
  {
    method: "post",
    url: "/configuration/removeimage",
    message: "Image removed successfully.",
  },
  {
    method: "post",
    url: "/configuration/removechoiceimage",
    message: "Image updated successfully.",
  },
  {
    method: "post",
    url: "/configuration/updateimage",
    message: "Image uploaded successfully.",
  },
  {
    method: "post",
    url: "/configuration/importchoiceimage",
    message: "Image uploaded successfully.",
  },
  {
    method: "post",
    url: "/configuration/updateimage",
    message: "Order updated successfully.",
  },
  {
    method: "post",
    url: "/user/resetpassword",
    message: "Password updated successfully."
  },
  {
    method: "post",
    url: "/user/updatename",
    message: "Name updated successfully."
  },
  {
    method: "post",
    url: "/user/updatecontact",
    message: "Email updated successfully."
  },
  {
    method: "post",
    url: "/usermanagement",
    message: "Save Successful."
  },
  {
    method: "post",
    url: "/rules",
    message: "Save Successful."
  },
  {
    method: "post",
    url: "/importdata",
    message: "Import Successful"
  },
  {
    method: "get",
    url: "/ordermanagement/CancelOrder",
    message: "Order Cancelled."
    },
    {
        method: "post",
        url: "/lookup/savefinanceterm",
        message: "Save Successful."
    },
    {
        method: "post",
        url: "/lookup/savedeliverymethod",
        message: "Save Successful."
    },
    {
        method: "post",
        url: "/lookup/savefreightmethod",
        message: "Save Successful."
    }

];
