import { Formik } from "formik";
import React, { useEffect } from "react";
import { HeaderLabel } from "../../components/label/HeaderLabel";
import { MainCard } from "../../components/surface/MainCard";
import * as Yup from "yup";
import {
  Button,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import { IUserFormValues } from "../../models/Auth";
import { apiStore } from "../../api/ApiFactory";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const FormValidationSchema = Yup.object().shape({
  username: Yup.string().required("User Name is required."),
  password: Yup.string().required("Password is required."),
});

const Login = () => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  return (
    <MainCard className="auth-card" elevation={4}>
      <Stack
        className="auth-container center-on-xs mb-3"
        justifyContent="center"
        alignItems="flex-start"
      >
        <img className="auth-logo" alt=""></img>
      </Stack>
      <HeaderLabel
        title="Log in"
        subtitle="Login with your credentials which you entered during registration."
      />

      <Formik
        initialValues={{
          username: "",
          password: "",
          submit: null,
        }}
        validationSchema={FormValidationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          let data: IUserFormValues = {
            userName: values.username,
            password: values.password,
          };

          apiStore.authApi
            .login(data)
            .catch((error) => {
              if (error.response?.data) {
                setErrors({ submit: error.response?.data });
              } else {
                setErrors({ submit: "Invalid Login Information." });
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ errors, handleSubmit, getFieldProps, isSubmitting, touched }) => (
          <form noValidate onSubmit={handleSubmit} className="form">
            {errors.submit && (
              <Grid container spacing={4} className="error-spacer">
                <Grid item xs={12}>
                  <FormHelperText error id="submit-helper">
                    {errors.submit}
                  </FormHelperText>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor="username">User Name</InputLabel>
                <TextField
                  fullWidth
                  id="username"
                  {...getFieldProps("username")}
                  placeholder="User Name*"
                  size="small"
                  error={Boolean(touched.username && errors.username)}
                />
                {touched.username && errors.username && (
                  <FormHelperText error id="username-helper">
                    {errors.username}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="email">Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="password"
                  {...getFieldProps("password")}
                  placeholder="Password*"
                  size="small"
                  type={isVisible ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsVisible(!isVisible)}
                        edge="end"
                      >
                        {isVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={Boolean(touched.password && errors.password)}
                />
                {touched.password && errors.password && (
                  <FormHelperText error id="password-helper">
                    {errors.password}
                  </FormHelperText>
                )}
                <Typography align="right" className="mt-1">
                  <Link to={"/auth/forgotpassword"}>Forgot Password</Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Log in"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default Login;
