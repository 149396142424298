import Icon from "@mdi/react";
import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { apiStore } from "../../../api/ApiFactory";
import { useAuth } from "../../../store/hooks/useAuth";
import { mdiExitToApp } from "@mdi/js";
import { mdiAccount } from "@mdi/js";
import { useNavigate } from "react-router-dom";

interface CSSModule {
  [className: string]: string;
}

const UserHeader = () => {
  let navigate = useNavigate();
  let authuser = useAuth().authuser;

  let firstName = authuser?.user.firstName ?? "";
  let lastName = authuser?.user.lastName ?? "";
  let userName = authuser?.user.username ?? "Guest";

  var displayName = firstName + " " + lastName;

  if (displayName === " ") {
    displayName = userName;
  }

  const replaceDefaults: CSSModule = {
    "dropdown-menu-end": "animate flip-item",
  };

  const handleLogout = () => {
    apiStore.authApi.logout().catch((error) => console.log(error));
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        id="header-user-button"
        tag="span"
        caret
        className="btn d-flex text-end header-item align-items-center"
      >
        <span className="me-2 d-sm-block header-user-item">
          <span className="header-user-name">{displayName}</span>
          <span className="header-role">
            {authuser?.user.roles ? authuser?.user.roles[0] : "Unknown Role"}
          </span>
        </span>
        <img className="header-user-image rounded-circle" alt="" />
      </DropdownToggle>
      <DropdownMenu
        end
        className="dropdown-menu-container"
        cssModule={replaceDefaults}
      >
        <div className="pb-3 bg-primary dropdown-menu-header">
          <span className="mb-0 text-white">{displayName}</span>
          <p className="mb-0 mt-0 font-size-5 text-white-50 fw-semibold">
            {authuser?.user.roles ? authuser?.user.roles[0] : "Unknown Role"}
          </p>
        </div>
        <DropdownItem className="dropdown-menu-item" onClick={goToProfile}>
          <div className="first">
            <Icon
              path={mdiAccount}
              size="16px"
              color="#444444"
              title={"Profile"}
            />
            <span>Profile</span>
          </div>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          className="dropdown-menu-item last"
          onClick={handleLogout}
        >
          <div className="last">
            <Icon
              path={mdiExitToApp}
              size="16px"
              color="#444444"
              title={"Logout"}
            />
            <span>Logout</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserHeader;
