import { Checkbox } from "@mui/material"
import { CellProps, HeaderProps, Hooks } from "react-table"

export const selectionHook = (hooks: Hooks<any>) => {
    hooks.allColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: '_selector',
        disableResizing: true,
        disableGroupBy: true,
        minWidth: 45,
        width: 45,
        maxWidth: 45,
        Aggregated: undefined,
        // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
          <Checkbox className="header-checkbox" {...getToggleAllRowsSelectedProps()} />
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
        Cell: ({ row }: CellProps<any>) => <Checkbox className="row-checkbox" {...row.getToggleRowSelectedProps()} />,
      },
      ...columns,
    ])
    hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
      // fix the parent group of the selection button to not be resizable
      const selectionGroupHeader = headerGroups[0].headers[0]
      selectionGroupHeader.canResize = false
    })
  }