import { useFormik, validateYupSchema } from "formik";
import { apiStore } from "../../../api/ApiFactory";
import { Configurator } from "../../../models/Configuration";
import * as Yup from "yup";
import { Button, FormHelperText, Grid, InputLabel, Stack, TextField } from "@mui/material";

interface IProps {
    record: Configurator;
    closeModal:()=>void;
}

const AppValidationSchema = Yup.object().shape({
    record: Yup.object({
        partNum: Yup.string().required("Configurator Name is required."),
        revision: Yup.string().required("Revision is required.")
    })
})

const ImportConfigurator = (props: IProps) => {

    const onClose = () => {
        props.closeModal();
      };

    interface IFormProp {
        partNum: string;
        revision: string
    }

    let { partNum, revision } : IFormProp = 
        { partNum: props.record.importConfigName, 
            revision: ""
        };

    const formik = useFormik( {
        validationSchema: AppValidationSchema,
        initialValues: {
            record: {partNum, revision},
            submit: null
        },
        onSubmit: (values, {setSubmitting, setErrors}) => {
            setSubmitting(true);

            const response = apiStore.boatConfigApi.importConfigurator(values.record);
            response
            .then((data) =>  {

                if(data.error.length > 0) {
                    setErrors({submit: data.error});
                }
                else {
                    props.closeModal(); 
                }
            })
            .catch((error) => {
                if(error?.error.length > 0) {
                    setErrors({submit: error.error});
                }
            })
            .finally(() => {
                setSubmitting(false);
            })
        }
    });

    return (
        <>
        
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                    {formik.errors.submit && (
                        <Grid item xs={12}>
                            <FormHelperText sx={{position:"absolute"}} error id="submit-helper">{formik.errors.submit}</FormHelperText>
                        </Grid>
                    )}
                    <Grid item xs={12}>

                        <InputLabel htmlFor="partNum">
                            Configurator *
                        </InputLabel>
                        <TextField
                            fullWidth
                            disabled={true}
                            size="small"
                            id="partNum"
                            {...formik.getFieldProps("record.partNum")}
                            placeholder="PartNum*"
                            error={Boolean(formik.touched.record?.partNum && formik.errors.record?.partNum )}
                        />
                        {formik.touched.record?.partNum && formik.touched.record?.partNum && (
                        <FormHelperText error id="partNum-helper">
                            {formik.errors.record?.partNum}
                        </FormHelperText>
                        )}
                    </Grid>


                    <Grid item xs={12} >
                        <InputLabel htmlFor="revision">
                            Revision * 
                        </InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="revision"
                            {...formik.getFieldProps("record.revision")}
                            placeholder="Revision *"
                            error={Boolean(formik.touched.record?.revision && formik.errors.record?.revision )}
                        />
                        {formik.touched.record?.revision && formik.touched.record?.revision && (
                        <FormHelperText error id="revision-helper">
                            {formik.errors.record?.revision}
                        </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            sx={{ mt: 2.5 }}
                        >
                            <Button
                                variant="outlined"
                                disabled={formik.isSubmitting}
                                onClick={ onClose }
                                color="secondary"
                            >
                                {" "} Cancel{" "}
                            </Button>
                            <Button
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                Import
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        
        </>
    )


};

export default ImportConfigurator;