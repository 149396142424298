import { ReactTable } from "../../components/table/ReactTable";
import { Table } from "../../components/table/Table";
import { ReportsData } from "../../../src/models/ReportsData";
import { useEffect, useMemo, useState, useCallback } from "react";
import { Column, TableInstance } from "react-table";
import { useAuth } from "../../../src/store/hooks/useAuth";
import { Roles } from "../../helpers/enums/AuthConstants";
import { apiStore } from "../../api/ApiFactory";
import { SelectColumnFilter } from "../../components/table/filters/SelectColumnFilter";
import { formatDate } from "../../helpers/utils/formatDate";
import {
    AlignHorizontalCenter,
    AlignVerticalCenter,
} from "@mui/icons-material";

const Report = () => {
    let isAdmin = useAuth().authuser?.user.role === Roles.ADMINISTRATOR;
    const [tableData, setTableData] = useState<ReportsData[]>([]);

    //Table Columns Structure
    const columns: Column<ReportsData>[] = [
        {
            Header: "Sales Rep",
            accessor: "salesRep_Name",
            width: 120,
            Filter: SelectColumnFilter,
        },
        {
            Header: "Customer Name",
            accessor: "customer_Name",
            width: 140,
            Filter: SelectColumnFilter,
        },
        {
            Header: "Order Number",
            accessor: "orderHed_OrderNum",
            width: 100,
        },
        {
            Header: "Part Number",
            accessor: "orderDtl_PartNum",
            width: 100,
        },
        {
            Header: "Hull Number",
            accessor: "orderRel_HullNum_c",
            width: 120,
        },
        {
            Header: "Order Date",
            accessor: "orderHed_OrderDate",
            width: 100,
            Cell: (cell: any) => (cell.value ? formatDate(cell.value) : ""),
        },
        {
            Header: "Calculated Gel Date",
            accessor: "calculated_GelDate",
            width: 100,
            Cell: (cell: any) => (cell.value ? formatDate(cell.value) : ""),
        },
        {
            Header: "Calculated ECD",
            accessor: "calculated_ECD",
            width: 100,
            Cell: (cell: any) => (cell.value ? formatDate(cell.value) : ""),
        },
        {
            Header: "Order Amount",
            accessor: "orderHed_DocOrderAmt",
            width: 120,
        },
        {
            Header: "Approval Number",
            accessor: "orderHed_ApprovalNum_c",
            width: 100,
        },
        {
            Header: "PO Number",
            accessor: "orderHed_PONum",
            width: 100,
        },
        {
            Header: "Calculated Invoice Num",
            accessor: "calculated_MaxInvcNum",
            width: 100,
        },

    ];

    const ViewRow = useCallback(
        (instance: TableInstance<ReportsData>) => () => { },
        []
    );

    const onPrint = useCallback(
        (instance: TableInstance<ReportsData>) => () => {
            const selectedRows = instance.selectedFlatRows;
            const rowsData = selectedRows.map((item) => item.original);
            localStorage.setItem("selectedReportData", JSON.stringify(rowsData));
            window.open(`/report/print`, "_blank");
        },
        []
    );

    useEffect(() => {
        const fetchData = async () => {
            const result = await apiStore.reportsApi.getReportsData();
            setTableData(result);
        };
        fetchData();
    }, []);

    return (
        <>
            <ReactTable
                name="reports-table"
                serverOnChange={false}
                columns={columns}
                data={tableData}
                isLoading={false}
                onPrint={onPrint}
            />
        </>
    );
};

export default Report;
