import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Backdrop } from '@mui/material';

const MainModal = ({ open, handleClose, children, customStyle }: any) => {


    const style = {
        bgcolor: 'background.paper',
        boxShadow: 24,
        outline: 0,
        //overflow: 'hidden',
        ...customStyle
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            closeAfterTransition
            BackdropProps={{
                timeout: 700,
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="modal-container">
                {children}
            </Box>
        </Modal>

    )
}

export default MainModal;