import { interceptorMessageMapping } from "./IntercepterMessageMapping";
export const interceptorMessageHandler = async (response: any, Toast: any) => {

  const message = await getAPIUrl(response);
  if (message) Toast.success({ title: message });
  return response;
};

const getAPIUrl = (response: any) => {
  const url = response?.config?.url?.split("?");
  const method = response.config.method;

  const obj = interceptorMessageMapping?.find(
    (item: any) => item.url === url[0] && item.method === method
  );

  if (obj && obj?.message) {
    return obj.message;
  }
  else {
    return "";
  }
};
