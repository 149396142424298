import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";

const initialState = {
    currentTab : 0
}

const slice = createSlice({
    name: "tabs",
    initialState,
    reducers: {
        setCurrenTab: (state, action) => {
           state.currentTab = action.payload
        }
    }
})


export const setCurrentTabValue = (value:number):
    AppThunk => (dispatch, getState) => {
        dispatch(setCurrenTab(value));

    };

export const { setCurrenTab } = slice.actions;
export default slice.reducer;