import { AppState } from "../../store";

//Selectors that pull values from state.
export const getSeriesList = (state: AppState) => state.reducer.Boat.seriesList;
export const getModelList = (state: AppState) => state.reducer.Boat.modelList;
export const getRuleList = (state: AppState) => state.reducer.Boat.ruleList;
export const getLookupsDetails = (state: AppState) => state.reducer.Boat.lookupsData;
export const selectAppLoadComplete = (state: AppState) => state.reducer.Auth.appLoadComplete;
export const getSelectedModal = (state: AppState) => state.reducer.Boat.selectedModal;
export const getCurrentStep = (state: AppState) => state.reducer.Boat.currentStep;
export const boatBreadCrumbTitle = (state: AppState) => state.reducer.Boat.breadCrumbTitle;
export const getPanelImage = (state: AppState) => state.reducer.Boat.panelImage;
export const getAccentImage = (state: AppState) => state.reducer.Boat.accentImage;
export const getCompletedSteps = (state: AppState) => state.reducer.Boat.completedSteps;
export const getBoatForm = (state: AppState) => state.reducer.Boat.boatForm;
export const getLeadForm = (state: AppState) => state.reducer.Boat.leadsForm;
export const getMotor = (state: AppState) => state.reducer.Boat.motor;
export const getOrderDetails = (state: AppState) => state.reducer.Boat.orderDetails;
