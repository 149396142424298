import { Navigate } from "react-router-dom";

//Enums
import { Permissions } from "../helpers/enums/AuthConstants";

//Auth Pages
import Login from "../features/auth/Login";
import Unauthorized from "../features/auth/Unauthorized";
import AuthRequired from "./AuthRequired";

//Private Pages & Features
import Empty from "../features/Empty";
import Dashboard from "../features/dashboard/Dashboard";
import UserManagement from "../features/user-management/UserManagementTable";
import WarrantyIndex from "../features/warranty/WarrantyIndex";
import WarrantyDetails from "../features/warranty/WarrantyDetails";
import WarrantyManagement from "../features/warranty/WarrantyManagement";
import Configuration from "../features/config/Configuration";
import OrderManamagement from "../features/orders/OrderManagement";
import FeatureLoad from "../components/loader/FeaturelLoad";
import { lazy } from "react";
import UserEdit from "../features/user-management/UserEdit";
import UserManagementIndex from "../features/user-management/UserManagementIndex";
import { RouteProps } from "../models/Route";
import EditConfiguration from "../features/config/ModelConfiguration/EditingConfiguration";
import AuthWrapper from "../features/auth/AuthWrapper";
import ManageChoices from "../features/config/ModelConfiguration/ManageChoices";
import ConfigurationIndex from "../features/config/ConfigurationIndex";
import UpdateSeries from "../features/config/Series/UpdateSeries";
import ConfiguratorEdit from "../features/config/Series/ConfiguratorEdit";
import ManageOption from "../features/config/ModelConfiguration/ManageOption";
import BoatOrderingSeries from "../features/boatOrdering/BoatOrderingSeries";
import BoatOrderingModel from "../features/boatOrdering/BoatOrderingModel";
import UserProfile from "../features/user-profile/UserProfile";
import PrintOrder from "../features/print/Order/PrintOrder";
import PrintReport from "../features/print/Report/PrintReport";
import DealerDetailIndex from "../features/dealer/DealerDetailIndex";
import Dealer from "../features/dealer/DealerTable";
import DealerEdit from "../features/dealer/DealerEdit";
import DealerLocation from "../features/dealer/DealerLocation";
import ForgotPassword from "../features/auth/ForgotPassword";
import AuthConfirmation from "../features/auth/Confirmation";
import AuthResetPassword from "../features/auth/ResetPassword";
import EditLookup from "../features/config/Lookups/EditLookup";
import LeadsManagement from "../features/leads/LeadsManagement";
import LeadsIndex from "../features/leads/LeadsIndex";
import PrintLead from "../features/leads/PrintLead";
import SeriesIndex from "../features/config/Series/SeriesIndex";
import ModelConfigurationIndex from "../features/config/ModelConfiguration/ModelConfigurationIndex";
import ManagePrintDetails from "../features/config/ModelConfiguration/ManagePrintDetails";
import DealerIndex from "../features/dealer/DealerIndex";
import AdditionalPricing from "../features/dealer/AdditionalPricing";
import WindowSticker from "../features/print/WindowSticker/WindowSticker";
import MarketingFormDetails from "../features/marketingForm/MarketingFormDetails";
import Report from "../features/report/Report";
import OrderManagementIndex from "../features/orders/OrderManagementIndex";
import ReportIndex from "../features/report/ReportIndex";
import EditWarranty from "../features/warranty/EditWarranty";
import EditFinanceTerms from "../features/config/Lookups/EditFinanceTerms";

const BoatIndex = FeatureLoad(
    lazy(() => import("../features/boatOrdering/BoatOrderingIndex"))
);

const setRoute = (props: RouteProps) => {
    let routedef = props;
    if (!routedef.permissions) return routedef;

    routedef.element = (
        <AuthRequired permissions={routedef.permissions}>
            {routedef.element}
        </AuthRequired>
    );
    return routedef;
};

//Children Declarations
const SetUserManagementChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <UserManagement />,
        permissions: [Permissions.ViewUserManagement],
    }),
    setRoute({
        path: "/user/add",
        title: "Add User",
        element: <UserEdit />,
        permissions: [Permissions.EditUser],
    }),
    setRoute({
        path: "/user/view",
        title: "View User",
        element: <UserEdit />,
        permissions: [Permissions.ViewUserManagement, Permissions.EditUser],
    }),
];

// Children Declarations
const SetBoatChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <BoatOrderingSeries />,
        permissions: [Permissions.ViewBoatOrdering],
    }),
    setRoute({
        path: "/boat/model/:id",
        title: "Seapro Series",
        element: <BoatOrderingModel />,
        permissions: [Permissions.ViewBoatOrdering],
    }),
    setRoute({
        path: "/boat/order/view/:id",
        title: "Seapro Series",
        element: <BoatOrderingModel />,
        permissions: [Permissions.ViewBoatOrdering],
    }),
    setRoute({
        path: "/boat/order/clone/:id",
        title: "Seapro Series",
        element: <BoatOrderingModel />,
        permissions: [Permissions.ViewBoatOrdering],
    })
];

const SetOrderManagementChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <OrderManamagement />,
        permissions: [Permissions.ViewOrderManagement],
    }),
    setRoute({
        path: "/orders/print/windowsticker/:orderNum",
        title: "",
        element: <WindowSticker />,
        permissions: [Permissions.ViewOrderManagement],
    }),
    setRoute({
        path: "/orders/print",
        title: "",
        element: <PrintOrder />,
        permissions: [Permissions.ViewOrderManagement],
    }),
]

// Children Declarations
const SetLeadChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <BoatOrderingSeries />,
    }),
    setRoute({
        path: "/order/model/:id",
        title: "Seapro Series",
        element: <BoatOrderingModel />,
    }),
];

const SetWarrantyChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <WarrantyManagement />,
        permissions: [Permissions.ViewWarranty],
    }),
    setRoute({
        path: "/warranty/create/:id",
        title: "Create",
        element: <EditWarranty />,
        permissions: [Permissions.EditWarranty],
    }),
    setRoute({
        path: "/warranty/view/:id",
        title: "View",
        element: <WarrantyDetails />,
        permissions: [Permissions.ViewWarranty],
    }),
    setRoute({
        path: "/warranty/details/:id/transfer",
        title: "Transfer",
        element: <EditWarranty />,
        permissions: [Permissions.EditWarranty],
    }),
];

const SetLeadsManagementChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <LeadsManagement />,
        permissions: [Permissions.ViewLeadsManagement],
    }),
    setRoute({
        path: "/leads/print",
        title: "",
        element: <PrintLead />,
        permissions: [Permissions.ViewLeadsManagement],
    }),
    setRoute({
        path: "/leads/print/windowsticker/:orderNum",
        title: "",
        element: <WindowSticker />,
        permissions: [Permissions.ViewLeadsManagement],
    })
];

const SetSeriesChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <UpdateSeries />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/config/series/configurator/add",
        title: "Add Configurator",
        element: <ConfiguratorEdit />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/config/series/configurator/view",
        title: "View Configurator",
        element: <ConfiguratorEdit />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
];

const setModelConfigurationChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <EditConfiguration />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/config/modellist/printDetails",
        title: "Print",
        element: <ManagePrintDetails />,
        permissions: [Permissions.EditBoatConfiguration],
    }),
    setRoute({
        path: "/config/modellist/manageOption",
        title: "Option",
        element: <ManageOption />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/config/modellist/manageOption/manageChoice",
        title: "Choice",
        element: <ManageChoices />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
];

const SetSettingChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <Configuration />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/config/series",
        title: "Series",
        element: <SeriesIndex />,
        permissions: [Permissions.ViewBoatConfiguration],
        children: SetSeriesChildren,
    }),
    setRoute({
        path: "/config/modellist",
        title: "Model",
        element: <ModelConfigurationIndex />,
        permissions: [Permissions.ViewBoatConfiguration],
        children: setModelConfigurationChildren,
    }),
    setRoute({
        path: "/config/option/add",
        title: "Add Option",
        element: <EditLookup />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/config/option/edit",
        title: "Edit Option",
        element: <EditLookup />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/config/option/addterm",
        title: "Add Finance Term",
        element: <EditFinanceTerms />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/config/option/editterm",
        title: "Edit Finance Term",
        element: <EditFinanceTerms />,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
];

const SetDealerChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <DealerEdit />,
        permissions: [Permissions.ViewDealerManagement],
    }),
    setRoute({
        path: "/dealer/view/:id/additionalpricing",
        title: "Additional Pricing",
        element: <AdditionalPricing />,
        permissions: [Permissions.ViewDealerManagement],
    }),
    setRoute({
        path: "/dealer/view/:id/location",
        title: "Location",
        element: <DealerLocation />,
        permissions: [Permissions.ViewDealerManagement],
    }),
];

const SetAddDealerChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <DealerEdit />,
        permissions: [Permissions.ViewDealerManagement],
    }),
];

const SetDealerListChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <Dealer />,
        permissions: [Permissions.ViewDealerManagement],
    }),
    setRoute({
        path: "/dealer/view/:id",
        title: "View Dealer",
        element: <DealerDetailIndex />,
        permissions: [Permissions.ViewDealerManagement],
        children: SetDealerChildren,
    }),
    setRoute({
        path: "/dealer/add/",
        title: "Add Dealer",
        element: <DealerDetailIndex />,
        permissions: [Permissions.ViewDealerManagement],
        children: SetAddDealerChildren,
    }),
];

const SetPrintReportChildren: Array<RouteProps> = [
    setRoute({
        index: true,
        element: <Report />,
        permissions: [Permissions.ViewSalesRepECDReport],
    }),
    setRoute({
        path: "/report/print",
        title: "Print Report",
        element: <PrintReport />,
        permissions: [Permissions.ViewSalesRepECDReport],
    }),
];

//Main Routes
//routes with no permissions don't need to call setRoute.
const protectedRoutes: Array<RouteProps> = [
    setRoute({
        path: "/dashboard",
        title: "Dashboard",
        element: <Dashboard />,
        authuser: true,
        hidelegacy: true
    }),
    setRoute({
        path: "/boat",
        title: "Boat Ordering",
        element: <BoatIndex />,
        children: SetBoatChildren,
        permissions: [Permissions.ViewBoatOrdering],
    }),
    setRoute({
        path: "/user",
        title: "User Profiles",
        element: <UserManagementIndex />,
        children: SetUserManagementChildren,
        permissions: [Permissions.ViewUserManagement],
    }),
    setRoute({
        path: "/config",
        title: "Configuration",
        element: <ConfigurationIndex />,
        children: SetSettingChildren,
        permissions: [Permissions.ViewBoatConfiguration],
    }),
    setRoute({
        path: "/orders",
        title: "Order History",
        element: <OrderManagementIndex />,
        children: SetOrderManagementChildren,
        permissions: [Permissions.ViewOrderManagement],
    }),
    setRoute({
        path: "/leads",
        title: "Leads",
        element: <LeadsIndex />,
        children: SetLeadsManagementChildren,
        permissions: [Permissions.ViewLeadsManagement],
    }),
    setRoute({
        path: "/documents",
        title: "Documents",
        element: <MarketingFormDetails />,
        authuser: true,
        hidelegacy: true
    }),
    setRoute({
        path: "/report",
        title: "Sales Rep ECD Report",
        element: <ReportIndex />,
        children: SetPrintReportChildren,
        permissions: [Permissions.ViewSalesRepECDReport],
    }),
    setRoute({
        path: "/warranty",
        title: "Warranty",
        element: <WarrantyIndex />,
        children: SetWarrantyChildren,
        permissions: [Permissions.ViewWarranty],
    }),

    {
        path: "/profile",
        title: "User Profile",
        element: <UserProfile />,
        authuser: true,
    },
    setRoute({
        path: "/dealer",
        title: "Dealer",
        element: <DealerIndex />,
        children: SetDealerListChildren,
        permissions: [Permissions.ViewDealerManagement],
    }),

    { path: "/empty", element: <Empty /> },
    { path: "/unauthorized", element: <Unauthorized /> },
    { path: "/", element: <Navigate replace to="/dashboard" />, exact: true },
];

const leadRoutes: Array<RouteProps> = [
    setRoute({
        path: "/order",
        title: "Build My Boat",
        element: <BoatIndex />,
        children: SetLeadChildren,
    }),
];

const SetAuthChildren: Array<RouteProps> = [
    { path: "/auth/login", element: <Login /> },
    { path: "/auth/forgotpassword", element: <ForgotPassword /> },
    { path: "/auth/confirmation", element: <AuthConfirmation /> },
    { path: "/auth/resetpassword", element: <AuthResetPassword /> },
];

const authRoutes: Array<RouteProps> = [
    {
        path: "/auth",
        element: <AuthWrapper />,
        children: SetAuthChildren,
    },
];

export { protectedRoutes, authRoutes, leadRoutes, setRoute };
