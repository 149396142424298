import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import CurrencyFormat from "../../../components/currencyFormat/CurrencyFormat";
import { useEffect, useState } from "react";
import { calculatePriceByType } from "../../../helpers/utils/utils";
import { IOrderPrint } from "../../../models/Print";

const PrintOrderTable = ({
  data,
  type,
}: {
  data: any;
  type: string | null;
}) => {
  const [tableData, setTableData] = useState<any>();
  const [totalCost, setTotalCost] = useState<number>();

  useEffect(() => {
    updateData(data);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      fontWeight: 700,
      border: 0,
    },
  }));

  const calculateCost = (data: any) => {
    const totalCost = data.reduce(
      (previousValue: number, currentValue: { price: number }) =>
        previousValue + Number(currentValue.price),
      0
    );
    return totalCost;
  };

  const updateData = (data: IOrderPrint) => {
    let state = [...data.items];
    let {salePriceMarkupModel, salePriceMarkupOption} = data.dealerContact;
    let msrp = data.msrp;

    var markdown = 0.0;

    if(type === "dealerCost") {

      if(data.termCode === "COD" || data.termCode === "HUNT") {
        markdown = 0.03;
      }

      if(data.termCode === "COD5" || data.termCode === "DM5") {
        markdown = 0.05;
      }

    }

    state = state.map((item) => {
      let salesprice = item.sectionName.toUpperCase() === "MODEL" ? salePriceMarkupModel : salePriceMarkupOption;

      return {
        ...item,
        price: calculatePriceByType(
          calculatePriceByType(item.price, "markdown", markdown),
          type,
          type == "msrp" ? msrp : salesprice
        ),
      };
    });

    let totalCost = calculateCost(state);
    const newObj = {
      name: type === "dealerCost" ? "Dealer Cost" : "Total Price",
      price: totalCost,
      optionName: "",
      sectionName: "",
    };

    state.push(newObj);
    setTableData(state);
    setTotalCost(totalCost);
  };

  const getPriceLabel = () => {
    if (type === "salesPrice") {
      return "Sales";
    } else if (type === "msrp") {
      return "MSRP";
    } else if (type === "map") {
      return "MAP";
    }
    return "Dealer";
  };

  return (
    <>
      <TableContainer className="price-table">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="show-action-column"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Item</StyledTableCell>
              <StyledTableCell>Option</StyledTableCell>
              <StyledTableCell align="right">
                {getPriceLabel()} Price
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row: any, index: number) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">
                      <span>{row.name}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>{row.optionName}</span>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <CurrencyFormat value={row.price} />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PrintOrderTable;
