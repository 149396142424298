import { Grid, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ActiveRoutePath } from "../../models/Route";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { boatBreadCrumbTitle } from "../../store/slices/BoatOrdering/BoatOrderingSelector";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { apiStore } from "../../api/ApiFactory";
interface BreadcrumbProps {
  routes: ActiveRoutePath[];
  maxDepth?: number;
  separator?: string;
  rightAlign?: boolean;
}

const Breadcrumbs = (props: BreadcrumbProps) => {
  const _maxDepth = props.maxDepth ?? 3;
  const _separator = props.separator ?? "/";
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let breadcrumbContent: JSX.Element[] = [];
  const breadCrumbTitle = useSelector(boatBreadCrumbTitle);
  useEffect(() => {
    if (!window?.location?.pathname?.includes("/boat")) {
      apiStore.boatOrderingApi.setBoatBreadCrumbTitle("");
    }
  }, [window?.location?.pathname]);
  //There is going to be at least two records for the Breadcrumbs to show up.
  if (props.routes && props.routes.length > 1) {
    props.routes.map((active, index, { length }) => {
      let crumb: JSX.Element;
 
      //Parent Routes
      if (index !== length - 1) {
        let navindex = index - (length-1);

        crumb = (
          <Typography
            key={"breadcrumb_" + index}
            className="breadcrumb-parent-route"
            component={Link}
            to={active.match.pathname}
            onClick={() => location.key !== "default" ? navigate(navindex) : null }
          >
            {active.title}
          </Typography>
        );
      }
      //Current Route
      else {
        crumb = (
          <Typography
            key={"breadcrumb_" + index}
            className="breadcrumb-current-route"
          >
            {breadCrumbTitle || active.title}
          </Typography>
        );
      }
      breadcrumbContent.push(crumb);
    });
  }
  return (
    <Grid
      className="breadcrumb-container"
      justifyContent={props.rightAlign ? "space-between" : "flex-start"}
      alignItems={props.rightAlign ? "center" : "flex-start"}
    >
      {breadcrumbContent.length > 0 && (
        <MuiBreadcrumbs
          aria-label="breadcrumb"
          maxItems={_maxDepth}
          separator={_separator}
        >
          {breadcrumbContent.map((value) => value)}
        </MuiBreadcrumbs>
      )}
    </Grid>
  );
};

export default Breadcrumbs;
