/**
 * Converts the newValue based on the type of oldValue.
 * @param {any} oldValue - The value used to determine the type for conversion.
 * @param {any} newValue - The value to be converted.
 * @returns {any} - The converted value.
 */
export function convertValue(oldValue: number | string | boolean | any, newValue: any): any {
    if (typeof oldValue === 'number') {
        return parseFloat(newValue);
    } else if (typeof oldValue === 'string') {
        return String(newValue);
    } else if (typeof oldValue === 'boolean') {
        return Boolean(newValue);
    } else {
        return newValue;
    }
}