import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Permissions } from "../../helpers/enums/AuthConstants";
import { DefaultCostType, Roles } from "../../helpers/enums/Common";
import { IAuthUser } from "../../models/Auth";
import { HasPermission } from "../../services/AuthService";
import { selectAuthUser } from "../../store/slices/Auth/AuthSelector";
import { apiStore } from "../../api/ApiFactory";
import { IOrderHistory } from "../../models/OrderManagement";
import { IOrderPrint } from "../../models/Print";

type IProp = {
  orderNum: number;
  handleClose: () => void;
  label: string;
  isLead?: boolean;
};

const PrintModal = ({ orderNum, handleClose, label, isLead }: IProp) => {
  const auth: IAuthUser | null = useSelector(selectAuthUser);

  const [printOption, setPrintOption] = useState("");
  const [allowDealerCost, setAllowDealerCost] = useState(true);
  const [data, setData] = useState<IOrderPrint>();
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: boolean;
  }>({});

  const handleChange = (event: SelectChangeEvent) => {
    setPrintOption(event.target.value as string);
  };

  useEffect(() => {
    if (!!isLead) {
      setPrintOption("msrp");
    } else {
      
      const permission_useDealerCost = HasPermission(auth?.user, Permissions.UseDealerCost, Roles.dealer);

      setAllowDealerCost(permission_useDealerCost);

      if (permission_useDealerCost) {
        setPrintOption("dealerCost");
      } else {
        setPrintOption("msrp");
      }
    }
    const initialState: { [key: string]: boolean } = {};
    const result = apiStore.orderManagementApi.getPrintDetails({
      orderNum: Number(orderNum),
    });
    result.then((response) => {
      setData(response);
      data?.additionalItems.forEach((item) => {
        initialState[item.name] = false;
      });
      setCheckboxStates(initialState);
    });
  }, []);

  const handlePrint = (orderNum: number) => {
    if (printOption === "windowSticker") {
      const trueCheckboxStates = Object.fromEntries(
        Object.entries(checkboxStates).filter(([key, value]) => value)
      );
      sessionStorage.setItem(
        "selectedCheckboxes",
        JSON.stringify(trueCheckboxStates)
      );
      if (!!isLead) {
        window.open(`/leads/print/windowsticker/${orderNum}`, "_blank");
      } else {
        window.open(`/orders/print/windowsticker/${orderNum}`, "_blank");
      }
    } else {
      if (!!isLead) {
        window.open(`/leads/print?order=${orderNum}`, "_blank");
      } else {
        window.open(
          `/orders/print?orderNum=${orderNum}&type=${printOption}`,
          "_blank"
        );
      }
    }
  };

  const handleCheckboxChange = (
    event: React.SyntheticEvent,
    checked: boolean
  ) => {
    const { name } = event.target as HTMLInputElement;
    setCheckboxStates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{"Print " + label}</Typography>
        </Grid>
        <Grid item md={12}>
          <InputLabel>Select a Print Option</InputLabel>
          <Select
            size="small"
            fullWidth
            onChange={handleChange}
            value={printOption}
          >
            {DefaultCostType.map((item) => {
              //If Lead or Legacy Dealer User only show msrp / window sticker
              if (!!isLead || !!auth?.user.isLegacyDealer) {
                if (item.key === "msrp") {
                  return (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  );
                } else return <></>;
              } else {
                if (item.key !== "dealerCost" || allowDealerCost) {
                  return (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  );
                } else return <></>;
              }
            })}
            <MenuItem value="windowSticker">Window Sticker</MenuItem>
          </Select>
        </Grid>
        {printOption == "windowSticker" && (
          <Grid item xs={12}>
            {data?.additionalItems.map((item) => (
              <FormGroup aria-label="position">
                <FormControlLabel
                  labelPlacement="end"
                  label={item.name}
                  name={item.name}
                  onChange={handleCheckboxChange}
                  control={<Checkbox checked={checkboxStates[item.name]} />}
                />
              </FormGroup>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" spacing={4} justifyContent="flex-end" mt={2}>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handlePrint(orderNum)}>
              Print
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default PrintModal;
