import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  RadioGroup,
  Tooltip,
  Radio,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { ICheckbox } from "../../models/Common";
import CurrencyFormat from "../currencyFormat/CurrencyFormat";
import { calculatePriceByType } from "../../helpers/utils/utils";
import { UiWidget } from "../../helpers/enums/UiWidget";
import SelectDropdown from "../../components/select/SelectDropdown";
import { SelectInterface, ISelectOption } from "../../models/BoatOrdering";

interface checkBoxProps {
  priceType: string;
  isView?: boolean;
  checkBoxData: ICheckbox[];
  onChange: (...arg: any) => void;
  uiWidget: String;
  optionName?: string;
  salePriceMarkupOption?: any;
  msrp: number;
}

const UIWidget = ({
  priceType,
  isView,
  checkBoxData,
  onChange,
  uiWidget,
  optionName,
  salePriceMarkupOption,
  msrp,
}: checkBoxProps): JSX.Element => {
  const IDealer: SelectInterface = {
    label: "",
    size: "small",
    name: "",
  };

  const _renderCheckbox = (chk: any) => {
    return chk?.map((item: any, index: number) => {
      return (
        <React.Fragment key={index}>
          {item?.isVisible ? (
            <FormGroup className="price-label" key={index}>
              <FormControlLabel
                sx={{
                  pl: 1,
                  ml: 0,
                  opacity: item?.isDisabled ? 0.5 : 1,
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#465C8E",
                      "&.Mui-checked": {
                        color: "#465C8E",
                      },
                    }}
                    disabled={isView || item?.disabled}
                    value={item.preSelected}
                    checked={item.preSelected}
                    onChange={(e) => onChange(item.id)}
                  />
                }
                label={
                  <Typography
                    variant="subtitle2"
                    component="h2"
                    sx={{
                      gap: "20px",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{item.name}</div>
                    <span>
                      <CurrencyFormat
                        value={calculatePriceByType(
                          item.price,
                          priceType,
                          priceType == "msrp" ? msrp : salePriceMarkupOption
                        )}
                      />
                    </span>
                  </Typography>
                }
              />
            </FormGroup>
          ) : null}
        </React.Fragment>
      );
    });
  };

  const _renderColorSwatch = (item: any, index: number) => {
    return (
      <>
        {item?.isVisible ? (
          <InputLabel
            htmlFor={`radio-${item?.id}`}
            className="lable"
            sx={{ overflow: "visible" }}
          >
            <Tooltip title={item?.name} arrow>
              <>
                <Grid
                  container
                  className="colorPicker"
                  key={index}
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  p="3px 15px 3px 3px "
                  m={0}
                  sx={{
                    boxShadow: "0px 0px 2px 0px #d0d0d0f2",
                    cursor: "pointer",
                    border: item?.preSelected && `2px solid ${item?.hexcode}`,
                    background: `${item?.hexcode}20`,
                    borderRadius: "48px",
                    minWidth: "200px",
                    width: "100%",
                  }}
                >
                  <Grid item>
                    <Radio
                      className="radio-btn"
                      icon={
                        <div className="ColorPickerbox">
                          <span style={{ background: item?.hexcode }} />
                        </div>
                      }
                      checkedIcon={
                        <div className="ColorPickerbox ColorPicked">
                          <span style={{ background: item?.hexcode }} />
                        </div>
                      }
                      id={`radio-${item?.id}`}
                      sx={{ opacity: item?.isDisabled ? 0.5 : 1 }}
                      value={item?.id}
                      name="color"
                      disabled={isView || item?.isDisabled}
                      checked={item.preSelected}
                      onChange={(e) => onChange(Number(e.target.value))}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="baseline"
                    >
                      <Grid item>
                        <Typography sx={{ fontSize: 14 }} color="#353535">
                          {item?.name}
                        </Typography>
                      </Grid>
                      <Grid item p={0}>
                        <Typography
                          variant="subtitle2"
                          align="left"
                          sx={{ fontSize: 12 }}
                          mb={0}
                        >
                          <small>
                            <CurrencyFormat
                              value={calculatePriceByType(
                                item.adjustedPrice ?? item.price,
                                priceType,
                                priceType == "msrp"
                                  ? msrp
                                  : salePriceMarkupOption
                              )}
                            />
                          </small>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Tooltip>
          </InputLabel>
        ) : null}
      </>
    );
  };

  const _renderRadioButton = (rdo: any) => {
    return (
      <FormControl fullWidth={true} className="price-label" sx={{ pl: 2 }}>
        <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name={optionName}
        >
          {rdo?.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                {item?.isVisible ? (
                  <FormControlLabel
                    name={optionName}
                    value={item.id?.toString()}
                    sx={{
                      width: "100%",
                      opacity: item?.isDisabled ? 0.5 : 1,
                    }}
                    control={
                      <Radio
                        sx={{
                          color: "#465C8E",
                          "&.Mui-checked": {
                            color: "#465C8E",
                          },
                        }}
                        value={item.id?.toString()}
                      />
                    }
                    onChange={(e: any) => onChange(item.id)}
                    checked={item.preSelected}
                    disabled={isView || item?.isDisabled}
                    label={
                      <Typography
                        variant="subtitle2"
                        component="h2"
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        {item.name}
                        <span>
                          <CurrencyFormat
                            value={calculatePriceByType(
                              item.price,
                              priceType,
                              priceType == "msrp" ? msrp : salePriceMarkupOption
                            )}
                          />
                        </span>
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                ) : null}
              </React.Fragment>
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const _renderUIWidget = () => {
    let options: ISelectOption[] = [];
    let selected: ISelectOption[] = [];

    var visibledata = checkBoxData?.filter((el: any) => el?.isVisible);

    const rdo: any = visibledata?.filter(
      (el: any) =>
        el?.importKey?.toLowerCase()?.includes("rdo") ||
        el?.importKey?.toLowerCase()?.includes("cmb")
    );
    const chk: any = visibledata?.filter((el: any) =>
      el?.importKey?.toLowerCase()?.includes("chk")
    );

    for (let i in visibledata) {
      options.push({
        value: `${visibledata[i].name} ($${visibledata[i].price})`,
        key: visibledata[i].id,
        disabled: visibledata[i].isDisabled,
      });
    }

    const newSelected: any = visibledata?.filter((el: any) => el.preSelected);

    for (let i in newSelected) {
      selected.push({
        value: `${newSelected[i].name} ($${newSelected[i].price})`,
        key: newSelected[i].id,
      });
    }

    switch (uiWidget) {
      case UiWidget.ColorSwatch:
        return (
          <RadioGroup
            key="ignore-error-trick"
            row
            className="price-label"
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {visibledata?.map((item, index) => {
              return (
                <li key={index} style={{ padding: 0, margin: "0 10px 10px 0" }}>
                  {_renderColorSwatch(item, index)}
                </li>
              );
            })}
          </RadioGroup>
        );
      case UiWidget.DropdownList:
        return (
          <FormControl fullWidth className="select-dropdown">
            <div className="form-group">
              <SelectDropdown
                selectData={IDealer}
                options={options}
                handleChange={(e: any) => onChange(e.target.value)}
                value={selected[0]?.key || ""}
              />
            </div>
          </FormControl>
        );
      case UiWidget.ChoiceList:
        let list: any[] = [];
        selected?.forEach((el: ISelectOption) => {
          list.push(el.value);
        });
        return (
          <div className="form-group mt-4">
            <FormControl
              fullWidth
              className="select-dropdown"
              variant="standard"
            >
              <Select
                multiple
                displayEmpty
                value={list}
                onChange={(e: any) => {
                  let data: any[] = [];
                  options?.forEach((opt: ISelectOption) => {
                    e.target.value?.forEach((value: string) => {
                      if (opt.value === value) {
                        data.push(opt.key);
                      }
                    });
                  });
                  onChange(data);
                }}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select</em>;
                  }
                  return selected.join(", ");
                }}
              >
                {options.map((item: any) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    disabled={item?.disabled}
                  >
                    <Checkbox checked={list.indexOf(item.value) > -1} />
                    <ListItemText primary={item.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      case UiWidget.RadioCheckCombo:
        return [_renderCheckbox(chk), _renderRadioButton(rdo)];
      case UiWidget.RadioButton:
        return _renderRadioButton(visibledata);
      case UiWidget.CheckBox:
        return _renderCheckbox(visibledata);
      default:
        return null;
    }
  };

  return _renderUIWidget();
};
export default UIWidget;
