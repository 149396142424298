export enum Permissions {
  ViewBoatOrdering = "ViewBoatOrdering",

  ViewOrderManagement = "ViewOrderManagement",

  ViewUserManagement = "ViewUserManagement",
  EditUser = "EditUser",

  ViewRules = "ViewRules",
  EditRules = "EditRules",
  DeleteRules = "DeleteRules",

  ViewBoatConfiguration = "ViewBoatConfiguration",
  EditBoatConfiguration = "EditBoatConfiguration",

  ViewDealerManagement = "ViewDealerManagement",
  UseDealerCost = "UseDealerCost",

  ViewWarranty = "ViewWarranty",
  EditWarranty = "EditWarranty",

  ViewLeadsManagement = "ViewLeadsManagement",
  ViewSalesRepECDReport = "ViewSalesRepECDReport"
}

export enum Roles {
  ADMINISTRATOR = "Admin",
  DEALER = "Dealer",
  SALESREP = "Sales Rep"
}
