import React, { useCallback, useEffect, useState } from "react";
import "../../../src/components/select/Style.scss";
import { Button, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import uploadFile from "../../../src/features/config/utils/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { apiStore } from "../../../src/api/ApiFactory";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuth } from "../../../src/store/hooks/useAuth";
import { Roles } from "../../helpers/enums/AuthConstants";

interface DialogProps {
    onClose: () => void;
    orderNumber: number;
    refreshGrid: () => void;
}

interface DataItem {
    id: number;
    orderNumber: number;
    mediaId: number;
    mediaUrl: string;
    fileType: string;
}

const UploadImageModal = (props: DialogProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isChange, setIsChange] = useState<boolean>(false);
    const [errors, setErrors] = useState<string | null>("");
    const [data, setData] = useState<DataItem[]>([]);
    let isAdmin = useAuth().authuser?.user.role == Roles.ADMINISTRATOR;
    const closeModal = useCallback(() => {
        if (isChange) {
            props.refreshGrid();
        }
        props.onClose();
    }, [isChange]);
    const addDataItem = (newItem: DataItem) => {
        setData((prevData) => [...prevData, newItem]);
    };

    const UploadImageModal = (file: File) => {
        if (file !== null && file !== undefined) {
            let form = new FormData();
            form.append("file", file);

            const result = apiStore.boatConfigApi.uploadOrderHistoryImage(
                form,
                props?.orderNumber
            );
            result

                .then((response: DataItem) => {
                    addDataItem(response);
                    setIsChange(true);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => { });
        }
    };

    const handleBrowseImage = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        //After the user selects the image, it will be uploaded
        const result: any = await uploadFile(event);
        UploadImageModal(result?.file);
    };

    const handleDeleteClick = (id: number, index: number) => {
        const response: any = apiStore.orderManagementApi.removeOrderHistoryImage(
            props?.orderNumber,
            id
        );
        response.then((res: any) => {
            const updatedData = [...data];
            updatedData.splice(index, 1);
            setData(updatedData);
            setIsChange(true);
        });
    };

    useEffect(() => {
        setData([]);
        const response: any = apiStore.orderManagementApi.getOrderHistoryImages(
            props?.orderNumber
        );
        response
            .then((res: any) => {
                setData(res);
                data.map((item: DataItem) => {
                    addDataItem(item);
                });

                setIsLoading(false);
            })
            .catch((error: any) => {
                setErrors("unable to fetch model");
                setIsLoading(false);
            });

        setIsLoading(true);
        setIsChange(false);
    }, []);

    const handleImageDownload = async (id: number, fileType: string) => {
        try {
            await apiStore.boatConfigApi.getImage(id, props!.orderNumber, fileType);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const handleClick = () => {
        const inputElement = document.getElementById("imageInput");
        if (inputElement) {
            inputElement.click();
        }
    };

    return (
        <>
            <div className="dialog">
                <div className="dialog-buttons" onClick={closeModal}>
                    <CloseIcon />
                </div>
                <Grid
                    container
                    alignItems={"center"}
                    direction={"column"}
                    width={"100%"}
                >
                    <Grid item>
                        <div className="box">
                            <IconButton
                                onClick={handleClick}
                                disabled={data.length > 2 || !isAdmin}
                            >
                                <AddIcon
                                    sx={{ width: 50, height: 50 }}
                                    style={{ color: "black" }}
                                />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="text"
                            component="label"
                            disabled={data.length > 2 || !isAdmin}
                        >
                            Browse Image
                            <input
                                id="imageInput"
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={(event) => handleBrowseImage(event)}
                            />
                        </Button>
                    </Grid>
                    <Grid item>
                        <p style={{ color: "red", paddingLeft: "10px" }}>
                            Note: You cannot upload more than three images.
                        </p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
                >
                    <Grid item>
                        <div className="container">
                            {data.length > 0 &&
                                data.map((item: DataItem, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            position: "relative",
                                            display: "inline-block",
                                        }}
                                    >
                                        <img
                                            key={index}
                                            src={item.mediaUrl}
                                            alt={`Image ${index}`}
                                            style={{
                                                width: "200px",
                                                height: "200px",
                                                marginLeft: "10px",
                                            }}
                                        />
                                        <span className="icon">
                                            {isAdmin && (
                                                <IconButton
                                                    style={{ color: "white" }}
                                                    onClick={() => handleDeleteClick(item.id, index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                            <IconButton
                                                style={{ color: "white" }}
                                                onClick={() =>
                                                    handleImageDownload(item.id, item.fileType)
                                                }
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default UploadImageModal;
