import { Button, makeStyles, Popover, Typography } from "@mui/material"
import { FormEvent, ReactElement, useCallback } from "react"
import { TableInstance } from "react-table"

interface FilterPageProps<T extends Record<string, unknown>> {
    instance: TableInstance<T>
    anchorElement?: Element
    onClose: () => void
    show: boolean
}


export function FilterModal<T extends Record<string, unknown>>({
    instance,
    anchorElement,
    onClose,
    show,
  }: FilterPageProps<T>): ReactElement {
    const { allColumns, setAllFilters } = instance
  
    const onSubmit = useCallback(
      (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onClose()
      },
      [onClose]
    )
  
    const resetFilters = useCallback(() => {
      setAllFilters([])
    }, [setAllFilters])

    return (
      <div>
        <Popover
          anchorEl={anchorElement}
          id='popover-filters'
          onClose={onClose}
          open={show}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className="columns-popover">
            <Typography className="popover-title">Filters</Typography>
            <form onSubmit={onSubmit}>
              <Button
                className="filter-reset-button"
                color='primary'
                onClick={resetFilters}
                style={{ position: 'absolute' }}
              >
                Reset
              </Button>
              <div className="filter-grid">
                {allColumns
                  .filter((it) => it.canFilter && it.Header != "Action")
                  .map((column) => (
                    <div key={column.id} className="filter-cell">
                      {column.render('Filter')}
                    </div>
                  ))}
              </div>
              <Button className="table-filter-hide" type='submit'>
                &nbsp;
              </Button>
            </form>
          </div>
        </Popover>
      </div>
    )
  }
  