import { Checkbox, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, TableInstance } from "react-table";
import { apiStore } from "../../../api/ApiFactory";
import { MainCard } from "../../../components/surface/MainCard";

import { ReactTable } from "../../../components/table/ReactTable";
import { convertValue } from "../../../helpers/utils/valueConverter";
import { FinanceTermLookup, ILookups, Lookup } from "../../../models/Configuration";
import { SelectColumnFilter } from "../../../components/table/filters/SelectColumnFilter";

const Lookups = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<ILookups>();

  useEffect(() => {
    const result = apiStore.boatConfigApi.getLookups();
    result.then((response: ILookups) => {
      setPageData(response);
    });
  }, []);

  const termsColumns: Column<FinanceTermLookup>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header:"Display Name",
        accessor: "displayName",
        InLineEdit: true,
        inputType: 'text',
      },
      {
        Header:"Description",
        accessor: "description",
        InLineEdit: true,
        inputType: 'text',
      },
      {
        Header: "Designation",
        accessor: (row) => {
          return row.designationTypeName
        },
        Filter: SelectColumnFilter
      },   
      {
        Header: "Is Active",
          accessor: "isActive",
          InLineEdit: true,
          inputType: 'checkbox',
        Cell: (cell: any) => (
          <Checkbox
            className="row-checkbox"
            disabled={true}
            checked={cell.value ? true : false}
          />
        ),
      }
    ],
    []
  );

  const lookupColumns: Column<Lookup>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header:"Display Name",
        accessor: "displayName",
        InLineEdit: true,
        inputType: 'text',
      },
      {
        Header:"Description",
        accessor: "description",
        InLineEdit: true,
        inputType: 'text',
      },      
      {
        Header: "Is Active",
          accessor: "isActive",
          InLineEdit: true,
          inputType: 'checkbox',
        Cell: (cell: any) => (
          <Checkbox
            className="row-checkbox"
            disabled={true}
            checked={cell.value ? true : false}
          />
        ),
      }
    ],
    []
  );

  const onAddTermOption = useCallback(
    (instance: TableInstance) => () => {
      console.log("added being called");
      navigate("option/addterm", {
        state: {
          option: { name: "Terms Option", key: "termsOption", data: null },
        },
      });
    },
    []
  );

  const onEditTermOption = useCallback(
    (instance: TableInstance) => () => {
      const option = instance.selectedFlatRows[0].original;
      navigate("option/editterm", {
        state: {
          option: { name: "Terms Option", key: "termsOption", data: option },
        },
      });
    },
    []
  );

  const onAddRequestedDelivery = useCallback(
    (instance: TableInstance) => () => {
      console.log("added being called");
      navigate("option/add", {
        state: {
          option: {
            name: "Requested Delivery",
            key: "requestedDelivery",
            data: null,
          },
        },
      });
    },
    []
  );

  const onEditRequestedDelivery = useCallback(
    (instance: TableInstance) => () => {
      const option = instance.selectedFlatRows[0].original;
      navigate("option/edit", {
        state: {
          option: {
            name: "Requested Delivery",
            key: "requestedDelivery",
            data: option,
          },
        },
      });
    },
    []
  );

  const onAddShipping = useCallback(
    (instance: TableInstance) => () => {
      console.log("added being called");
      navigate("option/add", {
        state: { option: { name: "Shipping", key: "shipping", data: null } },
      });
    },
    []
  );

  const onEditShipping = useCallback(
    (instance: TableInstance) => () => {
      const option = instance.selectedFlatRows[0].original;
      navigate("option/edit", {
        state: { option: { name: "Shipping", key: "shipping", data: option } },
      });
    },
    []
    );

    const [isLockedoptions, setIsLockedoptions] = useState<boolean>(true);
    const [isLockeddelivery, setIsLockeddelivery] = useState<boolean>(true);
    const [isLockedshipping, setIsLockedshipping] = useState<boolean>(true);

    const handleToggleoptions = useCallback(
        (instance: TableInstance<Lookup>) => () => {
            setIsLockedoptions(prevState => !prevState);
        },
        []
    );
    const handleToggledelivery = useCallback(
        (instance: TableInstance<Lookup>) => () => {
            setIsLockeddelivery(prevState => !prevState);
        },
        []
    );
    const handleToggleshipping = useCallback(
        (instance: TableInstance<Lookup>) => () => {
            setIsLockedshipping(prevState => !prevState);
        },
        []
    );

    const updateRowOriginal = (row:any, columnId:any, newValueConverted:any) => {
        switch (columnId) {
            case "description":
                row.original.description = newValueConverted;
                break;
            case "displayName":
                row.original.displayName = newValueConverted;
                break;
            case "isActive":
                row.original.isActive = newValueConverted;
                break;
            case "excludeBoatClub":
              row.original.excludeBoatClub = newValueConverted;
              break;
            default:
                console.warn(`No update case for columnId: ${columnId}`);
                break;
        }
    };

    const onInlineEditoptions = useCallback(
        async (rowId: string | number, columnId: string, newValue: any, instance: TableInstance<FinanceTermLookup>) => {
            const row = instance.rows.find(row => row.original.id === rowId);

            if (row) {
                const oldValue = row.values[columnId];
                const newValueConverted = convertValue(oldValue, newValue);;

                // Only update if the new value is different from the old value
                if (oldValue !== newValueConverted) {
                    const updatedValues: FinanceTermLookup = {
                        ...row.original,
                        [columnId as keyof FinanceTermLookup]: newValue,
                    };
                    
                    try{
                    const result = await apiStore.boatConfigApi.saveFinanceTerm(updatedValues);
                    if (result) {
                        row.values[columnId] = newValueConverted;
                        updateRowOriginal(row, columnId, newValueConverted);
                    }
                  }
                  catch(error){console.error(error);}
                } else {
                    console.log('No change detected. Update skipped.');
                }
            } else {
                console.error('Row not found:', rowId);
            }
        },
        []
    );

    const onInlineEditdelivery = useCallback(
      async (rowId: string | number, columnId: string, newValue: any, instance: TableInstance<Lookup>) => {
          const row = instance.rows.find(row => row.original.id === rowId);

          if (row) {
              const oldValue = row.values[columnId];
              const newValueConverted = convertValue(oldValue, newValue);

              // Only update if the new value is different from the old value
              if (oldValue !== newValueConverted) {
                  const updatedValues: Lookup = {
                      ...row.original,
                      [columnId as keyof Lookup]: newValue,
                  };
                  
                  try{
                  const result = await apiStore.boatConfigApi.saveDeliveryMethod(updatedValues);
                  if (result) {
                      row.values[columnId] = newValueConverted;
                      updateRowOriginal(row, columnId, newValueConverted);
                  }
                }
                catch(error){console.error(error);}
              } else {
                  console.log('No change detected. Update skipped.');
              }
          } else {
              console.error('Row not found:', rowId);
          }
      },
      []
  );

    const onInlineEditshipping = useCallback(
    async (rowId: string | number, columnId: string, newValue: any, instance: TableInstance<Lookup>) => {
        const row = instance.rows.find(row => row.original.id === rowId);

        if (row) {
            const oldValue = row.values[columnId];
            const newValueConverted = convertValue(oldValue, newValue);

            // Only update if the new value is different from the old value
            if (oldValue !== newValueConverted) {
                const updatedValues: Lookup = {
                    ...row.original,
                    [columnId as keyof Lookup]: newValue,
                };
                try{
                const result = await apiStore.boatConfigApi.saveFreightMethod(updatedValues);
                if (result) {
                    row.values[columnId] = newValueConverted;
                    updateRowOriginal(row, columnId, newValueConverted); 
                }
              }
              catch(error){console.error(error);}
            } else {
                console.log('No change detected. Update skipped.');
            }
        } else {
            console.error('Row not found:', rowId);
        }
    },
    []
);

  return (
    <Grid container spacing={4} justifyContent="flex-start">
      <Grid item md={12} xs={12}>
        <MainCard title="Terms Options">
          <ReactTable
            name="shipping-lookups-table"
            serverOnChange={false}
            columns={termsColumns}
            data={pageData?.termOptionList ? pageData?.termOptionList : []}
            onEdit={onEditTermOption}
            onAdd={onAddTermOption}
            isLoading={false}
            inlineLock={handleToggleoptions}
            inlineLockState={isLockedoptions}
            onInlineEdit={onInlineEditoptions}
          />
        </MainCard>
      </Grid>
      <Grid item md={12} xs={12}>
        <MainCard title="Requested Delivery">
          <ReactTable
            name="shipping-lookups-table"
            serverOnChange={false}
            columns={lookupColumns}
            data={
              pageData?.requestedDeliveryList
                ? pageData?.requestedDeliveryList
                : []
            }
            onEdit={onEditRequestedDelivery}
            onAdd={onAddRequestedDelivery}
            isLoading={false}
            inlineLock={handleToggledelivery}
            inlineLockState={isLockeddelivery}
            onInlineEdit={onInlineEditdelivery}
          />
        </MainCard>
      </Grid>
      <Grid item md={12} xs={12}>
        <MainCard title="Shipping">
          <ReactTable
            name="shipping-lookups-table"
            serverOnChange={false}
            columns={lookupColumns}
            data={
              pageData?.freightMethodList ? pageData?.freightMethodList : []
            }
            onEdit={onEditShipping}
            onAdd={onAddShipping}
            isLoading={false}
            inlineLock={handleToggleshipping}
            inlineLockState={isLockedshipping}
            onInlineEdit={onInlineEditshipping}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Lookups;
