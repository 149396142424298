import {
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Stack,
  Button,
  FormHelperText,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { MainCard } from "../../components/surface/MainCard";
import { SectionBox } from "../../components/surface/SectionBox";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { apiStore } from "../../api/ApiFactory";
import { IDealer, IDealerLocation } from "../../models/Dealer";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../store/context/NotificationContext";
import PercentIcon from "@mui/icons-material/Percent";
import { useAuth } from "../../store/hooks/useAuth";
import { Roles } from "../../helpers/enums/AuthConstants";
import { DefaultCostType } from "../../helpers/enums/Common";
import DealerLocationTable from "./DealerLocationTable";
import SubmitButtonGroup from "../../components/button/SubmitButtonGroup";
import { StackLabel } from "../../components/label/StackLabel";
import { getAllDealers } from "../../store/slices/Dealer/DealerSelector";
import { setDealerList } from "../../store/slices/Dealer/DealerSlice";
import { useAppSelector } from "../../store/hooks/hook";
import { ISelectOption, SelectInterface } from "../../models/BoatOrdering";
import { NameValueEntity } from "../../models/Common";
import { DesignationType } from "../../models/Lookup";
import SelectDropdown from "../../components/select/SelectDropdown";

const GetEmptyDealer = () => {
  const dealer: IDealer = {
    name: "",
    locations: [],
    defaultCostType: "",
    additionalPricing: [],
    isAvailableForLeads: true,
    isLegacy: false,
  };

  return dealer;
};

const IDesignationType: SelectInterface = {
  label: "Designation",
  size: "small",
  name: "record.designationTypeValue"
}

const DealerEdit = () => {

  const navigate = useNavigate();

  const { id } = useParams();

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [pageData, setData] = useState<IDealer>();
  const [designationTypeList, setDesignationTypeList] = useState<Array<ISelectOption>>();

  const {
    dealers,
    designationTypes
  } = useAppSelector((state) => ({
      dealers: state.reducer.Dealers.dealerList,
      designationTypes: state.reducer.Lookup.designationTypes
  }));

  const dispatch = useDispatch();

  const { Toast } = useNotification();
  let authuser = useAuth().authuser;

  useEffect(() => {
    if (id == undefined) {
      //Only Administrators can add new dealers.
      if (authuser?.user.role === Roles.ADMINISTRATOR) {
        setDropdownData();
        setData(GetEmptyDealer);
      } else {
        navigate("/dealer");
      }
    } else {
      if (dealers.length <= 0) {
        navigate("/dealer");
      } else {
        setPageData(Number(id));
      }
    }
  }, []);

  const setPageData = (id: number) => {

    setDropdownData();
    const index = dealers.findIndex((item) => item.id === id);
    const data = { ...dealers[index] };

    setData(data);
    setCurrentIndex(index);
  };

  const setDropdownData = () => {

    if(authuser?.user.role !== Roles.DEALER) {
      let data = designationTypes.filter(x => x.isDealerType === true)
        .map(v => { return {value: v.name, key: v.value}}) as ISelectOption[];

      data.unshift({key: "", value: "None"});

      setDesignationTypeList(data);
    }
  };

  const managePricing = () => {
    navigate(`/dealer/view/${id}/additionalpricing`);
  };

  const saveData = async (formData: IDealer) => {
    let title = "Dealer profile updated successfully.";
    try {
      const response = await apiStore.dealerManagementApi.saveDealer(formData);

      const data = [...dealers];

      title = currentIndex > -1 ? title : "Dealer profile created successfully.";
      Toast.success({ title });

      if (currentIndex != -1) {
        data[currentIndex] = response;
        dispatch(setDealerList(data));
      } else {
        data.push(response);
        dispatch(setDealerList(data));
        navigate(`/dealer/view/${response.id}`, { replace: true });
      }
    } catch (error) {
      throw error;
    }
  };

  const validationSchema = Yup.object().shape({
    record: Yup.object({
      name: Yup.string()
        .max(120, "Name is to long")
        .required("Name is required"),
      defaultCostType: Yup.string().required("Default Cost Type is required"),
      salePriceMarkupModel: Yup.number()
        .min(0, "Enter a value between 0 to 99")
        .max(99, "Enter a value between 0 to 99"),
      salePriceMarkupOption: Yup.number()
        .min(0, "Enter a value between 0 to 99")
        .max(99, "Enter a value between 0 to 99"),
    }),
  });

  if (pageData === undefined) {
    return <>Loading...</>;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          record: {
            ...pageData,
            isAvailableForLeads: pageData?.isAvailableForLeads,
            isLegacy: pageData?.isLegacy,
            salePriceMarkupModel: pageData?.salePriceMarkupModel
              ? Math.trunc(pageData?.salePriceMarkupModel * 100)
              : 0,
            salePriceMarkupOption: pageData?.salePriceMarkupOption
              ? Math.trunc(pageData?.salePriceMarkupOption * 100)
              : 0,
          },
          submit: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          let salePriceMarkupModel = values.record.salePriceMarkupModel
            ? values.record.salePriceMarkupModel / 100
            : 0;

          let salePriceMarkupOption = values.record.salePriceMarkupOption
            ? values.record.salePriceMarkupOption / 100
            : 0;

          let salePriceMarkupMotor = 0;

          const formData = {
            ...values.record,
            salePriceMarkupModel,
            salePriceMarkupMotor,
            salePriceMarkupOption,
          };
          saveData(formData)
            .catch((error) => {
              console.log(error);
              if (error.response?.data?.length > 0) {
                setErrors({
                  submit:
                    error.response?.data[0].errorMessage ?? "Unexpected Error",
                });
              } else {
                setErrors({ submit: "Unexpected Error" });
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          getFieldProps,
          isSubmitting,
          setFieldValue,
          touched,
          values,
          status,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={5} xl={4}>
                <MainCard title="DEALER INFORMATION" divider={true}>
                  <SectionBox>
                    <Grid container spacing={4}>
                      {errors.submit && (
                        <Grid item xs={12}>
                          <FormHelperText error id="submit-helper">
                            {errors.submit}
                          </FormHelperText>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <InputLabel>Name *</InputLabel>
                        <TextField
                          disabled={authuser?.user.role === Roles.DEALER}
                          fullWidth
                          size="small"
                          id="name"
                          {...getFieldProps("record.name")}
                          error={Boolean(
                            touched?.record?.name && errors?.record?.name
                          )}
                        />
                        {touched?.record?.name && errors?.record?.name && (
                          <FormHelperText error>
                            {errors?.record?.name}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </SectionBox>
                  <SectionBox title="Dealer Settings" divider={true}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={12} lg={6}>
                        <InputLabel>Default Cost Type *</InputLabel>
                        <Select
                          id="defaultCostType"
                          name="record.defaultCostType"
                          size="small"
                          value={values.record.defaultCostType}
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched?.record?.defaultCostType &&
                              errors?.record?.defaultCostType
                          )}
                        >
                          <MenuItem disabled value="">
                            <em>Select Cost Type</em>
                          </MenuItem>
                          {DefaultCostType.map((item) => (
                            <MenuItem key={item.key} value={item.key}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched?.record?.defaultCostType &&
                          errors?.record?.defaultCostType && (
                            <FormHelperText error id="username-helper">
                              {errors?.record?.defaultCostType}
                            </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} lg={6}></Grid>
                      <Grid item xs={12} lg={6}>
                        <InputLabel>Sale Price Model Markup</InputLabel>
                        <OutlinedInput
                          id="salePriceMarkupModel"
                          size="small"
                          fullWidth
                          {...getFieldProps("record.salePriceMarkupModel")}
                          error={Boolean(
                            touched?.record?.salePriceMarkupModel &&
                              errors?.record?.salePriceMarkupModel
                          )}
                          endAdornment={
                            <InputAdornment position="end">
                              <PercentIcon />
                            </InputAdornment>
                          }
                        />
                        {touched?.record?.salePriceMarkupModel &&
                          errors?.record?.salePriceMarkupModel && (
                            <FormHelperText error id="username-helper">
                              {errors?.record?.salePriceMarkupModel}
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <InputLabel>Sale Price Option Markup </InputLabel>
                        <OutlinedInput
                          id="salePriceMarkup"
                          size="small"
                          fullWidth
                          {...getFieldProps("record.salePriceMarkupOption")}
                          error={Boolean(
                            touched?.record?.salePriceMarkupOption &&
                              errors?.record?.salePriceMarkupOption
                          )}
                          endAdornment={
                            <InputAdornment position="end">
                              <PercentIcon />
                            </InputAdornment>
                          }
                        />
                        {touched?.record?.salePriceMarkupOption &&
                          errors?.record?.salePriceMarkupOption && (
                            <FormHelperText error id="username-helper">
                              {errors?.record?.salePriceMarkupOption}
                            </FormHelperText>
                          )}
                      </Grid>
                      {authuser?.user.role !== Roles.DEALER && designationTypeList && (
                        <Grid item xs={12} lg={6}>
                          <SelectDropdown
                            selectData={IDesignationType}
                            options={designationTypeList}
                            value={values.record.designationTypeValue}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            error={ errors?.record?.designationTypeValue && touched?.record?.designationTypeValue 
                              ? errors?.record.designationTypeValue: "" }
                          />

                          {touched?.record?.designationTypeValue && errors?.record?.designationTypeValue && (
                            <FormHelperText error>
                              {errors?.record?.designationTypeValue}
                            </FormHelperText>
                          )}

                      </Grid>
                      )}
                      
                      <Grid item xs={12}>
                        <FormControlLabel
                          labelPlacement="end"
                          label="Available For Leads"
                          disabled={values.record.isLegacy}
                          {...getFieldProps("record.isAvailableForLeads")}
                          value={values.record.isAvailableForLeads}
                          control={
                            <Checkbox
                              checked={values.record.isAvailableForLeads}
                              onChange={(event) => {
                                setFieldValue(
                                  "record.isAvailableForLeads",
                                  event.target.checked
                                );
                              }}
                            />
                          }
                        />

                        {values.record.isAvailableForLeads && (
                          <Typography sx={{ fontSize: "11px" }}>
                            Dealer assigned to lead if location closest to lead
                            address.
                          </Typography>
                        )}
                        {values.record.isAvailableForLeads === false && (
                          <Typography sx={{ fontSize: "11px" }}>
                            Dealer will not be assigned to lead.
                          </Typography>
                        )}
                      </Grid>
                      {authuser?.user.role !== Roles.DEALER && (
                        <>
                          <Grid item xs={12}>
                            <FormControlLabel
                              labelPlacement="end"
                              label="Legacy Dealer"
                              {...getFieldProps("record.isLegacy")}
                              value={values.record.isLegacy}
                              control={
                                <Checkbox
                                  checked={values.record.isLegacy}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "record.isLegacy",
                                      event.target.checked
                                    );

                                    if(event.target.checked) {
                                      setFieldValue("record.isAvailableForLeads", false);
                                    }
                                  }}
                                />
                              }
                            />
                            <Typography sx={{ fontSize: "11px" }}>
                              Legacy Dealer has restricted access to the DMS
                            </Typography>
                        
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <SubmitButtonGroup
                      disabled={isSubmitting}
                      hideBack={authuser?.user.role === Roles.DEALER}
                    />
                  </SectionBox>
                </MainCard>
              </Grid>
              {pageData && id && (
                <Grid item xs={12} md={6} lg={5} xl={4}>
                  <MainCard title={"ADDITIONAL PRICING"}>
                    <SectionBox>
                      {pageData?.additionalPricing?.length! > 0 && (
                        <MainCard subcard title="Line Items" divider={true}>
                          <SectionBox>
                            {pageData?.additionalPricing?.map(
                              (item: any, index: any) => (
                                <StackLabel
                                  key={index}
                                  title={item.name}
                                  label={item.value}
                                  currency
                                />
                              )
                            )}
                          </SectionBox>
                        </MainCard>
                      )}
                      <Stack direction="row" mt={1}>
                        <Button onClick={managePricing}>Manage Pricing</Button>
                      </Stack>
                    </SectionBox>
                  </MainCard>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </Formik>

      {pageData && id && <DealerLocationTable dealer={pageData} />}
    </>
  );
};

export default DealerEdit;
