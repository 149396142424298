import { Autocomplete, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { MainCard } from '../../components/surface/MainCard'
import { SectionBox } from '../../components/surface/SectionBox'
import { IDealer, IDealerLocation } from '../../models/Dealer'
import * as Yup from "yup";
import { Formik, useFormik } from 'formik'
import { apiStore } from '../../api/ApiFactory'
import { useDispatch, useSelector } from 'react-redux'
import { useNotification } from '../../store/context/NotificationContext'
import { useAuth } from '../../store/hooks/useAuth'
import { Roles } from '../../helpers/enums/AuthConstants'
import { StackLabel } from '../../components/label/StackLabel'
import SubmitButtonGroup from '../../components/button/SubmitButtonGroup'
import NumericFormat from "react-number-format";
import { Country, State } from '../../models/Lookup'
import { useAppSelector } from '../../store/hooks/hook'
import { getValueByKey } from '../../helpers/utils/getEnumValueKey'
import { DefaultCostType } from '../../helpers/enums/Common'
import { setDealerList } from '../../store/slices/Dealer/DealerSlice'

const DealerLocation = () => {

    const location = useLocation();
    const state: any = location?.state ?? null;
    const dealerLocation: IDealerLocation = state?.dealerLocation ? state?.dealerLocation : null;
    let { id } = useParams();
    const [pageData, setData] = useState<IDealer>();
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const [salesRepData, setSalesRepData] = useState([]);

    const {
        dealers,
        countryList
    } = useAppSelector((state) => ({
        dealers: state.reducer.Dealers.dealerList,
        countryList: state.reducer.Lookup.countryList
    }))

    const dispatch = useDispatch();
    const { Toast } = useNotification();
    const navigate = useNavigate();
    let authuser = useAuth().authuser;

    useEffect(() => {
    
        if (id == undefined) {
            navigate('/dealer');
        }
        else {
          if(dealers.length <= 0) {
            navigate('/dealer');
          }
          else {
            setPageData(Number(id));
          }
        }
      }, [])

    const setPageData = (id: number) => {
        const index = dealers.findIndex((item) => item.id === id);
        const data = { ...dealers[index] };
    
        setCurrentIndex(index);
        setData(data);
    }

    const validationSchema = Yup.object({
        customerID: Yup.string()
            .min(4, "Customer ID is to short")
            .max(120, "Customer ID is to long")
            .required("Customer ID is required"),
        salesRepCode: Yup.string()
            .required("Sales Rep Code is required"),
        name: Yup.string()
            .max(120, "Name is to long")
            .required("Name is required"),
        address1: Yup.string()
            .max(120, "Address 1 is to long"),
        address2: Yup.string()
            .max(120, "Address 2 is to long"),
        address3: Yup.string()
            .max(120, "Address 3 is to long"),
        city: Yup.string()
            .max(120, "City is to long"),
        state: Yup.string()
            .required("State is required")
            .max(120, "State is to long"),
        zipCode: Yup.string()
            .max(120, "Zip Code is to long"),
        country: Yup.string()
            .nullable()
            .required("Country is required"),

    })

    const initialValues = {
        id: dealerLocation?.id ? dealerLocation.id : null,
        customerID: dealerLocation?.customerID ? dealerLocation?.customerID : "",
        salesRepCode: dealerLocation?.salesRepCode ? dealerLocation?.salesRepCode : "",
        name: dealerLocation?.name ? dealerLocation?.name : "",
        address1: dealerLocation?.address1 ? dealerLocation?.address1 : "",
        address2: dealerLocation?.address2 ? dealerLocation?.address2 : "",
        address3: dealerLocation?.address2 ? dealerLocation?.address2 : "",
        city: dealerLocation?.city ? dealerLocation?.city : "",
        state: dealerLocation?.state ? dealerLocation?.state : null,
        zipCode: dealerLocation?.zipCode ? dealerLocation?.zipCode : "",
        country: dealerLocation?.country ? dealerLocation?.country : "US",
        isActive: dealerLocation?.isActive ? dealerLocation?.isActive : true,
        phone: dealerLocation?.phone ? dealerLocation?.phone : "",
        submit: null
    }


    useEffect(() => {
        const result = apiStore.dealerManagementApi.getSalesRepCode();
        result.then((response) => {
            setSalesRepData(response);
        })

    }, [])

    const saveData = async (formData: any) => {
        try {
            const result = await apiStore.dealerManagementApi.saveDealerLocation(formData);
            let title = "";
            if (id) {
                let data = dealers.slice();
                const dealerIndex = data.findIndex((dealer: any) => dealer.id === Number(id));
                const locationIndex = data[dealerIndex].locations!.findIndex((location) => location.id === result.id);
                if (locationIndex >= 0) {
                    const locations = [...data[dealerIndex].locations!];
                    locations[locationIndex] = { ...result };
                    const dealerData = { ...data[dealerIndex] };
                    data.splice(dealerIndex, 1, { ...dealerData, locations })
                    title = "location updated successfully";
                }
                else {
                    const locations = [...data[dealerIndex].locations!];
                    locations.push({ ...result });
                    const dealerData = { ...data[dealerIndex] };
                    data.splice(dealerIndex, 1, { ...dealerData, locations })
                    title = "location added successfully";
                }
                dispatch(setDealerList(data));
                Toast.success({ title });
                navigate(-1);
            }
        }
        catch (error) {
            Toast.error({
                title: "Error occured",
            });
        }
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={5} xl={4}>
                    <MainCard title='DEALER INFORMATION' divider={true}>
                            <SectionBox>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {pageData && ( 
                                            <MainCard subcard title="Details" divider={true}>
                                                <SectionBox>
                                                    <StackLabel title="Dealer Name" label={pageData.name} />
                                                    <StackLabel title="Default Cost Type" label={pageData.defaultCostType != "" ? getValueByKey(pageData.defaultCostType!, DefaultCostType) : "Not Set" } />
                                                    <StackLabel title="Sale Price Markup - Model" label={pageData.salePriceMarkupModel ? Math.trunc(pageData?.salePriceMarkupModel * 100) + "%" : "Not Set"} />
                                                    <StackLabel title="Sale Price Markup - Option" label={pageData.salePriceMarkupOption ? Math.trunc(pageData?.salePriceMarkupOption * 100) + "%" : "Not Set"} />
                                                    <StackLabel title="Available for Leads" label={pageData.isAvailableForLeads ? "Yes" : "No"} />
                                                </SectionBox>
                                            </MainCard>
                                        )}
                                    </Grid>
                                </Grid>
                            </SectionBox>
                        </MainCard>
                </Grid>
                <Grid item xs={12} lg={7} xl={8}>
                    <MainCard title="LOCATION" divider={true}>
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values: any, {setErrors, setSubmitting}) => {
                                const formData = { dealerId: id, address2: "", ...values };
                                var promise = saveData(formData);
                                promise.then((response) => {
                                    
                                })
                                .catch((error) => {

                                })
                                .finally(() => {
                                    setSubmitting(false)
                                })
                            }}
                        >
                            
                            {({
                                errors,
                                handleChange,
                                handleSubmit,
                                getFieldProps,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values,
                                status,
                                handleBlur
                            }) => (


                                <form noValidate onSubmit={handleSubmit}>
                                    <SectionBox>
                                        <Grid container spacing={2}>
                                            {errors.submit && (
                                                <Grid item xs={12}>
                                                    <FormHelperText error id="submit-helper">
                                                    {errors.submit}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            id="isActive"
                                                            name="isActive"
                                                            disabled = { authuser?.user.role === Roles.DEALER }
                                                            checked={values.isActive}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label="Active"
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <InputLabel> Customer Id * </InputLabel>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    id="customerID"
                                                    disabled = { authuser?.user.role === Roles.DEALER }
                                                    {...getFieldProps("customerID")}
                                                    error={Boolean(touched?.customerID && errors?.customerID )}
                                                />
                                                {touched?.customerID && errors?.customerID && (
                                                    <FormHelperText error>
                                                        {errors?.customerID}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            {salesRepData && salesRepData.length > 0 && (
                                                <Grid item xs={12} lg={6}>
                                                    <InputLabel htmlFor="salesRepCode">Sales Rep Code * </InputLabel>
                                                    <Select
                                                        id="salesRepCode"
                                                        name="salesRepCode"
                                                        size="small"
                                                        value={values.salesRepCode}
                                                        fullWidth
                                                        disabled={authuser?.user.role === Roles.DEALER}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched?.salesRepCode && errors?.salesRepCode )}
                                                    >
                                                        <MenuItem disabled value=""><em>Select Sales Rep Code</em></MenuItem>
                                                        {
                                                            salesRepData.map((item:any , index:number) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                                                        }
                                                    </Select>
                                                    {touched?.salesRepCode && errors?.salesRepCode && (
                                                        <FormHelperText error>
                                                            {errors?.salesRepCode}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            )}
                                            
                                            <Grid item xs={12} lg={6}>
                                                <InputLabel>
                                                    Name *
                                                </InputLabel>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    id=""
                                                    disabled = { authuser?.user.role === Roles.DEALER }
                                                    {...getFieldProps("name")}
                                                    error={Boolean(
                                                        touched?.name && errors?.name
                                                    )}

                                                />
                                                {touched?.name && errors?.name && (
                                                    <FormHelperText error id="username-helper">
                                                        {errors?.name}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </SectionBox>
                                    <SectionBox title="Contact Information" divider={true}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container spacing ={2}>
                                            <Grid item xs={12} lg={6}>
                                                        <InputLabel htmlFor="phone">Phone</InputLabel>
                                                        <NumericFormat
                                                            id="phone"
                                                            fullWidth
                                                            size="small"
                                                            {...getFieldProps("phone")}
                                                            value={values.phone}
                                                            format="(###)-###-####"
                                                            customInput={TextField}
                                                            placeholder="(###)-###-####"
                                                        />
                                                        {touched?.phone && errors?.phone && (
                                                            <FormHelperText error>
                                                                {errors?.phone}
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <InputLabel>Address Line 1 *</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            id="address1"
                                                            {...getFieldProps("address1")}
                                                            error={Boolean(
                                                                touched?.address1 && errors?.address1
                                                            )}
                                                        />
                                                        {touched?.address1 && errors?.address1 && (
                                                            <FormHelperText error>
                                                                {errors?.address1}
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputLabel>Address Line 2</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            id="address2"
                                                            {...getFieldProps("address2")}
                                                            error={Boolean(
                                                                touched?.address2 && errors?.address2
                                                            )}
                                                        />
                                                        {touched?.address2 && errors?.address2 && (
                                                            <FormHelperText error>
                                                                {errors?.address2}
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputLabel>Address Line 3</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            id="address3"
                                                            {...getFieldProps("address3")}
                                                            error={Boolean(
                                                                touched?.address3 && errors?.address3
                                                            )}
                                                        />
                                                        {touched?.address3 && errors?.address3 && (
                                                            <FormHelperText error>
                                                                {errors?.address3}
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputLabel>City *</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            id="city"
                                                            {...getFieldProps("city")}
                                                            error={Boolean(
                                                                touched?.city && errors?.city
                                                            )}
                                                        />
                                                        {touched?.city && errors?.city && (
                                                            <FormHelperText error>
                                                                {errors?.city}
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Grid container spacing={2}>
                                                    
                                                    
                                                    <Grid item xs={12}>
                                                        <InputLabel>State</InputLabel>
                                                        <Autocomplete 
                                                            id="state"
                                                            size="small"
                                                            value={
                                                                countryList?.find((x) => x.code === values.country)?.states.find((y) => y.value === values.state) ?? null
                                                            }
                                                            
                                                            //Key is set to country in order to refresh this control if country changes.
                                                            key={values.country}
                                                            options={
                                                                countryList?.find(
                                                                    (x) => x.code == values.country
                                                                )?.states ?? []
                                                            }
                                                            fullWidth
                                                            getOptionLabel={(option: State) => option.name} 
                                                            renderInput={(params) => (
                                                                <TextField {...params} name={values.state} />
                                                            )}
                                                            onChange={(event, value: State | null) => {
                                                                setFieldValue("state", value?.value ?? "");
                                                            }}                                        
                                                        />                                       
                                                        {touched?.state && errors?.state && (
                                                            <FormHelperText error>
                                                                {errors?.state}
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputLabel htmlFor="country">Country *</InputLabel>
                                                        <Autocomplete 
                                                            id="country"
                                                            size="small"
                                                            options={countryList!}
                                                            value={
                                                                countryList!.find((x) => x.code == values.country) 
                                                                ??
                                                                null
                                                            }
                                                            getOptionLabel={(option: Country) => option.name} 
                                                            renderInput={(params) => (
                                                                <TextField {...params} name={values.country} />
                                                            )}
                                                            onChange={(event, value: Country | null) => {
                                                                setFieldValue("country", value?.code ?? "");
                                                                setFieldValue("state", "")

                                                            }}                                        
                                                        />
                                                        {touched?.country && errors?.country && (
                                                            <FormHelperText error>
                                                                {errors?.country}
                                                            </FormHelperText>
                                                        )}  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputLabel>Zip Code *</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            id="zipCode"
                                                            {...getFieldProps("zipCode")}
                                                            error={Boolean(
                                                                touched?.zipCode && errors?.zipCode
                                                            )}
                                                        />
                                                        {touched?.zipCode && errors?.zipCode && (
                                                            <FormHelperText error>
                                                                {errors?.zipCode}
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <SubmitButtonGroup disabled={isSubmitting} />
                                    </SectionBox>
                                </form>
                            )}
                        </Formik>
                    </MainCard>
                </Grid>
            </Grid>
            
        </>
    )
}

export default DealerLocation