import { useSelector } from "react-redux";
import { IAuthUser } from "../../../models/Auth";
import { selectAuthUser } from "../../../store/slices/Auth/AuthSelector";
import { Button, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { DefaultCostType, PrintFormatType } from "../../../helpers/enums/Common";

type IProp = {
    model: any;
    handleClose: () => void,
    handlePrint: (type: string, format: string) => void,
    label: string
}

const ModelPrintModal = ({model, handleClose, handlePrint, label} : IProp) => {

    const auth: IAuthUser | null = useSelector(selectAuthUser);
    const [printOption, setPrintOption] = useState('');
    const [printFormatOption, setprintFormatOption] = useState('');

    const handleChange = (event:SelectChangeEvent)=>{
        setPrintOption(event.target.value as string);
    }
    const handlePrintFormatChange = (event: SelectChangeEvent) => {
        setprintFormatOption(event.target.value as string);
    }

    const isButtonDisabled: boolean = printFormatOption === '' || printOption === '';

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">{"Print " + label}</Typography>
                </Grid>
                <Grid item md={12}>
                    <InputLabel>Select a Print Option</InputLabel>
                    <Select
                        size="small"
                        fullWidth
                        onChange={handlePrintFormatChange}
                        value={printFormatOption}
                    >
                        {
                            PrintFormatType.filter(x => x.key).map((item) => {
                                return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                            })
                        }
                    </Select>
                </Grid>
                <Grid item md={12}>
                    <InputLabel>Select a Pricing Option</InputLabel>
                    <Select
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        value={printOption}
                        >
                        {
                            DefaultCostType.filter(x => x.key !== "salesPrice").map((item)=> { 
                                return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                            })
                        } 
                    </Select>
                </Grid>                
                <Grid item xs={12}>
                    <Stack direction="row" spacing={4} justifyContent="flex-end" mt={2}>
                        <Button variant="outlined" color="secondary" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={() => handlePrint(printOption, printFormatOption)} disabled={isButtonDisabled}>{printFormatOption === '' ? 'Print' : printFormatOption}</Button>
                    </Stack>
                </Grid>
            </Grid>
                   
            
        </>
      )

}



export default ModelPrintModal;